import { z } from "zod";

export const submitReferralFormSchema = z.object({
  // referrer info
  referrerFirstName: z.string().min(1, { message: "Required" }),
  referrerLastName: z.string().min(1, { message: "Required" }),
  referrerEmail: z
    .string()
    .min(1, "Required")
    .email({ message: "Invalid email" }),
  // candidate info
  jobId: z.string().min(1, { message: "Required" }),
  candidateFirstName: z.string().min(1, { message: "Required" }),
  candidateLastName: z.string().min(1, { message: "Required" }),
  candidateEmail: z
    .string()
    .min(1, "Required")
    .email({ message: "Invalid email" }),
  candidateLinkedinUrl: z
    .string()
    .regex(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/.+/, {
      message: "Invalid LinkedIn URL",
    })
    .max(200, { message: "LinkedIn URL cannot be more than 200 characters long" })
    .optional()
    .or(z.literal("")),
  resume: z.nullable(z.instanceof(File)).optional(),
  additionalComments: z.string().max(500, { message: "Must be less than 500 characters" }),
});

export type SubmitReferralFormSchemaType = z.infer<typeof submitReferralFormSchema>;
