import { createEntityAdapter, SerializedError } from "@reduxjs/toolkit";
import { EntityState } from "@reduxjs/toolkit/src/entities/models";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import {
  CAREERS_PAGE_JOB,
  CAREERS_PAGE_JOB_COUNT,
  EDIT_CAREERS_PAGE_JOB,
  LIST_TAG,
} from "services/doverapi/endpointTagsConstants";
import { ApiApiListEditCareersPageJobsRequest, CareersPageJob, PublishedJobCount } from "services/openapi";
import { PUBLISH_JOBS_PAGE_LIMIT } from "views/EditCareersPage/constants";

const careersPageJobAdapter = createEntityAdapter<CareersPageJob>();

interface ListCareersPageJobsResponse {
  results: CareersPageJob[];
  count: number;
}

const careersPageJob = doverApi.injectEndpoints({
  endpoints: build => ({
    listCareersPageJob: build.query<EntityState<CareersPageJob>, { clientId: string; offset?: number; limit?: number }>(
      {
        queryFn: async ({ clientId, offset = 0, limit = 300 }) => {
          // get unauthenticated api client by setting includeToken to false
          const { apiApi: client } = await getOpenApiClients({ includeToken: false });
          try {
            const response = await client.getCareersPageJobs({ clientId, offset, limit });
            return { data: careersPageJobAdapter.addMany(careersPageJobAdapter.getInitialState(), response.results) };
          } catch (error) {
            return {
              error: {
                serializedError: error as SerializedError,
              },
            };
          }
        },
        providesTags: (result, error, arg) => {
          return [{ type: CAREERS_PAGE_JOB, id: arg.clientId }];
        },
      }
    ),
    listEditCareersPageJobs: build.query<ListCareersPageJobsResponse, ApiApiListEditCareersPageJobsRequest>({
      queryFn: async ({ clientId, offset = 0, limit = PUBLISH_JOBS_PAGE_LIMIT }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const response = await client.listEditCareersPageJobs({ clientId, offset, limit });
          return {
            data: { results: response.results, count: response.count },
          };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: () => {
        return [{ type: EDIT_CAREERS_PAGE_JOB, id: LIST_TAG }];
      },
    }),
    getPublishedJobCount: build.query<PublishedJobCount, {}>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const response = await client.getPublishedJobCount();
          return { data: response };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: () => {
        return [{ type: CAREERS_PAGE_JOB_COUNT, id: LIST_TAG }];
      },
    }),
  }),
});

export const {
  useListCareersPageJobQuery,
  useListEditCareersPageJobsQuery,
  useGetPublishedJobCountQuery,
} = careersPageJob;
