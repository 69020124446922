/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActivityFeedItem,
    ActivityFeedItemFromJSON,
    ActivityFeedItemToJSON,
    AddCandidateInitialOutreachBulkRequest,
    AddCandidateInitialOutreachBulkRequestFromJSON,
    AddCandidateInitialOutreachBulkRequestToJSON,
    AddCandidateInitialOutreachBulkResponse,
    AddCandidateInitialOutreachBulkResponseFromJSON,
    AddCandidateInitialOutreachBulkResponseToJSON,
    AddCandidateInitialOutreachResponse,
    AddCandidateInitialOutreachResponseFromJSON,
    AddCandidateInitialOutreachResponseToJSON,
    AddCandidateInterviewProcessResponse,
    AddCandidateInterviewProcessResponseFromJSON,
    AddCandidateInterviewProcessResponseToJSON,
    AddQuestionsToInterviewRubricResponseRequest,
    AddQuestionsToInterviewRubricResponseRequestFromJSON,
    AddQuestionsToInterviewRubricResponseRequestToJSON,
    AdminCampaign,
    AdminCampaignFromJSON,
    AdminCampaignToJSON,
    AdminProfileAttribute,
    AdminProfileAttributeFromJSON,
    AdminProfileAttributeToJSON,
    AdminSearch,
    AdminSearchFromJSON,
    AdminSearchToJSON,
    AdminSearchTransformRelationship,
    AdminSearchTransformRelationshipFromJSON,
    AdminSearchTransformRelationshipToJSON,
    ApplicationPortalInboundApplication,
    ApplicationPortalInboundApplicationFromJSON,
    ApplicationPortalInboundApplicationToJSON,
    ApplicationPortalJob,
    ApplicationPortalJobFromJSON,
    ApplicationPortalJobToJSON,
    ApplicationQuestion,
    ApplicationQuestionFromJSON,
    ApplicationQuestionToJSON,
    ArchiveReason,
    ArchiveReasonFromJSON,
    ArchiveReasonToJSON,
    AtsData,
    AtsDataFromJSON,
    AtsDataToJSON,
    AtsInterviewFeedbackTemplate,
    AtsInterviewFeedbackTemplateFromJSON,
    AtsInterviewFeedbackTemplateToJSON,
    AtsJobStages,
    AtsJobStagesFromJSON,
    AtsJobStagesToJSON,
    AuthMetabaseUserResponse,
    AuthMetabaseUserResponseFromJSON,
    AuthMetabaseUserResponseToJSON,
    AuthUser,
    AuthUserFromJSON,
    AuthUserToJSON,
    AuthedUser,
    AuthedUserFromJSON,
    AuthedUserToJSON,
    AuthorizedEmailSenderAlias,
    AuthorizedEmailSenderAliasFromJSON,
    AuthorizedEmailSenderAliasToJSON,
    AutoformatInterviewRubricRequest,
    AutoformatInterviewRubricRequestFromJSON,
    AutoformatInterviewRubricRequestToJSON,
    BaseCandidateActionResponse,
    BaseCandidateActionResponseFromJSON,
    BaseCandidateActionResponseToJSON,
    BaseCandidateActionResponseError,
    BaseCandidateActionResponseErrorFromJSON,
    BaseCandidateActionResponseErrorToJSON,
    BaseClientCampaignLimit,
    BaseClientCampaignLimitFromJSON,
    BaseClientCampaignLimitToJSON,
    BeginBillingPortalSessionRequest,
    BeginBillingPortalSessionRequestFromJSON,
    BeginBillingPortalSessionRequestToJSON,
    BeginBillingPortalSessionResponse,
    BeginBillingPortalSessionResponseFromJSON,
    BeginBillingPortalSessionResponseToJSON,
    BeginCheckoutRequest,
    BeginCheckoutRequestFromJSON,
    BeginCheckoutRequestToJSON,
    BeginCheckoutResponse,
    BeginCheckoutResponseFromJSON,
    BeginCheckoutResponseToJSON,
    BenchmarkChannelPersonaStats,
    BenchmarkChannelPersonaStatsFromJSON,
    BenchmarkChannelPersonaStatsToJSON,
    BulkActionEmailTemplate,
    BulkActionEmailTemplateFromJSON,
    BulkActionEmailTemplateToJSON,
    BulkCampaignUpdate,
    BulkCampaignUpdateFromJSON,
    BulkCampaignUpdateToJSON,
    BulkCampaignUpdateResult,
    BulkCampaignUpdateResultFromJSON,
    BulkCampaignUpdateResultToJSON,
    BulkUpsertClientDomain,
    BulkUpsertClientDomainFromJSON,
    BulkUpsertClientDomainToJSON,
    BulkUpsertClientDomainResponse,
    BulkUpsertClientDomainResponseFromJSON,
    BulkUpsertClientDomainResponseToJSON,
    BulkUpsertJobFeatureSetting,
    BulkUpsertJobFeatureSettingFromJSON,
    BulkUpsertJobFeatureSettingToJSON,
    CalibrationCandidate,
    CalibrationCandidateFromJSON,
    CalibrationCandidateToJSON,
    Campaign,
    CampaignFromJSON,
    CampaignToJSON,
    CampaignMessageRequest,
    CampaignMessageRequestFromJSON,
    CampaignMessageRequestToJSON,
    CampaignSendTestEmailsRequest,
    CampaignSendTestEmailsRequestFromJSON,
    CampaignSendTestEmailsRequestToJSON,
    CampaignStats,
    CampaignStatsFromJSON,
    CampaignStatsToJSON,
    CanSourceProfileRequest,
    CanSourceProfileRequestFromJSON,
    CanSourceProfileRequestToJSON,
    CanSourceProfileResponse,
    CanSourceProfileResponseFromJSON,
    CanSourceProfileResponseToJSON,
    CancelEmailRequest,
    CancelEmailRequestFromJSON,
    CancelEmailRequestToJSON,
    CancelMultipartInterviewRequest,
    CancelMultipartInterviewRequestFromJSON,
    CancelMultipartInterviewRequestToJSON,
    CandidateActivityNote,
    CandidateActivityNoteFromJSON,
    CandidateActivityNoteToJSON,
    CandidateBio,
    CandidateBioFromJSON,
    CandidateBioToJSON,
    CandidateCSVExportResponse,
    CandidateCSVExportResponseFromJSON,
    CandidateCSVExportResponseToJSON,
    CandidateFiles,
    CandidateFilesFromJSON,
    CandidateFilesToJSON,
    CandidateMultipartInterview,
    CandidateMultipartInterviewFromJSON,
    CandidateMultipartInterviewToJSON,
    CandidateNextAction,
    CandidateNextActionFromJSON,
    CandidateNextActionToJSON,
    CandidatePipelineCountRequest,
    CandidatePipelineCountRequestFromJSON,
    CandidatePipelineCountRequestToJSON,
    CandidatePipelineCountResponse,
    CandidatePipelineCountResponseFromJSON,
    CandidatePipelineCountResponseToJSON,
    CandidatePipelineListRequest,
    CandidatePipelineListRequestFromJSON,
    CandidatePipelineListRequestToJSON,
    CandidatePipelineListResponse,
    CandidatePipelineListResponseFromJSON,
    CandidatePipelineListResponseToJSON,
    CanonicalCompany,
    CanonicalCompanyFromJSON,
    CanonicalCompanyToJSON,
    CanonicalCompanyNotFound,
    CanonicalCompanyNotFoundFromJSON,
    CanonicalCompanyNotFoundToJSON,
    CareersPageClient,
    CareersPageClientFromJSON,
    CareersPageClientToJSON,
    ChangeAppScoringEnabledRequest,
    ChangeAppScoringEnabledRequestFromJSON,
    ChangeAppScoringEnabledRequestToJSON,
    ChangeAppScoringEnabledResponse,
    ChangeAppScoringEnabledResponseFromJSON,
    ChangeAppScoringEnabledResponseToJSON,
    ChangeArchiveReasonRequest,
    ChangeArchiveReasonRequestFromJSON,
    ChangeArchiveReasonRequestToJSON,
    ChangeInterviewerRequest,
    ChangeInterviewerRequestFromJSON,
    ChangeInterviewerRequestToJSON,
    ChangeLogEntry,
    ChangeLogEntryFromJSON,
    ChangeLogEntryToJSON,
    CheckCanCreateManualContextForJobRequest,
    CheckCanCreateManualContextForJobRequestFromJSON,
    CheckCanCreateManualContextForJobRequestToJSON,
    CheckCanCreateManualContextForJobResponse,
    CheckCanCreateManualContextForJobResponseFromJSON,
    CheckCanCreateManualContextForJobResponseToJSON,
    CheckIfProfileExistsRequest,
    CheckIfProfileExistsRequestFromJSON,
    CheckIfProfileExistsRequestToJSON,
    CheckIfProfileExistsResponse,
    CheckIfProfileExistsResponseFromJSON,
    CheckIfProfileExistsResponseToJSON,
    Client,
    ClientFromJSON,
    ClientToJSON,
    ClientEmailTemplate,
    ClientEmailTemplateFromJSON,
    ClientEmailTemplateToJSON,
    ClientImage,
    ClientImageFromJSON,
    ClientImageToJSON,
    ClientInterviewer,
    ClientInterviewerFromJSON,
    ClientInterviewerToJSON,
    ClientOnboarding,
    ClientOnboardingFromJSON,
    ClientOnboardingToJSON,
    ClientUserFileStats,
    ClientUserFileStatsFromJSON,
    ClientUserFileStatsToJSON,
    CloneSearchV3Response,
    CloneSearchV3ResponseFromJSON,
    CloneSearchV3ResponseToJSON,
    CombinedFunnelStats,
    CombinedFunnelStatsFromJSON,
    CombinedFunnelStatsToJSON,
    Company,
    CompanyFromJSON,
    CompanyToJSON,
    CompanyList,
    CompanyListFromJSON,
    CompanyListToJSON,
    CompanySetupSteps,
    CompanySetupStepsFromJSON,
    CompanySetupStepsToJSON,
    CompleteCheckoutRequest,
    CompleteCheckoutRequestFromJSON,
    CompleteCheckoutRequestToJSON,
    ConfirmJobPersonaRequest,
    ConfirmJobPersonaRequestFromJSON,
    ConfirmJobPersonaRequestToJSON,
    Contact,
    ContactFromJSON,
    ContactToJSON,
    ContactEmail,
    ContactEmailFromJSON,
    ContactEmailToJSON,
    ContactPhoneNumber,
    ContactPhoneNumberFromJSON,
    ContactPhoneNumberToJSON,
    Country,
    CountryFromJSON,
    CountryToJSON,
    CreateCampaign,
    CreateCampaignFromJSON,
    CreateCampaignToJSON,
    CreateChangeLogForSearchResponse,
    CreateChangeLogForSearchResponseFromJSON,
    CreateChangeLogForSearchResponseToJSON,
    CreateContactEmailsRequest,
    CreateContactEmailsRequestFromJSON,
    CreateContactEmailsRequestToJSON,
    CreateContactPhoneNumbersRequest,
    CreateContactPhoneNumbersRequestFromJSON,
    CreateContactPhoneNumbersRequestToJSON,
    CreateDtnCandidateRequest,
    CreateDtnCandidateRequestFromJSON,
    CreateDtnCandidateRequestToJSON,
    CreateFeedbackTemplateRequest,
    CreateFeedbackTemplateRequestFromJSON,
    CreateFeedbackTemplateRequestToJSON,
    CreateHiringPipelineStageRequest,
    CreateHiringPipelineStageRequestFromJSON,
    CreateHiringPipelineStageRequestToJSON,
    CreateJobCandidateSourceSetting,
    CreateJobCandidateSourceSettingFromJSON,
    CreateJobCandidateSourceSettingToJSON,
    CreateJobCandidateSourceSettingError,
    CreateJobCandidateSourceSettingErrorFromJSON,
    CreateJobCandidateSourceSettingErrorToJSON,
    CreateJobDescriptionWithTextRequest,
    CreateJobDescriptionWithTextRequestFromJSON,
    CreateJobDescriptionWithTextRequestToJSON,
    CreateJobDescriptionWithTextResponse,
    CreateJobDescriptionWithTextResponseFromJSON,
    CreateJobDescriptionWithTextResponseToJSON,
    CreateNewJobRequest,
    CreateNewJobRequestFromJSON,
    CreateNewJobRequestToJSON,
    CreateNewJobResponse,
    CreateNewJobResponseFromJSON,
    CreateNewJobResponseToJSON,
    CreateReengagementTransformRequest,
    CreateReengagementTransformRequestFromJSON,
    CreateReengagementTransformRequestToJSON,
    CreateReengagementTransformResponseError,
    CreateReengagementTransformResponseErrorFromJSON,
    CreateReengagementTransformResponseErrorToJSON,
    CreateReengagementTransformResponseSuccess,
    CreateReengagementTransformResponseSuccessFromJSON,
    CreateReengagementTransformResponseSuccessToJSON,
    CurrentUserOnboardingStepResponse,
    CurrentUserOnboardingStepResponseFromJSON,
    CurrentUserOnboardingStepResponseToJSON,
    DashboardJob,
    DashboardJobFromJSON,
    DashboardJobToJSON,
    DeactivateJob,
    DeactivateJobFromJSON,
    DeactivateJobToJSON,
    DeactivationStats,
    DeactivationStatsFromJSON,
    DeactivationStatsToJSON,
    DepthRequest,
    DepthRequestFromJSON,
    DepthRequestToJSON,
    DepthResponse,
    DepthResponseFromJSON,
    DepthResponseToJSON,
    DiversityOption,
    DiversityOptionFromJSON,
    DiversityOptionToJSON,
    DoverEntityRoute,
    DoverEntityRouteFromJSON,
    DoverEntityRouteToJSON,
    DoverJobDescription,
    DoverJobDescriptionFromJSON,
    DoverJobDescriptionToJSON,
    DoverRole,
    DoverRoleFromJSON,
    DoverRoleToJSON,
    DoverUser,
    DoverUserFromJSON,
    DoverUserToJSON,
    EducationLevelsResponse,
    EducationLevelsResponseFromJSON,
    EducationLevelsResponseToJSON,
    Email,
    EmailFromJSON,
    EmailToJSON,
    EmailTemplateV2,
    EmailTemplateV2FromJSON,
    EmailTemplateV2ToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    EsSearchCountsRequest,
    EsSearchCountsRequestFromJSON,
    EsSearchCountsRequestToJSON,
    ExchangeEmailIdForEmailRequest,
    ExchangeEmailIdForEmailRequestFromJSON,
    ExchangeEmailIdForEmailRequestToJSON,
    ExchangeEmailIdForEmailResponse,
    ExchangeEmailIdForEmailResponseFromJSON,
    ExchangeEmailIdForEmailResponseToJSON,
    ExtensionRecordActionRequest,
    ExtensionRecordActionRequestFromJSON,
    ExtensionRecordActionRequestToJSON,
    ExtensionRecordActionResponse,
    ExtensionRecordActionResponseFromJSON,
    ExtensionRecordActionResponseToJSON,
    ExtensionV2FindEmailsRequest,
    ExtensionV2FindEmailsRequestFromJSON,
    ExtensionV2FindEmailsRequestToJSON,
    ExtensionV2FindEmailsResponse,
    ExtensionV2FindEmailsResponseFromJSON,
    ExtensionV2FindEmailsResponseToJSON,
    ExternalApplicantRequest,
    ExternalApplicantRequestFromJSON,
    ExternalApplicantRequestToJSON,
    ExternalApplicantResponse,
    ExternalApplicantResponseFromJSON,
    ExternalApplicantResponseToJSON,
    FeatureFlags,
    FeatureFlagsFromJSON,
    FeatureFlagsToJSON,
    FeedbackTemplateDetail,
    FeedbackTemplateDetailFromJSON,
    FeedbackTemplateDetailToJSON,
    FeedbackTemplateErrorResponse,
    FeedbackTemplateErrorResponseFromJSON,
    FeedbackTemplateErrorResponseToJSON,
    FeedbackTemplateListResponse,
    FeedbackTemplateListResponseFromJSON,
    FeedbackTemplateListResponseToJSON,
    FindAtsCandidateRequest,
    FindAtsCandidateRequestFromJSON,
    FindAtsCandidateRequestToJSON,
    FindAtsCandidateResponse,
    FindAtsCandidateResponseFromJSON,
    FindAtsCandidateResponseToJSON,
    FindDupeContactResponse,
    FindDupeContactResponseFromJSON,
    FindDupeContactResponseToJSON,
    FormatAiAnswerRequest,
    FormatAiAnswerRequestFromJSON,
    FormatAiAnswerRequestToJSON,
    FormatAiAnswerResponse,
    FormatAiAnswerResponseFromJSON,
    FormatAiAnswerResponseToJSON,
    FrontCandidate,
    FrontCandidateFromJSON,
    FrontCandidateToJSON,
    FrontHiringPipelineStage,
    FrontHiringPipelineStageFromJSON,
    FrontHiringPipelineStageToJSON,
    GenerateAndSaveCampaignContentResult,
    GenerateAndSaveCampaignContentResultFromJSON,
    GenerateAndSaveCampaignContentResultToJSON,
    GenerateCareersPageError,
    GenerateCareersPageErrorFromJSON,
    GenerateCareersPageErrorToJSON,
    GenerateCareersPageRequest,
    GenerateCareersPageRequestFromJSON,
    GenerateCareersPageRequestToJSON,
    GenerateCareersPageResponse,
    GenerateCareersPageResponseFromJSON,
    GenerateCareersPageResponseToJSON,
    GenerateJobDescriptionError,
    GenerateJobDescriptionErrorFromJSON,
    GenerateJobDescriptionErrorToJSON,
    GenerateJobDescriptionRequest,
    GenerateJobDescriptionRequestFromJSON,
    GenerateJobDescriptionRequestToJSON,
    GenerateJobDescriptionResponse,
    GenerateJobDescriptionResponseFromJSON,
    GenerateJobDescriptionResponseToJSON,
    GenerateOutreachNoProfileRequest,
    GenerateOutreachNoProfileRequestFromJSON,
    GenerateOutreachNoProfileRequestToJSON,
    GenerateOutreachNoProfileResponse,
    GenerateOutreachNoProfileResponseFromJSON,
    GenerateOutreachNoProfileResponseToJSON,
    GenericEmailResponseTemplate,
    GenericEmailResponseTemplateFromJSON,
    GenericEmailResponseTemplateToJSON,
    GetBulkRejectionTemplateRequest,
    GetBulkRejectionTemplateRequestFromJSON,
    GetBulkRejectionTemplateRequestToJSON,
    GetCandidateEmailActivityRequest,
    GetCandidateEmailActivityRequestFromJSON,
    GetCandidateEmailActivityRequestToJSON,
    GetCandidateEmailActivityResponse,
    GetCandidateEmailActivityResponseFromJSON,
    GetCandidateEmailActivityResponseToJSON,
    GetChangeLogEntryRequest,
    GetChangeLogEntryRequestFromJSON,
    GetChangeLogEntryRequestToJSON,
    GetCompaniesByUrnRequest,
    GetCompaniesByUrnRequestFromJSON,
    GetCompaniesByUrnRequestToJSON,
    GetCompanyPitchQuestionSchemaResponse,
    GetCompanyPitchQuestionSchemaResponseFromJSON,
    GetCompanyPitchQuestionSchemaResponseToJSON,
    GetDoverOutboundConfiguration200Response,
    GetDoverOutboundConfiguration200ResponseFromJSON,
    GetDoverOutboundConfiguration200ResponseToJSON,
    GetEmailTemplateRequestV2,
    GetEmailTemplateRequestV2FromJSON,
    GetEmailTemplateRequestV2ToJSON,
    GetFeatureFlagsByCandidateRequest,
    GetFeatureFlagsByCandidateRequestFromJSON,
    GetFeatureFlagsByCandidateRequestToJSON,
    GetFeatureFlagsByCandidateResponse,
    GetFeatureFlagsByCandidateResponseFromJSON,
    GetFeatureFlagsByCandidateResponseToJSON,
    GetInDepthExplanationRequest,
    GetInDepthExplanationRequestFromJSON,
    GetInDepthExplanationRequestToJSON,
    GetInDepthExplanationResponse,
    GetInDepthExplanationResponseFromJSON,
    GetInDepthExplanationResponseToJSON,
    GetJobPitchQuestionSchemaResponse,
    GetJobPitchQuestionSchemaResponseFromJSON,
    GetJobPitchQuestionSchemaResponseToJSON,
    GetOrCreateApiKeyCredentialRequest,
    GetOrCreateApiKeyCredentialRequestFromJSON,
    GetOrCreateApiKeyCredentialRequestToJSON,
    GetOrCreateApiKeyCredentialResponse,
    GetOrCreateApiKeyCredentialResponseFromJSON,
    GetOrCreateApiKeyCredentialResponseToJSON,
    GetOrCreateMultipartStagesRequest,
    GetOrCreateMultipartStagesRequestFromJSON,
    GetOrCreateMultipartStagesRequestToJSON,
    GetOrCreateUserOnboardingFlowRequest,
    GetOrCreateUserOnboardingFlowRequestFromJSON,
    GetOrCreateUserOnboardingFlowRequestToJSON,
    GetPersonaForJobRequest,
    GetPersonaForJobRequestFromJSON,
    GetPersonaForJobRequestToJSON,
    GetPersonaForJobResponse,
    GetPersonaForJobResponseFromJSON,
    GetPersonaForJobResponseToJSON,
    GetPersonaViaSoftMatchRequest,
    GetPersonaViaSoftMatchRequestFromJSON,
    GetPersonaViaSoftMatchRequestToJSON,
    GetRemainingConciergeHoursResponse,
    GetRemainingConciergeHoursResponseFromJSON,
    GetRemainingConciergeHoursResponseToJSON,
    GetSchedulingLinkRequest,
    GetSchedulingLinkRequestFromJSON,
    GetSchedulingLinkRequestToJSON,
    GetSearchParamsFromJDRequest,
    GetSearchParamsFromJDRequestFromJSON,
    GetSearchParamsFromJDRequestToJSON,
    GetSearchParamsFromJDResponse,
    GetSearchParamsFromJDResponseFromJSON,
    GetSearchParamsFromJDResponseToJSON,
    GmailAuth,
    GmailAuthFromJSON,
    GmailAuthToJSON,
    HasSeenOnboardingFlow,
    HasSeenOnboardingFlowFromJSON,
    HasSeenOnboardingFlowToJSON,
    HiringPipelineStage,
    HiringPipelineStageFromJSON,
    HiringPipelineStageToJSON,
    HiringPipelineStageListResponse,
    HiringPipelineStageListResponseFromJSON,
    HiringPipelineStageListResponseToJSON,
    HiringPlanJobPosition,
    HiringPlanJobPositionFromJSON,
    HiringPlanJobPositionToJSON,
    HiringPlanJobPositionOpening,
    HiringPlanJobPositionOpeningFromJSON,
    HiringPlanJobPositionOpeningToJSON,
    HiringStageEmailTemplate,
    HiringStageEmailTemplateFromJSON,
    HiringStageEmailTemplateToJSON,
    HiringStageEmailTemplateSendTestEmailRequest,
    HiringStageEmailTemplateSendTestEmailRequestFromJSON,
    HiringStageEmailTemplateSendTestEmailRequestToJSON,
    InboundApplicationResponse,
    InboundApplicationResponseFromJSON,
    InboundApplicationResponseToJSON,
    Industry,
    IndustryFromJSON,
    IndustryToJSON,
    InlineObject,
    InlineObjectFromJSON,
    InlineObjectToJSON,
    InlineObject1,
    InlineObject1FromJSON,
    InlineObject1ToJSON,
    InlineObject10,
    InlineObject10FromJSON,
    InlineObject10ToJSON,
    InlineObject11,
    InlineObject11FromJSON,
    InlineObject11ToJSON,
    InlineObject12,
    InlineObject12FromJSON,
    InlineObject12ToJSON,
    InlineObject13,
    InlineObject13FromJSON,
    InlineObject13ToJSON,
    InlineObject14,
    InlineObject14FromJSON,
    InlineObject14ToJSON,
    InlineObject15,
    InlineObject15FromJSON,
    InlineObject15ToJSON,
    InlineObject16,
    InlineObject16FromJSON,
    InlineObject16ToJSON,
    InlineObject17,
    InlineObject17FromJSON,
    InlineObject17ToJSON,
    InlineObject18,
    InlineObject18FromJSON,
    InlineObject18ToJSON,
    InlineObject19,
    InlineObject19FromJSON,
    InlineObject19ToJSON,
    InlineObject2,
    InlineObject2FromJSON,
    InlineObject2ToJSON,
    InlineObject20,
    InlineObject20FromJSON,
    InlineObject20ToJSON,
    InlineObject21,
    InlineObject21FromJSON,
    InlineObject21ToJSON,
    InlineObject22,
    InlineObject22FromJSON,
    InlineObject22ToJSON,
    InlineObject23,
    InlineObject23FromJSON,
    InlineObject23ToJSON,
    InlineObject24,
    InlineObject24FromJSON,
    InlineObject24ToJSON,
    InlineObject25,
    InlineObject25FromJSON,
    InlineObject25ToJSON,
    InlineObject26,
    InlineObject26FromJSON,
    InlineObject26ToJSON,
    InlineObject27,
    InlineObject27FromJSON,
    InlineObject27ToJSON,
    InlineObject28,
    InlineObject28FromJSON,
    InlineObject28ToJSON,
    InlineObject3,
    InlineObject3FromJSON,
    InlineObject3ToJSON,
    InlineObject4,
    InlineObject4FromJSON,
    InlineObject4ToJSON,
    InlineObject5,
    InlineObject5FromJSON,
    InlineObject5ToJSON,
    InlineObject6,
    InlineObject6FromJSON,
    InlineObject6ToJSON,
    InlineObject7,
    InlineObject7FromJSON,
    InlineObject7ToJSON,
    InlineObject8,
    InlineObject8FromJSON,
    InlineObject8ToJSON,
    InlineObject9,
    InlineObject9FromJSON,
    InlineObject9ToJSON,
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
    InlineResponse20010,
    InlineResponse20010FromJSON,
    InlineResponse20010ToJSON,
    InlineResponse20011,
    InlineResponse20011FromJSON,
    InlineResponse20011ToJSON,
    InlineResponse20012,
    InlineResponse20012FromJSON,
    InlineResponse20012ToJSON,
    InlineResponse20013,
    InlineResponse20013FromJSON,
    InlineResponse20013ToJSON,
    InlineResponse20014,
    InlineResponse20014FromJSON,
    InlineResponse20014ToJSON,
    InlineResponse20015,
    InlineResponse20015FromJSON,
    InlineResponse20015ToJSON,
    InlineResponse20016,
    InlineResponse20016FromJSON,
    InlineResponse20016ToJSON,
    InlineResponse20017,
    InlineResponse20017FromJSON,
    InlineResponse20017ToJSON,
    InlineResponse20018,
    InlineResponse20018FromJSON,
    InlineResponse20018ToJSON,
    InlineResponse20019,
    InlineResponse20019FromJSON,
    InlineResponse20019ToJSON,
    InlineResponse2002,
    InlineResponse2002FromJSON,
    InlineResponse2002ToJSON,
    InlineResponse20020,
    InlineResponse20020FromJSON,
    InlineResponse20020ToJSON,
    InlineResponse20021,
    InlineResponse20021FromJSON,
    InlineResponse20021ToJSON,
    InlineResponse20022,
    InlineResponse20022FromJSON,
    InlineResponse20022ToJSON,
    InlineResponse20023,
    InlineResponse20023FromJSON,
    InlineResponse20023ToJSON,
    InlineResponse20024,
    InlineResponse20024FromJSON,
    InlineResponse20024ToJSON,
    InlineResponse20025,
    InlineResponse20025FromJSON,
    InlineResponse20025ToJSON,
    InlineResponse20026,
    InlineResponse20026FromJSON,
    InlineResponse20026ToJSON,
    InlineResponse20027,
    InlineResponse20027FromJSON,
    InlineResponse20027ToJSON,
    InlineResponse20028,
    InlineResponse20028FromJSON,
    InlineResponse20028ToJSON,
    InlineResponse20029,
    InlineResponse20029FromJSON,
    InlineResponse20029ToJSON,
    InlineResponse2003,
    InlineResponse2003FromJSON,
    InlineResponse2003ToJSON,
    InlineResponse20033,
    InlineResponse20033FromJSON,
    InlineResponse20033ToJSON,
    InlineResponse20034,
    InlineResponse20034FromJSON,
    InlineResponse20034ToJSON,
    InlineResponse20035,
    InlineResponse20035FromJSON,
    InlineResponse20035ToJSON,
    InlineResponse20036,
    InlineResponse20036FromJSON,
    InlineResponse20036ToJSON,
    InlineResponse20037,
    InlineResponse20037FromJSON,
    InlineResponse20037ToJSON,
    InlineResponse20038,
    InlineResponse20038FromJSON,
    InlineResponse20038ToJSON,
    InlineResponse20039,
    InlineResponse20039FromJSON,
    InlineResponse20039ToJSON,
    InlineResponse2004,
    InlineResponse2004FromJSON,
    InlineResponse2004ToJSON,
    InlineResponse20040,
    InlineResponse20040FromJSON,
    InlineResponse20040ToJSON,
    InlineResponse20041,
    InlineResponse20041FromJSON,
    InlineResponse20041ToJSON,
    InlineResponse20042,
    InlineResponse20042FromJSON,
    InlineResponse20042ToJSON,
    InlineResponse20043,
    InlineResponse20043FromJSON,
    InlineResponse20043ToJSON,
    InlineResponse20044,
    InlineResponse20044FromJSON,
    InlineResponse20044ToJSON,
    InlineResponse20045,
    InlineResponse20045FromJSON,
    InlineResponse20045ToJSON,
    InlineResponse20046,
    InlineResponse20046FromJSON,
    InlineResponse20046ToJSON,
    InlineResponse20047,
    InlineResponse20047FromJSON,
    InlineResponse20047ToJSON,
    InlineResponse20048,
    InlineResponse20048FromJSON,
    InlineResponse20048ToJSON,
    InlineResponse20049,
    InlineResponse20049FromJSON,
    InlineResponse20049ToJSON,
    InlineResponse2005,
    InlineResponse2005FromJSON,
    InlineResponse2005ToJSON,
    InlineResponse20050,
    InlineResponse20050FromJSON,
    InlineResponse20050ToJSON,
    InlineResponse20051,
    InlineResponse20051FromJSON,
    InlineResponse20051ToJSON,
    InlineResponse20052,
    InlineResponse20052FromJSON,
    InlineResponse20052ToJSON,
    InlineResponse20053,
    InlineResponse20053FromJSON,
    InlineResponse20053ToJSON,
    InlineResponse20054,
    InlineResponse20054FromJSON,
    InlineResponse20054ToJSON,
    InlineResponse20055,
    InlineResponse20055FromJSON,
    InlineResponse20055ToJSON,
    InlineResponse20056,
    InlineResponse20056FromJSON,
    InlineResponse20056ToJSON,
    InlineResponse20057,
    InlineResponse20057FromJSON,
    InlineResponse20057ToJSON,
    InlineResponse20058,
    InlineResponse20058FromJSON,
    InlineResponse20058ToJSON,
    InlineResponse20059,
    InlineResponse20059FromJSON,
    InlineResponse20059ToJSON,
    InlineResponse2006,
    InlineResponse2006FromJSON,
    InlineResponse2006ToJSON,
    InlineResponse20060,
    InlineResponse20060FromJSON,
    InlineResponse20060ToJSON,
    InlineResponse20061,
    InlineResponse20061FromJSON,
    InlineResponse20061ToJSON,
    InlineResponse20062,
    InlineResponse20062FromJSON,
    InlineResponse20062ToJSON,
    InlineResponse20063,
    InlineResponse20063FromJSON,
    InlineResponse20063ToJSON,
    InlineResponse20064,
    InlineResponse20064FromJSON,
    InlineResponse20064ToJSON,
    InlineResponse20065,
    InlineResponse20065FromJSON,
    InlineResponse20065ToJSON,
    InlineResponse20066,
    InlineResponse20066FromJSON,
    InlineResponse20066ToJSON,
    InlineResponse20067,
    InlineResponse20067FromJSON,
    InlineResponse20067ToJSON,
    InlineResponse20068,
    InlineResponse20068FromJSON,
    InlineResponse20068ToJSON,
    InlineResponse20069,
    InlineResponse20069FromJSON,
    InlineResponse20069ToJSON,
    InlineResponse2007,
    InlineResponse2007FromJSON,
    InlineResponse2007ToJSON,
    InlineResponse20070,
    InlineResponse20070FromJSON,
    InlineResponse20070ToJSON,
    InlineResponse20071,
    InlineResponse20071FromJSON,
    InlineResponse20071ToJSON,
    InlineResponse20072,
    InlineResponse20072FromJSON,
    InlineResponse20072ToJSON,
    InlineResponse20073,
    InlineResponse20073FromJSON,
    InlineResponse20073ToJSON,
    InlineResponse20074,
    InlineResponse20074FromJSON,
    InlineResponse20074ToJSON,
    InlineResponse20075,
    InlineResponse20075FromJSON,
    InlineResponse20075ToJSON,
    InlineResponse20076,
    InlineResponse20076FromJSON,
    InlineResponse20076ToJSON,
    InlineResponse2008,
    InlineResponse2008FromJSON,
    InlineResponse2008ToJSON,
    InlineResponse2009,
    InlineResponse2009FromJSON,
    InlineResponse2009ToJSON,
    InlineResponse400,
    InlineResponse400FromJSON,
    InlineResponse400ToJSON,
    InlineResponse4001,
    InlineResponse4001FromJSON,
    InlineResponse4001ToJSON,
    Interview,
    InterviewFromJSON,
    InterviewToJSON,
    InterviewQuestionSuggestionsRequest,
    InterviewQuestionSuggestionsRequestFromJSON,
    InterviewQuestionSuggestionsRequestToJSON,
    InterviewQuestionSuggestionsResponse,
    InterviewQuestionSuggestionsResponseFromJSON,
    InterviewQuestionSuggestionsResponseToJSON,
    InterviewRubricResponse,
    InterviewRubricResponseFromJSON,
    InterviewRubricResponseToJSON,
    InterviewRubricTemplateError,
    InterviewRubricTemplateErrorFromJSON,
    InterviewRubricTemplateErrorToJSON,
    InterviewStatus,
    InterviewStatusFromJSON,
    InterviewStatusToJSON,
    InterviewTranscriptError,
    InterviewTranscriptErrorFromJSON,
    InterviewTranscriptErrorToJSON,
    InterviewTranscriptResponse,
    InterviewTranscriptResponseFromJSON,
    InterviewTranscriptResponseToJSON,
    InterviewerSchedulingInfo,
    InterviewerSchedulingInfoFromJSON,
    InterviewerSchedulingInfoToJSON,
    JobAtsSourceSetting,
    JobAtsSourceSettingFromJSON,
    JobAtsSourceSettingToJSON,
    JobAtsSourceSettingUpsertMultipleRequest,
    JobAtsSourceSettingUpsertMultipleRequestFromJSON,
    JobAtsSourceSettingUpsertMultipleRequestToJSON,
    JobCandidateSourceSetting,
    JobCandidateSourceSettingFromJSON,
    JobCandidateSourceSettingToJSON,
    JobCandidateSourceStats,
    JobCandidateSourceStatsFromJSON,
    JobCandidateSourceStatsToJSON,
    JobFeatureSetting,
    JobFeatureSettingFromJSON,
    JobFeatureSettingToJSON,
    JobFeatures,
    JobFeaturesFromJSON,
    JobFeaturesToJSON,
    JobFunnelStats,
    JobFunnelStatsFromJSON,
    JobFunnelStatsToJSON,
    JobInboundStats,
    JobInboundStatsFromJSON,
    JobInboundStatsToJSON,
    JobOutboundStats,
    JobOutboundStatsFromJSON,
    JobOutboundStatsToJSON,
    JobOutreachBenchmarks,
    JobOutreachBenchmarksFromJSON,
    JobOutreachBenchmarksToJSON,
    JobOutreachStats,
    JobOutreachStatsFromJSON,
    JobOutreachStatsToJSON,
    JobReferral,
    JobReferralFromJSON,
    JobReferralToJSON,
    JobReferralStats,
    JobReferralStatsFromJSON,
    JobReferralStatsToJSON,
    JobReferralUpdateError,
    JobReferralUpdateErrorFromJSON,
    JobReferralUpdateErrorToJSON,
    JobReferralsStats,
    JobReferralsStatsFromJSON,
    JobReferralsStatsToJSON,
    JobReportMeta,
    JobReportMetaFromJSON,
    JobReportMetaToJSON,
    JobSetup,
    JobSetupFromJSON,
    JobSetupToJSON,
    JobSetupSteps,
    JobSetupStepsFromJSON,
    JobSetupStepsToJSON,
    JobSetupStepsWithSetupSummaryState,
    JobSetupStepsWithSetupSummaryStateFromJSON,
    JobSetupStepsWithSetupSummaryStateToJSON,
    JobSetupTitlePattern,
    JobSetupTitlePatternFromJSON,
    JobSetupTitlePatternToJSON,
    Keyword,
    KeywordFromJSON,
    KeywordToJSON,
    KeywordWithBucketIndicesRequest,
    KeywordWithBucketIndicesRequestFromJSON,
    KeywordWithBucketIndicesRequestToJSON,
    KeywordWithBucketIndicesResponse,
    KeywordWithBucketIndicesResponseFromJSON,
    KeywordWithBucketIndicesResponseToJSON,
    LeverAuthInfo,
    LeverAuthInfoFromJSON,
    LeverAuthInfoToJSON,
    ListApplicationsReturn,
    ListApplicationsReturnFromJSON,
    ListApplicationsReturnToJSON,
    ListAppsViaAIRequest,
    ListAppsViaAIRequestFromJSON,
    ListAppsViaAIRequestToJSON,
    ListAppsViaAIResponse,
    ListAppsViaAIResponseFromJSON,
    ListAppsViaAIResponseToJSON,
    ListArchiveReasonsResponse,
    ListArchiveReasonsResponseFromJSON,
    ListArchiveReasonsResponseToJSON,
    ListCompanySizeSerializer,
    ListCompanySizeSerializerFromJSON,
    ListCompanySizeSerializerToJSON,
    ListLocationsResponse,
    ListLocationsResponseFromJSON,
    ListLocationsResponseToJSON,
    ListSaapReviewApplicationRequest,
    ListSaapReviewApplicationRequestFromJSON,
    ListSaapReviewApplicationRequestToJSON,
    ListStatesResponse,
    ListStatesResponseFromJSON,
    ListStatesResponseToJSON,
    MarkOnboardingCompleteRequest,
    MarkOnboardingCompleteRequestFromJSON,
    MarkOnboardingCompleteRequestToJSON,
    MarkOnboardingCompleteResponse,
    MarkOnboardingCompleteResponseFromJSON,
    MarkOnboardingCompleteResponseToJSON,
    Mention,
    MentionFromJSON,
    MentionToJSON,
    MergeContactsRequest,
    MergeContactsRequestFromJSON,
    MergeContactsRequestToJSON,
    ModifyTitlePatternsInSearchRequest,
    ModifyTitlePatternsInSearchRequestFromJSON,
    ModifyTitlePatternsInSearchRequestToJSON,
    ModifyTitlePatternsInSearchResponse,
    ModifyTitlePatternsInSearchResponseFromJSON,
    ModifyTitlePatternsInSearchResponseToJSON,
    MoveJobRequest,
    MoveJobRequestFromJSON,
    MoveJobRequestToJSON,
    MultipartSchedulingCandidate,
    MultipartSchedulingCandidateFromJSON,
    MultipartSchedulingCandidateToJSON,
    MultipartSchedulingClientInterviewerAvailability,
    MultipartSchedulingClientInterviewerAvailabilityFromJSON,
    MultipartSchedulingClientInterviewerAvailabilityToJSON,
    MultipartSchedulingClientInterviewerCalendarEvent,
    MultipartSchedulingClientInterviewerCalendarEventFromJSON,
    MultipartSchedulingClientInterviewerCalendarEventToJSON,
    MultipartSchedulingClientInterviewerCalendarEventsError,
    MultipartSchedulingClientInterviewerCalendarEventsErrorFromJSON,
    MultipartSchedulingClientInterviewerCalendarEventsErrorToJSON,
    MultipartSchedulingMultipartInterviewStage,
    MultipartSchedulingMultipartInterviewStageFromJSON,
    MultipartSchedulingMultipartInterviewStageToJSON,
    NextActionForCandidatesRequest,
    NextActionForCandidatesRequestFromJSON,
    NextActionForCandidatesRequestToJSON,
    NotificationConfig,
    NotificationConfigFromJSON,
    NotificationConfigToJSON,
    OnboardingHiringPipelineStage,
    OnboardingHiringPipelineStageFromJSON,
    OnboardingHiringPipelineStageToJSON,
    OnboardingMultipartInterviewStage,
    OnboardingMultipartInterviewStageFromJSON,
    OnboardingMultipartInterviewStageToJSON,
    OtherSearchesUsingPersonaRequest,
    OtherSearchesUsingPersonaRequestFromJSON,
    OtherSearchesUsingPersonaRequestToJSON,
    OtherSearchesUsingPersonaResponse,
    OtherSearchesUsingPersonaResponseFromJSON,
    OtherSearchesUsingPersonaResponseToJSON,
    OutreachGeneration,
    OutreachGenerationFromJSON,
    OutreachGenerationToJSON,
    OutreachGenerationError,
    OutreachGenerationErrorFromJSON,
    OutreachGenerationErrorToJSON,
    ParseCandidateInfoResult,
    ParseCandidateInfoResultFromJSON,
    ParseCandidateInfoResultToJSON,
    ParseClientEmailTemplate,
    ParseClientEmailTemplateFromJSON,
    ParseClientEmailTemplateToJSON,
    ParseJDFeaturesError,
    ParseJDFeaturesErrorFromJSON,
    ParseJDFeaturesErrorToJSON,
    ParseJDFeaturesRequest,
    ParseJDFeaturesRequestFromJSON,
    ParseJDFeaturesRequestToJSON,
    ParseJDFeaturesResponse,
    ParseJDFeaturesResponseFromJSON,
    ParseJDFeaturesResponseToJSON,
    Persona,
    PersonaFromJSON,
    PersonaToJSON,
    PersonaIDRequest,
    PersonaIDRequestFromJSON,
    PersonaIDRequestToJSON,
    PersonaQuestionnaire,
    PersonaQuestionnaireFromJSON,
    PersonaQuestionnaireToJSON,
    PipelineCandidate,
    PipelineCandidateFromJSON,
    PipelineCandidateToJSON,
    ProUser,
    ProUserFromJSON,
    ProUserToJSON,
    ProfileRequest,
    ProfileRequestFromJSON,
    ProfileRequestToJSON,
    ProfileResponse,
    ProfileResponseFromJSON,
    ProfileResponseToJSON,
    ProfileSearchKeywordSerializer,
    ProfileSearchKeywordSerializerFromJSON,
    ProfileSearchKeywordSerializerToJSON,
    ProfileSearchLocationSerializer,
    ProfileSearchLocationSerializerFromJSON,
    ProfileSearchLocationSerializerToJSON,
    PublishedJobCount,
    PublishedJobCountFromJSON,
    PublishedJobCountToJSON,
    QueueBulkRejectRequest,
    QueueBulkRejectRequestFromJSON,
    QueueBulkRejectRequestToJSON,
    RecruitingPartnerListResponse,
    RecruitingPartnerListResponseFromJSON,
    RecruitingPartnerListResponseToJSON,
    RegenerateSampleCandidateRequest,
    RegenerateSampleCandidateRequestFromJSON,
    RegenerateSampleCandidateRequestToJSON,
    RegenerateSampleCandidateResponse,
    RegenerateSampleCandidateResponseFromJSON,
    RegenerateSampleCandidateResponseToJSON,
    RegisterSaapReviewedCandidateRequest,
    RegisterSaapReviewedCandidateRequestFromJSON,
    RegisterSaapReviewedCandidateRequestToJSON,
    RegisterSaapReviewedCandidateSubmissionResponse,
    RegisterSaapReviewedCandidateSubmissionResponseFromJSON,
    RegisterSaapReviewedCandidateSubmissionResponseToJSON,
    RegisterSimilarCandidateRequest,
    RegisterSimilarCandidateRequestFromJSON,
    RegisterSimilarCandidateRequestToJSON,
    RegisterSimilarCandidateSubmissionResponse,
    RegisterSimilarCandidateSubmissionResponseFromJSON,
    RegisterSimilarCandidateSubmissionResponseToJSON,
    RelatedCandidate,
    RelatedCandidateFromJSON,
    RelatedCandidateToJSON,
    RemoveFailingPeopleFromOutbox,
    RemoveFailingPeopleFromOutboxFromJSON,
    RemoveFailingPeopleFromOutboxToJSON,
    RemoveFailingPeopleFromOutboxResponse,
    RemoveFailingPeopleFromOutboxResponseFromJSON,
    RemoveFailingPeopleFromOutboxResponseToJSON,
    RemoveFromOutboxRequest,
    RemoveFromOutboxRequestFromJSON,
    RemoveFromOutboxRequestToJSON,
    RemoveFromOutboxResponse,
    RemoveFromOutboxResponseFromJSON,
    RemoveFromOutboxResponseToJSON,
    RemoveSampleCandidatesRequest,
    RemoveSampleCandidatesRequestFromJSON,
    RemoveSampleCandidatesRequestToJSON,
    RemoveSampleCandidatesResponse,
    RemoveSampleCandidatesResponseFromJSON,
    RemoveSampleCandidatesResponseToJSON,
    RequestPlanUpgradeError,
    RequestPlanUpgradeErrorFromJSON,
    RequestPlanUpgradeErrorToJSON,
    RequestPlanUpgradeRequest,
    RequestPlanUpgradeRequestFromJSON,
    RequestPlanUpgradeRequestToJSON,
    RequestPlanUpgradeResponse,
    RequestPlanUpgradeResponseFromJSON,
    RequestPlanUpgradeResponseToJSON,
    RescheduleInterviewRequest,
    RescheduleInterviewRequestFromJSON,
    RescheduleInterviewRequestToJSON,
    ResendEmailVerificationEmailRequest,
    ResendEmailVerificationEmailRequestFromJSON,
    ResendEmailVerificationEmailRequestToJSON,
    RetrieveEmailsForLinkedinPublicUrlRequest,
    RetrieveEmailsForLinkedinPublicUrlRequestFromJSON,
    RetrieveEmailsForLinkedinPublicUrlRequestToJSON,
    RetrieveEmailsForLinkedinPublicUrlResponse,
    RetrieveEmailsForLinkedinPublicUrlResponseFromJSON,
    RetrieveEmailsForLinkedinPublicUrlResponseToJSON,
    SaveCompanyPitchQuestionSchema,
    SaveCompanyPitchQuestionSchemaFromJSON,
    SaveCompanyPitchQuestionSchemaToJSON,
    SaveCompanyPitchQuestionSchema1,
    SaveCompanyPitchQuestionSchema1FromJSON,
    SaveCompanyPitchQuestionSchema1ToJSON,
    SaveJobPitchQuestionSchema,
    SaveJobPitchQuestionSchemaFromJSON,
    SaveJobPitchQuestionSchemaToJSON,
    SaveJobPitchQuestionSchema1,
    SaveJobPitchQuestionSchema1FromJSON,
    SaveJobPitchQuestionSchema1ToJSON,
    SchedulableCalendar,
    SchedulableCalendarFromJSON,
    SchedulableCalendarToJSON,
    ScheduleCandidateInterviewRequest,
    ScheduleCandidateInterviewRequestFromJSON,
    ScheduleCandidateInterviewRequestToJSON,
    SchedulingLink,
    SchedulingLinkFromJSON,
    SchedulingLinkToJSON,
    SchoolList,
    SchoolListFromJSON,
    SchoolListToJSON,
    SearchDoverResponse,
    SearchDoverResponseFromJSON,
    SearchDoverResponseToJSON,
    SearchTransformValidationResult,
    SearchTransformValidationResultFromJSON,
    SearchTransformValidationResultToJSON,
    SearchV3,
    SearchV3FromJSON,
    SearchV3ToJSON,
    SearchV3PersonaQuestionnaire,
    SearchV3PersonaQuestionnaireFromJSON,
    SearchV3PersonaQuestionnaireToJSON,
    SendEmailRequest,
    SendEmailRequestFromJSON,
    SendEmailRequestToJSON,
    SendJobSlackChannelInviteResponse,
    SendJobSlackChannelInviteResponseFromJSON,
    SendJobSlackChannelInviteResponseToJSON,
    SendNewUserInvitationeRequest,
    SendNewUserInvitationeRequestFromJSON,
    SendNewUserInvitationeRequestToJSON,
    Seniority,
    SeniorityFromJSON,
    SeniorityToJSON,
    SetUserToBlockedRequest,
    SetUserToBlockedRequestFromJSON,
    SetUserToBlockedRequestToJSON,
    SetUserToBlockedResponse,
    SetUserToBlockedResponseFromJSON,
    SetUserToBlockedResponseToJSON,
    ShortenedCandidate,
    ShortenedCandidateFromJSON,
    ShortenedCandidateToJSON,
    ShowUnreviewedSimilarCandidatesRequest,
    ShowUnreviewedSimilarCandidatesRequestFromJSON,
    ShowUnreviewedSimilarCandidatesRequestToJSON,
    ShowUnreviewedSimilarCandidatesResponse,
    ShowUnreviewedSimilarCandidatesResponseFromJSON,
    ShowUnreviewedSimilarCandidatesResponseToJSON,
    SlimInterviewRubricResponse,
    SlimInterviewRubricResponseFromJSON,
    SlimInterviewRubricResponseToJSON,
    SlimPersona,
    SlimPersonaFromJSON,
    SlimPersonaToJSON,
    SlimSearchTemplate,
    SlimSearchTemplateFromJSON,
    SlimSearchTemplateToJSON,
    SnoozeJob,
    SnoozeJobFromJSON,
    SnoozeJobToJSON,
    SubmitDecisionRequest,
    SubmitDecisionRequestFromJSON,
    SubmitDecisionRequestToJSON,
    SubmitMultipartInterviewSchedulingRequest,
    SubmitMultipartInterviewSchedulingRequestFromJSON,
    SubmitMultipartInterviewSchedulingRequestToJSON,
    SubmitUserCredentialRequest,
    SubmitUserCredentialRequestFromJSON,
    SubmitUserCredentialRequestToJSON,
    SwapInterviewStageTypeRequest,
    SwapInterviewStageTypeRequestFromJSON,
    SwapInterviewStageTypeRequestToJSON,
    SyncAndListJobAtsStages,
    SyncAndListJobAtsStagesFromJSON,
    SyncAndListJobAtsStagesToJSON,
    SyncAtsSourcesError,
    SyncAtsSourcesErrorFromJSON,
    SyncAtsSourcesErrorToJSON,
    SyncAtsSourcesResponse,
    SyncAtsSourcesResponseFromJSON,
    SyncAtsSourcesResponseToJSON,
    TalentNetworkRequest,
    TalentNetworkRequestFromJSON,
    TalentNetworkRequestToJSON,
    TalentNetworkReturn,
    TalentNetworkReturnFromJSON,
    TalentNetworkReturnToJSON,
    Title,
    TitleFromJSON,
    TitleToJSON,
    TitlePattern,
    TitlePatternFromJSON,
    TitlePatternToJSON,
    TitlePatternInfo,
    TitlePatternInfoFromJSON,
    TitlePatternInfoToJSON,
    ToggleStarCandidateRequest,
    ToggleStarCandidateRequestFromJSON,
    ToggleStarCandidateRequestToJSON,
    UpdateCampaignMessageRequest,
    UpdateCampaignMessageRequestFromJSON,
    UpdateCampaignMessageRequestToJSON,
    UpdateCandidateBioBody,
    UpdateCandidateBioBodyFromJSON,
    UpdateCandidateBioBodyToJSON,
    UpdateCandidateInterview,
    UpdateCandidateInterviewFromJSON,
    UpdateCandidateInterviewToJSON,
    UpdateClientBody,
    UpdateClientBodyFromJSON,
    UpdateClientBodyToJSON,
    UpdateClientRoleResponse,
    UpdateClientRoleResponseFromJSON,
    UpdateClientRoleResponseToJSON,
    UpdateDoverOutboundConfiguration,
    UpdateDoverOutboundConfigurationFromJSON,
    UpdateDoverOutboundConfigurationToJSON,
    UpdateFeedbackTemplateRequest,
    UpdateFeedbackTemplateRequestFromJSON,
    UpdateFeedbackTemplateRequestToJSON,
    UpdateHiringPipelineStageRequest,
    UpdateHiringPipelineStageRequestFromJSON,
    UpdateHiringPipelineStageRequestToJSON,
    UpdateJobDescription,
    UpdateJobDescriptionFromJSON,
    UpdateJobDescriptionToJSON,
    UpdateJobHiringTeam,
    UpdateJobHiringTeamFromJSON,
    UpdateJobHiringTeamToJSON,
    UpdateJobVisibility,
    UpdateJobVisibilityFromJSON,
    UpdateJobVisibilityToJSON,
    UpdateSearchFromFeaturesError,
    UpdateSearchFromFeaturesErrorFromJSON,
    UpdateSearchFromFeaturesErrorToJSON,
    UpdateSearchFromFeaturesRequest,
    UpdateSearchFromFeaturesRequestFromJSON,
    UpdateSearchFromFeaturesRequestToJSON,
    UpdateSearchFromFeaturesSuccess,
    UpdateSearchFromFeaturesSuccessFromJSON,
    UpdateSearchFromFeaturesSuccessToJSON,
    UpdateUserClientRoleRequest,
    UpdateUserClientRoleRequestFromJSON,
    UpdateUserClientRoleRequestToJSON,
    UpsertJobFeatureSetting,
    UpsertJobFeatureSettingFromJSON,
    UpsertJobFeatureSettingToJSON,
    UserOnboardingFlow,
    UserOnboardingFlowFromJSON,
    UserOnboardingFlowToJSON,
    UserRating,
    UserRatingFromJSON,
    UserRatingToJSON,
    UserRolesAndPermissions,
    UserRolesAndPermissionsFromJSON,
    UserRolesAndPermissionsToJSON,
    UserUploadedFile,
    UserUploadedFileFromJSON,
    UserUploadedFileToJSON,
    ValidEmailSendAtTime,
    ValidEmailSendAtTimeFromJSON,
    ValidEmailSendAtTimeToJSON,
    ValidSendAtTimesRequest,
    ValidSendAtTimesRequestFromJSON,
    ValidSendAtTimesRequestToJSON,
    ValidateSchedulingLinkForCandidateRequest,
    ValidateSchedulingLinkForCandidateRequestFromJSON,
    ValidateSchedulingLinkForCandidateRequestToJSON,
    ValidateSchedulingLinkForCandidateResponse,
    ValidateSchedulingLinkForCandidateResponseFromJSON,
    ValidateSchedulingLinkForCandidateResponseToJSON,
    WorldRegionResponse,
    WorldRegionResponseFromJSON,
    WorldRegionResponseToJSON,
} from '../models';

export interface ApiApiAddCampaignToSearchRequest {
    id: string;
    data: InlineObject27;
}

export interface ApiApiAddCandidateInitialOutreachFlowRequest {
    job: string;
    firstName: string;
    lastName: string;
    campaign: string;
    email?: string;
    emailId?: string;
    linkedinProfileUrl?: string;
    resume?: Blob;
    firstMessageBody?: string;
    firstMessageSubject?: string;
    ccEmails?: Array<string>;
}

export interface ApiApiAddCandidateInitialOutreachFlowBulkRequest {
    data: AddCandidateInitialOutreachBulkRequest;
}

export interface ApiApiAddCandidateToInterviewProcessFlowRequest {
    job: string;
    pipelineStage: string;
    firstName: string;
    lastName: string;
    email?: string;
    linkedinProfileUrl?: string;
    sourceId?: string;
    sourceName?: string;
    agencyName?: string;
    agencyEmail?: string;
    resume?: Blob;
}

export interface ApiApiAddEmailRequest {
    data: Email;
}

export interface ApiApiAddEmailToCandidateRequest {
    data: InlineObject;
}

export interface ApiApiAddQuestionsToInterviewRubricResponseOperationRequest {
    id: string;
    data: AddQuestionsToInterviewRubricResponseRequest;
}

export interface ApiApiAdminListClientsRequest {
    fullTextSearch?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiApiV1AdminAdminCampaignsCreateRequest {
    data: AdminCampaign;
}

export interface ApiApiApiV1AdminAdminCampaignsDeleteRequest {
    id: string;
}

export interface ApiApiApiV1AdminAdminCampaignsPartialUpdateRequest {
    id: string;
    data: AdminCampaign;
}

export interface ApiApiApiV1AdminAdminCampaignsReadRequest {
    id: string;
}

export interface ApiApiApiV1AdminAdminCampaignsUpdateRequest {
    id: string;
    data: AdminCampaign;
}

export interface ApiApiApiV1AdminAdminSlimSearchTemplatesCreateRequest {
    data: SlimSearchTemplate;
}

export interface ApiApiApiV1AdminAdminSlimSearchTemplatesDeleteRequest {
    id: number;
}

export interface ApiApiApiV1AdminAdminSlimSearchTemplatesPartialUpdateRequest {
    id: number;
    data: SlimSearchTemplate;
}

export interface ApiApiApiV1AdminAdminSlimSearchTemplatesReadRequest {
    id: number;
}

export interface ApiApiApiV1AdminAdminSlimSearchTemplatesUpdateRequest {
    id: number;
    data: SlimSearchTemplate;
}

export interface ApiApiApiV1AdminCalibrationCandidatesBulkCreateRequest {
    data: CalibrationCandidate;
}

export interface ApiApiApiV1ArchiveReasonsUpdateRequest {
    id: string;
    data: ArchiveReason;
}

export interface ApiApiApiV1CampaignMessageRequestPartialUpdateRequest {
    id: string;
    data: UpdateCampaignMessageRequest;
}

export interface ApiApiApiV1CampaignsUpdateRequest {
    id: string;
    data: Campaign;
}

export interface ApiApiApiV1CandidateBiosUpdateRequest {
    id: string;
    data: CandidateBio;
}

export interface ApiApiApiV1CandidateNoteDeleteRequest {
    id: string;
}

export interface ApiApiApiV1CandidateNotePartialUpdateRequest {
    id: string;
    data: CandidateActivityNote;
}

export interface ApiApiApiV1CandidateNoteUpdateRequest {
    id: string;
    data: CandidateActivityNote;
}

export interface ApiApiApiV1ClientEmailTemplatesUpdateRequest {
    id: string;
    data: ClientEmailTemplate;
}

export interface ApiApiApiV1ClientImagesCreateRequest {
    fileContents: Blob;
}

export interface ApiApiApiV1ClientImagesListRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiApiV1ClientSetupUpdateRequest {
    id: string;
    data: ClientOnboarding;
}

export interface ApiApiApiV1ClientsUpdateRequest {
    id: string;
    data: Client;
}

export interface ApiApiApiV1ContactEmailsUpdateRequest {
    id: string;
    data: ContactEmail;
}

export interface ApiApiApiV1ContactPhoneNumbersUpdateRequest {
    id: string;
    data: ContactPhoneNumber;
}

export interface ApiApiApiV1ContactsUpdateRequest {
    id: string;
    data: Contact;
}

export interface ApiApiApiV1EmailsUpdateRequest {
    id: string;
    data: Email;
}

export interface ApiApiApiV1FeedbackTemplateUpdateRequest {
    id: string;
    data: UpdateFeedbackTemplateRequest;
}

export interface ApiApiApiV1GenericEmailResponseTemplatesUpdateRequest {
    id: string;
    data: GenericEmailResponseTemplate;
}

export interface ApiApiApiV1HiringStageEmailTemplatesUpdateRequest {
    id: string;
    data: HiringStageEmailTemplate;
}

export interface ApiApiApiV1InboundApplicationListRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiApiV1InboundApplicationQuestionDeleteRequest {
    id: string;
}

export interface ApiApiApiV1InboundApplicationQuestionPartialUpdateRequest {
    id: string;
    data: ApplicationQuestion;
}

export interface ApiApiApiV1InboundApplicationQuestionUpdateRequest {
    id: string;
    data: ApplicationQuestion;
}

export interface ApiApiApiV1InboundApplicationReadRequest {
    id: string;
}

export interface ApiApiApiV1InboundPartnerCreateRequest {
    data: ApplicationPortalInboundApplication;
}

export interface ApiApiApiV1InterviewerProUserUpdateRequest {
    user: string;
    data: ProUser;
}

export interface ApiApiApiV1InterviewerSchedulingInfoUpdateRequest {
    id: string;
    data: InterviewerSchedulingInfo;
}

export interface ApiApiApiV1JobDescriptionsUpdateRequest {
    id: string;
    data: DoverJobDescription;
}

export interface ApiApiApiV1JobReferralsUpdateRequest {
    id: string;
    data: JobReferral;
}

export interface ApiApiApiV1JobSetupsUpdateRequest {
    id: string;
    data: JobSetup;
}

export interface ApiApiApiV1JobsInterviewPlanPartialUpdateRequest {
    id: string;
    jobId: string;
    data: UpdateHiringPipelineStageRequest;
}

export interface ApiApiApiV1NotificationConfigPartialUpdateRequest {
    id: string;
    data: NotificationConfig;
}

export interface ApiApiApiV1OnboardingUserOnboardingFlowPartialUpdateRequest {
    id: string;
    data: UserOnboardingFlow;
}

export interface ApiApiApiV1OutreachGenerationReadRequest {
    id: string;
}

export interface ApiApiApiV1OutreachGenerationUpdateRequest {
    id: string;
    data: OutreachGeneration;
}

export interface ApiApiApiV1PartnerJobsListRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiApiV1ProfileAttributeDeleteRequest {
    id: string;
}

export interface ApiApiApiV1ProfileAttributeListRequest {
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiApiV1ProfileAttributePartialUpdateRequest {
    id: string;
    data: AdminProfileAttribute;
}

export interface ApiApiApiV1ProfileAttributeReadRequest {
    id: string;
}

export interface ApiApiApiV1RolesUpdateRequest {
    id: string;
    data: DoverRole;
}

export interface ApiApiApiV1UsersUpdateRequest {
    user: string;
    data: DoverUser | null;
}

export interface ApiApiApiV2MentionsUpdateRequest {
    id: string;
    data: Mention;
}

export interface ApiApiApiV2SaapplicationReviewListRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiApiV2TalentReviewListRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiAuthMetabaseUserRequest {
    returnTo: string;
}

export interface ApiApiAutocategoCandidateEmailRequest {
    data: InlineObject1;
}

export interface ApiApiAutoformatInterviewRubricResponseRequest {
    id: string;
    data: AutoformatInterviewRubricRequest;
}

export interface ApiApiBeginBillingPortalSessionOperationRequest {
    data: BeginBillingPortalSessionRequest;
}

export interface ApiApiBeginCheckoutOperationRequest {
    data: BeginCheckoutRequest;
}

export interface ApiApiBulkCreateCalibrationCandidatesRequest {
    data: Array<CalibrationCandidate>;
}

export interface ApiApiBulkDestroyCalibrationCandidatesRequest {
    data: Array<string>;
}

export interface ApiApiBulkUpdateCalibrationCandidatesRequest {
    data: Array<CalibrationCandidate>;
}

export interface ApiApiBulkUpdateCampaignsRequest {
    data: BulkCampaignUpdate;
}

export interface ApiApiBulkUpsertClientDomainsRequest {
    data: BulkUpsertClientDomain;
}

export interface ApiApiBulkUpsertJobFeatureSettingsRequest {
    id: string;
    data: Array<BulkUpsertJobFeatureSetting>;
}

export interface ApiApiCampaignSendTestEmailsOperationRequest {
    data: CampaignSendTestEmailsRequest;
}

export interface ApiApiCanManuallySourceProfileRequest {
    data: CheckCanCreateManualContextForJobRequest;
}

export interface ApiApiCancelEmailOperationRequest {
    id: string;
    data: CancelEmailRequest;
}

export interface ApiApiCancelInterviewRequest {
    candidateId: string;
    data: object;
}

export interface ApiApiCancelMultipartInterviewRequestRequest {
    data: CancelMultipartInterviewRequest;
}

export interface ApiApiCancelQueuedAutomatedEmailsRequest {
    data: InlineObject2;
}

export interface ApiApiChangeAppScoringEnabledOperationRequest {
    id: string;
    data: ChangeAppScoringEnabledRequest;
}

export interface ApiApiChangeArchiveReasonOperationRequest {
    id: string;
    data: ChangeArchiveReasonRequest;
}

export interface ApiApiChangeInterviewerOperationRequest {
    data: ChangeInterviewerRequest;
}

export interface ApiApiCheckIfProfileExistsOperationRequest {
    data: CheckIfProfileExistsRequest;
}

export interface ApiApiCloneSearchV3Request {
    data: InlineObject25;
}

export interface ApiApiCompleteCheckoutOperationRequest {
    data: CompleteCheckoutRequest;
}

export interface ApiApiConfirmJobPersonaOperationRequest {
    id: string;
    data: ConfirmJobPersonaRequest;
}

export interface ApiApiCreateApplicationPortalInboundApplicationRequest {
    jobId: string;
    firstName: string;
    lastName: string;
    email: string;
    referrerSource?: string | null;
    linkedinUrl?: string;
    phoneNumber?: string | null;
    resume?: Blob;
    additionalInfo?: string;
    applicationQuestions?: string;
    bookmarked?: boolean;
    optedInToTalentNetwork?: boolean | null;
}

export interface ApiApiCreateApplicationQuestionRequest {
    data: ApplicationQuestion;
}

export interface ApiApiCreateArchiveReasonRequest {
    data: ArchiveReason;
}

export interface ApiApiCreateCampaignRequest {
    data: CreateCampaign;
}

export interface ApiApiCreateCandidateActivityNoteRequest {
    data: CandidateActivityNote;
}

export interface ApiApiCreateCandidateFileRequest {
    candidate: string;
    uploadedContext: CreateCandidateFileUploadedContextEnum;
    name?: string | null;
    fileContents?: Blob;
}

export interface ApiApiCreateChangeLogForSearchRequest {
    data: GetChangeLogEntryRequest;
}

export interface ApiApiCreateClientEmailTemplateRequest {
    data: ClientEmailTemplate;
}

export interface ApiApiCreateCompanyListRequest {
    data: CompanyList;
}

export interface ApiApiCreateContactEmailsOperationRequest {
    data: CreateContactEmailsRequest;
}

export interface ApiApiCreateContactPhoneNumbersOperationRequest {
    data: CreateContactPhoneNumbersRequest;
}

export interface ApiApiCreateDtnCandidateOperationRequest {
    data: CreateDtnCandidateRequest;
}

export interface ApiApiCreateFeedbackTemplateOperationRequest {
    data: CreateFeedbackTemplateRequest;
}

export interface ApiApiCreateHiringPipelineStageOperationRequest {
    jobId: string;
    data: CreateHiringPipelineStageRequest;
}

export interface ApiApiCreateHiringStageEmailTemplateRequest {
    data: HiringStageEmailTemplate;
}

export interface ApiApiCreateInterviewerRequest {
    data: ClientInterviewer;
}

export interface ApiApiCreateJobDescriptionWithTextOperationRequest {
    data: CreateJobDescriptionWithTextRequest;
}

export interface ApiApiCreateJobPositionForHiringPlanRequest {
    data: HiringPlanJobPosition;
}

export interface ApiApiCreateJobPositionOpeningForHiringPlanRequest {
    data: HiringPlanJobPositionOpening;
}

export interface ApiApiCreateNewJobOperationRequest {
    data: CreateNewJobRequest;
}

export interface ApiApiCreateNotificationConfigRequest {
    data: NotificationConfig;
}

export interface ApiApiCreateOrUpdateOutreachGenerationRequest {
    data: OutreachGeneration;
}

export interface ApiApiCreatePersonaRequest {
    data: Persona;
}

export interface ApiApiCreateProUserRequest {
    data: DoverUser | null;
}

export interface ApiApiCreateProfileAttributesRequest {
    data: AdminProfileAttribute;
}

export interface ApiApiCreateReengagementTransformOperationRequest {
    data: CreateReengagementTransformRequest;
}

export interface ApiApiCreateSchoolListRequest {
    data: SchoolList;
}

export interface ApiApiCreateSearchRequest {
    data: AdminSearch;
}

export interface ApiApiCreateSearchFromPQRequest {
    data: InlineObject26;
}

export interface ApiApiCreateSearchV3Request {
    data: SearchV3;
}

export interface ApiApiCreateTitlePatternRequest {
    data: TitlePattern;
}

export interface ApiApiCreateUserRatingRequest {
    data: UserRating;
}

export interface ApiApiCreateUserUploadedFileRequest {
    fileContents: Blob;
    uploadedContext: CreateUserUploadedFileUploadedContextEnum;
    originalFileName?: string;
    lastProcessedAt?: Date;
}

export interface ApiApiDeactivateJobRequest {
    id: string;
    data: DeactivateJob;
}

export interface ApiApiDeleteClientEmailTemplateRequest {
    id: string;
}

export interface ApiApiDeleteCompanyListRequest {
    id: string;
}

export interface ApiApiDeleteContactEmailRequest {
    id: string;
}

export interface ApiApiDeleteContactPhoneNumberRequest {
    id: string;
}

export interface ApiApiDeleteEmailRequest {
    id: string;
}

export interface ApiApiDeleteFeedbackTemplateRequest {
    id: string;
}

export interface ApiApiDeleteHiringStageEmailTemplateRequest {
    id: string;
}

export interface ApiApiDeleteJobPositionForHiringPlanRequest {
    id: string;
}

export interface ApiApiDeleteJobPositionOpeningForHiringPlanRequest {
    id: number;
}

export interface ApiApiDeletePersonaRequest {
    id: string;
}

export interface ApiApiDeleteSchoolListRequest {
    id: string;
}

export interface ApiApiDestroyCandidateFileRequest {
    id: string;
}

export interface ApiApiDestroySearchRequest {
    id: string;
}

export interface ApiApiDetermineNextActionForCandidatesRequest {
    data: NextActionForCandidatesRequest;
}

export interface ApiApiDisableHiringPipelineStageRequest {
    id: string;
    jobId: string;
}

export interface ApiApiExchangeEmailIdForEmailOperationRequest {
    data: ExchangeEmailIdForEmailRequest;
}

export interface ApiApiExportDataAsCSVRequest {
    id: string;
}

export interface ApiApiExtensionV2CanSourceProfileRequest {
    data: CanSourceProfileRequest;
}

export interface ApiApiExtensionV2FindEmailsOperationRequest {
    data: ExtensionV2FindEmailsRequest;
}

export interface ApiApiFetchCandidateCountsRequest {
    jobId: string;
    data: CandidatePipelineCountRequest;
}

export interface ApiApiFetchFunnelStatsRequest {
    id: string;
}

export interface ApiApiFindAtsCandidateOperationRequest {
    data: FindAtsCandidateRequest;
}

export interface ApiApiFindDupeContactRequest {
    id: string;
    candidateId: string;
}

export interface ApiApiFindGenericResponseTemplatesRequest {
    data: InlineObject3;
}

export interface ApiApiFindRelatedCandidatesRequest {
    contactId: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiFindSlackUserDataRequest {
    data: InlineObject4;
}

export interface ApiApiFormatIndividualAIAnswerRequest {
    id: string;
    data: FormatAiAnswerRequest;
}

export interface ApiApiGenerateAndSaveCampaignContentRequest {
    id: string;
    data: InlineObject16;
}

export interface ApiApiGenerateCareersPageOperationRequest {
    data: GenerateCareersPageRequest;
}

export interface ApiApiGenerateEmailOutreachRequest {
    campaign: string;
}

export interface ApiApiGenerateInterviewQuestionsRequest {
    id: string;
    data: InterviewQuestionSuggestionsRequest;
}

export interface ApiApiGenerateJobDescriptionOperationRequest {
    data: GenerateJobDescriptionRequest;
}

export interface ApiApiGenerateNotInterestedResponseRequest {
    data: InlineObject5;
}

export interface ApiApiGenerateOutreachEmailRequest {
    id: string;
    data: OutreachGeneration;
}

export interface ApiApiGenerateOutreachSimpleRequest {
    data: GenerateOutreachNoProfileRequest;
}

export interface ApiApiGetApplicationPortalJobRequest {
    jobOrSearchId: string;
}

export interface ApiApiGetAshbyDataRequest {
    data: InlineObject15;
}

export interface ApiApiGetAtsInterviewFeedbackTemplatesRequest {
    id: string;
}

export interface ApiApiGetAtsInterviewStagesRequest {
    id: string;
}

export interface ApiApiGetBaseClientCampaignLimitsRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiGetBenchmarkPersonaChannelStatsRequest {
    id: string;
}

export interface ApiApiGetBulkRejectionTemplateOperationRequest {
    data: GetBulkRejectionTemplateRequest;
}

export interface ApiApiGetCampaignRequest {
    id: string;
}

export interface ApiApiGetCampaignMessageRequestRequest {
    id: string;
}

export interface ApiApiGetCampaignStatsRequest {
    id: string;
}

export interface ApiApiGetCandidateBioRequest {
    id: string;
}

export interface ApiApiGetCandidateByEmailRequest {
    data: InlineObject6;
}

export interface ApiApiGetCandidateEmailActivityOperationRequest {
    data: GetCandidateEmailActivityRequest;
}

export interface ApiApiGetCandidateSourceStatsRequest {
    id: string;
}

export interface ApiApiGetCandidateV2Request {
    id: string;
    expand?: string;
}

export interface ApiApiGetCanonicalCompanyInfoRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiGetCareersPageClientRequest {
    id: string;
}

export interface ApiApiGetCareersPageClientBySlugRequest {
    slug: string;
}

export interface ApiApiGetCareersPageJobsRequest {
    clientId: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiGetChangeLogsRequest {
    data: GetChangeLogEntryRequest;
}

export interface ApiApiGetClientEmailTemplateRequest {
    id: string;
}

export interface ApiApiGetClientInterviewerAvailabilityRequest {
    id: string;
}

export interface ApiApiGetClientInterviewerCalendarEventsRequest {
    id: string;
    start: number;
    end: number;
}

export interface ApiApiGetClientInterviewerForUserRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiGetClientOnboardingRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiGetClientUserFileStatsRequest {
    statsUploadedContext: string;
    uploadedContext?: string;
    processingState?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiGetCompaniesByUrnOperationRequest {
    data: GetCompaniesByUrnRequest;
}

export interface ApiApiGetCompanyPitchQuestionSchemaRequest {
    clientId?: string;
}

export interface ApiApiGetCompanySetupStepsRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiGetContactRequest {
    id: string;
}

export interface ApiApiGetDeactivationStatsRequest {
    id: string;
}

export interface ApiApiGetDoverEntityRequest {
    id: string;
}

export interface ApiApiGetDoverInterviewerCandidatesRequest {
    doverEntityId: string;
    isCallCompleted?: boolean;
}

export interface ApiApiGetDoverOutboundConfigurationRequest {
    id: string;
}

export interface ApiApiGetEmailTemplateV2Request {
    data: GetEmailTemplateRequestV2;
}

export interface ApiApiGetExampleLinkedinRequest {
    id: string;
}

export interface ApiApiGetFeatureFlagsByCandidateOperationRequest {
    data: GetFeatureFlagsByCandidateRequest;
}

export interface ApiApiGetFeedbackTemplateRequest {
    id: string;
}

export interface ApiApiGetGmailAuthValidRequest {
    userId?: string;
}

export interface ApiApiGetGreenhouseDataRequest {
    data: InlineObject18;
}

export interface ApiApiGetHiringPipelineStageRequest {
    id: string;
    jobId: string;
}

export interface ApiApiGetHiringStageEmailTemplateRequest {
    id: string;
}

export interface ApiApiGetInDepthExplanationOperationRequest {
    data: GetInDepthExplanationRequest;
}

export interface ApiApiGetIndustryRequest {
    id: string;
}

export interface ApiApiGetInitialCallInterviewRequest {
    id: string;
}

export interface ApiApiGetInterviewPlanByJobRequest {
    job: string;
}

export interface ApiApiGetInterviewRubricResponseRequest {
    candidateId: string;
    limit?: number;
    offset?: number;
    interviewId?: string;
    forceCreate?: boolean;
}

export interface ApiApiGetInterviewRubricResponseByIdRequest {
    id: string;
}

export interface ApiApiGetInterviewStatusRequest {
    candidateId: string;
}

export interface ApiApiGetInterviewerRequest {
    id: string;
}

export interface ApiApiGetInterviewerProUserRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiGetJobRequest {
    id: string;
}

export interface ApiApiGetJobApplicationEventRequest {
    id: string;
}

export interface ApiApiGetJobDescriptionRequest {
    id: string;
}

export interface ApiApiGetJobFeaturesRequest {
    id: string;
}

export interface ApiApiGetJobFunnelStatsRequest {
    id: string;
    start?: string;
    end?: string;
    channelName?: string;
    candidateSource?: Array<string>;
    demographic?: string;
}

export interface ApiApiGetJobInboundStatsRequest {
    id: string;
    start?: string;
    end?: string;
    channelName?: string;
    candidateSource?: Array<string>;
}

export interface ApiApiGetJobOutboundStatsRequest {
    id: string;
    start?: string;
    end?: string;
    channelName?: string;
    candidateSource?: Array<string>;
    demographic?: string;
}

export interface ApiApiGetJobOutreachBenchmarksRequest {
    id: string;
}

export interface ApiApiGetJobOutreachStatsRequest {
    id: string;
    start?: string;
    end?: string;
    channelName?: string;
    candidateSource?: Array<string>;
    demographic?: string;
}

export interface ApiApiGetJobPitchQuestionSchemaRequest {
    jobId: string;
}

export interface ApiApiGetJobPositionRequest {
    id: string;
}

export interface ApiApiGetJobReferralsStatsRequest {
    id: string;
    start?: string;
    end?: string;
}

export interface ApiApiGetJobReferrersRequest {
    job?: string;
    referrer?: string;
    triagingStatus?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiGetJobReportMetaRequest {
    id: string;
}

export interface ApiApiGetJobSetupRequest {
    id: string;
}

export interface ApiApiGetJobSetupStepsRequest {
    id: string;
}

export interface ApiApiGetKeywordRequest {
    id: string;
}

export interface ApiApiGetKeywordsWithBucketIndicesRequest {
    data: Array<KeywordWithBucketIndicesRequest>;
}

export interface ApiApiGetLeverAuthInfoRequest {
    id: string;
}

export interface ApiApiGetMatchingCountRequest {
    id: string;
}

export interface ApiApiGetMeetingTypeRequest {
    accountId?: string;
}

export interface ApiApiGetMissingEmailsRequest {
    data: InlineObject7;
}

export interface ApiApiGetMultipartInterviewStageRequest {
    id: string;
    candidateId?: string;
}

export interface ApiApiGetMultipartSchedulingCandidateRequest {
    id: string;
}

export interface ApiApiGetOrCreateApiKeyCredentialOperationRequest {
    data: GetOrCreateApiKeyCredentialRequest;
}

export interface ApiApiGetOrCreateKeywordWithNameRequest {
    data: InlineObject23;
}

export interface ApiApiGetOrCreateMultipartInterviewSubstagesRequest {
    data: GetOrCreateMultipartStagesRequest;
}

export interface ApiApiGetOrCreateUserOnboardingFlowOperationRequest {
    data: GetOrCreateUserOnboardingFlowRequest;
}

export interface ApiApiGetPQUsingPersonaV2Request {
    personaId: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiGetPersonaForJobOperationRequest {
    data: GetPersonaForJobRequest;
}

export interface ApiApiGetPersonaQuestionnaireRequest {
    id: string;
}

export interface ApiApiGetPersonaViaSoftMatchOperationRequest {
    data: GetPersonaViaSoftMatchRequest;
}

export interface ApiApiGetProUserRequest {
    user: string;
}

export interface ApiApiGetReferralStatsRequest {
    job?: string;
    referrer?: string;
    triagingStatus?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiGetRelatedKeywordsRequest {
    id: string;
}

export interface ApiApiGetSchedulableCalendarsRequest {
    id: string;
}

export interface ApiApiGetSchedulingLinkOperationRequest {
    data: GetSchedulingLinkRequest;
}

export interface ApiApiGetSearchRequest {
    id: string;
}

export interface ApiApiGetSearchCountEstimateRequest {
    data: EsSearchCountsRequest;
}

export interface ApiApiGetSearchParamsFromJDOperationRequest {
    data: GetSearchParamsFromJDRequest;
}

export interface ApiApiGetSearchTransformOutputRequest {
    id: string;
    searchTransformName: string;
}

export interface ApiApiGetSearchTransformRelationshipRequest {
    childSearch: string;
}

export interface ApiApiGetSearchV3Request {
    id: string;
}

export interface ApiApiGetSearchV3DepthResultRequest {
    data: DepthRequest;
}

export interface ApiApiGetSimilarCandidatesSearchesRequest {
    job?: string;
    searchTypeList?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiGetTranscriptForInterviewRequest {
    candidateInterviewId: string;
}

export interface ApiApiGetUserInfoRequest {
    isHiringManager?: string;
    limit?: number;
    offset?: number;
    checkEmailVerification?: boolean;
}

export interface ApiApiGetUserInterviewerSchedulingInfoRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiGetUserRatingsByEntityRequest {
    entityType: string;
    entityUuid: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiGetUserRolesAndPermissionsRequest {
    isHiringManager?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiGetUserUploadedFileRequest {
    id: string;
}

export interface ApiApiGetUsersClientRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiHasSeenOnboardingFlowRequest {
    flowName?: string;
    proUserUserEmail?: string;
    relatedObjectUuid?: string;
    contentTypeModel?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiHiringStageEmailTemplateSendTestEmailOperationRequest {
    data: HiringStageEmailTemplateSendTestEmailRequest;
}

export interface ApiApiJobCheckSlackbotAuthRequest {
    id: string;
}

export interface ApiApiListApplicationQuestionsRequest {
    limit?: number;
    offset?: number;
    clientId?: string;
    job?: string;
}

export interface ApiApiListApplicationsViaAIRequest {
    data: ListAppsViaAIRequest;
}

export interface ApiApiListArchiveReasonsRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListAtsSourcesRequest {
    ordering?: string;
    atsType?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListAuthenticatedUsersRequest {
    isHiringManager?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListBucketChildrenAndAliasesRequest {
    data: InlineObject24;
}

export interface ApiApiListCampaignsRequest {
    jobCampaignConfigJob?: string;
    jobCampaignConfigJobClient?: string;
    jobCampaignConfigJobPersona?: string;
    state?: string;
    fullTextSearch?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListCandidateActivityNotesRequest {
    candidate?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListCandidateEmailEventsV2Request {
    id: string;
}

export interface ApiApiListCandidateFilesRequest {
    limit?: number;
    offset?: number;
    candidateId?: string;
}

export interface ApiApiListCandidateInterviewEventsRequest {
    id: string;
}

export interface ApiApiListCandidateMovedJobEventsRequest {
    id: string;
}

export interface ApiApiListCandidateMultipartInterviewRequest {
    candidateId: string;
    interviewStageId: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListCandidateSourcesRequest {
    thirdPartyAllowed?: boolean;
    includeAdminPreview?: boolean;
    limit?: number;
    offset?: number;
    jobId?: string;
    includeYcWaas?: boolean;
}

export interface ApiApiListCandidateStageChangeEventsRequest {
    id: string;
}

export interface ApiApiListClientDomainsRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListClientEmailTemplatesRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListCompanyListsRequest {
    fullTextSearch?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListContactEmailsRequest {
    contactId: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListContactPhoneNumbersRequest {
    contactId: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListCurrencyOptionsRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListDoverEntitiesRequest {
    email?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListDoverInterviewerPreviewsRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListDoverOutboundPersonaStatsRequest {
    limit?: number;
    offset?: number;
    job?: string;
}

export interface ApiApiListEditCareersPageJobsRequest {
    clientId: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListEmailSenderAliasesRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListFeedbackFormsForInterviewRequest {
    interviewId: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListFeedbackTemplatesRequest {
    search?: string;
    limit?: number;
    offset?: number;
    name?: string;
    matchExact?: boolean;
}

export interface ApiApiListFieldsOfStudyRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListGenericEmailResponseTemplatesRequest {
    templateType?: string;
    jobId?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListHiringPipelineStagesRequest {
    jobId: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListHiringStageEmailTemplatesRequest {
    job?: string;
    templateTypeList?: string;
    requiredEmailAlias?: string;
    requiredInterviewer?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListIndustriesRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListIndustriesChildrenAndAliasesRequest {
    data: InlineObject20;
}

export interface ApiApiListInterviewRubricTemplatesForPersonaRequest {
    limit?: number;
    offset?: number;
    personaId?: number;
}

export interface ApiApiListInterviewersRequest {
    limit?: number;
    offset?: number;
    hiringPipelineStageId?: string;
}

export interface ApiApiListJobAtsSourceSettingsRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListJobCandidateSourceSettingsRequest {
    job?: string;
    direction?: string;
    state?: string;
    desiredState?: string;
    includeAdminPreview?: boolean;
    coreSource?: string;
    limit?: number;
    offset?: number;
    includeYcWaas?: boolean;
}

export interface ApiApiListJobPersonasRequest {
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListJobPositionsRequest {
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListJobReferralsRequest {
    job?: string;
    referrer?: string;
    triagingStatus?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListJobsRequest {
    fullTextSearch?: string;
    client?: string;
    active?: string;
    clientReviewType?: string;
    hasActiveCampaign?: string;
    myJobs?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListJobsWithSetupStepsRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListKeywordsRequest {
    limit?: number;
    offset?: number;
    customerFacing?: boolean;
}

export interface ApiApiListLocationOptionsRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListMentionsForUserRequest {
    created?: string;
    user?: string;
    markedAsRead?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListNotificationConfigRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListOnboardingHiringPipelineStagesRequest {
    job?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListOtherSearchesUsingPersonaRequest {
    data: OtherSearchesUsingPersonaRequest;
}

export interface ApiApiListPersonasRequest {
    idList?: string;
    fullTextSearch?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListPipelineCandidatesRequest {
    jobId: string;
    data: CandidatePipelineListRequest;
    expand?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListProUsersRequest {
    isHiringManager?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListProUsersByClientIdRequest {
    clientId?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListProfileSearchIndustriesRequest {
    idList?: string;
    fullTextSearch?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListProfileSearchJobsRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListProfileSearchKeywordsRequest {
    idList?: string;
    queryText?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListRecruitingPartnersRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListRolesRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListSaapReviewApplicationsRequest {
    data: ListSaapReviewApplicationRequest;
}

export interface ApiApiListSearchTransformsRequest {
    id: string;
}

export interface ApiApiListSearchV3Request {
    job?: string;
    searchTypeList?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListSearchV3ProfileResultsRequest {
    data: ProfileRequest;
}

export interface ApiApiListSearchesRequest {
    job?: string;
    jobClient?: string;
    active?: string;
    proOnly?: string;
    fullTextSearch?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListSimilarCompaniesRequest {
    idList?: string;
    fullTextSearch?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListSimilarSchoolsRequest {
    idList?: string;
    nameList?: string;
    fullTextSearch?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListSlimSearchTemplatesRequest {
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListStarredCandidatesRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListTalentNetworkRequest {
    data: TalentNetworkRequest;
}

export interface ApiApiListTitlePatternsRequest {
    idList?: string;
    fullTextSearch?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListTitlePatternsForPersonaRequest {
    data: PersonaIDRequest;
}

export interface ApiApiListUnsubscribeFootersRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListUserCredentialsRequest {
    limit?: number;
    offset?: number;
}

export interface ApiApiListUserFacingCampaignsRequest {
    showDeleted?: string;
    job?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListUserOnboardingFlowsRequest {
    flowName?: string;
    proUserUserEmail?: string;
    relatedObjectUuid?: string;
    contentTypeModel?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListUserUploadedFilesRequest {
    uploadedContext?: string;
    processingState?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListUsersRequest {
    fullTextSearch?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ApiApiListValidEmailSendRequestSendTimesRequest {
    data: ValidSendAtTimesRequest;
}

export interface ApiApiMarkJobReferralAsContactedRequest {
    id: string;
    data: JobReferral;
}

export interface ApiApiMarkOnboardingCompleteOperationRequest {
    data: MarkOnboardingCompleteRequest;
}

export interface ApiApiMergeContactsOperationRequest {
    data: MergeContactsRequest;
}

export interface ApiApiModifyTitlePatternsInSearchOperationRequest {
    id: string;
    data: ModifyTitlePatternsInSearchRequest;
}

export interface ApiApiMoveJobOperationRequest {
    id: string;
    data: MoveJobRequest;
}

export interface ApiApiParseCandidateInfoRequest {
    resume: Blob;
}

export interface ApiApiParseClientEmailTemplateRequest {
    data: ParseClientEmailTemplate;
}

export interface ApiApiParseJDFeaturesOperationRequest {
    data: ParseJDFeaturesRequest;
}

export interface ApiApiPartialBulkUpdateCalibrationCandidatesRequest {
    data: Array<CalibrationCandidate>;
}

export interface ApiApiPartialUpdateCampaignRequest {
    id: string;
    data: Campaign;
}

export interface ApiApiPartialUpdateCandidateBioRequest {
    id: string;
    data: UpdateCandidateBioBody;
}

export interface ApiApiPartialUpdateCandidateInterviewRequest {
    id: string;
    data: UpdateCandidateInterview;
}

export interface ApiApiPartialUpdateClientRequest {
    id: string;
    data: UpdateClientBody;
}

export interface ApiApiPartialUpdateClientEmailTemplateRequest {
    id: string;
    data: ClientEmailTemplate;
}

export interface ApiApiPartialUpdateCompanyListRequest {
    id: string;
    data: CompanyList;
}

export interface ApiApiPartialUpdateContactRequest {
    id: string;
    data: Contact;
}

export interface ApiApiPartialUpdateContactEmailRequest {
    id: string;
    data: ContactEmail;
}

export interface ApiApiPartialUpdateContactPhoneNumberRequest {
    id: string;
    data: ContactPhoneNumber;
}

export interface ApiApiPartialUpdateFeedbackTemplateRequest {
    id: string;
    data: UpdateFeedbackTemplateRequest;
}

export interface ApiApiPartialUpdateGenericEmailResponseTemplateRequest {
    id: string;
    data: GenericEmailResponseTemplate;
}

export interface ApiApiPartialUpdateHiringStageEmailTemplateRequest {
    id: string;
    data: HiringStageEmailTemplate;
}

export interface ApiApiPartialUpdateInboundApplicationRequest {
    id: string;
    jobId?: string;
    referrerSource?: string | null;
    firstName?: string;
    lastName?: string;
    linkedinUrl?: string;
    email?: string;
    phoneNumber?: string | null;
    resume?: Blob;
    additionalInfo?: string;
    applicationQuestions?: string;
    bookmarked?: boolean;
    optedInToTalentNetwork?: boolean | null;
}

export interface ApiApiPartialUpdateInterviewRubricResponseRequest {
    id: string;
    data: InterviewRubricResponse | null;
}

export interface ApiApiPartialUpdateInterviewStageRequest {
    id: string;
    jobId: string;
    data: OnboardingMultipartInterviewStage;
}

export interface ApiApiPartialUpdateInterviewerRequest {
    id: string;
    data: ClientInterviewer;
}

export interface ApiApiPartialUpdateInterviewerProUserRequest {
    user: string;
    data: ProUser;
}

export interface ApiApiPartialUpdateInterviewerSchedulingInfoRequest {
    id: string;
    data: InterviewerSchedulingInfo;
}

export interface ApiApiPartialUpdateJobPositionRequest {
    id: string;
    data: HiringPlanJobPosition;
}

export interface ApiApiPartialUpdateJobPositionOpeningRequest {
    id: number;
    data: HiringPlanJobPositionOpening;
}

export interface ApiApiPartialUpdateJobReferralRequest {
    id: string;
    data: JobReferral;
}

export interface ApiApiPartialUpdateJobSetupRequest {
    id: string;
    data: JobSetup;
}

export interface ApiApiPartialUpdateMentionRequest {
    id: string;
    data: Mention;
}

export interface ApiApiPartialUpdateOnboardingHiringPipelineStageRequest {
    id: string;
    data: OnboardingHiringPipelineStage;
}

export interface ApiApiPartialUpdateOnboardingMultipartInterviewStageRequest {
    id: string;
    data: OnboardingMultipartInterviewStage;
}

export interface ApiApiPartialUpdateOutreachGenerationRequest {
    id: string;
    data: OutreachGeneration;
}

export interface ApiApiPartialUpdatePersonaRequest {
    id: string;
    data: Persona;
}

export interface ApiApiPartialUpdateProUserRequest {
    user: string;
    data: DoverUser | null;
}

export interface ApiApiPartialUpdateRoleRequest {
    id: string;
    data: DoverRole;
}

export interface ApiApiPartialUpdateSchoolListRequest {
    id: string;
    data: SchoolList;
}

export interface ApiApiPartialUpdateSearchRequest {
    id: string;
    data: AdminSearch;
}

export interface ApiApiPartialUpdateSearchV3Request {
    id: string;
    data: SearchV3;
}

export interface ApiApiPatchClientOnboardingRequest {
    id: string;
    data: ClientOnboarding;
}

export interface ApiApiPostSlackThreadReplyRequest {
    data: InlineObject8;
}

export interface ApiApiQueueBulkRejectionRequest {
    data: QueueBulkRejectRequest;
}

export interface ApiApiQueueEmailRequest {
    id: string;
    data: SendEmailRequest;
}

export interface ApiApiQueueSchedulingRemindersFrontRequest {
    data: InlineObject9;
}

export interface ApiApiReactivateJobRequest {
    id: string;
    data: object;
}

export interface ApiApiRecordOutreachGenerationExtensionActionRequest {
    data: ExtensionRecordActionRequest;
}

export interface ApiApiRegenerateSampleCandidateOperationRequest {
    data: RegenerateSampleCandidateRequest;
}

export interface ApiApiRegisterSaapReviewedCandidateSubmissionRequest {
    data: RegisterSaapReviewedCandidateRequest;
}

export interface ApiApiRegisterSimilarCandidateReviewRequest {
    data: RegisterSimilarCandidateRequest;
}

export interface ApiApiRemoveCampaignFromSearchRequest {
    id: string;
    data: InlineObject28;
}

export interface ApiApiRemoveFailingPeopleFromOutboxRequest {
    data: RemoveFailingPeopleFromOutbox;
}

export interface ApiApiRemoveFromOutboxOperationRequest {
    data: RemoveFromOutboxRequest;
}

export interface ApiApiRemoveSampleCandidatesOperationRequest {
    data: RemoveSampleCandidatesRequest;
}

export interface ApiApiReplaceInterviewerRequest {
    candidateId: string;
    data: object;
}

export interface ApiApiRequestPlanUpgradeOperationRequest {
    data: RequestPlanUpgradeRequest;
}

export interface ApiApiRescheduleInterviewOperationRequest {
    id: string;
    data: RescheduleInterviewRequest;
}

export interface ApiApiResendEmailVerificationEmailOperationRequest {
    data: ResendEmailVerificationEmailRequest;
}

export interface ApiApiResetAndRelinkChildSearchRequest {
    id: string;
    data: object;
}

export interface ApiApiRetrieveEmailsForLinkedinPublicUrlOperationRequest {
    data: RetrieveEmailsForLinkedinPublicUrlRequest;
}

export interface ApiApiRetrieveJobReferralRequest {
    id: string;
}

export interface ApiApiRetrieveUserOnboardingFlowRequest {
    id: string;
}

export interface ApiApiRetrieveUserRatingRequest {
    id: string;
}

export interface ApiApiRevertToNeedsReviewRequest {
    id: string;
    data: JobReferral;
}

export interface ApiApiSaveCompanyPitchQuestionSchemaRequest {
    data: SaveCompanyPitchQuestionSchema;
}

export interface ApiApiSaveJobPitchQuestionSchemaRequest {
    jobId: string;
    data: SaveJobPitchQuestionSchema;
}

export interface ApiApiScheduleCandidateInterviewOperationRequest {
    data: ScheduleCandidateInterviewRequest;
}

export interface ApiApiSearchDoverRequest {
    search?: string;
    type?: string;
}

export interface ApiApiSendCandidateToSlackRequest {
    data: InlineObject10;
}

export interface ApiApiSendEmailRequest {
    id: string;
}

export interface ApiApiSendJobSlackChannelInviteRequest {
    id: string;
}

export interface ApiApiSendNewUserInvitationRequest {
    data: SendNewUserInvitationeRequest;
}

export interface ApiApiSetAliasRequest {
    data: InlineObject13;
}

export interface ApiApiSetAllEmailTemplateStateRequest {
    data: InlineObject19;
}

export interface ApiApiSetClientAliasRequest {
    data: InlineObject14;
}

export interface ApiApiSetUserBlockedFlagRequest {
    user: string;
    data: SetUserToBlockedRequest;
}

export interface ApiApiShowUnreviewedSimilarCandidatesOperationRequest {
    data: ShowUnreviewedSimilarCandidatesRequest;
}

export interface ApiApiSnoozeJobRequest {
    id: string;
    data: SnoozeJob;
}

export interface ApiApiSubmitDecisionOperationRequest {
    id: string;
    data: SubmitDecisionRequest;
}

export interface ApiApiSubmitExternalApplicantRequest {
    data: ExternalApplicantRequest;
}

export interface ApiApiSubmitMultipartInterviewSchedulingRequestRequest {
    data: SubmitMultipartInterviewSchedulingRequest;
}

export interface ApiApiSubmitNoShowRescheduleCandidateRequest {
    candidateId: string;
}

export interface ApiApiSubmitPartnerApplicantRequest {
    data: ExternalApplicantRequest;
}

export interface ApiApiSubmitPersonaQuestionnaireRequest {
    id: string;
    data: InlineObject22;
}

export interface ApiApiSubmitUserCredentialOperationRequest {
    data: SubmitUserCredentialRequest;
}

export interface ApiApiSwapInterviewStageTypeOperationRequest {
    id: string;
    jobId: string;
    data: SwapInterviewStageTypeRequest;
}

export interface ApiApiSyncAndListJobAtsStagesRequest {
    id: string;
    data: InlineObject21;
}

export interface ApiApiSyncAtsSourcesRequest {
    id: string;
    data: object;
}

export interface ApiApiToggleStarCandidateOperationRequest {
    data: ToggleStarCandidateRequest;
}

export interface ApiApiUpdateArchiveReasonRequest {
    id: string;
    data: ArchiveReason;
}

export interface ApiApiUpdateCampaignMessageRequestRequest {
    id: string;
    data: UpdateCampaignMessageRequest;
}

export interface ApiApiUpdateCandidateInterviewRequest {
    id: string;
    data: UpdateCandidateInterview;
}

export interface ApiApiUpdateCompanyListRequest {
    id: string;
    data: CompanyList;
}

export interface ApiApiUpdateDoverOutboundConfigurationRequest {
    id: string;
    data: UpdateDoverOutboundConfiguration;
}

export interface ApiApiUpdateEmailRequest {
    id: string;
    data: Email;
}

export interface ApiApiUpdateHiringPipelineStageOperationRequest {
    id: string;
    jobId: string;
    data: UpdateHiringPipelineStageRequest;
}

export interface ApiApiUpdateInboundApplicationRequest {
    id: string;
    jobId: string;
    firstName: string;
    lastName: string;
    email: string;
    referrerSource?: string | null;
    linkedinUrl?: string;
    phoneNumber?: string | null;
    resume?: Blob;
    additionalInfo?: string;
    applicationQuestions?: string;
    bookmarked?: boolean;
    optedInToTalentNetwork?: boolean | null;
}

export interface ApiApiUpdateInterviewRubricResponseRequest {
    id: string;
    data: InterviewRubricResponse | null;
}

export interface ApiApiUpdateInterviewerRequest {
    id: string;
    data: ClientInterviewer;
}

export interface ApiApiUpdateJobDescriptionRequest {
    id: string;
    data: UpdateJobDescription;
}

export interface ApiApiUpdateJobHiringTeamRequest {
    id: string;
    data: UpdateJobHiringTeam;
}

export interface ApiApiUpdateJobPositionRequest {
    id: string;
    data: HiringPlanJobPosition;
}

export interface ApiApiUpdateJobPositionOpeningRequest {
    id: number;
    data: HiringPlanJobPositionOpening;
}

export interface ApiApiUpdateJobVisibilityRequest {
    id: string;
    data: UpdateJobVisibility;
}

export interface ApiApiUpdateNotificationConfigRequest {
    id: string;
    data: NotificationConfig;
}

export interface ApiApiUpdateOnboardingHiringPipelineStageRequest {
    id: string;
    data: OnboardingHiringPipelineStage;
}

export interface ApiApiUpdatePendingCustomerResponseRequest {
    data: InlineObject11;
}

export interface ApiApiUpdatePersonaRequest {
    id: string;
    data: Persona;
}

export interface ApiApiUpdateProfileAttributesRequest {
    id: string;
    data: AdminProfileAttribute;
}

export interface ApiApiUpdateReplyTypeRequest {
    data: InlineObject12;
}

export interface ApiApiUpdateSchoolListRequest {
    id: string;
    data: SchoolList;
}

export interface ApiApiUpdateSearchRequest {
    id: string;
    data: AdminSearch;
}

export interface ApiApiUpdateSearchFromFeaturesOperationRequest {
    id: string;
    data: UpdateSearchFromFeaturesRequest;
}

export interface ApiApiUpdateSearchV3Request {
    id: string;
    data: SearchV3;
}

export interface ApiApiUpdateUserClientRoleOperationRequest {
    user: string;
    data: UpdateUserClientRoleRequest;
}

export interface ApiApiUpdateUserOnboardingFlowRequest {
    id: string;
    data: UserOnboardingFlow;
}

export interface ApiApiUploadLogoRequest {
    file: Blob;
}

export interface ApiApiUpsertJobCandidateSourceSettingRequest {
    data: CreateJobCandidateSourceSetting;
}

export interface ApiApiUpsertJobFeatureSettingRequest {
    data: UpsertJobFeatureSetting;
}

export interface ApiApiUpsertMultipleJobAtsSourceSettingsRequest {
    data: JobAtsSourceSettingUpsertMultipleRequest;
}

export interface ApiApiValidateEmailRequest {
    data: Email;
}

export interface ApiApiValidateSchedulingLinkForCandidateOperationRequest {
    data: ValidateSchedulingLinkForCandidateRequest;
}

export interface ApiApiWithdrawCandidateRequest {
    candidateId: string;
    data: InlineObject17;
}

/**
 * ApiApi - interface
 * 
 * @export
 * @interface ApiApiInterface
 */
export interface ApiApiInterface {
    /**
     * Associate a campaign with a search
     * @param {string} id 
     * @param {InlineObject27} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    addCampaignToSearchRaw(requestParameters: ApiApiAddCampaignToSearchRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Associate a campaign with a search
     */
    addCampaignToSearch(requestParameters: ApiApiAddCampaignToSearchRequest): Promise<InlineResponse2001>;

    /**
     * manually add a candidate to initial outreach
     * @param {string} job 
     * @param {string} firstName 
     * @param {string} lastName 
     * @param {string} campaign 
     * @param {string} [email] 
     * @param {string} [emailId] 
     * @param {string} [linkedinProfileUrl] 
     * @param {Blob} [resume] 
     * @param {string} [firstMessageBody] 
     * @param {string} [firstMessageSubject] 
     * @param {Array<string>} [ccEmails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    addCandidateInitialOutreachFlowRaw(requestParameters: ApiApiAddCandidateInitialOutreachFlowRequest): Promise<runtime.ApiResponse<AddCandidateInitialOutreachResponse>>;

    /**
     * manually add a candidate to initial outreach
     */
    addCandidateInitialOutreachFlow(requestParameters: ApiApiAddCandidateInitialOutreachFlowRequest): Promise<AddCandidateInitialOutreachResponse>;

    /**
     * manually add a candidates in bulk to initial outreach
     * @param {AddCandidateInitialOutreachBulkRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    addCandidateInitialOutreachFlowBulkRaw(requestParameters: ApiApiAddCandidateInitialOutreachFlowBulkRequest): Promise<runtime.ApiResponse<AddCandidateInitialOutreachBulkResponse>>;

    /**
     * manually add a candidates in bulk to initial outreach
     */
    addCandidateInitialOutreachFlowBulk(requestParameters: ApiApiAddCandidateInitialOutreachFlowBulkRequest): Promise<AddCandidateInitialOutreachBulkResponse>;

    /**
     * manually add a candidate to the interview process
     * @param {string} job 
     * @param {string} pipelineStage 
     * @param {string} firstName 
     * @param {string} lastName 
     * @param {string} [email] 
     * @param {string} [linkedinProfileUrl] 
     * @param {string} [sourceId] 
     * @param {string} [sourceName] 
     * @param {string} [agencyName] 
     * @param {string} [agencyEmail] 
     * @param {Blob} [resume] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    addCandidateToInterviewProcessFlowRaw(requestParameters: ApiApiAddCandidateToInterviewProcessFlowRequest): Promise<runtime.ApiResponse<AddCandidateInterviewProcessResponse>>;

    /**
     * manually add a candidate to the interview process
     */
    addCandidateToInterviewProcessFlow(requestParameters: ApiApiAddCandidateToInterviewProcessFlowRequest): Promise<AddCandidateInterviewProcessResponse>;

    /**
     * create candidate email
     * @param {Email} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    addEmailRaw(requestParameters: ApiApiAddEmailRequest): Promise<runtime.ApiResponse<Email>>;

    /**
     * create candidate email
     */
    addEmail(requestParameters: ApiApiAddEmailRequest): Promise<Email>;

    /**
     * add a given email to a candidate and return the original candidate info
     * @param {InlineObject} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    addEmailToCandidateRaw(requestParameters: ApiApiAddEmailToCandidateRequest): Promise<runtime.ApiResponse<FrontCandidate>>;

    /**
     * add a given email to a candidate and return the original candidate info
     */
    addEmailToCandidate(requestParameters: ApiApiAddEmailToCandidateRequest): Promise<FrontCandidate>;

    /**
     * Add questions to an interview rubric response
     * @param {string} id A UUID string identifying this interview rubric response.
     * @param {AddQuestionsToInterviewRubricResponseRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    addQuestionsToInterviewRubricResponseRaw(requestParameters: ApiApiAddQuestionsToInterviewRubricResponseOperationRequest): Promise<runtime.ApiResponse<InterviewRubricResponse>>;

    /**
     * Add questions to an interview rubric response
     */
    addQuestionsToInterviewRubricResponse(requestParameters: ApiApiAddQuestionsToInterviewRubricResponseOperationRequest): Promise<InterviewRubricResponse>;

    /**
     * admin list clients
     * @param {string} [fullTextSearch] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    adminListClientsRaw(requestParameters: ApiApiAdminListClientsRequest): Promise<runtime.ApiResponse<InlineResponse2008>>;

    /**
     * admin list clients
     */
    adminListClients(requestParameters: ApiApiAdminListClientsRequest): Promise<InlineResponse2008>;

    /**
     * 
     * @param {AdminCampaign} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1AdminAdminCampaignsCreateRaw(requestParameters: ApiApiApiV1AdminAdminCampaignsCreateRequest): Promise<runtime.ApiResponse<AdminCampaign>>;

    /**
     */
    apiV1AdminAdminCampaignsCreate(requestParameters: ApiApiApiV1AdminAdminCampaignsCreateRequest): Promise<AdminCampaign>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1AdminAdminCampaignsDeleteRaw(requestParameters: ApiApiApiV1AdminAdminCampaignsDeleteRequest): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiV1AdminAdminCampaignsDelete(requestParameters: ApiApiApiV1AdminAdminCampaignsDeleteRequest): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {AdminCampaign} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1AdminAdminCampaignsPartialUpdateRaw(requestParameters: ApiApiApiV1AdminAdminCampaignsPartialUpdateRequest): Promise<runtime.ApiResponse<AdminCampaign>>;

    /**
     */
    apiV1AdminAdminCampaignsPartialUpdate(requestParameters: ApiApiApiV1AdminAdminCampaignsPartialUpdateRequest): Promise<AdminCampaign>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1AdminAdminCampaignsReadRaw(requestParameters: ApiApiApiV1AdminAdminCampaignsReadRequest): Promise<runtime.ApiResponse<AdminCampaign>>;

    /**
     */
    apiV1AdminAdminCampaignsRead(requestParameters: ApiApiApiV1AdminAdminCampaignsReadRequest): Promise<AdminCampaign>;

    /**
     * 
     * @param {string} id 
     * @param {AdminCampaign} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1AdminAdminCampaignsUpdateRaw(requestParameters: ApiApiApiV1AdminAdminCampaignsUpdateRequest): Promise<runtime.ApiResponse<AdminCampaign>>;

    /**
     */
    apiV1AdminAdminCampaignsUpdate(requestParameters: ApiApiApiV1AdminAdminCampaignsUpdateRequest): Promise<AdminCampaign>;

    /**
     * 
     * @param {SlimSearchTemplate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1AdminAdminSlimSearchTemplatesCreateRaw(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesCreateRequest): Promise<runtime.ApiResponse<SlimSearchTemplate>>;

    /**
     */
    apiV1AdminAdminSlimSearchTemplatesCreate(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesCreateRequest): Promise<SlimSearchTemplate>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Persona.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1AdminAdminSlimSearchTemplatesDeleteRaw(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesDeleteRequest): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiV1AdminAdminSlimSearchTemplatesDelete(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesDeleteRequest): Promise<void>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Persona.
     * @param {SlimSearchTemplate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1AdminAdminSlimSearchTemplatesPartialUpdateRaw(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesPartialUpdateRequest): Promise<runtime.ApiResponse<SlimSearchTemplate>>;

    /**
     */
    apiV1AdminAdminSlimSearchTemplatesPartialUpdate(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesPartialUpdateRequest): Promise<SlimSearchTemplate>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Persona.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1AdminAdminSlimSearchTemplatesReadRaw(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesReadRequest): Promise<runtime.ApiResponse<SlimSearchTemplate>>;

    /**
     */
    apiV1AdminAdminSlimSearchTemplatesRead(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesReadRequest): Promise<SlimSearchTemplate>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Persona.
     * @param {SlimSearchTemplate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1AdminAdminSlimSearchTemplatesUpdateRaw(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesUpdateRequest): Promise<runtime.ApiResponse<SlimSearchTemplate>>;

    /**
     */
    apiV1AdminAdminSlimSearchTemplatesUpdate(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesUpdateRequest): Promise<SlimSearchTemplate>;

    /**
     * 
     * @param {CalibrationCandidate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1AdminCalibrationCandidatesBulkCreateRaw(requestParameters: ApiApiApiV1AdminCalibrationCandidatesBulkCreateRequest): Promise<runtime.ApiResponse<CalibrationCandidate>>;

    /**
     */
    apiV1AdminCalibrationCandidatesBulkCreate(requestParameters: ApiApiApiV1AdminCalibrationCandidatesBulkCreateRequest): Promise<CalibrationCandidate>;

    /**
     * 
     * @param {string} id A UUID string identifying this archive reason.
     * @param {ArchiveReason} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1ArchiveReasonsUpdateRaw(requestParameters: ApiApiApiV1ArchiveReasonsUpdateRequest): Promise<runtime.ApiResponse<ArchiveReason>>;

    /**
     */
    apiV1ArchiveReasonsUpdate(requestParameters: ApiApiApiV1ArchiveReasonsUpdateRequest): Promise<ArchiveReason>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateCampaignMessageRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1CampaignMessageRequestPartialUpdateRaw(requestParameters: ApiApiApiV1CampaignMessageRequestPartialUpdateRequest): Promise<runtime.ApiResponse<UpdateCampaignMessageRequest>>;

    /**
     */
    apiV1CampaignMessageRequestPartialUpdate(requestParameters: ApiApiApiV1CampaignMessageRequestPartialUpdateRequest): Promise<UpdateCampaignMessageRequest>;

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {Campaign} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1CampaignsUpdateRaw(requestParameters: ApiApiApiV1CampaignsUpdateRequest): Promise<runtime.ApiResponse<Campaign>>;

    /**
     */
    apiV1CampaignsUpdate(requestParameters: ApiApiApiV1CampaignsUpdateRequest): Promise<Campaign>;

    /**
     * 
     * @param {string} id 
     * @param {CandidateBio} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1CandidateBiosUpdateRaw(requestParameters: ApiApiApiV1CandidateBiosUpdateRequest): Promise<runtime.ApiResponse<CandidateBio>>;

    /**
     */
    apiV1CandidateBiosUpdate(requestParameters: ApiApiApiV1CandidateBiosUpdateRequest): Promise<CandidateBio>;

    /**
     * 
     * @param {string} id A UUID string identifying this candidate activity note.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1CandidateNoteDeleteRaw(requestParameters: ApiApiApiV1CandidateNoteDeleteRequest): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiV1CandidateNoteDelete(requestParameters: ApiApiApiV1CandidateNoteDeleteRequest): Promise<void>;

    /**
     * 
     * @param {string} id A UUID string identifying this candidate activity note.
     * @param {CandidateActivityNote} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1CandidateNotePartialUpdateRaw(requestParameters: ApiApiApiV1CandidateNotePartialUpdateRequest): Promise<runtime.ApiResponse<CandidateActivityNote>>;

    /**
     */
    apiV1CandidateNotePartialUpdate(requestParameters: ApiApiApiV1CandidateNotePartialUpdateRequest): Promise<CandidateActivityNote>;

    /**
     * 
     * @param {string} id A UUID string identifying this candidate activity note.
     * @param {CandidateActivityNote} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1CandidateNoteUpdateRaw(requestParameters: ApiApiApiV1CandidateNoteUpdateRequest): Promise<runtime.ApiResponse<CandidateActivityNote>>;

    /**
     */
    apiV1CandidateNoteUpdate(requestParameters: ApiApiApiV1CandidateNoteUpdateRequest): Promise<CandidateActivityNote>;

    /**
     * 
     * @param {string} id 
     * @param {ClientEmailTemplate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1ClientEmailTemplatesUpdateRaw(requestParameters: ApiApiApiV1ClientEmailTemplatesUpdateRequest): Promise<runtime.ApiResponse<ClientEmailTemplate>>;

    /**
     */
    apiV1ClientEmailTemplatesUpdate(requestParameters: ApiApiApiV1ClientEmailTemplatesUpdateRequest): Promise<ClientEmailTemplate>;

    /**
     * 
     * @param {Blob} fileContents 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1ClientImagesCreateRaw(requestParameters: ApiApiApiV1ClientImagesCreateRequest): Promise<runtime.ApiResponse<ClientImage>>;

    /**
     */
    apiV1ClientImagesCreate(requestParameters: ApiApiApiV1ClientImagesCreateRequest): Promise<ClientImage>;

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1ClientImagesListRaw(requestParameters: ApiApiApiV1ClientImagesListRequest): Promise<runtime.ApiResponse<InlineResponse20020>>;

    /**
     */
    apiV1ClientImagesList(requestParameters: ApiApiApiV1ClientImagesListRequest): Promise<InlineResponse20020>;

    /**
     * 
     * @param {string} id 
     * @param {ClientOnboarding} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1ClientSetupUpdateRaw(requestParameters: ApiApiApiV1ClientSetupUpdateRequest): Promise<runtime.ApiResponse<ClientOnboarding>>;

    /**
     */
    apiV1ClientSetupUpdate(requestParameters: ApiApiApiV1ClientSetupUpdateRequest): Promise<ClientOnboarding>;

    /**
     * 
     * @param {string} id A UUID string identifying this client.
     * @param {Client} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1ClientsUpdateRaw(requestParameters: ApiApiApiV1ClientsUpdateRequest): Promise<runtime.ApiResponse<Client>>;

    /**
     */
    apiV1ClientsUpdate(requestParameters: ApiApiApiV1ClientsUpdateRequest): Promise<Client>;

    /**
     * 
     * @param {string} id 
     * @param {ContactEmail} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1ContactEmailsUpdateRaw(requestParameters: ApiApiApiV1ContactEmailsUpdateRequest): Promise<runtime.ApiResponse<ContactEmail>>;

    /**
     */
    apiV1ContactEmailsUpdate(requestParameters: ApiApiApiV1ContactEmailsUpdateRequest): Promise<ContactEmail>;

    /**
     * 
     * @param {string} id 
     * @param {ContactPhoneNumber} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1ContactPhoneNumbersUpdateRaw(requestParameters: ApiApiApiV1ContactPhoneNumbersUpdateRequest): Promise<runtime.ApiResponse<ContactPhoneNumber>>;

    /**
     */
    apiV1ContactPhoneNumbersUpdate(requestParameters: ApiApiApiV1ContactPhoneNumbersUpdateRequest): Promise<ContactPhoneNumber>;

    /**
     * 
     * @param {string} id 
     * @param {Contact} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1ContactsUpdateRaw(requestParameters: ApiApiApiV1ContactsUpdateRequest): Promise<runtime.ApiResponse<Contact>>;

    /**
     */
    apiV1ContactsUpdate(requestParameters: ApiApiApiV1ContactsUpdateRequest): Promise<Contact>;

    /**
     * 
     * @param {string} id A UUID string identifying this email.
     * @param {Email} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1EmailsUpdateRaw(requestParameters: ApiApiApiV1EmailsUpdateRequest): Promise<runtime.ApiResponse<Email>>;

    /**
     */
    apiV1EmailsUpdate(requestParameters: ApiApiApiV1EmailsUpdateRequest): Promise<Email>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateFeedbackTemplateRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1FeedbackTemplateUpdateRaw(requestParameters: ApiApiApiV1FeedbackTemplateUpdateRequest): Promise<runtime.ApiResponse<UpdateFeedbackTemplateRequest>>;

    /**
     */
    apiV1FeedbackTemplateUpdate(requestParameters: ApiApiApiV1FeedbackTemplateUpdateRequest): Promise<UpdateFeedbackTemplateRequest>;

    /**
     * 
     * @param {string} id A UUID string identifying this generic email response template.
     * @param {GenericEmailResponseTemplate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1GenericEmailResponseTemplatesUpdateRaw(requestParameters: ApiApiApiV1GenericEmailResponseTemplatesUpdateRequest): Promise<runtime.ApiResponse<GenericEmailResponseTemplate>>;

    /**
     */
    apiV1GenericEmailResponseTemplatesUpdate(requestParameters: ApiApiApiV1GenericEmailResponseTemplatesUpdateRequest): Promise<GenericEmailResponseTemplate>;

    /**
     * 
     * @param {string} id A UUID string identifying this hiring stage email template.
     * @param {HiringStageEmailTemplate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1HiringStageEmailTemplatesUpdateRaw(requestParameters: ApiApiApiV1HiringStageEmailTemplatesUpdateRequest): Promise<runtime.ApiResponse<HiringStageEmailTemplate>>;

    /**
     */
    apiV1HiringStageEmailTemplatesUpdate(requestParameters: ApiApiApiV1HiringStageEmailTemplatesUpdateRequest): Promise<HiringStageEmailTemplate>;

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1InboundApplicationListRaw(requestParameters: ApiApiApiV1InboundApplicationListRequest): Promise<runtime.ApiResponse<InlineResponse20039>>;

    /**
     */
    apiV1InboundApplicationList(requestParameters: ApiApiApiV1InboundApplicationListRequest): Promise<InlineResponse20039>;

    /**
     * 
     * @param {string} id A UUID string identifying this application question.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1InboundApplicationQuestionDeleteRaw(requestParameters: ApiApiApiV1InboundApplicationQuestionDeleteRequest): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiV1InboundApplicationQuestionDelete(requestParameters: ApiApiApiV1InboundApplicationQuestionDeleteRequest): Promise<void>;

    /**
     * 
     * @param {string} id A UUID string identifying this application question.
     * @param {ApplicationQuestion} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1InboundApplicationQuestionPartialUpdateRaw(requestParameters: ApiApiApiV1InboundApplicationQuestionPartialUpdateRequest): Promise<runtime.ApiResponse<ApplicationQuestion>>;

    /**
     */
    apiV1InboundApplicationQuestionPartialUpdate(requestParameters: ApiApiApiV1InboundApplicationQuestionPartialUpdateRequest): Promise<ApplicationQuestion>;

    /**
     * 
     * @param {string} id A UUID string identifying this application question.
     * @param {ApplicationQuestion} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1InboundApplicationQuestionUpdateRaw(requestParameters: ApiApiApiV1InboundApplicationQuestionUpdateRequest): Promise<runtime.ApiResponse<ApplicationQuestion>>;

    /**
     */
    apiV1InboundApplicationQuestionUpdate(requestParameters: ApiApiApiV1InboundApplicationQuestionUpdateRequest): Promise<ApplicationQuestion>;

    /**
     * 
     * @param {string} id A UUID string identifying this inbound application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1InboundApplicationReadRaw(requestParameters: ApiApiApiV1InboundApplicationReadRequest): Promise<runtime.ApiResponse<InboundApplicationResponse>>;

    /**
     */
    apiV1InboundApplicationRead(requestParameters: ApiApiApiV1InboundApplicationReadRequest): Promise<InboundApplicationResponse>;

    /**
     * 
     * @param {ApplicationPortalInboundApplication} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1InboundPartnerCreateRaw(requestParameters: ApiApiApiV1InboundPartnerCreateRequest): Promise<runtime.ApiResponse<ApplicationPortalInboundApplication>>;

    /**
     */
    apiV1InboundPartnerCreate(requestParameters: ApiApiApiV1InboundPartnerCreateRequest): Promise<ApplicationPortalInboundApplication>;

    /**
     * 
     * @param {string} user A unique value identifying this pro user.
     * @param {ProUser} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1InterviewerProUserUpdateRaw(requestParameters: ApiApiApiV1InterviewerProUserUpdateRequest): Promise<runtime.ApiResponse<ProUser>>;

    /**
     */
    apiV1InterviewerProUserUpdate(requestParameters: ApiApiApiV1InterviewerProUserUpdateRequest): Promise<ProUser>;

    /**
     * 
     * @param {string} id A UUID string identifying this interviewer scheduling info.
     * @param {InterviewerSchedulingInfo} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1InterviewerSchedulingInfoUpdateRaw(requestParameters: ApiApiApiV1InterviewerSchedulingInfoUpdateRequest): Promise<runtime.ApiResponse<InterviewerSchedulingInfo>>;

    /**
     */
    apiV1InterviewerSchedulingInfoUpdate(requestParameters: ApiApiApiV1InterviewerSchedulingInfoUpdateRequest): Promise<InterviewerSchedulingInfo>;

    /**
     * 
     * @param {string} id A UUID string identifying this job description.
     * @param {DoverJobDescription} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1JobDescriptionsUpdateRaw(requestParameters: ApiApiApiV1JobDescriptionsUpdateRequest): Promise<runtime.ApiResponse<DoverJobDescription>>;

    /**
     */
    apiV1JobDescriptionsUpdate(requestParameters: ApiApiApiV1JobDescriptionsUpdateRequest): Promise<DoverJobDescription>;

    /**
     * 
     * @param {string} id 
     * @param {JobReferral} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1JobReferralsUpdateRaw(requestParameters: ApiApiApiV1JobReferralsUpdateRequest): Promise<runtime.ApiResponse<JobReferral>>;

    /**
     */
    apiV1JobReferralsUpdate(requestParameters: ApiApiApiV1JobReferralsUpdateRequest): Promise<JobReferral>;

    /**
     * 
     * @param {string} id 
     * @param {JobSetup} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1JobSetupsUpdateRaw(requestParameters: ApiApiApiV1JobSetupsUpdateRequest): Promise<runtime.ApiResponse<JobSetup>>;

    /**
     */
    apiV1JobSetupsUpdate(requestParameters: ApiApiApiV1JobSetupsUpdateRequest): Promise<JobSetup>;

    /**
     * 
     * @param {string} id 
     * @param {string} jobId 
     * @param {UpdateHiringPipelineStageRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1JobsInterviewPlanPartialUpdateRaw(requestParameters: ApiApiApiV1JobsInterviewPlanPartialUpdateRequest): Promise<runtime.ApiResponse<UpdateHiringPipelineStageRequest>>;

    /**
     */
    apiV1JobsInterviewPlanPartialUpdate(requestParameters: ApiApiApiV1JobsInterviewPlanPartialUpdateRequest): Promise<UpdateHiringPipelineStageRequest>;

    /**
     * 
     * @param {string} id 
     * @param {NotificationConfig} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1NotificationConfigPartialUpdateRaw(requestParameters: ApiApiApiV1NotificationConfigPartialUpdateRequest): Promise<runtime.ApiResponse<NotificationConfig>>;

    /**
     */
    apiV1NotificationConfigPartialUpdate(requestParameters: ApiApiApiV1NotificationConfigPartialUpdateRequest): Promise<NotificationConfig>;

    /**
     * 
     * @param {string} id A UUID string identifying this user onboarding flow.
     * @param {UserOnboardingFlow} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1OnboardingUserOnboardingFlowPartialUpdateRaw(requestParameters: ApiApiApiV1OnboardingUserOnboardingFlowPartialUpdateRequest): Promise<runtime.ApiResponse<UserOnboardingFlow>>;

    /**
     */
    apiV1OnboardingUserOnboardingFlowPartialUpdate(requestParameters: ApiApiApiV1OnboardingUserOnboardingFlowPartialUpdateRequest): Promise<UserOnboardingFlow>;

    /**
     * 
     * @param {string} id A UUID string identifying this outreach generation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1OutreachGenerationReadRaw(requestParameters: ApiApiApiV1OutreachGenerationReadRequest): Promise<runtime.ApiResponse<OutreachGeneration>>;

    /**
     */
    apiV1OutreachGenerationRead(requestParameters: ApiApiApiV1OutreachGenerationReadRequest): Promise<OutreachGeneration>;

    /**
     * 
     * @param {string} id A UUID string identifying this outreach generation.
     * @param {OutreachGeneration} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1OutreachGenerationUpdateRaw(requestParameters: ApiApiApiV1OutreachGenerationUpdateRequest): Promise<runtime.ApiResponse<OutreachGeneration>>;

    /**
     */
    apiV1OutreachGenerationUpdate(requestParameters: ApiApiApiV1OutreachGenerationUpdateRequest): Promise<OutreachGeneration>;

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1PartnerJobsListRaw(requestParameters: ApiApiApiV1PartnerJobsListRequest): Promise<runtime.ApiResponse<InlineResponse20057>>;

    /**
     */
    apiV1PartnerJobsList(requestParameters: ApiApiApiV1PartnerJobsListRequest): Promise<InlineResponse20057>;

    /**
     * 
     * @param {string} id A UUID string identifying this profile attribute.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1ProfileAttributeDeleteRaw(requestParameters: ApiApiApiV1ProfileAttributeDeleteRequest): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiV1ProfileAttributeDelete(requestParameters: ApiApiApiV1ProfileAttributeDeleteRequest): Promise<void>;

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1ProfileAttributeListRaw(requestParameters: ApiApiApiV1ProfileAttributeListRequest): Promise<runtime.ApiResponse<InlineResponse20059>>;

    /**
     */
    apiV1ProfileAttributeList(requestParameters: ApiApiApiV1ProfileAttributeListRequest): Promise<InlineResponse20059>;

    /**
     * 
     * @param {string} id A UUID string identifying this profile attribute.
     * @param {AdminProfileAttribute} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1ProfileAttributePartialUpdateRaw(requestParameters: ApiApiApiV1ProfileAttributePartialUpdateRequest): Promise<runtime.ApiResponse<AdminProfileAttribute>>;

    /**
     */
    apiV1ProfileAttributePartialUpdate(requestParameters: ApiApiApiV1ProfileAttributePartialUpdateRequest): Promise<AdminProfileAttribute>;

    /**
     * 
     * @param {string} id A UUID string identifying this profile attribute.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1ProfileAttributeReadRaw(requestParameters: ApiApiApiV1ProfileAttributeReadRequest): Promise<runtime.ApiResponse<AdminProfileAttribute>>;

    /**
     */
    apiV1ProfileAttributeRead(requestParameters: ApiApiApiV1ProfileAttributeReadRequest): Promise<AdminProfileAttribute>;

    /**
     * 
     * @param {string} id A UUID string identifying this dover role.
     * @param {DoverRole} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1RolesUpdateRaw(requestParameters: ApiApiApiV1RolesUpdateRequest): Promise<runtime.ApiResponse<DoverRole>>;

    /**
     */
    apiV1RolesUpdate(requestParameters: ApiApiApiV1RolesUpdateRequest): Promise<DoverRole>;

    /**
     * 
     * @param {string} user 
     * @param {DoverUser} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV1UsersUpdateRaw(requestParameters: ApiApiApiV1UsersUpdateRequest): Promise<runtime.ApiResponse<DoverUser>>;

    /**
     */
    apiV1UsersUpdate(requestParameters: ApiApiApiV1UsersUpdateRequest): Promise<DoverUser>;

    /**
     * 
     * @param {string} id A UUID string identifying this mention.
     * @param {Mention} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV2MentionsUpdateRaw(requestParameters: ApiApiApiV2MentionsUpdateRequest): Promise<runtime.ApiResponse<Mention>>;

    /**
     */
    apiV2MentionsUpdate(requestParameters: ApiApiApiV2MentionsUpdateRequest): Promise<Mention>;

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV2SaapplicationReviewListRaw(requestParameters: ApiApiApiV2SaapplicationReviewListRequest): Promise<runtime.ApiResponse<InlineResponse20074>>;

    /**
     */
    apiV2SaapplicationReviewList(requestParameters: ApiApiApiV2SaapplicationReviewListRequest): Promise<InlineResponse20074>;

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    apiV2TalentReviewListRaw(requestParameters: ApiApiApiV2TalentReviewListRequest): Promise<runtime.ApiResponse<InlineResponse20076>>;

    /**
     */
    apiV2TalentReviewList(requestParameters: ApiApiApiV2TalentReviewListRequest): Promise<InlineResponse20076>;

    /**
     * Authenticate Metabase user
     * @param {string} returnTo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    authMetabaseUserRaw(requestParameters: ApiApiAuthMetabaseUserRequest): Promise<runtime.ApiResponse<AuthMetabaseUserResponse>>;

    /**
     * Authenticate Metabase user
     */
    authMetabaseUser(requestParameters: ApiApiAuthMetabaseUserRequest): Promise<AuthMetabaseUserResponse>;

    /**
     * Use GPT3 to do initial categorization of a candidate email
     * @param {InlineObject1} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    autocategoCandidateEmailRaw(requestParameters: ApiApiAutocategoCandidateEmailRequest): Promise<runtime.ApiResponse<InlineResponse200>>;

    /**
     * Use GPT3 to do initial categorization of a candidate email
     */
    autocategoCandidateEmail(requestParameters: ApiApiAutocategoCandidateEmailRequest): Promise<InlineResponse200>;

    /**
     * Autoformat an interview response for a candidate for the interview specified
     * @param {string} id A UUID string identifying this interview rubric response.
     * @param {AutoformatInterviewRubricRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    autoformatInterviewRubricResponseRaw(requestParameters: ApiApiAutoformatInterviewRubricResponseRequest): Promise<runtime.ApiResponse<InterviewRubricResponse>>;

    /**
     * Autoformat an interview response for a candidate for the interview specified
     */
    autoformatInterviewRubricResponse(requestParameters: ApiApiAutoformatInterviewRubricResponseRequest): Promise<InterviewRubricResponse>;

    /**
     * Initiates a billing portal session with Stripe.
     * @param {BeginBillingPortalSessionRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    beginBillingPortalSessionRaw(requestParameters: ApiApiBeginBillingPortalSessionOperationRequest): Promise<runtime.ApiResponse<BeginBillingPortalSessionResponse>>;

    /**
     * Initiates a billing portal session with Stripe.
     */
    beginBillingPortalSession(requestParameters: ApiApiBeginBillingPortalSessionOperationRequest): Promise<BeginBillingPortalSessionResponse>;

    /**
     * Initiates the checkout process for securely collected credit card info.
     * @param {BeginCheckoutRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    beginCheckoutRaw(requestParameters: ApiApiBeginCheckoutOperationRequest): Promise<runtime.ApiResponse<BeginCheckoutResponse>>;

    /**
     * Initiates the checkout process for securely collected credit card info.
     */
    beginCheckout(requestParameters: ApiApiBeginCheckoutOperationRequest): Promise<BeginCheckoutResponse>;

    /**
     * bulk create calibration candidate
     * @param {Array<CalibrationCandidate>} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    bulkCreateCalibrationCandidatesRaw(requestParameters: ApiApiBulkCreateCalibrationCandidatesRequest): Promise<runtime.ApiResponse<Array<CalibrationCandidate>>>;

    /**
     * bulk create calibration candidate
     */
    bulkCreateCalibrationCandidates(requestParameters: ApiApiBulkCreateCalibrationCandidatesRequest): Promise<Array<CalibrationCandidate>>;

    /**
     * bulk delete calibration candidate
     * @param {Array<string>} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    bulkDestroyCalibrationCandidatesRaw(requestParameters: ApiApiBulkDestroyCalibrationCandidatesRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * bulk delete calibration candidate
     */
    bulkDestroyCalibrationCandidates(requestParameters: ApiApiBulkDestroyCalibrationCandidatesRequest): Promise<void>;

    /**
     * bulk update calibration candidate
     * @param {Array<CalibrationCandidate>} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    bulkUpdateCalibrationCandidatesRaw(requestParameters: ApiApiBulkUpdateCalibrationCandidatesRequest): Promise<runtime.ApiResponse<Array<CalibrationCandidate>>>;

    /**
     * bulk update calibration candidate
     */
    bulkUpdateCalibrationCandidates(requestParameters: ApiApiBulkUpdateCalibrationCandidatesRequest): Promise<Array<CalibrationCandidate>>;

    /**
     * attempts to update all campaigns associated with a job
     * @param {BulkCampaignUpdate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    bulkUpdateCampaignsRaw(requestParameters: ApiApiBulkUpdateCampaignsRequest): Promise<runtime.ApiResponse<BulkCampaignUpdateResult>>;

    /**
     * attempts to update all campaigns associated with a job
     */
    bulkUpdateCampaigns(requestParameters: ApiApiBulkUpdateCampaignsRequest): Promise<BulkCampaignUpdateResult>;

    /**
     * bulk upserts client domains
     * @param {BulkUpsertClientDomain} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    bulkUpsertClientDomainsRaw(requestParameters: ApiApiBulkUpsertClientDomainsRequest): Promise<runtime.ApiResponse<BulkUpsertClientDomainResponse>>;

    /**
     * bulk upserts client domains
     */
    bulkUpsertClientDomains(requestParameters: ApiApiBulkUpsertClientDomainsRequest): Promise<BulkUpsertClientDomainResponse>;

    /**
     * updates the states of multiple job features for a given job in bulk
     * @param {string} id 
     * @param {Array<BulkUpsertJobFeatureSetting>} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    bulkUpsertJobFeatureSettingsRaw(requestParameters: ApiApiBulkUpsertJobFeatureSettingsRequest): Promise<runtime.ApiResponse<Array<BulkUpsertJobFeatureSetting>>>;

    /**
     * updates the states of multiple job features for a given job in bulk
     */
    bulkUpsertJobFeatureSettings(requestParameters: ApiApiBulkUpsertJobFeatureSettingsRequest): Promise<Array<BulkUpsertJobFeatureSetting>>;

    /**
     * Send test emails
     * @param {CampaignSendTestEmailsRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    campaignSendTestEmailsRaw(requestParameters: ApiApiCampaignSendTestEmailsOperationRequest): Promise<runtime.ApiResponse<object>>;

    /**
     * Send test emails
     */
    campaignSendTestEmails(requestParameters: ApiApiCampaignSendTestEmailsOperationRequest): Promise<object>;

    /**
     * checks if we can create a manual sourcing context for a linkedin id and job
     * @param {CheckCanCreateManualContextForJobRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    canManuallySourceProfileRaw(requestParameters: ApiApiCanManuallySourceProfileRequest): Promise<runtime.ApiResponse<CheckCanCreateManualContextForJobResponse>>;

    /**
     * checks if we can create a manual sourcing context for a linkedin id and job
     */
    canManuallySourceProfile(requestParameters: ApiApiCanManuallySourceProfileRequest): Promise<CheckCanCreateManualContextForJobResponse>;

    /**
     * Cancel an email to a candidate.
     * @param {string} id A UUID string identifying this candidate.
     * @param {CancelEmailRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    cancelEmailRaw(requestParameters: ApiApiCancelEmailOperationRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>>;

    /**
     * Cancel an email to a candidate.
     */
    cancelEmail(requestParameters: ApiApiCancelEmailOperationRequest): Promise<BaseCandidateActionResponse>;

    /**
     * Cancel initial interview for this candidate.
     * @param {string} candidateId 
     * @param {object} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    cancelInterviewRaw(requestParameters: ApiApiCancelInterviewRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Cancel initial interview for this candidate.
     */
    cancelInterview(requestParameters: ApiApiCancelInterviewRequest): Promise<InlineResponse2001>;

    /**
     * Cancel multipart interview request
     * @param {CancelMultipartInterviewRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    cancelMultipartInterviewRequestRaw(requestParameters: ApiApiCancelMultipartInterviewRequestRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Cancel multipart interview request
     */
    cancelMultipartInterviewRequest(requestParameters: ApiApiCancelMultipartInterviewRequestRequest): Promise<void>;

    /**
     * Cancel all queued automated EmailSendRequests for a candidate
     * @param {InlineObject2} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    cancelQueuedAutomatedEmailsRaw(requestParameters: ApiApiCancelQueuedAutomatedEmailsRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Cancel all queued automated EmailSendRequests for a candidate
     */
    cancelQueuedAutomatedEmails(requestParameters: ApiApiCancelQueuedAutomatedEmailsRequest): Promise<InlineResponse2001>;

    /**
     * 
     * @param {string} id 
     * @param {ChangeAppScoringEnabledRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    changeAppScoringEnabledRaw(requestParameters: ApiApiChangeAppScoringEnabledOperationRequest): Promise<runtime.ApiResponse<ChangeAppScoringEnabledResponse>>;

    /**
     */
    changeAppScoringEnabled(requestParameters: ApiApiChangeAppScoringEnabledOperationRequest): Promise<ChangeAppScoringEnabledResponse>;

    /**
     * Update archive reason on a candidate.
     * @param {string} id A UUID string identifying this candidate.
     * @param {ChangeArchiveReasonRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    changeArchiveReasonRaw(requestParameters: ApiApiChangeArchiveReasonOperationRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>>;

    /**
     * Update archive reason on a candidate.
     */
    changeArchiveReason(requestParameters: ApiApiChangeArchiveReasonOperationRequest): Promise<BaseCandidateActionResponse>;

    /**
     * Change interviewer
     * @param {ChangeInterviewerRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    changeInterviewerRaw(requestParameters: ApiApiChangeInterviewerOperationRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Change interviewer
     */
    changeInterviewer(requestParameters: ApiApiChangeInterviewerOperationRequest): Promise<void>;

    /**
     * checks if profile exists
     * @param {CheckIfProfileExistsRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    checkIfProfileExistsRaw(requestParameters: ApiApiCheckIfProfileExistsOperationRequest): Promise<runtime.ApiResponse<CheckIfProfileExistsResponse>>;

    /**
     * checks if profile exists
     */
    checkIfProfileExists(requestParameters: ApiApiCheckIfProfileExistsOperationRequest): Promise<CheckIfProfileExistsResponse>;

    /**
     * Clear user alias
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    clearAliasRaw(): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Clear user alias
     */
    clearAlias(): Promise<InlineResponse2001>;

    /**
     * Clone a search_v3 object
     * @param {InlineObject25} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    cloneSearchV3Raw(requestParameters: ApiApiCloneSearchV3Request): Promise<runtime.ApiResponse<CloneSearchV3Response>>;

    /**
     * Clone a search_v3 object
     */
    cloneSearchV3(requestParameters: ApiApiCloneSearchV3Request): Promise<CloneSearchV3Response>;

    /**
     * Completes the checkout process by securely passing a session id from the external billing service.
     * @param {CompleteCheckoutRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    completeCheckoutRaw(requestParameters: ApiApiCompleteCheckoutOperationRequest): Promise<runtime.ApiResponse<object>>;

    /**
     * Completes the checkout process by securely passing a session id from the external billing service.
     */
    completeCheckout(requestParameters: ApiApiCompleteCheckoutOperationRequest): Promise<object>;

    /**
     * 
     * @param {string} id 
     * @param {ConfirmJobPersonaRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    confirmJobPersonaRaw(requestParameters: ApiApiConfirmJobPersonaOperationRequest): Promise<runtime.ApiResponse<InlineResponse20010>>;

    /**
     */
    confirmJobPersona(requestParameters: ApiApiConfirmJobPersonaOperationRequest): Promise<InlineResponse20010>;

    /**
     * create inbound application
     * @param {string} jobId 
     * @param {string} firstName 
     * @param {string} lastName 
     * @param {string} email 
     * @param {string} [referrerSource] 
     * @param {string} [linkedinUrl] 
     * @param {string} [phoneNumber] 
     * @param {Blob} [resume] 
     * @param {string} [additionalInfo] 
     * @param {string} [applicationQuestions] 
     * @param {boolean} [bookmarked] 
     * @param {boolean} [optedInToTalentNetwork] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createApplicationPortalInboundApplicationRaw(requestParameters: ApiApiCreateApplicationPortalInboundApplicationRequest): Promise<runtime.ApiResponse<ApplicationPortalInboundApplication>>;

    /**
     * create inbound application
     */
    createApplicationPortalInboundApplication(requestParameters: ApiApiCreateApplicationPortalInboundApplicationRequest): Promise<ApplicationPortalInboundApplication>;

    /**
     * create application question
     * @param {ApplicationQuestion} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createApplicationQuestionRaw(requestParameters: ApiApiCreateApplicationQuestionRequest): Promise<runtime.ApiResponse<ApplicationQuestion>>;

    /**
     * create application question
     */
    createApplicationQuestion(requestParameters: ApiApiCreateApplicationQuestionRequest): Promise<ApplicationQuestion>;

    /**
     * create archive reason
     * @param {ArchiveReason} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createArchiveReasonRaw(requestParameters: ApiApiCreateArchiveReasonRequest): Promise<runtime.ApiResponse<ArchiveReason>>;

    /**
     * create archive reason
     */
    createArchiveReason(requestParameters: ApiApiCreateArchiveReasonRequest): Promise<ArchiveReason>;

    /**
     * create campaign
     * @param {CreateCampaign} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createCampaignRaw(requestParameters: ApiApiCreateCampaignRequest): Promise<runtime.ApiResponse<CreateCampaign>>;

    /**
     * create campaign
     */
    createCampaign(requestParameters: ApiApiCreateCampaignRequest): Promise<CreateCampaign>;

    /**
     * create candidate activity note
     * @param {CandidateActivityNote} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createCandidateActivityNoteRaw(requestParameters: ApiApiCreateCandidateActivityNoteRequest): Promise<runtime.ApiResponse<CandidateActivityNote>>;

    /**
     * create candidate activity note
     */
    createCandidateActivityNote(requestParameters: ApiApiCreateCandidateActivityNoteRequest): Promise<CandidateActivityNote>;

    /**
     * Create Candidate Files
     * @param {string} candidate 
     * @param {string} uploadedContext 
     * @param {string} [name] 
     * @param {Blob} [fileContents] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createCandidateFileRaw(requestParameters: ApiApiCreateCandidateFileRequest): Promise<runtime.ApiResponse<CandidateFiles>>;

    /**
     * Create Candidate Files
     */
    createCandidateFile(requestParameters: ApiApiCreateCandidateFileRequest): Promise<CandidateFiles>;

    /**
     * Create change log for search
     * @param {GetChangeLogEntryRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createChangeLogForSearchRaw(requestParameters: ApiApiCreateChangeLogForSearchRequest): Promise<runtime.ApiResponse<CreateChangeLogForSearchResponse>>;

    /**
     * Create change log for search
     */
    createChangeLogForSearch(requestParameters: ApiApiCreateChangeLogForSearchRequest): Promise<CreateChangeLogForSearchResponse>;

    /**
     * create a client email template
     * @param {ClientEmailTemplate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createClientEmailTemplateRaw(requestParameters: ApiApiCreateClientEmailTemplateRequest): Promise<runtime.ApiResponse<ClientEmailTemplate>>;

    /**
     * create a client email template
     */
    createClientEmailTemplate(requestParameters: ApiApiCreateClientEmailTemplateRequest): Promise<ClientEmailTemplate>;

    /**
     * create a CompanyList
     * @param {CompanyList} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createCompanyListRaw(requestParameters: ApiApiCreateCompanyListRequest): Promise<runtime.ApiResponse<CompanyList>>;

    /**
     * create a CompanyList
     */
    createCompanyList(requestParameters: ApiApiCreateCompanyListRequest): Promise<CompanyList>;

    /**
     * bulk create a set of emails for a specific contact
     * @param {CreateContactEmailsRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createContactEmailsRaw(requestParameters: ApiApiCreateContactEmailsOperationRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * bulk create a set of emails for a specific contact
     */
    createContactEmails(requestParameters: ApiApiCreateContactEmailsOperationRequest): Promise<void>;

    /**
     * bulk create a set of phone numbers for a specific contact
     * @param {CreateContactPhoneNumbersRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createContactPhoneNumbersRaw(requestParameters: ApiApiCreateContactPhoneNumbersOperationRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * bulk create a set of phone numbers for a specific contact
     */
    createContactPhoneNumbers(requestParameters: ApiApiCreateContactPhoneNumbersOperationRequest): Promise<void>;

    /**
     * Create DTN Canddate. This will create a candidate and then user can action in the UI.
     * @param {CreateDtnCandidateRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createDtnCandidateRaw(requestParameters: ApiApiCreateDtnCandidateOperationRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>>;

    /**
     * Create DTN Canddate. This will create a candidate and then user can action in the UI.
     */
    createDtnCandidate(requestParameters: ApiApiCreateDtnCandidateOperationRequest): Promise<BaseCandidateActionResponse>;

    /**
     * create feedback template
     * @param {CreateFeedbackTemplateRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createFeedbackTemplateRaw(requestParameters: ApiApiCreateFeedbackTemplateOperationRequest): Promise<runtime.ApiResponse<FeedbackTemplateDetail>>;

    /**
     * create feedback template
     */
    createFeedbackTemplate(requestParameters: ApiApiCreateFeedbackTemplateOperationRequest): Promise<FeedbackTemplateDetail>;

    /**
     * Create a HiringPipelineStage
     * @param {string} jobId 
     * @param {CreateHiringPipelineStageRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createHiringPipelineStageRaw(requestParameters: ApiApiCreateHiringPipelineStageOperationRequest): Promise<runtime.ApiResponse<HiringPipelineStage>>;

    /**
     * Create a HiringPipelineStage
     */
    createHiringPipelineStage(requestParameters: ApiApiCreateHiringPipelineStageOperationRequest): Promise<HiringPipelineStage>;

    /**
     * create hiring stage email template
     * @param {HiringStageEmailTemplate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createHiringStageEmailTemplateRaw(requestParameters: ApiApiCreateHiringStageEmailTemplateRequest): Promise<runtime.ApiResponse<HiringStageEmailTemplate>>;

    /**
     * create hiring stage email template
     */
    createHiringStageEmailTemplate(requestParameters: ApiApiCreateHiringStageEmailTemplateRequest): Promise<HiringStageEmailTemplate>;

    /**
     * create interviewer
     * @param {ClientInterviewer} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createInterviewerRaw(requestParameters: ApiApiCreateInterviewerRequest): Promise<runtime.ApiResponse<ClientInterviewer>>;

    /**
     * create interviewer
     */
    createInterviewer(requestParameters: ApiApiCreateInterviewerRequest): Promise<ClientInterviewer>;

    /**
     * create jd object with text
     * @param {CreateJobDescriptionWithTextRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createJobDescriptionWithTextRaw(requestParameters: ApiApiCreateJobDescriptionWithTextOperationRequest): Promise<runtime.ApiResponse<CreateJobDescriptionWithTextResponse>>;

    /**
     * create jd object with text
     */
    createJobDescriptionWithText(requestParameters: ApiApiCreateJobDescriptionWithTextOperationRequest): Promise<CreateJobDescriptionWithTextResponse>;

    /**
     * create job position for hiring plan
     * @param {HiringPlanJobPosition} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createJobPositionForHiringPlanRaw(requestParameters: ApiApiCreateJobPositionForHiringPlanRequest): Promise<runtime.ApiResponse<HiringPlanJobPosition>>;

    /**
     * create job position for hiring plan
     */
    createJobPositionForHiringPlan(requestParameters: ApiApiCreateJobPositionForHiringPlanRequest): Promise<HiringPlanJobPosition>;

    /**
     * create job position opening for hiring plan
     * @param {HiringPlanJobPositionOpening} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createJobPositionOpeningForHiringPlanRaw(requestParameters: ApiApiCreateJobPositionOpeningForHiringPlanRequest): Promise<runtime.ApiResponse<HiringPlanJobPositionOpening>>;

    /**
     * create job position opening for hiring plan
     */
    createJobPositionOpeningForHiringPlan(requestParameters: ApiApiCreateJobPositionOpeningForHiringPlanRequest): Promise<HiringPlanJobPositionOpening>;

    /**
     * 
     * @param {CreateNewJobRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createNewJobRaw(requestParameters: ApiApiCreateNewJobOperationRequest): Promise<runtime.ApiResponse<CreateNewJobResponse>>;

    /**
     */
    createNewJob(requestParameters: ApiApiCreateNewJobOperationRequest): Promise<CreateNewJobResponse>;

    /**
     * create notification config
     * @param {NotificationConfig} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createNotificationConfigRaw(requestParameters: ApiApiCreateNotificationConfigRequest): Promise<runtime.ApiResponse<NotificationConfig>>;

    /**
     * create notification config
     */
    createNotificationConfig(requestParameters: ApiApiCreateNotificationConfigRequest): Promise<NotificationConfig>;

    /**
     * creates or update outreach generation
     * @param {OutreachGeneration} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createOrUpdateOutreachGenerationRaw(requestParameters: ApiApiCreateOrUpdateOutreachGenerationRequest): Promise<runtime.ApiResponse<OutreachGeneration>>;

    /**
     * creates or update outreach generation
     */
    createOrUpdateOutreachGeneration(requestParameters: ApiApiCreateOrUpdateOutreachGenerationRequest): Promise<OutreachGeneration>;

    /**
     * create a Persona
     * @param {Persona} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createPersonaRaw(requestParameters: ApiApiCreatePersonaRequest): Promise<runtime.ApiResponse<Persona>>;

    /**
     * create a Persona
     */
    createPersona(requestParameters: ApiApiCreatePersonaRequest): Promise<Persona>;

    /**
     * create pro user
     * @param {DoverUser} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createProUserRaw(requestParameters: ApiApiCreateProUserRequest): Promise<runtime.ApiResponse<DoverUser>>;

    /**
     * create pro user
     */
    createProUser(requestParameters: ApiApiCreateProUserRequest): Promise<DoverUser>;

    /**
     * create profile attribute
     * @param {AdminProfileAttribute} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createProfileAttributesRaw(requestParameters: ApiApiCreateProfileAttributesRequest): Promise<runtime.ApiResponse<AdminProfileAttribute>>;

    /**
     * create profile attribute
     */
    createProfileAttributes(requestParameters: ApiApiCreateProfileAttributesRequest): Promise<AdminProfileAttribute>;

    /**
     * Create a reengagement transform
     * @param {CreateReengagementTransformRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createReengagementTransformRaw(requestParameters: ApiApiCreateReengagementTransformOperationRequest): Promise<runtime.ApiResponse<CreateReengagementTransformResponseSuccess>>;

    /**
     * Create a reengagement transform
     */
    createReengagementTransform(requestParameters: ApiApiCreateReengagementTransformOperationRequest): Promise<CreateReengagementTransformResponseSuccess>;

    /**
     * create a SchoolList
     * @param {SchoolList} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createSchoolListRaw(requestParameters: ApiApiCreateSchoolListRequest): Promise<runtime.ApiResponse<SchoolList>>;

    /**
     * create a SchoolList
     */
    createSchoolList(requestParameters: ApiApiCreateSchoolListRequest): Promise<SchoolList>;

    /**
     * create search
     * @param {AdminSearch} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createSearchRaw(requestParameters: ApiApiCreateSearchRequest): Promise<runtime.ApiResponse<AdminSearch>>;

    /**
     * create search
     */
    createSearch(requestParameters: ApiApiCreateSearchRequest): Promise<AdminSearch>;

    /**
     * Create a search from the answers to a Persona Questionnaire
     * @param {InlineObject26} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createSearchFromPQRaw(requestParameters: ApiApiCreateSearchFromPQRequest): Promise<runtime.ApiResponse<InlineObject25>>;

    /**
     * Create a search from the answers to a Persona Questionnaire
     */
    createSearchFromPQ(requestParameters: ApiApiCreateSearchFromPQRequest): Promise<InlineObject25>;

    /**
     * create search v3 params
     * @param {SearchV3} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createSearchV3Raw(requestParameters: ApiApiCreateSearchV3Request): Promise<runtime.ApiResponse<SearchV3>>;

    /**
     * create search v3 params
     */
    createSearchV3(requestParameters: ApiApiCreateSearchV3Request): Promise<SearchV3>;

    /**
     * create a title patterns
     * @param {TitlePattern} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createTitlePatternRaw(requestParameters: ApiApiCreateTitlePatternRequest): Promise<runtime.ApiResponse<TitlePattern>>;

    /**
     * create a title patterns
     */
    createTitlePattern(requestParameters: ApiApiCreateTitlePatternRequest): Promise<TitlePattern>;

    /**
     * create UserRating
     * @param {UserRating} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createUserRatingRaw(requestParameters: ApiApiCreateUserRatingRequest): Promise<runtime.ApiResponse<UserRating>>;

    /**
     * create UserRating
     */
    createUserRating(requestParameters: ApiApiCreateUserRatingRequest): Promise<UserRating>;

    /**
     * upload a file
     * @param {Blob} fileContents 
     * @param {string} uploadedContext 
     * @param {string} [originalFileName] 
     * @param {Date} [lastProcessedAt] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    createUserUploadedFileRaw(requestParameters: ApiApiCreateUserUploadedFileRequest): Promise<runtime.ApiResponse<UserUploadedFile>>;

    /**
     * upload a file
     */
    createUserUploadedFile(requestParameters: ApiApiCreateUserUploadedFileRequest): Promise<UserUploadedFile>;

    /**
     * Deactivates a job
     * @param {string} id 
     * @param {DeactivateJob} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    deactivateJobRaw(requestParameters: ApiApiDeactivateJobRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Deactivates a job
     */
    deactivateJob(requestParameters: ApiApiDeactivateJobRequest): Promise<InlineResponse2001>;

    /**
     * delete client email template with given id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    deleteClientEmailTemplateRaw(requestParameters: ApiApiDeleteClientEmailTemplateRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * delete client email template with given id
     */
    deleteClientEmailTemplate(requestParameters: ApiApiDeleteClientEmailTemplateRequest): Promise<void>;

    /**
     * delete a CompanyList
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    deleteCompanyListRaw(requestParameters: ApiApiDeleteCompanyListRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * delete a CompanyList
     */
    deleteCompanyList(requestParameters: ApiApiDeleteCompanyListRequest): Promise<void>;

    /**
     * delete contact email
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    deleteContactEmailRaw(requestParameters: ApiApiDeleteContactEmailRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * delete contact email
     */
    deleteContactEmail(requestParameters: ApiApiDeleteContactEmailRequest): Promise<void>;

    /**
     * delete contact phone number
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    deleteContactPhoneNumberRaw(requestParameters: ApiApiDeleteContactPhoneNumberRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * delete contact phone number
     */
    deleteContactPhoneNumber(requestParameters: ApiApiDeleteContactPhoneNumberRequest): Promise<void>;

    /**
     * delete candidate email
     * @param {string} id A UUID string identifying this email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    deleteEmailRaw(requestParameters: ApiApiDeleteEmailRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * delete candidate email
     */
    deleteEmail(requestParameters: ApiApiDeleteEmailRequest): Promise<void>;

    /**
     * delete feedback template with given id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    deleteFeedbackTemplateRaw(requestParameters: ApiApiDeleteFeedbackTemplateRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * delete feedback template with given id
     */
    deleteFeedbackTemplate(requestParameters: ApiApiDeleteFeedbackTemplateRequest): Promise<void>;

    /**
     * destroy hiring stage email template
     * @param {string} id A UUID string identifying this hiring stage email template.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    deleteHiringStageEmailTemplateRaw(requestParameters: ApiApiDeleteHiringStageEmailTemplateRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * destroy hiring stage email template
     */
    deleteHiringStageEmailTemplate(requestParameters: ApiApiDeleteHiringStageEmailTemplateRequest): Promise<void>;

    /**
     * delete job position
     * @param {string} id A UUID string identifying this job position.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    deleteJobPositionForHiringPlanRaw(requestParameters: ApiApiDeleteJobPositionForHiringPlanRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * delete job position
     */
    deleteJobPositionForHiringPlan(requestParameters: ApiApiDeleteJobPositionForHiringPlanRequest): Promise<void>;

    /**
     * delete job position opening
     * @param {number} id A unique integer value identifying this job position opening.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    deleteJobPositionOpeningForHiringPlanRaw(requestParameters: ApiApiDeleteJobPositionOpeningForHiringPlanRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * delete job position opening
     */
    deleteJobPositionOpeningForHiringPlan(requestParameters: ApiApiDeleteJobPositionOpeningForHiringPlanRequest): Promise<void>;

    /**
     * delete a Persona
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    deletePersonaRaw(requestParameters: ApiApiDeletePersonaRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * delete a Persona
     */
    deletePersona(requestParameters: ApiApiDeletePersonaRequest): Promise<void>;

    /**
     * delete a SchoolList
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    deleteSchoolListRaw(requestParameters: ApiApiDeleteSchoolListRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * delete a SchoolList
     */
    deleteSchoolList(requestParameters: ApiApiDeleteSchoolListRequest): Promise<void>;

    /**
     * Delete Candidate Files
     * @param {string} id A UUID string identifying this candidate file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    destroyCandidateFileRaw(requestParameters: ApiApiDestroyCandidateFileRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete Candidate Files
     */
    destroyCandidateFile(requestParameters: ApiApiDestroyCandidateFileRequest): Promise<void>;

    /**
     * delete search
     * @param {string} id A UUID string identifying this search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    destroySearchRaw(requestParameters: ApiApiDestroySearchRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * delete search
     */
    destroySearch(requestParameters: ApiApiDestroySearchRequest): Promise<void>;

    /**
     * determine next action for candidates
     * @param {NextActionForCandidatesRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    determineNextActionForCandidatesRaw(requestParameters: ApiApiDetermineNextActionForCandidatesRequest): Promise<runtime.ApiResponse<Array<CandidateNextAction>>>;

    /**
     * determine next action for candidates
     */
    determineNextActionForCandidates(requestParameters: ApiApiDetermineNextActionForCandidatesRequest): Promise<Array<CandidateNextAction>>;

    /**
     * Disable a HiringPipelineStage
     * @param {string} id 
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    disableHiringPipelineStageRaw(requestParameters: ApiApiDisableHiringPipelineStageRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Disable a HiringPipelineStage
     */
    disableHiringPipelineStage(requestParameters: ApiApiDisableHiringPipelineStageRequest): Promise<void>;

    /**
     * Retrieves emails for linkedin public url
     * @param {ExchangeEmailIdForEmailRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    exchangeEmailIdForEmailRaw(requestParameters: ApiApiExchangeEmailIdForEmailOperationRequest): Promise<runtime.ApiResponse<ExchangeEmailIdForEmailResponse>>;

    /**
     * Retrieves emails for linkedin public url
     */
    exchangeEmailIdForEmail(requestParameters: ApiApiExchangeEmailIdForEmailOperationRequest): Promise<ExchangeEmailIdForEmailResponse>;

    /**
     * 
     * @param {string} id A UUID string identifying this client.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    exportDataAsCSVRaw(requestParameters: ApiApiExportDataAsCSVRequest): Promise<runtime.ApiResponse<CandidateCSVExportResponse>>;

    /**
     */
    exportDataAsCSV(requestParameters: ApiApiExportDataAsCSVRequest): Promise<CandidateCSVExportResponse>;

    /**
     * ensure that we can source the current profile
     * @param {CanSourceProfileRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    extensionV2CanSourceProfileRaw(requestParameters: ApiApiExtensionV2CanSourceProfileRequest): Promise<runtime.ApiResponse<CanSourceProfileResponse>>;

    /**
     * ensure that we can source the current profile
     */
    extensionV2CanSourceProfile(requestParameters: ApiApiExtensionV2CanSourceProfileRequest): Promise<CanSourceProfileResponse>;

    /**
     * Retrieves emails given linkedin profile information
     * @param {ExtensionV2FindEmailsRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    extensionV2FindEmailsRaw(requestParameters: ApiApiExtensionV2FindEmailsOperationRequest): Promise<runtime.ApiResponse<ExtensionV2FindEmailsResponse>>;

    /**
     * Retrieves emails given linkedin profile information
     */
    extensionV2FindEmails(requestParameters: ApiApiExtensionV2FindEmailsOperationRequest): Promise<ExtensionV2FindEmailsResponse>;

    /**
     * fetch candidate counts
     * @param {string} jobId 
     * @param {CandidatePipelineCountRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    fetchCandidateCountsRaw(requestParameters: ApiApiFetchCandidateCountsRequest): Promise<runtime.ApiResponse<Array<CandidatePipelineCountResponse>>>;

    /**
     * fetch candidate counts
     */
    fetchCandidateCounts(requestParameters: ApiApiFetchCandidateCountsRequest): Promise<Array<CandidatePipelineCountResponse>>;

    /**
     * fetches hiring funnel stats for the provided job
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    fetchFunnelStatsRaw(requestParameters: ApiApiFetchFunnelStatsRequest): Promise<runtime.ApiResponse<CombinedFunnelStats>>;

    /**
     * fetches hiring funnel stats for the provided job
     */
    fetchFunnelStats(requestParameters: ApiApiFetchFunnelStatsRequest): Promise<CombinedFunnelStats>;

    /**
     * finds the associated ats candidate, if one exists, given an ats candidate url
     * @param {FindAtsCandidateRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    findAtsCandidateRaw(requestParameters: ApiApiFindAtsCandidateOperationRequest): Promise<runtime.ApiResponse<FindAtsCandidateResponse>>;

    /**
     * finds the associated ats candidate, if one exists, given an ats candidate url
     */
    findAtsCandidate(requestParameters: ApiApiFindAtsCandidateOperationRequest): Promise<FindAtsCandidateResponse>;

    /**
     * tries to find a possible dupe contact that can be manually merged (null responses imply that we can\'t support manual merging)
     * @param {string} id 
     * @param {string} candidateId Candidate ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    findDupeContactRaw(requestParameters: ApiApiFindDupeContactRequest): Promise<runtime.ApiResponse<FindDupeContactResponse>>;

    /**
     * tries to find a possible dupe contact that can be manually merged (null responses imply that we can\'t support manual merging)
     */
    findDupeContact(requestParameters: ApiApiFindDupeContactRequest): Promise<FindDupeContactResponse>;

    /**
     * Find generic response templates that a candidate hasn\'t seen before
     * @param {InlineObject3} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    findGenericResponseTemplatesRaw(requestParameters: ApiApiFindGenericResponseTemplatesRequest): Promise<runtime.ApiResponse<Array<InlineResponse2002>>>;

    /**
     * Find generic response templates that a candidate hasn\'t seen before
     */
    findGenericResponseTemplates(requestParameters: ApiApiFindGenericResponseTemplatesRequest): Promise<Array<InlineResponse2002>>;

    /**
     * find all candidates related to a given contact
     * @param {string} contactId Contact ID
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    findRelatedCandidatesRaw(requestParameters: ApiApiFindRelatedCandidatesRequest): Promise<runtime.ApiResponse<Array<RelatedCandidate>>>;

    /**
     * find all candidates related to a given contact
     */
    findRelatedCandidates(requestParameters: ApiApiFindRelatedCandidatesRequest): Promise<Array<RelatedCandidate>>;

    /**
     * Find slack user data asscociated with the job channel
     * @param {InlineObject4} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    findSlackUserDataRaw(requestParameters: ApiApiFindSlackUserDataRequest): Promise<runtime.ApiResponse<Array<InlineResponse2003>>>;

    /**
     * Find slack user data asscociated with the job channel
     */
    findSlackUserData(requestParameters: ApiApiFindSlackUserDataRequest): Promise<Array<InlineResponse2003>>;

    /**
     * Update a single answer for an interview, based on the possible actions in InterviewQuestionFormattingPromptType enum.
     * @param {string} id A UUID string identifying this interview rubric response.
     * @param {FormatAiAnswerRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    formatIndividualAIAnswerRaw(requestParameters: ApiApiFormatIndividualAIAnswerRequest): Promise<runtime.ApiResponse<FormatAiAnswerResponse>>;

    /**
     * Update a single answer for an interview, based on the possible actions in InterviewQuestionFormattingPromptType enum.
     */
    formatIndividualAIAnswer(requestParameters: ApiApiFormatIndividualAIAnswerRequest): Promise<FormatAiAnswerResponse>;

    /**
     * Accept events from Front
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    frontEventWebhookRaw(): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Accept events from Front
     */
    frontEventWebhook(): Promise<InlineResponse2001>;

    /**
     * attempts to generate and save content to a campaign\'s messages
     * @param {string} id A UUID string identifying this campaign.
     * @param {InlineObject16} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    generateAndSaveCampaignContentRaw(requestParameters: ApiApiGenerateAndSaveCampaignContentRequest): Promise<runtime.ApiResponse<GenerateAndSaveCampaignContentResult>>;

    /**
     * attempts to generate and save content to a campaign\'s messages
     */
    generateAndSaveCampaignContent(requestParameters: ApiApiGenerateAndSaveCampaignContentRequest): Promise<GenerateAndSaveCampaignContentResult>;

    /**
     * generateCareersPage
     * @param {GenerateCareersPageRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    generateCareersPageRaw(requestParameters: ApiApiGenerateCareersPageOperationRequest): Promise<runtime.ApiResponse<GenerateCareersPageResponse>>;

    /**
     * generateCareersPage
     */
    generateCareersPage(requestParameters: ApiApiGenerateCareersPageOperationRequest): Promise<GenerateCareersPageResponse>;

    /**
     * Generate Email Outreach
     * @param {string} campaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    generateEmailOutreachRaw(requestParameters: ApiApiGenerateEmailOutreachRequest): Promise<runtime.ApiResponse<InlineResponse20035>>;

    /**
     * Generate Email Outreach
     */
    generateEmailOutreach(requestParameters: ApiApiGenerateEmailOutreachRequest): Promise<InlineResponse20035>;

    /**
     * Use AI to generate interview questions
     * @param {string} id A UUID string identifying this interview rubric response.
     * @param {InterviewQuestionSuggestionsRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    generateInterviewQuestionsRaw(requestParameters: ApiApiGenerateInterviewQuestionsRequest): Promise<runtime.ApiResponse<InterviewQuestionSuggestionsResponse>>;

    /**
     * Use AI to generate interview questions
     */
    generateInterviewQuestions(requestParameters: ApiApiGenerateInterviewQuestionsRequest): Promise<InterviewQuestionSuggestionsResponse>;

    /**
     * generates a job description for a given job using GPT-4
     * @param {GenerateJobDescriptionRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    generateJobDescriptionRaw(requestParameters: ApiApiGenerateJobDescriptionOperationRequest): Promise<runtime.ApiResponse<GenerateJobDescriptionResponse>>;

    /**
     * generates a job description for a given job using GPT-4
     */
    generateJobDescription(requestParameters: ApiApiGenerateJobDescriptionOperationRequest): Promise<GenerateJobDescriptionResponse>;

    /**
     * Use GPT3 to generate a Not Interested response
     * @param {InlineObject5} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    generateNotInterestedResponseRaw(requestParameters: ApiApiGenerateNotInterestedResponseRequest): Promise<runtime.ApiResponse<InlineResponse200>>;

    /**
     * Use GPT3 to generate a Not Interested response
     */
    generateNotInterestedResponse(requestParameters: ApiApiGenerateNotInterestedResponseRequest): Promise<InlineResponse200>;

    /**
     * generates new outreach email
     * @param {string} id A UUID string identifying this outreach generation.
     * @param {OutreachGeneration} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    generateOutreachEmailRaw(requestParameters: ApiApiGenerateOutreachEmailRequest): Promise<runtime.ApiResponse<OutreachGeneration>>;

    /**
     * generates new outreach email
     */
    generateOutreachEmail(requestParameters: ApiApiGenerateOutreachEmailRequest): Promise<OutreachGeneration>;

    /**
     * generate outreach with no profile
     * @param {GenerateOutreachNoProfileRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    generateOutreachSimpleRaw(requestParameters: ApiApiGenerateOutreachSimpleRequest): Promise<runtime.ApiResponse<GenerateOutreachNoProfileResponse>>;

    /**
     * generate outreach with no profile
     */
    generateOutreachSimple(requestParameters: ApiApiGenerateOutreachSimpleRequest): Promise<GenerateOutreachNoProfileResponse>;

    /**
     * retrieve search
     * @param {string} jobOrSearchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getApplicationPortalJobRaw(requestParameters: ApiApiGetApplicationPortalJobRequest): Promise<runtime.ApiResponse<ApplicationPortalJob>>;

    /**
     * retrieve search
     */
    getApplicationPortalJob(requestParameters: ApiApiGetApplicationPortalJobRequest): Promise<ApplicationPortalJob>;

    /**
     * retrieve Ashby data
     * @param {InlineObject15} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getAshbyDataRaw(requestParameters: ApiApiGetAshbyDataRequest): Promise<runtime.ApiResponse<AtsData>>;

    /**
     * retrieve Ashby data
     */
    getAshbyData(requestParameters: ApiApiGetAshbyDataRequest): Promise<AtsData>;

    /**
     * Fetch interview feedback templates using ATS api
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getAtsInterviewFeedbackTemplatesRaw(requestParameters: ApiApiGetAtsInterviewFeedbackTemplatesRequest): Promise<runtime.ApiResponse<Array<AtsInterviewFeedbackTemplate>>>;

    /**
     * Fetch interview feedback templates using ATS api
     */
    getAtsInterviewFeedbackTemplates(requestParameters: ApiApiGetAtsInterviewFeedbackTemplatesRequest): Promise<Array<AtsInterviewFeedbackTemplate>>;

    /**
     * Fetch interview stages associated with the given job from ATS api
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getAtsInterviewStagesRaw(requestParameters: ApiApiGetAtsInterviewStagesRequest): Promise<runtime.ApiResponse<Array<AtsJobStages>>>;

    /**
     * Fetch interview stages associated with the given job from ATS api
     */
    getAtsInterviewStages(requestParameters: ApiApiGetAtsInterviewStagesRequest): Promise<Array<AtsJobStages>>;

    /**
     * Return the campaign limits data for a base client
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getBaseClientCampaignLimitsRaw(requestParameters: ApiApiGetBaseClientCampaignLimitsRequest): Promise<runtime.ApiResponse<BaseClientCampaignLimit>>;

    /**
     * Return the campaign limits data for a base client
     */
    getBaseClientCampaignLimits(requestParameters: ApiApiGetBaseClientCampaignLimitsRequest): Promise<BaseClientCampaignLimit>;

    /**
     * Returns benchmarks for what percentage of candidates are coming through each channel
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getBenchmarkPersonaChannelStatsRaw(requestParameters: ApiApiGetBenchmarkPersonaChannelStatsRequest): Promise<runtime.ApiResponse<BenchmarkChannelPersonaStats>>;

    /**
     * Returns benchmarks for what percentage of candidates are coming through each channel
     */
    getBenchmarkPersonaChannelStats(requestParameters: ApiApiGetBenchmarkPersonaChannelStatsRequest): Promise<BenchmarkChannelPersonaStats>;

    /**
     * Fetch the template to use for bulk rejection given a hiring pipeline stage
     * @param {GetBulkRejectionTemplateRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getBulkRejectionTemplateRaw(requestParameters: ApiApiGetBulkRejectionTemplateOperationRequest): Promise<runtime.ApiResponse<BulkActionEmailTemplate>>;

    /**
     * Fetch the template to use for bulk rejection given a hiring pipeline stage
     */
    getBulkRejectionTemplate(requestParameters: ApiApiGetBulkRejectionTemplateOperationRequest): Promise<BulkActionEmailTemplate>;

    /**
     * retrieve campaign
     * @param {string} id A UUID string identifying this campaign.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCampaignRaw(requestParameters: ApiApiGetCampaignRequest): Promise<runtime.ApiResponse<Campaign>>;

    /**
     * retrieve campaign
     */
    getCampaign(requestParameters: ApiApiGetCampaignRequest): Promise<Campaign>;

    /**
     * retrieve campaign message request
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCampaignMessageRequestRaw(requestParameters: ApiApiGetCampaignMessageRequestRequest): Promise<runtime.ApiResponse<CampaignMessageRequest>>;

    /**
     * retrieve campaign message request
     */
    getCampaignMessageRequest(requestParameters: ApiApiGetCampaignMessageRequestRequest): Promise<CampaignMessageRequest>;

    /**
     * retrieves stats for a campaign
     * @param {string} id A UUID string identifying this campaign.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCampaignStatsRaw(requestParameters: ApiApiGetCampaignStatsRequest): Promise<runtime.ApiResponse<CampaignStats>>;

    /**
     * retrieves stats for a campaign
     */
    getCampaignStats(requestParameters: ApiApiGetCampaignStatsRequest): Promise<CampaignStats>;

    /**
     * retrieve candidate bio
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCandidateBioRaw(requestParameters: ApiApiGetCandidateBioRequest): Promise<runtime.ApiResponse<CandidateBio>>;

    /**
     * retrieve candidate bio
     */
    getCandidateBio(requestParameters: ApiApiGetCandidateBioRequest): Promise<CandidateBio>;

    /**
     * fetch candidate info for a given candidate and company email
     * @param {InlineObject6} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCandidateByEmailRaw(requestParameters: ApiApiGetCandidateByEmailRequest): Promise<runtime.ApiResponse<FrontCandidate>>;

    /**
     * fetch candidate info for a given candidate and company email
     */
    getCandidateByEmail(requestParameters: ApiApiGetCandidateByEmailRequest): Promise<FrontCandidate>;

    /**
     * get candidate email activity
     * @param {GetCandidateEmailActivityRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCandidateEmailActivityRaw(requestParameters: ApiApiGetCandidateEmailActivityOperationRequest): Promise<runtime.ApiResponse<GetCandidateEmailActivityResponse>>;

    /**
     * get candidate email activity
     */
    getCandidateEmailActivity(requestParameters: ApiApiGetCandidateEmailActivityOperationRequest): Promise<GetCandidateEmailActivityResponse>;

    /**
     * Returns stats about how sources perform for this job\'s persona
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCandidateSourceStatsRaw(requestParameters: ApiApiGetCandidateSourceStatsRequest): Promise<runtime.ApiResponse<Array<JobCandidateSourceStats>>>;

    /**
     * Returns stats about how sources perform for this job\'s persona
     */
    getCandidateSourceStats(requestParameters: ApiApiGetCandidateSourceStatsRequest): Promise<Array<JobCandidateSourceStats>>;

    /**
     * retrieve candidate
     * @param {string} id 
     * @param {string} [expand] Conditionally annotate certain information onto each candidate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCandidateV2Raw(requestParameters: ApiApiGetCandidateV2Request): Promise<runtime.ApiResponse<PipelineCandidate>>;

    /**
     * retrieve candidate
     */
    getCandidateV2(requestParameters: ApiApiGetCandidateV2Request): Promise<PipelineCandidate>;

    /**
     * gets the Client\'s associated CanonicalCompany record
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCanonicalCompanyInfoRaw(requestParameters: ApiApiGetCanonicalCompanyInfoRequest): Promise<runtime.ApiResponse<CanonicalCompany>>;

    /**
     * gets the Client\'s associated CanonicalCompany record
     */
    getCanonicalCompanyInfo(requestParameters: ApiApiGetCanonicalCompanyInfoRequest): Promise<CanonicalCompany>;

    /**
     * Unauthenticated route to retrieve public client information for dover careers page
     * @param {string} id A UUID string identifying this client.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCareersPageClientRaw(requestParameters: ApiApiGetCareersPageClientRequest): Promise<runtime.ApiResponse<CareersPageClient>>;

    /**
     * Unauthenticated route to retrieve public client information for dover careers page
     */
    getCareersPageClient(requestParameters: ApiApiGetCareersPageClientRequest): Promise<CareersPageClient>;

    /**
     * Unauthenticated route to retrieve public client information for dover careers page
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCareersPageClientBySlugRaw(requestParameters: ApiApiGetCareersPageClientBySlugRequest): Promise<runtime.ApiResponse<CareersPageClient>>;

    /**
     * Unauthenticated route to retrieve public client information for dover careers page
     */
    getCareersPageClientBySlug(requestParameters: ApiApiGetCareersPageClientBySlugRequest): Promise<CareersPageClient>;

    /**
     * Unauthenticated route to get active jobs by client for dover careers page
     * @param {string} clientId 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCareersPageJobsRaw(requestParameters: ApiApiGetCareersPageJobsRequest): Promise<runtime.ApiResponse<InlineResponse20017>>;

    /**
     * Unauthenticated route to get active jobs by client for dover careers page
     */
    getCareersPageJobs(requestParameters: ApiApiGetCareersPageJobsRequest): Promise<InlineResponse20017>;

    /**
     * Get change logs by searchId
     * @param {GetChangeLogEntryRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getChangeLogsRaw(requestParameters: ApiApiGetChangeLogsRequest): Promise<runtime.ApiResponse<Array<ChangeLogEntry>>>;

    /**
     * Get change logs by searchId
     */
    getChangeLogs(requestParameters: ApiApiGetChangeLogsRequest): Promise<Array<ChangeLogEntry>>;

    /**
     * Get domains associated with the current user client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getClientDomainsRaw(): Promise<runtime.ApiResponse<Array<string>>>;

    /**
     * Get domains associated with the current user client
     */
    getClientDomains(): Promise<Array<string>>;

    /**
     * get a client email template by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getClientEmailTemplateRaw(requestParameters: ApiApiGetClientEmailTemplateRequest): Promise<runtime.ApiResponse<ClientEmailTemplate>>;

    /**
     * get a client email template by id
     */
    getClientEmailTemplate(requestParameters: ApiApiGetClientEmailTemplateRequest): Promise<ClientEmailTemplate>;

    /**
     * get interview preference availiability for an interviewer
     * @param {string} id A UUID string identifying this client interviewer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getClientInterviewerAvailabilityRaw(requestParameters: ApiApiGetClientInterviewerAvailabilityRequest): Promise<runtime.ApiResponse<MultipartSchedulingClientInterviewerAvailability>>;

    /**
     * get interview preference availiability for an interviewer
     */
    getClientInterviewerAvailability(requestParameters: ApiApiGetClientInterviewerAvailabilityRequest): Promise<MultipartSchedulingClientInterviewerAvailability>;

    /**
     * get calendar events for an interviewer
     * @param {string} id A UUID string identifying this client interviewer.
     * @param {number} start 
     * @param {number} end 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getClientInterviewerCalendarEventsRaw(requestParameters: ApiApiGetClientInterviewerCalendarEventsRequest): Promise<runtime.ApiResponse<Array<MultipartSchedulingClientInterviewerCalendarEvent>>>;

    /**
     * get calendar events for an interviewer
     */
    getClientInterviewerCalendarEvents(requestParameters: ApiApiGetClientInterviewerCalendarEventsRequest): Promise<Array<MultipartSchedulingClientInterviewerCalendarEvent>>;

    /**
     * get the client interviewer associated with the requesting user
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getClientInterviewerForUserRaw(requestParameters: ApiApiGetClientInterviewerForUserRequest): Promise<runtime.ApiResponse<ClientInterviewer>>;

    /**
     * get the client interviewer associated with the requesting user
     */
    getClientInterviewerForUser(requestParameters: ApiApiGetClientInterviewerForUserRequest): Promise<ClientInterviewer>;

    /**
     * retrieve client onboarding settings for authed user
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getClientOnboardingRaw(requestParameters: ApiApiGetClientOnboardingRequest): Promise<runtime.ApiResponse<ClientOnboarding>>;

    /**
     * retrieve client onboarding settings for authed user
     */
    getClientOnboarding(requestParameters: ApiApiGetClientOnboardingRequest): Promise<ClientOnboarding>;

    /**
     * get file upload stats for users in a single client for a given uploaded context
     * @param {string} statsUploadedContext 
     * @param {string} [uploadedContext] 
     * @param {string} [processingState] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getClientUserFileStatsRaw(requestParameters: ApiApiGetClientUserFileStatsRequest): Promise<runtime.ApiResponse<Array<ClientUserFileStats>>>;

    /**
     * get file upload stats for users in a single client for a given uploaded context
     */
    getClientUserFileStats(requestParameters: ApiApiGetClientUserFileStatsRequest): Promise<Array<ClientUserFileStats>>;

    /**
     * Get companies by urn
     * @param {GetCompaniesByUrnRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCompaniesByUrnRaw(requestParameters: ApiApiGetCompaniesByUrnOperationRequest): Promise<runtime.ApiResponse<Array<Company>>>;

    /**
     * Get companies by urn
     */
    getCompaniesByUrn(requestParameters: ApiApiGetCompaniesByUrnOperationRequest): Promise<Array<Company>>;

    /**
     * getCompanyPitchQuestionSchema
     * @param {string} [clientId] Optional client id to get pitch for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCompanyPitchQuestionSchemaRaw(requestParameters: ApiApiGetCompanyPitchQuestionSchemaRequest): Promise<runtime.ApiResponse<GetCompanyPitchQuestionSchemaResponse>>;

    /**
     * getCompanyPitchQuestionSchema
     */
    getCompanyPitchQuestionSchema(requestParameters: ApiApiGetCompanyPitchQuestionSchemaRequest): Promise<GetCompanyPitchQuestionSchemaResponse>;

    /**
     * gets the setup steps and their state for the requester\'s client
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCompanySetupStepsRaw(requestParameters: ApiApiGetCompanySetupStepsRequest): Promise<runtime.ApiResponse<CompanySetupSteps>>;

    /**
     * gets the setup steps and their state for the requester\'s client
     */
    getCompanySetupSteps(requestParameters: ApiApiGetCompanySetupStepsRequest): Promise<CompanySetupSteps>;

    /**
     * get a contact by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getContactRaw(requestParameters: ApiApiGetContactRequest): Promise<runtime.ApiResponse<Contact>>;

    /**
     * get a contact by id
     */
    getContact(requestParameters: ApiApiGetContactRequest): Promise<Contact>;

    /**
     * Returns the current onboarding step for the requesting user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getCurrentOnboardingStepForUserRaw(): Promise<runtime.ApiResponse<CurrentUserOnboardingStepResponse>>;

    /**
     * Returns the current onboarding step for the requesting user
     */
    getCurrentOnboardingStepForUser(): Promise<CurrentUserOnboardingStepResponse>;

    /**
     * Returns stats needed for the deactivation modal
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getDeactivationStatsRaw(requestParameters: ApiApiGetDeactivationStatsRequest): Promise<runtime.ApiResponse<DeactivationStats>>;

    /**
     * Returns stats needed for the deactivation modal
     */
    getDeactivationStats(requestParameters: ApiApiGetDeactivationStatsRequest): Promise<DeactivationStats>;

    /**
     * retrieve dover entity
     * @param {string} id A UUID string identifying this dover entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getDoverEntityRaw(requestParameters: ApiApiGetDoverEntityRequest): Promise<runtime.ApiResponse<DoverEntityRoute>>;

    /**
     * retrieve dover entity
     */
    getDoverEntity(requestParameters: ApiApiGetDoverEntityRequest): Promise<DoverEntityRoute>;

    /**
     * Get list of candidates where first round is in scheduling or scheduled.
     * @param {string} doverEntityId 
     * @param {boolean} [isCallCompleted] candidate stages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getDoverInterviewerCandidatesRaw(requestParameters: ApiApiGetDoverInterviewerCandidatesRequest): Promise<runtime.ApiResponse<Array<ShortenedCandidate>>>;

    /**
     * Get list of candidates where first round is in scheduling or scheduled.
     */
    getDoverInterviewerCandidates(requestParameters: ApiApiGetDoverInterviewerCandidatesRequest): Promise<Array<ShortenedCandidate>>;

    /**
     * gets the emails per week option (passive, standard, boosted)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getDoverOutboundConfigurationRaw(requestParameters: ApiApiGetDoverOutboundConfigurationRequest): Promise<runtime.ApiResponse<GetDoverOutboundConfiguration200Response>>;

    /**
     * gets the emails per week option (passive, standard, boosted)
     */
    getDoverOutboundConfiguration(requestParameters: ApiApiGetDoverOutboundConfigurationRequest): Promise<GetDoverOutboundConfiguration200Response>;

    /**
     * Fetch a template for the candidate and specified action
     * @param {GetEmailTemplateRequestV2} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getEmailTemplateV2Raw(requestParameters: ApiApiGetEmailTemplateV2Request): Promise<runtime.ApiResponse<EmailTemplateV2>>;

    /**
     * Fetch a template for the candidate and specified action
     */
    getEmailTemplateV2(requestParameters: ApiApiGetEmailTemplateV2Request): Promise<EmailTemplateV2>;

    /**
     * get example linkedin for a profile attribute
     * @param {string} id A UUID string identifying this profile attribute.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getExampleLinkedinRaw(requestParameters: ApiApiGetExampleLinkedinRequest): Promise<runtime.ApiResponse<InlineResponse20060>>;

    /**
     * get example linkedin for a profile attribute
     */
    getExampleLinkedin(requestParameters: ApiApiGetExampleLinkedinRequest): Promise<InlineResponse20060>;

    /**
     * get feature flags by candidate
     * @param {GetFeatureFlagsByCandidateRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getFeatureFlagsByCandidateRaw(requestParameters: ApiApiGetFeatureFlagsByCandidateOperationRequest): Promise<runtime.ApiResponse<GetFeatureFlagsByCandidateResponse>>;

    /**
     * get feature flags by candidate
     */
    getFeatureFlagsByCandidate(requestParameters: ApiApiGetFeatureFlagsByCandidateOperationRequest): Promise<GetFeatureFlagsByCandidateResponse>;

    /**
     * Get all feature flags for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getFeatureFlagsForUserRaw(): Promise<runtime.ApiResponse<FeatureFlags>>;

    /**
     * Get all feature flags for a user
     */
    getFeatureFlagsForUser(): Promise<FeatureFlags>;

    /**
     * retrieve feedback template with the given id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getFeedbackTemplateRaw(requestParameters: ApiApiGetFeedbackTemplateRequest): Promise<runtime.ApiResponse<FeedbackTemplateDetail>>;

    /**
     * retrieve feedback template with the given id
     */
    getFeedbackTemplate(requestParameters: ApiApiGetFeedbackTemplateRequest): Promise<FeedbackTemplateDetail>;

    /**
     * Get boolean value for if user has a ClientEmailSender with valid ClientGoogleCredential.
     * @param {string} [userId] optional user id to check if they have gmail auth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getGmailAuthValidRaw(requestParameters: ApiApiGetGmailAuthValidRequest): Promise<runtime.ApiResponse<GmailAuth>>;

    /**
     * Get boolean value for if user has a ClientEmailSender with valid ClientGoogleCredential.
     */
    getGmailAuthValid(requestParameters: ApiApiGetGmailAuthValidRequest): Promise<GmailAuth>;

    /**
     * retrieve Greenhouse data
     * @param {InlineObject18} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getGreenhouseDataRaw(requestParameters: ApiApiGetGreenhouseDataRequest): Promise<runtime.ApiResponse<AtsData>>;

    /**
     * retrieve Greenhouse data
     */
    getGreenhouseData(requestParameters: ApiApiGetGreenhouseDataRequest): Promise<AtsData>;

    /**
     * Get HiringPipelineStage
     * @param {string} id 
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getHiringPipelineStageRaw(requestParameters: ApiApiGetHiringPipelineStageRequest): Promise<runtime.ApiResponse<HiringPipelineStage>>;

    /**
     * Get HiringPipelineStage
     */
    getHiringPipelineStage(requestParameters: ApiApiGetHiringPipelineStageRequest): Promise<HiringPipelineStage>;

    /**
     * retrieve hiring stage email template
     * @param {string} id A UUID string identifying this hiring stage email template.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getHiringStageEmailTemplateRaw(requestParameters: ApiApiGetHiringStageEmailTemplateRequest): Promise<runtime.ApiResponse<HiringStageEmailTemplate>>;

    /**
     * retrieve hiring stage email template
     */
    getHiringStageEmailTemplate(requestParameters: ApiApiGetHiringStageEmailTemplateRequest): Promise<HiringStageEmailTemplate>;

    /**
     * Get an in depth explanation of how a candidate fares against a search
     * @param {GetInDepthExplanationRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getInDepthExplanationRaw(requestParameters: ApiApiGetInDepthExplanationOperationRequest): Promise<runtime.ApiResponse<GetInDepthExplanationResponse>>;

    /**
     * Get an in depth explanation of how a candidate fares against a search
     */
    getInDepthExplanation(requestParameters: ApiApiGetInDepthExplanationOperationRequest): Promise<GetInDepthExplanationResponse>;

    /**
     * retrieve industries
     * @param {string} id A UUID string identifying this keyword.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getIndustryRaw(requestParameters: ApiApiGetIndustryRequest): Promise<runtime.ApiResponse<Industry>>;

    /**
     * retrieve industries
     */
    getIndustry(requestParameters: ApiApiGetIndustryRequest): Promise<Industry>;

    /**
     * Gets the initial call interview for a candidate
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getInitialCallInterviewRaw(requestParameters: ApiApiGetInitialCallInterviewRequest): Promise<runtime.ApiResponse<Interview>>;

    /**
     * Gets the initial call interview for a candidate
     */
    getInitialCallInterview(requestParameters: ApiApiGetInitialCallInterviewRequest): Promise<Interview>;

    /**
     * fetch interview plan info for a given job
     * @param {string} job 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getInterviewPlanByJobRaw(requestParameters: ApiApiGetInterviewPlanByJobRequest): Promise<runtime.ApiResponse<Array<FrontHiringPipelineStage>>>;

    /**
     * fetch interview plan info for a given job
     */
    getInterviewPlanByJob(requestParameters: ApiApiGetInterviewPlanByJobRequest): Promise<Array<FrontHiringPipelineStage>>;

    /**
     * Get an interview response for a candidate for the interview specifified
     * @param {string} candidateId candidate id
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [interviewId] interview id
     * @param {boolean} [forceCreate] force create a new feedback form even if one already exists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getInterviewRubricResponseRaw(requestParameters: ApiApiGetInterviewRubricResponseRequest): Promise<runtime.ApiResponse<InterviewRubricResponse>>;

    /**
     * Get an interview response for a candidate for the interview specifified
     */
    getInterviewRubricResponse(requestParameters: ApiApiGetInterviewRubricResponseRequest): Promise<InterviewRubricResponse>;

    /**
     * get interview rubric response
     * @param {string} id A UUID string identifying this interview rubric response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getInterviewRubricResponseByIdRaw(requestParameters: ApiApiGetInterviewRubricResponseByIdRequest): Promise<runtime.ApiResponse<InterviewRubricResponse>>;

    /**
     * get interview rubric response
     */
    getInterviewRubricResponseById(requestParameters: ApiApiGetInterviewRubricResponseByIdRequest): Promise<InterviewRubricResponse>;

    /**
     * Get where a candidate is at a given moment in the hiring/interview proccess
     * @param {string} candidateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getInterviewStatusRaw(requestParameters: ApiApiGetInterviewStatusRequest): Promise<runtime.ApiResponse<InterviewStatus>>;

    /**
     * Get where a candidate is at a given moment in the hiring/interview proccess
     */
    getInterviewStatus(requestParameters: ApiApiGetInterviewStatusRequest): Promise<InterviewStatus>;

    /**
     * retrieve interviewer
     * @param {string} id A UUID string identifying this client interviewer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getInterviewerRaw(requestParameters: ApiApiGetInterviewerRequest): Promise<runtime.ApiResponse<ClientInterviewer>>;

    /**
     * retrieve interviewer
     */
    getInterviewer(requestParameters: ApiApiGetInterviewerRequest): Promise<ClientInterviewer>;

    /**
     * get the pro user
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getInterviewerProUserRaw(requestParameters: ApiApiGetInterviewerProUserRequest): Promise<runtime.ApiResponse<ProUser>>;

    /**
     * get the pro user
     */
    getInterviewerProUser(requestParameters: ApiApiGetInterviewerProUserRequest): Promise<ProUser>;

    /**
     * retrieve job
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobRaw(requestParameters: ApiApiGetJobRequest): Promise<runtime.ApiResponse<DashboardJob>>;

    /**
     * retrieve job
     */
    getJob(requestParameters: ApiApiGetJobRequest): Promise<DashboardJob>;

    /**
     * Gets the job application inbound event
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobApplicationEventRaw(requestParameters: ApiApiGetJobApplicationEventRequest): Promise<runtime.ApiResponse<Array<ActivityFeedItem>>>;

    /**
     * Gets the job application inbound event
     */
    getJobApplicationEvent(requestParameters: ApiApiGetJobApplicationEventRequest): Promise<Array<ActivityFeedItem>>;

    /**
     * Returns the JD for the job
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobDescriptionRaw(requestParameters: ApiApiGetJobDescriptionRequest): Promise<runtime.ApiResponse<DoverJobDescription>>;

    /**
     * Returns the JD for the job
     */
    getJobDescription(requestParameters: ApiApiGetJobDescriptionRequest): Promise<DoverJobDescription>;

    /**
     * gets the set of job features and state for a given job
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobFeaturesRaw(requestParameters: ApiApiGetJobFeaturesRequest): Promise<runtime.ApiResponse<JobFeatures>>;

    /**
     * gets the set of job features and state for a given job
     */
    getJobFeatures(requestParameters: ApiApiGetJobFeaturesRequest): Promise<JobFeatures>;

    /**
     * get stats about this job\'s funnel
     * @param {string} id A UUID string identifying this job.
     * @param {string} [start] The start time from which we should include data points when computing stats. Must be an iso-format string rounded to the nearest day.
     * @param {string} [end] The end time up to which we should include data points when computing stats. Must be an iso-format string rounded to the nearest day.
     * @param {string} [channelName] Filter down to only candidates that came from the specified channel when computing stats.
     * @param {Array<string>} [candidateSource] Filter down to only candidates that came from the specified source when computing stats.
     * @param {string} [demographic] Filter down to only candidates of the specified demographic when computing stats.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobFunnelStatsRaw(requestParameters: ApiApiGetJobFunnelStatsRequest): Promise<runtime.ApiResponse<JobFunnelStats>>;

    /**
     * get stats about this job\'s funnel
     */
    getJobFunnelStats(requestParameters: ApiApiGetJobFunnelStatsRequest): Promise<JobFunnelStats>;

    /**
     * get stats about how inbound is performing for this job
     * @param {string} id A UUID string identifying this job.
     * @param {string} [start] The start time from which we should include data points when computing stats. Must be an iso-format string rounded to the nearest day.
     * @param {string} [end] The end time up to which we should include data points when computing stats. Must be an iso-format string rounded to the nearest day.
     * @param {string} [channelName] Filter down to only candidates that came from the specified channel when computing stats.
     * @param {Array<string>} [candidateSource] Filter down to only candidates that came from the specified source when computing stats.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobInboundStatsRaw(requestParameters: ApiApiGetJobInboundStatsRequest): Promise<runtime.ApiResponse<JobInboundStats>>;

    /**
     * get stats about how inbound is performing for this job
     */
    getJobInboundStats(requestParameters: ApiApiGetJobInboundStatsRequest): Promise<JobInboundStats>;

    /**
     * get stats about how outbound is performing for this job
     * @param {string} id A UUID string identifying this job.
     * @param {string} [start] The start time from which we should include data points when computing stats. Must be an iso-format string rounded to the nearest day.
     * @param {string} [end] The end time up to which we should include data points when computing stats. Must be an iso-format string rounded to the nearest day.
     * @param {string} [channelName] Filter down to only candidates that came from the specified channel when computing stats.
     * @param {Array<string>} [candidateSource] Filter down to only candidates that came from the specified source when computing stats.
     * @param {string} [demographic] Filter down to only candidates of the specified demographic when computing stats.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobOutboundStatsRaw(requestParameters: ApiApiGetJobOutboundStatsRequest): Promise<runtime.ApiResponse<JobOutboundStats>>;

    /**
     * get stats about how outbound is performing for this job
     */
    getJobOutboundStats(requestParameters: ApiApiGetJobOutboundStatsRequest): Promise<JobOutboundStats>;

    /**
     * get benchmarks about how this job\'s outreach should be performing
     * @param {string} id A UUID string identifying this job.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobOutreachBenchmarksRaw(requestParameters: ApiApiGetJobOutreachBenchmarksRequest): Promise<runtime.ApiResponse<JobOutreachBenchmarks>>;

    /**
     * get benchmarks about how this job\'s outreach should be performing
     */
    getJobOutreachBenchmarks(requestParameters: ApiApiGetJobOutreachBenchmarksRequest): Promise<JobOutreachBenchmarks>;

    /**
     * get stats about this job\'s outreach performance
     * @param {string} id A UUID string identifying this job.
     * @param {string} [start] The start time from which we should include data points when computing stats. Must be an iso-format string rounded to the nearest day.
     * @param {string} [end] The end time up to which we should include data points when computing stats. Must be an iso-format string rounded to the nearest day.
     * @param {string} [channelName] Filter down to only candidates that came from the specified channel when computing stats.
     * @param {Array<string>} [candidateSource] Filter down to only candidates that came from the specified source when computing stats.
     * @param {string} [demographic] Filter down to only candidates of the specified demographic when computing stats.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobOutreachStatsRaw(requestParameters: ApiApiGetJobOutreachStatsRequest): Promise<runtime.ApiResponse<JobOutreachStats>>;

    /**
     * get stats about this job\'s outreach performance
     */
    getJobOutreachStats(requestParameters: ApiApiGetJobOutreachStatsRequest): Promise<JobOutreachStats>;

    /**
     * getJobPitchQuestionSchema
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobPitchQuestionSchemaRaw(requestParameters: ApiApiGetJobPitchQuestionSchemaRequest): Promise<runtime.ApiResponse<GetJobPitchQuestionSchemaResponse>>;

    /**
     * getJobPitchQuestionSchema
     */
    getJobPitchQuestionSchema(requestParameters: ApiApiGetJobPitchQuestionSchemaRequest): Promise<GetJobPitchQuestionSchemaResponse>;

    /**
     * retrieve job position
     * @param {string} id A UUID string identifying this job position.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobPositionRaw(requestParameters: ApiApiGetJobPositionRequest): Promise<runtime.ApiResponse<HiringPlanJobPosition>>;

    /**
     * retrieve job position
     */
    getJobPosition(requestParameters: ApiApiGetJobPositionRequest): Promise<HiringPlanJobPosition>;

    /**
     * get stats about how referrals is performing for this job
     * @param {string} id A UUID string identifying this job.
     * @param {string} [start] The start time from which we should include data points when computing stats. Must be an iso-format string rounded to the nearest day.
     * @param {string} [end] The end time up to which we should include data points when computing stats. Must be an iso-format string rounded to the nearest day.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobReferralsStatsRaw(requestParameters: ApiApiGetJobReferralsStatsRequest): Promise<runtime.ApiResponse<JobReferralsStats>>;

    /**
     * get stats about how referrals is performing for this job
     */
    getJobReferralsStats(requestParameters: ApiApiGetJobReferralsStatsRequest): Promise<JobReferralsStats>;

    /**
     * get all employees that referred at least one passing connection
     * @param {string} [job] 
     * @param {string} [referrer] 
     * @param {string} [triagingStatus] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobReferrersRaw(requestParameters: ApiApiGetJobReferrersRequest): Promise<runtime.ApiResponse<Array<AuthUser>>>;

    /**
     * get all employees that referred at least one passing connection
     */
    getJobReferrers(requestParameters: ApiApiGetJobReferrersRequest): Promise<Array<AuthUser>>;

    /**
     * get metadata about reporting for this job
     * @param {string} id A UUID string identifying this job.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobReportMetaRaw(requestParameters: ApiApiGetJobReportMetaRequest): Promise<runtime.ApiResponse<JobReportMeta>>;

    /**
     * get metadata about reporting for this job
     */
    getJobReportMeta(requestParameters: ApiApiGetJobReportMetaRequest): Promise<JobReportMeta>;

    /**
     * retrieve job setup info
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobSetupRaw(requestParameters: ApiApiGetJobSetupRequest): Promise<runtime.ApiResponse<JobSetup>>;

    /**
     * retrieve job setup info
     */
    getJobSetup(requestParameters: ApiApiGetJobSetupRequest): Promise<JobSetup>;

    /**
     * gets the setup steps and their state for a given job
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getJobSetupStepsRaw(requestParameters: ApiApiGetJobSetupStepsRequest): Promise<runtime.ApiResponse<JobSetupStepsWithSetupSummaryState>>;

    /**
     * gets the setup steps and their state for a given job
     */
    getJobSetupSteps(requestParameters: ApiApiGetJobSetupStepsRequest): Promise<JobSetupStepsWithSetupSummaryState>;

    /**
     * retrieve keyword
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getKeywordRaw(requestParameters: ApiApiGetKeywordRequest): Promise<runtime.ApiResponse<Keyword>>;

    /**
     * retrieve keyword
     */
    getKeyword(requestParameters: ApiApiGetKeywordRequest): Promise<Keyword>;

    /**
     * get keywords with bucket indices
     * @param {Array<KeywordWithBucketIndicesRequest>} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getKeywordsWithBucketIndicesRaw(requestParameters: ApiApiGetKeywordsWithBucketIndicesRequest): Promise<runtime.ApiResponse<Array<KeywordWithBucketIndicesResponse>>>;

    /**
     * get keywords with bucket indices
     */
    getKeywordsWithBucketIndices(requestParameters: ApiApiGetKeywordsWithBucketIndicesRequest): Promise<Array<KeywordWithBucketIndicesResponse>>;

    /**
     * Get information about client\'s Lever refresh token.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getLeverAuthInfoRaw(requestParameters: ApiApiGetLeverAuthInfoRequest): Promise<runtime.ApiResponse<LeverAuthInfo>>;

    /**
     * Get information about client\'s Lever refresh token.
     */
    getLeverAuthInfo(requestParameters: ApiApiGetLeverAuthInfoRequest): Promise<LeverAuthInfo>;

    /**
     * retrieve Lever data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getLeverDataRaw(): Promise<runtime.ApiResponse<AtsData>>;

    /**
     * retrieve Lever data
     */
    getLeverData(): Promise<AtsData>;

    /**
     * recalculate matching count of profile attribute
     * @param {string} id A UUID string identifying this profile attribute.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getMatchingCountRaw(requestParameters: ApiApiGetMatchingCountRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * recalculate matching count of profile attribute
     */
    getMatchingCount(requestParameters: ApiApiGetMatchingCountRequest): Promise<InlineResponse2001>;

    /**
     * Get meeting type of interviewer.
     * @param {string} [accountId] nylas account id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getMeetingTypeRaw(requestParameters: ApiApiGetMeetingTypeRequest): Promise<runtime.ApiResponse<InlineResponse20052>>;

    /**
     * Get meeting type of interviewer.
     */
    getMeetingType(requestParameters: ApiApiGetMeetingTypeRequest): Promise<InlineResponse20052>;

    /**
     * Filter the potentially new emails from a list
     * @param {InlineObject7} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getMissingEmailsRaw(requestParameters: ApiApiGetMissingEmailsRequest): Promise<runtime.ApiResponse<Array<string>>>;

    /**
     * Filter the potentially new emails from a list
     */
    getMissingEmails(requestParameters: ApiApiGetMissingEmailsRequest): Promise<Array<string>>;

    /**
     * Get the relevant multipart substage information for a given multipart interview
     * @param {string} id 
     * @param {string} [candidateId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getMultipartInterviewStageRaw(requestParameters: ApiApiGetMultipartInterviewStageRequest): Promise<runtime.ApiResponse<MultipartSchedulingMultipartInterviewStage>>;

    /**
     * Get the relevant multipart substage information for a given multipart interview
     */
    getMultipartInterviewStage(requestParameters: ApiApiGetMultipartInterviewStageRequest): Promise<MultipartSchedulingMultipartInterviewStage>;

    /**
     * retrieve candidate
     * @param {string} id A UUID string identifying this candidate.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getMultipartSchedulingCandidateRaw(requestParameters: ApiApiGetMultipartSchedulingCandidateRequest): Promise<runtime.ApiResponse<MultipartSchedulingCandidate>>;

    /**
     * retrieve candidate
     */
    getMultipartSchedulingCandidate(requestParameters: ApiApiGetMultipartSchedulingCandidateRequest): Promise<MultipartSchedulingCandidate>;

    /**
     * Returns an API Key for a provided job source and the requesting user\'s client. Returns existing key if one exists.
     * @param {GetOrCreateApiKeyCredentialRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getOrCreateApiKeyCredentialRaw(requestParameters: ApiApiGetOrCreateApiKeyCredentialOperationRequest): Promise<runtime.ApiResponse<GetOrCreateApiKeyCredentialResponse>>;

    /**
     * Returns an API Key for a provided job source and the requesting user\'s client. Returns existing key if one exists.
     */
    getOrCreateApiKeyCredential(requestParameters: ApiApiGetOrCreateApiKeyCredentialOperationRequest): Promise<GetOrCreateApiKeyCredentialResponse>;

    /**
     * Create a keyword based on name
     * @param {InlineObject23} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getOrCreateKeywordWithNameRaw(requestParameters: ApiApiGetOrCreateKeywordWithNameRequest): Promise<runtime.ApiResponse<ProfileSearchKeywordSerializer>>;

    /**
     * Create a keyword based on name
     */
    getOrCreateKeywordWithName(requestParameters: ApiApiGetOrCreateKeywordWithNameRequest): Promise<ProfileSearchKeywordSerializer>;

    /**
     * Given a candidate and a hiring pipeline stage, will get or create the relevant multipart interview and its substages
     * @param {GetOrCreateMultipartStagesRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getOrCreateMultipartInterviewSubstagesRaw(requestParameters: ApiApiGetOrCreateMultipartInterviewSubstagesRequest): Promise<runtime.ApiResponse<CandidateMultipartInterview>>;

    /**
     * Given a candidate and a hiring pipeline stage, will get or create the relevant multipart interview and its substages
     */
    getOrCreateMultipartInterviewSubstages(requestParameters: ApiApiGetOrCreateMultipartInterviewSubstagesRequest): Promise<CandidateMultipartInterview>;

    /**
     * retrieves or creates a user onboarding flow record given a user and a flow name
     * @param {GetOrCreateUserOnboardingFlowRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getOrCreateUserOnboardingFlowRaw(requestParameters: ApiApiGetOrCreateUserOnboardingFlowOperationRequest): Promise<runtime.ApiResponse<UserOnboardingFlow>>;

    /**
     * retrieves or creates a user onboarding flow record given a user and a flow name
     */
    getOrCreateUserOnboardingFlow(requestParameters: ApiApiGetOrCreateUserOnboardingFlowOperationRequest): Promise<UserOnboardingFlow>;

    /**
     * get persona questionnaire using persona v2
     * @param {string} personaId 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getPQUsingPersonaV2Raw(requestParameters: ApiApiGetPQUsingPersonaV2Request): Promise<runtime.ApiResponse<SearchV3PersonaQuestionnaire>>;

    /**
     * get persona questionnaire using persona v2
     */
    getPQUsingPersonaV2(requestParameters: ApiApiGetPQUsingPersonaV2Request): Promise<SearchV3PersonaQuestionnaire>;

    /**
     * Given a job, try to fetch the persona that is associated with the search
     * @param {GetPersonaForJobRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getPersonaForJobRaw(requestParameters: ApiApiGetPersonaForJobOperationRequest): Promise<runtime.ApiResponse<GetPersonaForJobResponse>>;

    /**
     * Given a job, try to fetch the persona that is associated with the search
     */
    getPersonaForJob(requestParameters: ApiApiGetPersonaForJobOperationRequest): Promise<GetPersonaForJobResponse>;

    /**
     * get persona questionnaire
     * @param {string} id A UUID string identifying this persona questionnaire.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getPersonaQuestionnaireRaw(requestParameters: ApiApiGetPersonaQuestionnaireRequest): Promise<runtime.ApiResponse<PersonaQuestionnaire>>;

    /**
     * get persona questionnaire
     */
    getPersonaQuestionnaire(requestParameters: ApiApiGetPersonaQuestionnaireRequest): Promise<PersonaQuestionnaire>;

    /**
     * 
     * @param {GetPersonaViaSoftMatchRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getPersonaViaSoftMatchRaw(requestParameters: ApiApiGetPersonaViaSoftMatchOperationRequest): Promise<runtime.ApiResponse<SlimPersona>>;

    /**
     */
    getPersonaViaSoftMatch(requestParameters: ApiApiGetPersonaViaSoftMatchOperationRequest): Promise<SlimPersona>;

    /**
     * retrieve pro user
     * @param {string} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getProUserRaw(requestParameters: ApiApiGetProUserRequest): Promise<runtime.ApiResponse<DoverUser>>;

    /**
     * retrieve pro user
     */
    getProUser(requestParameters: ApiApiGetProUserRequest): Promise<DoverUser>;

    /**
     * In-app route to get count of published jobs for a client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getPublishedJobCountRaw(): Promise<runtime.ApiResponse<PublishedJobCount>>;

    /**
     * In-app route to get count of published jobs for a client
     */
    getPublishedJobCount(): Promise<PublishedJobCount>;

    /**
     * gets the triaging tab counts
     * @param {string} [job] 
     * @param {string} [referrer] 
     * @param {string} [triagingStatus] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getReferralStatsRaw(requestParameters: ApiApiGetReferralStatsRequest): Promise<runtime.ApiResponse<JobReferralStats>>;

    /**
     * gets the triaging tab counts
     */
    getReferralStats(requestParameters: ApiApiGetReferralStatsRequest): Promise<JobReferralStats>;

    /**
     * get keywords related to a bucket of industries
     * @param {string} id A UUID string identifying this keyword.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getRelatedKeywordsRaw(requestParameters: ApiApiGetRelatedKeywordsRequest): Promise<runtime.ApiResponse<InlineResponse20044>>;

    /**
     * get keywords related to a bucket of industries
     */
    getRelatedKeywords(requestParameters: ApiApiGetRelatedKeywordsRequest): Promise<InlineResponse20044>;

    /**
     * Get remaining concierge hours from meta base, None if not on concierge plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getRemainingConciergeHoursRaw(): Promise<runtime.ApiResponse<GetRemainingConciergeHoursResponse>>;

    /**
     * Get remaining concierge hours from meta base, None if not on concierge plan
     */
    getRemainingConciergeHours(): Promise<GetRemainingConciergeHoursResponse>;

    /**
     * Get calendars for scheduling_email_alias with appropriate write permissions.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getSchedulableCalendarsRaw(requestParameters: ApiApiGetSchedulableCalendarsRequest): Promise<runtime.ApiResponse<Array<SchedulableCalendar>>>;

    /**
     * Get calendars for scheduling_email_alias with appropriate write permissions.
     */
    getSchedulableCalendars(requestParameters: ApiApiGetSchedulableCalendarsRequest): Promise<Array<SchedulableCalendar>>;

    /**
     * Get a scheduling link, given candidate, interviewer, and HPS
     * @param {GetSchedulingLinkRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getSchedulingLinkRaw(requestParameters: ApiApiGetSchedulingLinkOperationRequest): Promise<runtime.ApiResponse<SchedulingLink>>;

    /**
     * Get a scheduling link, given candidate, interviewer, and HPS
     */
    getSchedulingLink(requestParameters: ApiApiGetSchedulingLinkOperationRequest): Promise<SchedulingLink>;

    /**
     * retrieve search
     * @param {string} id A UUID string identifying this search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getSearchRaw(requestParameters: ApiApiGetSearchRequest): Promise<runtime.ApiResponse<AdminSearch>>;

    /**
     * retrieve search
     */
    getSearch(requestParameters: ApiApiGetSearchRequest): Promise<AdminSearch>;

    /**
     * getSearchCountEstimate
     * @param {EsSearchCountsRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getSearchCountEstimateRaw(requestParameters: ApiApiGetSearchCountEstimateRequest): Promise<runtime.ApiResponse<InlineResponse20066>>;

    /**
     * getSearchCountEstimate
     */
    getSearchCountEstimate(requestParameters: ApiApiGetSearchCountEstimateRequest): Promise<InlineResponse20066>;

    /**
     * get search v3 params from JD
     * @param {GetSearchParamsFromJDRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getSearchParamsFromJDRaw(requestParameters: ApiApiGetSearchParamsFromJDOperationRequest): Promise<runtime.ApiResponse<GetSearchParamsFromJDResponse>>;

    /**
     * get search v3 params from JD
     */
    getSearchParamsFromJD(requestParameters: ApiApiGetSearchParamsFromJDOperationRequest): Promise<GetSearchParamsFromJDResponse>;

    /**
     * Given a search and a search transform, produces a new set of search params
     * @param {string} id A UUID string identifying this search.
     * @param {string} searchTransformName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getSearchTransformOutputRaw(requestParameters: ApiApiGetSearchTransformOutputRequest): Promise<runtime.ApiResponse<object>>;

    /**
     * Given a search and a search transform, produces a new set of search params
     */
    getSearchTransformOutput(requestParameters: ApiApiGetSearchTransformOutputRequest): Promise<object>;

    /**
     * retrieve search transform relationship
     * @param {string} childSearch A unique value identifying this search transform relationship.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getSearchTransformRelationshipRaw(requestParameters: ApiApiGetSearchTransformRelationshipRequest): Promise<runtime.ApiResponse<AdminSearchTransformRelationship>>;

    /**
     * retrieve search transform relationship
     */
    getSearchTransformRelationship(requestParameters: ApiApiGetSearchTransformRelationshipRequest): Promise<AdminSearchTransformRelationship>;

    /**
     * get search v3 params
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getSearchV3Raw(requestParameters: ApiApiGetSearchV3Request): Promise<runtime.ApiResponse<SearchV3>>;

    /**
     * get search v3 params
     */
    getSearchV3(requestParameters: ApiApiGetSearchV3Request): Promise<SearchV3>;

    /**
     * get search v3 depth result
     * @param {DepthRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getSearchV3DepthResultRaw(requestParameters: ApiApiGetSearchV3DepthResultRequest): Promise<runtime.ApiResponse<DepthResponse>>;

    /**
     * get search v3 depth result
     */
    getSearchV3DepthResult(requestParameters: ApiApiGetSearchV3DepthResultRequest): Promise<DepthResponse>;

    /**
     * get searches with similar candidates sourcing
     * @param {string} [job] 
     * @param {string} [searchTypeList] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getSimilarCandidatesSearchesRaw(requestParameters: ApiApiGetSimilarCandidatesSearchesRequest): Promise<runtime.ApiResponse<Array<SearchV3>>>;

    /**
     * get searches with similar candidates sourcing
     */
    getSimilarCandidatesSearches(requestParameters: ApiApiGetSimilarCandidatesSearchesRequest): Promise<Array<SearchV3>>;

    /**
     * Get transcript for candidate interview
     * @param {string} candidateInterviewId Candidate Interview ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getTranscriptForInterviewRaw(requestParameters: ApiApiGetTranscriptForInterviewRequest): Promise<runtime.ApiResponse<InterviewTranscriptResponse>>;

    /**
     * Get transcript for candidate interview
     */
    getTranscriptForInterview(requestParameters: ApiApiGetTranscriptForInterviewRequest): Promise<InterviewTranscriptResponse>;

    /**
     * retrieves information about the requesting user
     * @param {string} [isHiringManager] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [checkEmailVerification] check live email verification via auth0 for initial sign up
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getUserInfoRaw(requestParameters: ApiApiGetUserInfoRequest): Promise<runtime.ApiResponse<DoverUser>>;

    /**
     * retrieves information about the requesting user
     */
    getUserInfo(requestParameters: ApiApiGetUserInfoRequest): Promise<DoverUser>;

    /**
     * get the interviewer scheduling info for the current user
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getUserInterviewerSchedulingInfoRaw(requestParameters: ApiApiGetUserInterviewerSchedulingInfoRequest): Promise<runtime.ApiResponse<InterviewerSchedulingInfo>>;

    /**
     * get the interviewer scheduling info for the current user
     */
    getUserInterviewerSchedulingInfo(requestParameters: ApiApiGetUserInterviewerSchedulingInfoRequest): Promise<InterviewerSchedulingInfo>;

    /**
     * get user ratings by the entity type and uuid
     * @param {string} entityType 
     * @param {string} entityUuid 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getUserRatingsByEntityRaw(requestParameters: ApiApiGetUserRatingsByEntityRequest): Promise<runtime.ApiResponse<Array<UserRating>>>;

    /**
     * get user ratings by the entity type and uuid
     */
    getUserRatingsByEntity(requestParameters: ApiApiGetUserRatingsByEntityRequest): Promise<Array<UserRating>>;

    /**
     * retrieves roles and permissions information about the requesting user
     * @param {string} [isHiringManager] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getUserRolesAndPermissionsRaw(requestParameters: ApiApiGetUserRolesAndPermissionsRequest): Promise<runtime.ApiResponse<UserRolesAndPermissions>>;

    /**
     * retrieves roles and permissions information about the requesting user
     */
    getUserRolesAndPermissions(requestParameters: ApiApiGetUserRolesAndPermissionsRequest): Promise<UserRolesAndPermissions>;

    /**
     * retrieve a information about file you\'ve uploaded
     * @param {string} id A UUID string identifying this user uploaded file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getUserUploadedFileRaw(requestParameters: ApiApiGetUserUploadedFileRequest): Promise<runtime.ApiResponse<UserUploadedFile>>;

    /**
     * retrieve a information about file you\'ve uploaded
     */
    getUserUploadedFile(requestParameters: ApiApiGetUserUploadedFileRequest): Promise<UserUploadedFile>;

    /**
     * Return the client for the requesting user
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getUsersClientRaw(requestParameters: ApiApiGetUsersClientRequest): Promise<runtime.ApiResponse<Client>>;

    /**
     * Return the client for the requesting user
     */
    getUsersClient(requestParameters: ApiApiGetUsersClientRequest): Promise<Client>;

    /**
     * Given a user return their onboarding flow if they have one
     * @param {string} [flowName] 
     * @param {string} [proUserUserEmail] 
     * @param {string} [relatedObjectUuid] 
     * @param {string} [contentTypeModel] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    hasSeenOnboardingFlowRaw(requestParameters: ApiApiHasSeenOnboardingFlowRequest): Promise<runtime.ApiResponse<HasSeenOnboardingFlow>>;

    /**
     * Given a user return their onboarding flow if they have one
     */
    hasSeenOnboardingFlow(requestParameters: ApiApiHasSeenOnboardingFlowRequest): Promise<HasSeenOnboardingFlow>;

    /**
     * Send test email
     * @param {HiringStageEmailTemplateSendTestEmailRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    hiringStageEmailTemplateSendTestEmailRaw(requestParameters: ApiApiHiringStageEmailTemplateSendTestEmailOperationRequest): Promise<runtime.ApiResponse<object>>;

    /**
     * Send test email
     */
    hiringStageEmailTemplateSendTestEmail(requestParameters: ApiApiHiringStageEmailTemplateSendTestEmailOperationRequest): Promise<object>;

    /**
     * Check that slackbot auth is set up correctly
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    jobCheckSlackbotAuthRaw(requestParameters: ApiApiJobCheckSlackbotAuthRequest): Promise<runtime.ApiResponse<InlineResponse20050>>;

    /**
     * Check that slackbot auth is set up correctly
     */
    jobCheckSlackbotAuth(requestParameters: ApiApiJobCheckSlackbotAuthRequest): Promise<InlineResponse20050>;

    /**
     * list application questions
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [clientId] Filter by client id
     * @param {string} [job] Filter by job id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listApplicationQuestionsRaw(requestParameters: ApiApiListApplicationQuestionsRequest): Promise<runtime.ApiResponse<InlineResponse20040>>;

    /**
     * list application questions
     */
    listApplicationQuestions(requestParameters: ApiApiListApplicationQuestionsRequest): Promise<InlineResponse20040>;

    /**
     * list scored saap review applications via AI
     * @param {ListAppsViaAIRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listApplicationsViaAIRaw(requestParameters: ApiApiListApplicationsViaAIRequest): Promise<runtime.ApiResponse<ListAppsViaAIResponse>>;

    /**
     * list scored saap review applications via AI
     */
    listApplicationsViaAI(requestParameters: ApiApiListApplicationsViaAIRequest): Promise<ListAppsViaAIResponse>;

    /**
     * list archive reasons
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listArchiveReasonsRaw(requestParameters: ApiApiListArchiveReasonsRequest): Promise<runtime.ApiResponse<ListArchiveReasonsResponse>>;

    /**
     * list archive reasons
     */
    listArchiveReasons(requestParameters: ApiApiListArchiveReasonsRequest): Promise<ListArchiveReasonsResponse>;

    /**
     * list ats sources
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [atsType] Filter the returned list by ats_type
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listAtsSourcesRaw(requestParameters: ApiApiListAtsSourcesRequest): Promise<runtime.ApiResponse<InlineResponse20012>>;

    /**
     * list ats sources
     */
    listAtsSources(requestParameters: ApiApiListAtsSourcesRequest): Promise<InlineResponse20012>;

    /**
     * list all users for a client
     * @param {string} [isHiringManager] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listAuthenticatedUsersRaw(requestParameters: ApiApiListAuthenticatedUsersRequest): Promise<runtime.ApiResponse<Array<AuthedUser>>>;

    /**
     * list all users for a client
     */
    listAuthenticatedUsers(requestParameters: ApiApiListAuthenticatedUsersRequest): Promise<Array<AuthedUser>>;

    /**
     * list all EmailSenderAliases that a user has permissions for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listAuthorizedEmailAliasesRaw(): Promise<runtime.ApiResponse<Array<AuthorizedEmailSenderAlias>>>;

    /**
     * list all EmailSenderAliases that a user has permissions for
     */
    listAuthorizedEmailAliases(): Promise<Array<AuthorizedEmailSenderAlias>>;

    /**
     * List keyword buckets children and aliases
     * @param {InlineObject24} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listBucketChildrenAndAliasesRaw(requestParameters: ApiApiListBucketChildrenAndAliasesRequest): Promise<runtime.ApiResponse<InlineResponse20063>>;

    /**
     * List keyword buckets children and aliases
     */
    listBucketChildrenAndAliases(requestParameters: ApiApiListBucketChildrenAndAliasesRequest): Promise<InlineResponse20063>;

    /**
     * list campaigns
     * @param {string} [jobCampaignConfigJob] Filter the returned list by job_campaign_config__job
     * @param {string} [jobCampaignConfigJobClient] Filter the returned list by job_campaign_config__job__client
     * @param {string} [jobCampaignConfigJobPersona] Filter the returned list by job_campaign_config__job__persona
     * @param {string} [state] Filter the returned list by state
     * @param {string} [fullTextSearch] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listCampaignsRaw(requestParameters: ApiApiListCampaignsRequest): Promise<runtime.ApiResponse<InlineResponse2006>>;

    /**
     * list campaigns
     */
    listCampaigns(requestParameters: ApiApiListCampaignsRequest): Promise<InlineResponse2006>;

    /**
     * list candidate activity notes
     * @param {string} [candidate] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listCandidateActivityNotesRaw(requestParameters: ApiApiListCandidateActivityNotesRequest): Promise<runtime.ApiResponse<InlineResponse20015>>;

    /**
     * list candidate activity notes
     */
    listCandidateActivityNotes(requestParameters: ApiApiListCandidateActivityNotesRequest): Promise<InlineResponse20015>;

    /**
     * list candidate email events
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listCandidateEmailEventsV2Raw(requestParameters: ApiApiListCandidateEmailEventsV2Request): Promise<runtime.ApiResponse<Array<ActivityFeedItem>>>;

    /**
     * list candidate email events
     */
    listCandidateEmailEventsV2(requestParameters: ApiApiListCandidateEmailEventsV2Request): Promise<Array<ActivityFeedItem>>;

    /**
     * List Candidate Files
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [candidateId] Filter by candidate id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listCandidateFilesRaw(requestParameters: ApiApiListCandidateFilesRequest): Promise<runtime.ApiResponse<InlineResponse20073>>;

    /**
     * List Candidate Files
     */
    listCandidateFiles(requestParameters: ApiApiListCandidateFilesRequest): Promise<InlineResponse20073>;

    /**
     * list candidate interview events
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listCandidateInterviewEventsRaw(requestParameters: ApiApiListCandidateInterviewEventsRequest): Promise<runtime.ApiResponse<Array<ActivityFeedItem>>>;

    /**
     * list candidate interview events
     */
    listCandidateInterviewEvents(requestParameters: ApiApiListCandidateInterviewEventsRequest): Promise<Array<ActivityFeedItem>>;

    /**
     * list moved job events
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listCandidateMovedJobEventsRaw(requestParameters: ApiApiListCandidateMovedJobEventsRequest): Promise<runtime.ApiResponse<Array<ActivityFeedItem>>>;

    /**
     * list moved job events
     */
    listCandidateMovedJobEvents(requestParameters: ApiApiListCandidateMovedJobEventsRequest): Promise<Array<ActivityFeedItem>>;

    /**
     * list candidate multipart interview
     * @param {string} candidateId 
     * @param {string} interviewStageId 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listCandidateMultipartInterviewRaw(requestParameters: ApiApiListCandidateMultipartInterviewRequest): Promise<runtime.ApiResponse<InlineResponse20014>>;

    /**
     * list candidate multipart interview
     */
    listCandidateMultipartInterview(requestParameters: ApiApiListCandidateMultipartInterviewRequest): Promise<InlineResponse20014>;

    /**
     * list candidate sources
     * @param {boolean} [thirdPartyAllowed] 
     * @param {boolean} [includeAdminPreview] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [jobId] Optionally scope results of this api to a specific job.
     * @param {boolean} [includeYcWaas] Optionally include YC WaaS as a candidate source.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listCandidateSourcesRaw(requestParameters: ApiApiListCandidateSourcesRequest): Promise<runtime.ApiResponse<InlineResponse20016>>;

    /**
     * list candidate sources
     */
    listCandidateSources(requestParameters: ApiApiListCandidateSourcesRequest): Promise<InlineResponse20016>;

    /**
     * list stage change events
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listCandidateStageChangeEventsRaw(requestParameters: ApiApiListCandidateStageChangeEventsRequest): Promise<runtime.ApiResponse<Array<ActivityFeedItem>>>;

    /**
     * list stage change events
     */
    listCandidateStageChangeEvents(requestParameters: ApiApiListCandidateStageChangeEventsRequest): Promise<Array<ActivityFeedItem>>;

    /**
     * list client domains
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listClientDomainsRaw(requestParameters: ApiApiListClientDomainsRequest): Promise<runtime.ApiResponse<InlineResponse20018>>;

    /**
     * list client domains
     */
    listClientDomains(requestParameters: ApiApiListClientDomainsRequest): Promise<InlineResponse20018>;

    /**
     * list given client\'s email templates
     * @param {number} [limit] Limit for pagination
     * @param {number} [offset] Offset for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listClientEmailTemplatesRaw(requestParameters: ApiApiListClientEmailTemplatesRequest): Promise<runtime.ApiResponse<InlineResponse20019>>;

    /**
     * list given client\'s email templates
     */
    listClientEmailTemplates(requestParameters: ApiApiListClientEmailTemplatesRequest): Promise<InlineResponse20019>;

    /**
     * list CompanyLists
     * @param {string} [fullTextSearch] A search term.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listCompanyListsRaw(requestParameters: ApiApiListCompanyListsRequest): Promise<runtime.ApiResponse<InlineResponse20023>>;

    /**
     * list CompanyLists
     */
    listCompanyLists(requestParameters: ApiApiListCompanyListsRequest): Promise<InlineResponse20023>;

    /**
     * list CompanySizes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listCompanySizesRaw(): Promise<runtime.ApiResponse<Array<ListCompanySizeSerializer>>>;

    /**
     * list CompanySizes
     */
    listCompanySizes(): Promise<Array<ListCompanySizeSerializer>>;

    /**
     * list all emails currently scoped to the given contact
     * @param {string} contactId contact id to list contact emails for
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listContactEmailsRaw(requestParameters: ApiApiListContactEmailsRequest): Promise<runtime.ApiResponse<InlineResponse20024>>;

    /**
     * list all emails currently scoped to the given contact
     */
    listContactEmails(requestParameters: ApiApiListContactEmailsRequest): Promise<InlineResponse20024>;

    /**
     * list all phone numbers currently scoped to the given contact
     * @param {string} contactId contact id to list contact phone numbers for
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listContactPhoneNumbersRaw(requestParameters: ApiApiListContactPhoneNumbersRequest): Promise<runtime.ApiResponse<InlineResponse20025>>;

    /**
     * list all phone numbers currently scoped to the given contact
     */
    listContactPhoneNumbers(requestParameters: ApiApiListContactPhoneNumbersRequest): Promise<InlineResponse20025>;

    /**
     * list Countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listCountriesRaw(): Promise<runtime.ApiResponse<Array<Country>>>;

    /**
     * list Countries
     */
    listCountries(): Promise<Array<Country>>;

    /**
     * Lists the currency options for compensation
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listCurrencyOptionsRaw(requestParameters: ApiApiListCurrencyOptionsRequest): Promise<runtime.ApiResponse<Array<string>>>;

    /**
     * Lists the currency options for compensation
     */
    listCurrencyOptions(requestParameters: ApiApiListCurrencyOptionsRequest): Promise<Array<string>>;

    /**
     * list diversity options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listDiversityOptionsRaw(): Promise<runtime.ApiResponse<Array<DiversityOption>>>;

    /**
     * list diversity options
     */
    listDiversityOptions(): Promise<Array<DiversityOption>>;

    /**
     * list dover entities
     * @param {string} [email] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listDoverEntitiesRaw(requestParameters: ApiApiListDoverEntitiesRequest): Promise<runtime.ApiResponse<InlineResponse20026>>;

    /**
     * list dover entities
     */
    listDoverEntities(requestParameters: ApiApiListDoverEntitiesRequest): Promise<InlineResponse20026>;

    /**
     * list dover interviewer previews
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listDoverInterviewerPreviewsRaw(requestParameters: ApiApiListDoverInterviewerPreviewsRequest): Promise<runtime.ApiResponse<InlineResponse20027>>;

    /**
     * list dover interviewer previews
     */
    listDoverInterviewerPreviews(requestParameters: ApiApiListDoverInterviewerPreviewsRequest): Promise<InlineResponse20027>;

    /**
     * retrive dover outbound persona stats list
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [job] Filter down to DoverOutboundPersonaStats associated with a Job\&#39;s Persona
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listDoverOutboundPersonaStatsRaw(requestParameters: ApiApiListDoverOutboundPersonaStatsRequest): Promise<runtime.ApiResponse<InlineResponse20028>>;

    /**
     * retrive dover outbound persona stats list
     */
    listDoverOutboundPersonaStats(requestParameters: ApiApiListDoverOutboundPersonaStatsRequest): Promise<InlineResponse20028>;

    /**
     * In-app route to power list of publishable jobs in editable careers page
     * @param {string} clientId 
     * @param {number} [limit] Limit for pagination
     * @param {number} [offset] Offset for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listEditCareersPageJobsRaw(requestParameters: ApiApiListEditCareersPageJobsRequest): Promise<runtime.ApiResponse<InlineResponse20017>>;

    /**
     * In-app route to power list of publishable jobs in editable careers page
     */
    listEditCareersPageJobs(requestParameters: ApiApiListEditCareersPageJobsRequest): Promise<InlineResponse20017>;

    /**
     * list education levels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listEducationLevelsRaw(): Promise<runtime.ApiResponse<Array<EducationLevelsResponse>>>;

    /**
     * list education levels
     */
    listEducationLevels(): Promise<Array<EducationLevelsResponse>>;

    /**
     * list client email sender alias
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listEmailSenderAliasesRaw(requestParameters: ApiApiListEmailSenderAliasesRequest): Promise<runtime.ApiResponse<InlineResponse20029>>;

    /**
     * list client email sender alias
     */
    listEmailSenderAliases(requestParameters: ApiApiListEmailSenderAliasesRequest): Promise<InlineResponse20029>;

    /**
     * Get all feedback forms for a candidate for the interview specifified
     * @param {string} interviewId interview id
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listFeedbackFormsForInterviewRaw(requestParameters: ApiApiListFeedbackFormsForInterviewRequest): Promise<runtime.ApiResponse<Array<SlimInterviewRubricResponse>>>;

    /**
     * Get all feedback forms for a candidate for the interview specifified
     */
    listFeedbackFormsForInterview(requestParameters: ApiApiListFeedbackFormsForInterviewRequest): Promise<Array<SlimInterviewRubricResponse>>;

    /**
     * list feedback templates
     * @param {string} [search] A search term.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [name] search feedback templates by name
     * @param {boolean} [matchExact] search feedback templates by exact name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listFeedbackTemplatesRaw(requestParameters: ApiApiListFeedbackTemplatesRequest): Promise<runtime.ApiResponse<FeedbackTemplateListResponse>>;

    /**
     * list feedback templates
     */
    listFeedbackTemplates(requestParameters: ApiApiListFeedbackTemplatesRequest): Promise<FeedbackTemplateListResponse>;

    /**
     * list fields of study
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listFieldsOfStudyRaw(requestParameters: ApiApiListFieldsOfStudyRequest): Promise<runtime.ApiResponse<InlineResponse20033>>;

    /**
     * list fields of study
     */
    listFieldsOfStudy(requestParameters: ApiApiListFieldsOfStudyRequest): Promise<InlineResponse20033>;

    /**
     * list generic email response templates
     * @param {string} [templateType] 
     * @param {string} [jobId] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listGenericEmailResponseTemplatesRaw(requestParameters: ApiApiListGenericEmailResponseTemplatesRequest): Promise<runtime.ApiResponse<InlineResponse20036>>;

    /**
     * list generic email response templates
     */
    listGenericEmailResponseTemplates(requestParameters: ApiApiListGenericEmailResponseTemplatesRequest): Promise<InlineResponse20036>;

    /**
     * List HiringPipelineStages
     * @param {string} jobId 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listHiringPipelineStagesRaw(requestParameters: ApiApiListHiringPipelineStagesRequest): Promise<runtime.ApiResponse<HiringPipelineStageListResponse>>;

    /**
     * List HiringPipelineStages
     */
    listHiringPipelineStages(requestParameters: ApiApiListHiringPipelineStagesRequest): Promise<HiringPipelineStageListResponse>;

    /**
     * list hiring stage email templates
     * @param {string} [job] 
     * @param {string} [templateTypeList] 
     * @param {string} [requiredEmailAlias] 
     * @param {string} [requiredInterviewer] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listHiringStageEmailTemplatesRaw(requestParameters: ApiApiListHiringStageEmailTemplatesRequest): Promise<runtime.ApiResponse<InlineResponse20038>>;

    /**
     * list hiring stage email templates
     */
    listHiringStageEmailTemplates(requestParameters: ApiApiListHiringStageEmailTemplatesRequest): Promise<InlineResponse20038>;

    /**
     * list industries
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listIndustriesRaw(requestParameters: ApiApiListIndustriesRequest): Promise<runtime.ApiResponse<InlineResponse20043>>;

    /**
     * list industries
     */
    listIndustries(requestParameters: ApiApiListIndustriesRequest): Promise<InlineResponse20043>;

    /**
     * List industries children and aliases
     * @param {InlineObject20} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listIndustriesChildrenAndAliasesRaw(requestParameters: ApiApiListIndustriesChildrenAndAliasesRequest): Promise<runtime.ApiResponse<InlineResponse20042>>;

    /**
     * List industries children and aliases
     */
    listIndustriesChildrenAndAliases(requestParameters: ApiApiListIndustriesChildrenAndAliasesRequest): Promise<InlineResponse20042>;

    /**
     * list interview rubric templates
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {number} [personaId] persona id to list feedback templates for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listInterviewRubricTemplatesForPersonaRaw(requestParameters: ApiApiListInterviewRubricTemplatesForPersonaRequest): Promise<runtime.ApiResponse<InlineResponse20045>>;

    /**
     * list interview rubric templates
     */
    listInterviewRubricTemplatesForPersona(requestParameters: ApiApiListInterviewRubricTemplatesForPersonaRequest): Promise<InlineResponse20045>;

    /**
     * list interviewer
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [hiringPipelineStageId] Filter interviewers by a HiringPipelineStage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listInterviewersRaw(requestParameters: ApiApiListInterviewersRequest): Promise<runtime.ApiResponse<InlineResponse20021>>;

    /**
     * list interviewer
     */
    listInterviewers(requestParameters: ApiApiListInterviewersRequest): Promise<InlineResponse20021>;

    /**
     * list job ats source settings
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listJobAtsSourceSettingsRaw(requestParameters: ApiApiListJobAtsSourceSettingsRequest): Promise<runtime.ApiResponse<InlineResponse20046>>;

    /**
     * list job ats source settings
     */
    listJobAtsSourceSettings(requestParameters: ApiApiListJobAtsSourceSettingsRequest): Promise<InlineResponse20046>;

    /**
     * list job candidate source settings
     * @param {string} [job] Filter the returned list by job
     * @param {string} [direction] Filter the returned list by direction
     * @param {string} [state] Filter the returned list by state
     * @param {string} [desiredState] Filter the returned list by desired_state
     * @param {boolean} [includeAdminPreview] Filter the returned list by include_admin_preview
     * @param {string} [coreSource] Filter the returned list by core_source
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [includeYcWaas] Optionally include YC WaaS as a candidate source.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listJobCandidateSourceSettingsRaw(requestParameters: ApiApiListJobCandidateSourceSettingsRequest): Promise<runtime.ApiResponse<InlineResponse20047>>;

    /**
     * list job candidate source settings
     */
    listJobCandidateSourceSettings(requestParameters: ApiApiListJobCandidateSourceSettingsRequest): Promise<InlineResponse20047>;

    /**
     * list available job personas
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listJobPersonasRaw(requestParameters: ApiApiListJobPersonasRequest): Promise<runtime.ApiResponse<InlineResponse20055>>;

    /**
     * list available job personas
     */
    listJobPersonas(requestParameters: ApiApiListJobPersonasRequest): Promise<InlineResponse20055>;

    /**
     * list job position
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listJobPositionsRaw(requestParameters: ApiApiListJobPositionsRequest): Promise<runtime.ApiResponse<InlineResponse20037>>;

    /**
     * list job position
     */
    listJobPositions(requestParameters: ApiApiListJobPositionsRequest): Promise<InlineResponse20037>;

    /**
     * list job referrals
     * @param {string} [job] Filter the returned list by job
     * @param {string} [referrer] Filter the returned list by referrer
     * @param {string} [triagingStatus] Filter the returned list by triaging_status
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listJobReferralsRaw(requestParameters: ApiApiListJobReferralsRequest): Promise<runtime.ApiResponse<InlineResponse20048>>;

    /**
     * list job referrals
     */
    listJobReferrals(requestParameters: ApiApiListJobReferralsRequest): Promise<InlineResponse20048>;

    /**
     * list jobs
     * @param {string} [fullTextSearch] A search term.
     * @param {string} [client] 
     * @param {string} [active] 
     * @param {string} [clientReviewType] 
     * @param {string} [hasActiveCampaign] 
     * @param {string} [myJobs] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listJobsRaw(requestParameters: ApiApiListJobsRequest): Promise<runtime.ApiResponse<InlineResponse20049>>;

    /**
     * list jobs
     */
    listJobs(requestParameters: ApiApiListJobsRequest): Promise<InlineResponse20049>;

    /**
     * gets the setup steps and their state for all jobs for a given client
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listJobsWithSetupStepsRaw(requestParameters: ApiApiListJobsWithSetupStepsRequest): Promise<runtime.ApiResponse<Array<JobSetupSteps>>>;

    /**
     * gets the setup steps and their state for all jobs for a given client
     */
    listJobsWithSetupSteps(requestParameters: ApiApiListJobsWithSetupStepsRequest): Promise<Array<JobSetupSteps>>;

    /**
     * list keywords
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {boolean} [customerFacing] Filter down to Keywords with a specified value of customer_facing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listKeywordsRaw(requestParameters: ApiApiListKeywordsRequest): Promise<runtime.ApiResponse<InlineResponse20051>>;

    /**
     * list keywords
     */
    listKeywords(requestParameters: ApiApiListKeywordsRequest): Promise<InlineResponse20051>;

    /**
     * Lists the remote and in office location options for a job
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listLocationOptionsRaw(requestParameters: ApiApiListLocationOptionsRequest): Promise<runtime.ApiResponse<ListLocationsResponse>>;

    /**
     * Lists the remote and in office location options for a job
     */
    listLocationOptions(requestParameters: ApiApiListLocationOptionsRequest): Promise<ListLocationsResponse>;

    /**
     * list locations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listLocationsRaw(): Promise<runtime.ApiResponse<Array<ProfileSearchLocationSerializer>>>;

    /**
     * list locations
     */
    listLocations(): Promise<Array<ProfileSearchLocationSerializer>>;

    /**
     * list mentions for a given user
     * @param {string} [created] 
     * @param {string} [user] 
     * @param {string} [markedAsRead] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listMentionsForUserRaw(requestParameters: ApiApiListMentionsForUserRequest): Promise<runtime.ApiResponse<Array<Mention>>>;

    /**
     * list mentions for a given user
     */
    listMentionsForUser(requestParameters: ApiApiListMentionsForUserRequest): Promise<Array<Mention>>;

    /**
     * list notification config
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listNotificationConfigRaw(requestParameters: ApiApiListNotificationConfigRequest): Promise<runtime.ApiResponse<InlineResponse20053>>;

    /**
     * list notification config
     */
    listNotificationConfig(requestParameters: ApiApiListNotificationConfigRequest): Promise<InlineResponse20053>;

    /**
     * list hiring pipeline stages
     * @param {string} [job] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listOnboardingHiringPipelineStagesRaw(requestParameters: ApiApiListOnboardingHiringPipelineStagesRequest): Promise<runtime.ApiResponse<InlineResponse20054>>;

    /**
     * list hiring pipeline stages
     */
    listOnboardingHiringPipelineStages(requestParameters: ApiApiListOnboardingHiringPipelineStagesRequest): Promise<InlineResponse20054>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listOnboardingTitlePatternsRaw(): Promise<runtime.ApiResponse<Array<JobSetupTitlePattern>>>;

    /**
     */
    listOnboardingTitlePatterns(): Promise<Array<JobSetupTitlePattern>>;

    /**
     * Given a persona, list the other searches using this persona (pertaining to the requesting client)
     * @param {OtherSearchesUsingPersonaRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listOtherSearchesUsingPersonaRaw(requestParameters: ApiApiListOtherSearchesUsingPersonaRequest): Promise<runtime.ApiResponse<OtherSearchesUsingPersonaResponse>>;

    /**
     * Given a persona, list the other searches using this persona (pertaining to the requesting client)
     */
    listOtherSearchesUsingPersona(requestParameters: ApiApiListOtherSearchesUsingPersonaRequest): Promise<OtherSearchesUsingPersonaResponse>;

    /**
     * list Personas
     * @param {string} [idList] 
     * @param {string} [fullTextSearch] A search term.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listPersonasRaw(requestParameters: ApiApiListPersonasRequest): Promise<runtime.ApiResponse<InlineResponse20058>>;

    /**
     * list Personas
     */
    listPersonas(requestParameters: ApiApiListPersonasRequest): Promise<InlineResponse20058>;

    /**
     * list candidates
     * @param {string} jobId 
     * @param {CandidatePipelineListRequest} data 
     * @param {string} [expand] Conditionally annotate certain information onto each candidate
     * @param {string} [ordering] Order candidates by a field or multiple fields
     * @param {number} [limit] Limit for pagination
     * @param {number} [offset] Offset for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listPipelineCandidatesRaw(requestParameters: ApiApiListPipelineCandidatesRequest): Promise<runtime.ApiResponse<CandidatePipelineListResponse>>;

    /**
     * list candidates
     */
    listPipelineCandidates(requestParameters: ApiApiListPipelineCandidatesRequest): Promise<CandidatePipelineListResponse>;

    /**
     * list pro users
     * @param {string} [isHiringManager] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listProUsersRaw(requestParameters: ApiApiListProUsersRequest): Promise<runtime.ApiResponse<InlineResponse20072>>;

    /**
     * list pro users
     */
    listProUsers(requestParameters: ApiApiListProUsersRequest): Promise<InlineResponse20072>;

    /**
     * list pro users by client id
     * @param {string} [clientId] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listProUsersByClientIdRaw(requestParameters: ApiApiListProUsersByClientIdRequest): Promise<runtime.ApiResponse<InlineResponse20072>>;

    /**
     * list pro users by client id
     */
    listProUsersByClientId(requestParameters: ApiApiListProUsersByClientIdRequest): Promise<InlineResponse20072>;

    /**
     * list profile attributes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listProfileAttributesRaw(): Promise<runtime.ApiResponse<Array<AdminProfileAttribute>>>;

    /**
     * list profile attributes
     */
    listProfileAttributes(): Promise<Array<AdminProfileAttribute>>;

    /**
     * list industries where customer_facing=True
     * @param {string} [idList] 
     * @param {string} [fullTextSearch] A search term.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listProfileSearchIndustriesRaw(requestParameters: ApiApiListProfileSearchIndustriesRequest): Promise<runtime.ApiResponse<InlineResponse20041>>;

    /**
     * list industries where customer_facing=True
     */
    listProfileSearchIndustries(requestParameters: ApiApiListProfileSearchIndustriesRequest): Promise<InlineResponse20041>;

    /**
     * list profile search jobs
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listProfileSearchJobsRaw(requestParameters: ApiApiListProfileSearchJobsRequest): Promise<runtime.ApiResponse<InlineResponse20061>>;

    /**
     * list profile search jobs
     */
    listProfileSearchJobs(requestParameters: ApiApiListProfileSearchJobsRequest): Promise<InlineResponse20061>;

    /**
     * list Keywords where customer_facing=True
     * @param {string} [idList] 
     * @param {string} [queryText] A search term.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listProfileSearchKeywordsRaw(requestParameters: ApiApiListProfileSearchKeywordsRequest): Promise<runtime.ApiResponse<InlineResponse20062>>;

    /**
     * list Keywords where customer_facing=True
     */
    listProfileSearchKeywords(requestParameters: ApiApiListProfileSearchKeywordsRequest): Promise<InlineResponse20062>;

    /**
     * list recruiting partners
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listRecruitingPartnersRaw(requestParameters: ApiApiListRecruitingPartnersRequest): Promise<runtime.ApiResponse<RecruitingPartnerListResponse>>;

    /**
     * list recruiting partners
     */
    listRecruitingPartners(requestParameters: ApiApiListRecruitingPartnersRequest): Promise<RecruitingPartnerListResponse>;

    /**
     * list roles for dover perms
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listRolesRaw(requestParameters: ApiApiListRolesRequest): Promise<runtime.ApiResponse<InlineResponse20064>>;

    /**
     * list roles for dover perms
     */
    listRoles(requestParameters: ApiApiListRolesRequest): Promise<InlineResponse20064>;

    /**
     * list scored saap review applications
     * @param {ListSaapReviewApplicationRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listSaapReviewApplicationsRaw(requestParameters: ApiApiListSaapReviewApplicationsRequest): Promise<runtime.ApiResponse<ListApplicationsReturn>>;

    /**
     * list scored saap review applications
     */
    listSaapReviewApplications(requestParameters: ApiApiListSaapReviewApplicationsRequest): Promise<ListApplicationsReturn>;

    /**
     * list SchoolList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listSchoolListRaw(): Promise<runtime.ApiResponse<Array<SchoolList>>>;

    /**
     * list SchoolList
     */
    listSchoolList(): Promise<Array<SchoolList>>;

    /**
     * List all search transforms and their relative support for a given search
     * @param {string} id A UUID string identifying this search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listSearchTransformsRaw(requestParameters: ApiApiListSearchTransformsRequest): Promise<runtime.ApiResponse<Array<SearchTransformValidationResult>>>;

    /**
     * List all search transforms and their relative support for a given search
     */
    listSearchTransforms(requestParameters: ApiApiListSearchTransformsRequest): Promise<Array<SearchTransformValidationResult>>;

    /**
     * list search v3 params
     * @param {string} [job] 
     * @param {string} [searchTypeList] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listSearchV3Raw(requestParameters: ApiApiListSearchV3Request): Promise<runtime.ApiResponse<InlineResponse20067>>;

    /**
     * list search v3 params
     */
    listSearchV3(requestParameters: ApiApiListSearchV3Request): Promise<InlineResponse20067>;

    /**
     * list search v3 profile results
     * @param {ProfileRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listSearchV3ProfileResultsRaw(requestParameters: ApiApiListSearchV3ProfileResultsRequest): Promise<runtime.ApiResponse<Array<ProfileResponse>>>;

    /**
     * list search v3 profile results
     */
    listSearchV3ProfileResults(requestParameters: ApiApiListSearchV3ProfileResultsRequest): Promise<Array<ProfileResponse>>;

    /**
     * list searches
     * @param {string} [job] 
     * @param {string} [jobClient] 
     * @param {string} [active] 
     * @param {string} [proOnly] 
     * @param {string} [fullTextSearch] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listSearchesRaw(requestParameters: ApiApiListSearchesRequest): Promise<runtime.ApiResponse<InlineResponse2009>>;

    /**
     * list searches
     */
    listSearches(requestParameters: ApiApiListSearchesRequest): Promise<InlineResponse2009>;

    /**
     * list seniorities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listSenioritiesRaw(): Promise<runtime.ApiResponse<Array<Seniority>>>;

    /**
     * list seniorities
     */
    listSeniorities(): Promise<Array<Seniority>>;

    /**
     * list similar companies
     * @param {string} [idList] 
     * @param {string} [fullTextSearch] A search term.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listSimilarCompaniesRaw(requestParameters: ApiApiListSimilarCompaniesRequest): Promise<runtime.ApiResponse<InlineResponse20022>>;

    /**
     * list similar companies
     */
    listSimilarCompanies(requestParameters: ApiApiListSimilarCompaniesRequest): Promise<InlineResponse20022>;

    /**
     * list similar schools
     * @param {string} [idList] 
     * @param {string} [nameList] 
     * @param {string} [fullTextSearch] A search term.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listSimilarSchoolsRaw(requestParameters: ApiApiListSimilarSchoolsRequest): Promise<runtime.ApiResponse<InlineResponse20065>>;

    /**
     * list similar schools
     */
    listSimilarSchools(requestParameters: ApiApiListSimilarSchoolsRequest): Promise<InlineResponse20065>;

    /**
     * list slim search templates
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listSlimSearchTemplatesRaw(requestParameters: ApiApiListSlimSearchTemplatesRequest): Promise<runtime.ApiResponse<InlineResponse2007>>;

    /**
     * list slim search templates
     */
    listSlimSearchTemplates(requestParameters: ApiApiListSlimSearchTemplatesRequest): Promise<InlineResponse2007>;

    /**
     * list starred candidates for a user
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listStarredCandidatesRaw(requestParameters: ApiApiListStarredCandidatesRequest): Promise<runtime.ApiResponse<InlineResponse20075>>;

    /**
     * list starred candidates for a user
     */
    listStarredCandidates(requestParameters: ApiApiListStarredCandidatesRequest): Promise<InlineResponse20075>;

    /**
     * list states
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listStatesRaw(): Promise<runtime.ApiResponse<Array<ListStatesResponse>>>;

    /**
     * list states
     */
    listStates(): Promise<Array<ListStatesResponse>>;

    /**
     * list talent network candidates
     * @param {TalentNetworkRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listTalentNetworkRaw(requestParameters: ApiApiListTalentNetworkRequest): Promise<runtime.ApiResponse<TalentNetworkReturn>>;

    /**
     * list talent network candidates
     */
    listTalentNetwork(requestParameters: ApiApiListTalentNetworkRequest): Promise<TalentNetworkReturn>;

    /**
     * list Title patterns
     * @param {string} [idList] 
     * @param {string} [fullTextSearch] A search term.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listTitlePatternsRaw(requestParameters: ApiApiListTitlePatternsRequest): Promise<runtime.ApiResponse<InlineResponse20069>>;

    /**
     * list Title patterns
     */
    listTitlePatterns(requestParameters: ApiApiListTitlePatternsRequest): Promise<InlineResponse20069>;

    /**
     * Given a persona, list the title patterns, enriched with whether pattern is selected and or default
     * @param {PersonaIDRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listTitlePatternsForPersonaRaw(requestParameters: ApiApiListTitlePatternsForPersonaRequest): Promise<runtime.ApiResponse<Array<TitlePatternInfo>>>;

    /**
     * Given a persona, list the title patterns, enriched with whether pattern is selected and or default
     */
    listTitlePatternsForPersona(requestParameters: ApiApiListTitlePatternsForPersonaRequest): Promise<Array<TitlePatternInfo>>;

    /**
     * list titles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listTitlesRaw(): Promise<runtime.ApiResponse<Array<Title>>>;

    /**
     * list titles
     */
    listTitles(): Promise<Array<Title>>;

    /**
     * list unsubscribe footers
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listUnsubscribeFootersRaw(requestParameters: ApiApiListUnsubscribeFootersRequest): Promise<runtime.ApiResponse<InlineResponse20070>>;

    /**
     * list unsubscribe footers
     */
    listUnsubscribeFooters(requestParameters: ApiApiListUnsubscribeFootersRequest): Promise<InlineResponse20070>;

    /**
     * list user credentials
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listUserCredentialsRaw(requestParameters: ApiApiListUserCredentialsRequest): Promise<runtime.ApiResponse<InlineResponse20071>>;

    /**
     * list user credentials
     */
    listUserCredentials(requestParameters: ApiApiListUserCredentialsRequest): Promise<InlineResponse20071>;

    /**
     * list campaigns
     * @param {string} [showDeleted] 
     * @param {string} [job] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listUserFacingCampaignsRaw(requestParameters: ApiApiListUserFacingCampaignsRequest): Promise<runtime.ApiResponse<InlineResponse20013>>;

    /**
     * list campaigns
     */
    listUserFacingCampaigns(requestParameters: ApiApiListUserFacingCampaignsRequest): Promise<InlineResponse20013>;

    /**
     * List user onboarding flows
     * @param {string} [flowName] 
     * @param {string} [proUserUserEmail] 
     * @param {string} [relatedObjectUuid] 
     * @param {string} [contentTypeModel] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listUserOnboardingFlowsRaw(requestParameters: ApiApiListUserOnboardingFlowsRequest): Promise<runtime.ApiResponse<InlineResponse20056>>;

    /**
     * List user onboarding flows
     */
    listUserOnboardingFlows(requestParameters: ApiApiListUserOnboardingFlowsRequest): Promise<InlineResponse20056>;

    /**
     * list information about files that users in your organization have uploaded
     * @param {string} [uploadedContext] Filter the returned list by uploaded_context
     * @param {string} [processingState] Filter the returned list by processing_state
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listUserUploadedFilesRaw(requestParameters: ApiApiListUserUploadedFilesRequest): Promise<runtime.ApiResponse<InlineResponse20034>>;

    /**
     * list information about files that users in your organization have uploaded
     */
    listUserUploadedFiles(requestParameters: ApiApiListUserUploadedFilesRequest): Promise<InlineResponse20034>;

    /**
     * list users
     * @param {string} [fullTextSearch] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listUsersRaw(requestParameters: ApiApiListUsersRequest): Promise<runtime.ApiResponse<InlineResponse20011>>;

    /**
     * list users
     */
    listUsers(requestParameters: ApiApiListUsersRequest): Promise<InlineResponse20011>;

    /**
     * list valid send at times for Email Send Request
     * @param {ValidSendAtTimesRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listValidEmailSendRequestSendTimesRaw(requestParameters: ApiApiListValidEmailSendRequestSendTimesRequest): Promise<runtime.ApiResponse<Array<ValidEmailSendAtTime>>>;

    /**
     * list valid send at times for Email Send Request
     */
    listValidEmailSendRequestSendTimes(requestParameters: ApiApiListValidEmailSendRequestSendTimesRequest): Promise<Array<ValidEmailSendAtTime>>;

    /**
     * list world regions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    listWorldRegionsRaw(): Promise<runtime.ApiResponse<Array<WorldRegionResponse>>>;

    /**
     * list world regions
     */
    listWorldRegions(): Promise<Array<WorldRegionResponse>>;

    /**
     * mark that the authenticated user manually contacted the referral
     * @param {string} id 
     * @param {JobReferral} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    markJobReferralAsContactedRaw(requestParameters: ApiApiMarkJobReferralAsContactedRequest): Promise<runtime.ApiResponse<JobReferral>>;

    /**
     * mark that the authenticated user manually contacted the referral
     */
    markJobReferralAsContacted(requestParameters: ApiApiMarkJobReferralAsContactedRequest): Promise<JobReferral>;

    /**
     * Returns the current onboarding step for the requesting user
     * @param {MarkOnboardingCompleteRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    markOnboardingCompleteRaw(requestParameters: ApiApiMarkOnboardingCompleteOperationRequest): Promise<runtime.ApiResponse<MarkOnboardingCompleteResponse>>;

    /**
     * Returns the current onboarding step for the requesting user
     */
    markOnboardingComplete(requestParameters: ApiApiMarkOnboardingCompleteOperationRequest): Promise<MarkOnboardingCompleteResponse>;

    /**
     * merge a secondary contact\'s info into a primary contact
     * @param {MergeContactsRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    mergeContactsRaw(requestParameters: ApiApiMergeContactsOperationRequest): Promise<runtime.ApiResponse<MergeContactsRequest>>;

    /**
     * merge a secondary contact\'s info into a primary contact
     */
    mergeContacts(requestParameters: ApiApiMergeContactsOperationRequest): Promise<MergeContactsRequest>;

    /**
     * NANA2
     * @param {string} id 
     * @param {ModifyTitlePatternsInSearchRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    modifyTitlePatternsInSearchRaw(requestParameters: ApiApiModifyTitlePatternsInSearchOperationRequest): Promise<runtime.ApiResponse<ModifyTitlePatternsInSearchResponse>>;

    /**
     * NANA2
     */
    modifyTitlePatternsInSearch(requestParameters: ApiApiModifyTitlePatternsInSearchOperationRequest): Promise<ModifyTitlePatternsInSearchResponse>;

    /**
     * Send an email to a candidate.
     * @param {string} id A UUID string identifying this candidate.
     * @param {MoveJobRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    moveJobRaw(requestParameters: ApiApiMoveJobOperationRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>>;

    /**
     * Send an email to a candidate.
     */
    moveJob(requestParameters: ApiApiMoveJobOperationRequest): Promise<BaseCandidateActionResponse>;

    /**
     * parse basic candidate info from a resume
     * @param {Blob} resume 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    parseCandidateInfoRaw(requestParameters: ApiApiParseCandidateInfoRequest): Promise<runtime.ApiResponse<ParseCandidateInfoResult>>;

    /**
     * parse basic candidate info from a resume
     */
    parseCandidateInfo(requestParameters: ApiApiParseCandidateInfoRequest): Promise<ParseCandidateInfoResult>;

    /**
     * Parses client email template from existing conte. Does not persist DB object.
     * @param {ParseClientEmailTemplate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    parseClientEmailTemplateRaw(requestParameters: ApiApiParseClientEmailTemplateRequest): Promise<runtime.ApiResponse<ParseClientEmailTemplate>>;

    /**
     * Parses client email template from existing conte. Does not persist DB object.
     */
    parseClientEmailTemplate(requestParameters: ApiApiParseClientEmailTemplateRequest): Promise<ParseClientEmailTemplate>;

    /**
     * parse features from a job description
     * @param {ParseJDFeaturesRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    parseJDFeaturesRaw(requestParameters: ApiApiParseJDFeaturesOperationRequest): Promise<runtime.ApiResponse<ParseJDFeaturesResponse>>;

    /**
     * parse features from a job description
     */
    parseJDFeatures(requestParameters: ApiApiParseJDFeaturesOperationRequest): Promise<ParseJDFeaturesResponse>;

    /**
     * partial update calibration candidate
     * @param {Array<CalibrationCandidate>} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialBulkUpdateCalibrationCandidatesRaw(requestParameters: ApiApiPartialBulkUpdateCalibrationCandidatesRequest): Promise<runtime.ApiResponse<Array<CalibrationCandidate>>>;

    /**
     * partial update calibration candidate
     */
    partialBulkUpdateCalibrationCandidates(requestParameters: ApiApiPartialBulkUpdateCalibrationCandidatesRequest): Promise<Array<CalibrationCandidate>>;

    /**
     * partial update campaign
     * @param {string} id A UUID string identifying this campaign.
     * @param {Campaign} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateCampaignRaw(requestParameters: ApiApiPartialUpdateCampaignRequest): Promise<runtime.ApiResponse<Campaign>>;

    /**
     * partial update campaign
     */
    partialUpdateCampaign(requestParameters: ApiApiPartialUpdateCampaignRequest): Promise<Campaign>;

    /**
     * partial update candidate bio
     * @param {string} id 
     * @param {UpdateCandidateBioBody} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateCandidateBioRaw(requestParameters: ApiApiPartialUpdateCandidateBioRequest): Promise<runtime.ApiResponse<CandidateBio>>;

    /**
     * partial update candidate bio
     */
    partialUpdateCandidateBio(requestParameters: ApiApiPartialUpdateCandidateBioRequest): Promise<CandidateBio>;

    /**
     * partially update a candidate interview
     * @param {string} id A UUID string identifying this candidate interview.
     * @param {UpdateCandidateInterview} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateCandidateInterviewRaw(requestParameters: ApiApiPartialUpdateCandidateInterviewRequest): Promise<runtime.ApiResponse<UpdateCandidateInterview>>;

    /**
     * partially update a candidate interview
     */
    partialUpdateCandidateInterview(requestParameters: ApiApiPartialUpdateCandidateInterviewRequest): Promise<UpdateCandidateInterview>;

    /**
     * partial client update
     * @param {string} id A UUID string identifying this client.
     * @param {UpdateClientBody} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateClientRaw(requestParameters: ApiApiPartialUpdateClientRequest): Promise<runtime.ApiResponse<Client>>;

    /**
     * partial client update
     */
    partialUpdateClient(requestParameters: ApiApiPartialUpdateClientRequest): Promise<Client>;

    /**
     * partial update client email template
     * @param {string} id 
     * @param {ClientEmailTemplate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateClientEmailTemplateRaw(requestParameters: ApiApiPartialUpdateClientEmailTemplateRequest): Promise<runtime.ApiResponse<ClientEmailTemplate>>;

    /**
     * partial update client email template
     */
    partialUpdateClientEmailTemplate(requestParameters: ApiApiPartialUpdateClientEmailTemplateRequest): Promise<ClientEmailTemplate>;

    /**
     * partial update CompanyList
     * @param {string} id 
     * @param {CompanyList} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateCompanyListRaw(requestParameters: ApiApiPartialUpdateCompanyListRequest): Promise<runtime.ApiResponse<CompanyList>>;

    /**
     * partial update CompanyList
     */
    partialUpdateCompanyList(requestParameters: ApiApiPartialUpdateCompanyListRequest): Promise<CompanyList>;

    /**
     * partial update contact
     * @param {string} id 
     * @param {Contact} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateContactRaw(requestParameters: ApiApiPartialUpdateContactRequest): Promise<runtime.ApiResponse<Contact>>;

    /**
     * partial update contact
     */
    partialUpdateContact(requestParameters: ApiApiPartialUpdateContactRequest): Promise<Contact>;

    /**
     * partial update contact email
     * @param {string} id 
     * @param {ContactEmail} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateContactEmailRaw(requestParameters: ApiApiPartialUpdateContactEmailRequest): Promise<runtime.ApiResponse<ContactEmail>>;

    /**
     * partial update contact email
     */
    partialUpdateContactEmail(requestParameters: ApiApiPartialUpdateContactEmailRequest): Promise<ContactEmail>;

    /**
     * partial update contact phone number
     * @param {string} id 
     * @param {ContactPhoneNumber} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateContactPhoneNumberRaw(requestParameters: ApiApiPartialUpdateContactPhoneNumberRequest): Promise<runtime.ApiResponse<ContactPhoneNumber>>;

    /**
     * partial update contact phone number
     */
    partialUpdateContactPhoneNumber(requestParameters: ApiApiPartialUpdateContactPhoneNumberRequest): Promise<ContactPhoneNumber>;

    /**
     * partial update feedback template
     * @param {string} id 
     * @param {UpdateFeedbackTemplateRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateFeedbackTemplateRaw(requestParameters: ApiApiPartialUpdateFeedbackTemplateRequest): Promise<runtime.ApiResponse<FeedbackTemplateDetail>>;

    /**
     * partial update feedback template
     */
    partialUpdateFeedbackTemplate(requestParameters: ApiApiPartialUpdateFeedbackTemplateRequest): Promise<FeedbackTemplateDetail>;

    /**
     * partial update generic email response template
     * @param {string} id A UUID string identifying this generic email response template.
     * @param {GenericEmailResponseTemplate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateGenericEmailResponseTemplateRaw(requestParameters: ApiApiPartialUpdateGenericEmailResponseTemplateRequest): Promise<runtime.ApiResponse<GenericEmailResponseTemplate>>;

    /**
     * partial update generic email response template
     */
    partialUpdateGenericEmailResponseTemplate(requestParameters: ApiApiPartialUpdateGenericEmailResponseTemplateRequest): Promise<GenericEmailResponseTemplate>;

    /**
     * partial update hiring stage email template
     * @param {string} id A UUID string identifying this hiring stage email template.
     * @param {HiringStageEmailTemplate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateHiringStageEmailTemplateRaw(requestParameters: ApiApiPartialUpdateHiringStageEmailTemplateRequest): Promise<runtime.ApiResponse<HiringStageEmailTemplate>>;

    /**
     * partial update hiring stage email template
     */
    partialUpdateHiringStageEmailTemplate(requestParameters: ApiApiPartialUpdateHiringStageEmailTemplateRequest): Promise<HiringStageEmailTemplate>;

    /**
     * partially update inbound application
     * @param {string} id A UUID string identifying this inbound application.
     * @param {string} [jobId] 
     * @param {string} [referrerSource] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [linkedinUrl] 
     * @param {string} [email] 
     * @param {string} [phoneNumber] 
     * @param {Blob} [resume] 
     * @param {string} [additionalInfo] 
     * @param {string} [applicationQuestions] 
     * @param {boolean} [bookmarked] 
     * @param {boolean} [optedInToTalentNetwork] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateInboundApplicationRaw(requestParameters: ApiApiPartialUpdateInboundApplicationRequest): Promise<runtime.ApiResponse<ApplicationPortalInboundApplication>>;

    /**
     * partially update inbound application
     */
    partialUpdateInboundApplication(requestParameters: ApiApiPartialUpdateInboundApplicationRequest): Promise<ApplicationPortalInboundApplication>;

    /**
     * partial interview rubric response update
     * @param {string} id A UUID string identifying this interview rubric response.
     * @param {InterviewRubricResponse} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateInterviewRubricResponseRaw(requestParameters: ApiApiPartialUpdateInterviewRubricResponseRequest): Promise<runtime.ApiResponse<InterviewRubricResponse>>;

    /**
     * partial interview rubric response update
     */
    partialUpdateInterviewRubricResponse(requestParameters: ApiApiPartialUpdateInterviewRubricResponseRequest): Promise<InterviewRubricResponse>;

    /**
     * update multipart pipeline stage
     * @param {string} id 
     * @param {string} jobId 
     * @param {OnboardingMultipartInterviewStage} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateInterviewStageRaw(requestParameters: ApiApiPartialUpdateInterviewStageRequest): Promise<runtime.ApiResponse<OnboardingMultipartInterviewStage>>;

    /**
     * update multipart pipeline stage
     */
    partialUpdateInterviewStage(requestParameters: ApiApiPartialUpdateInterviewStageRequest): Promise<OnboardingMultipartInterviewStage>;

    /**
     * partial interviewer update
     * @param {string} id A UUID string identifying this client interviewer.
     * @param {ClientInterviewer} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateInterviewerRaw(requestParameters: ApiApiPartialUpdateInterviewerRequest): Promise<runtime.ApiResponse<ClientInterviewer>>;

    /**
     * partial interviewer update
     */
    partialUpdateInterviewer(requestParameters: ApiApiPartialUpdateInterviewerRequest): Promise<ClientInterviewer>;

    /**
     * partial update hiring stage email template
     * @param {string} user A unique value identifying this pro user.
     * @param {ProUser} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateInterviewerProUserRaw(requestParameters: ApiApiPartialUpdateInterviewerProUserRequest): Promise<runtime.ApiResponse<ProUser>>;

    /**
     * partial update hiring stage email template
     */
    partialUpdateInterviewerProUser(requestParameters: ApiApiPartialUpdateInterviewerProUserRequest): Promise<ProUser>;

    /**
     * partial update interviewer scheduling info
     * @param {string} id A UUID string identifying this interviewer scheduling info.
     * @param {InterviewerSchedulingInfo} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateInterviewerSchedulingInfoRaw(requestParameters: ApiApiPartialUpdateInterviewerSchedulingInfoRequest): Promise<runtime.ApiResponse<InterviewerSchedulingInfo>>;

    /**
     * partial update interviewer scheduling info
     */
    partialUpdateInterviewerSchedulingInfo(requestParameters: ApiApiPartialUpdateInterviewerSchedulingInfoRequest): Promise<InterviewerSchedulingInfo>;

    /**
     * partially update a job position
     * @param {string} id A UUID string identifying this job position.
     * @param {HiringPlanJobPosition} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateJobPositionRaw(requestParameters: ApiApiPartialUpdateJobPositionRequest): Promise<runtime.ApiResponse<HiringPlanJobPosition>>;

    /**
     * partially update a job position
     */
    partialUpdateJobPosition(requestParameters: ApiApiPartialUpdateJobPositionRequest): Promise<HiringPlanJobPosition>;

    /**
     * partially update a job position opening
     * @param {number} id A unique integer value identifying this job position opening.
     * @param {HiringPlanJobPositionOpening} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateJobPositionOpeningRaw(requestParameters: ApiApiPartialUpdateJobPositionOpeningRequest): Promise<runtime.ApiResponse<HiringPlanJobPositionOpening>>;

    /**
     * partially update a job position opening
     */
    partialUpdateJobPositionOpening(requestParameters: ApiApiPartialUpdateJobPositionOpeningRequest): Promise<HiringPlanJobPositionOpening>;

    /**
     * partially update a job referral
     * @param {string} id 
     * @param {JobReferral} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateJobReferralRaw(requestParameters: ApiApiPartialUpdateJobReferralRequest): Promise<runtime.ApiResponse<JobReferral>>;

    /**
     * partially update a job referral
     */
    partialUpdateJobReferral(requestParameters: ApiApiPartialUpdateJobReferralRequest): Promise<JobReferral>;

    /**
     * partial job setup info update
     * @param {string} id 
     * @param {JobSetup} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateJobSetupRaw(requestParameters: ApiApiPartialUpdateJobSetupRequest): Promise<runtime.ApiResponse<JobSetup>>;

    /**
     * partial job setup info update
     */
    partialUpdateJobSetup(requestParameters: ApiApiPartialUpdateJobSetupRequest): Promise<JobSetup>;

    /**
     * partially update mention
     * @param {string} id A UUID string identifying this mention.
     * @param {Mention} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateMentionRaw(requestParameters: ApiApiPartialUpdateMentionRequest): Promise<runtime.ApiResponse<Mention>>;

    /**
     * partially update mention
     */
    partialUpdateMention(requestParameters: ApiApiPartialUpdateMentionRequest): Promise<Mention>;

    /**
     * partial update a hiring pipeline stage
     * @param {string} id A UUID string identifying this hiring pipeline stage.
     * @param {OnboardingHiringPipelineStage} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateOnboardingHiringPipelineStageRaw(requestParameters: ApiApiPartialUpdateOnboardingHiringPipelineStageRequest): Promise<runtime.ApiResponse<OnboardingHiringPipelineStage>>;

    /**
     * partial update a hiring pipeline stage
     */
    partialUpdateOnboardingHiringPipelineStage(requestParameters: ApiApiPartialUpdateOnboardingHiringPipelineStageRequest): Promise<OnboardingHiringPipelineStage>;

    /**
     * update multipart pipeline stage
     * @param {string} id A UUID string identifying this hiring pipeline stage.
     * @param {OnboardingMultipartInterviewStage} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateOnboardingMultipartInterviewStageRaw(requestParameters: ApiApiPartialUpdateOnboardingMultipartInterviewStageRequest): Promise<runtime.ApiResponse<OnboardingMultipartInterviewStage>>;

    /**
     * update multipart pipeline stage
     */
    partialUpdateOnboardingMultipartInterviewStage(requestParameters: ApiApiPartialUpdateOnboardingMultipartInterviewStageRequest): Promise<OnboardingMultipartInterviewStage>;

    /**
     * idempotent update of outreach generation
     * @param {string} id A UUID string identifying this outreach generation.
     * @param {OutreachGeneration} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateOutreachGenerationRaw(requestParameters: ApiApiPartialUpdateOutreachGenerationRequest): Promise<runtime.ApiResponse<OutreachGeneration>>;

    /**
     * idempotent update of outreach generation
     */
    partialUpdateOutreachGeneration(requestParameters: ApiApiPartialUpdateOutreachGenerationRequest): Promise<OutreachGeneration>;

    /**
     * partial update Persona
     * @param {string} id 
     * @param {Persona} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdatePersonaRaw(requestParameters: ApiApiPartialUpdatePersonaRequest): Promise<runtime.ApiResponse<Persona>>;

    /**
     * partial update Persona
     */
    partialUpdatePersona(requestParameters: ApiApiPartialUpdatePersonaRequest): Promise<Persona>;

    /**
     * partial updates info for the requesting user
     * @param {string} user 
     * @param {DoverUser} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateProUserRaw(requestParameters: ApiApiPartialUpdateProUserRequest): Promise<runtime.ApiResponse<DoverUser>>;

    /**
     * partial updates info for the requesting user
     */
    partialUpdateProUser(requestParameters: ApiApiPartialUpdateProUserRequest): Promise<DoverUser>;

    /**
     * update a dover role
     * @param {string} id A UUID string identifying this dover role.
     * @param {DoverRole} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateRoleRaw(requestParameters: ApiApiPartialUpdateRoleRequest): Promise<runtime.ApiResponse<DoverRole>>;

    /**
     * update a dover role
     */
    partialUpdateRole(requestParameters: ApiApiPartialUpdateRoleRequest): Promise<DoverRole>;

    /**
     * partial update SchoolList
     * @param {string} id 
     * @param {SchoolList} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateSchoolListRaw(requestParameters: ApiApiPartialUpdateSchoolListRequest): Promise<runtime.ApiResponse<SchoolList>>;

    /**
     * partial update SchoolList
     */
    partialUpdateSchoolList(requestParameters: ApiApiPartialUpdateSchoolListRequest): Promise<SchoolList>;

    /**
     * partial search update
     * @param {string} id A UUID string identifying this search.
     * @param {AdminSearch} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateSearchRaw(requestParameters: ApiApiPartialUpdateSearchRequest): Promise<runtime.ApiResponse<AdminSearch>>;

    /**
     * partial search update
     */
    partialUpdateSearch(requestParameters: ApiApiPartialUpdateSearchRequest): Promise<AdminSearch>;

    /**
     * partial update search v3 params
     * @param {string} id 
     * @param {SearchV3} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    partialUpdateSearchV3Raw(requestParameters: ApiApiPartialUpdateSearchV3Request): Promise<runtime.ApiResponse<SearchV3>>;

    /**
     * partial update search v3 params
     */
    partialUpdateSearchV3(requestParameters: ApiApiPartialUpdateSearchV3Request): Promise<SearchV3>;

    /**
     * partial client onboarding settings update
     * @param {string} id 
     * @param {ClientOnboarding} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    patchClientOnboardingRaw(requestParameters: ApiApiPatchClientOnboardingRequest): Promise<runtime.ApiResponse<ClientOnboarding>>;

    /**
     * partial client onboarding settings update
     */
    patchClientOnboarding(requestParameters: ApiApiPatchClientOnboardingRequest): Promise<ClientOnboarding>;

    /**
     * adds reply to Slack thread with candidate
     * @param {InlineObject8} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    postSlackThreadReplyRaw(requestParameters: ApiApiPostSlackThreadReplyRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * adds reply to Slack thread with candidate
     */
    postSlackThreadReply(requestParameters: ApiApiPostSlackThreadReplyRequest): Promise<InlineResponse2001>;

    /**
     * Queues a batch of rejections for multiple candidates.
     * @param {QueueBulkRejectRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    queueBulkRejectionRaw(requestParameters: ApiApiQueueBulkRejectionRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>>;

    /**
     * Queues a batch of rejections for multiple candidates.
     */
    queueBulkRejection(requestParameters: ApiApiQueueBulkRejectionRequest): Promise<BaseCandidateActionResponse>;

    /**
     * Send an email to a candidate.
     * @param {string} id A UUID string identifying this candidate.
     * @param {SendEmailRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    queueEmailRaw(requestParameters: ApiApiQueueEmailRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>>;

    /**
     * Send an email to a candidate.
     */
    queueEmail(requestParameters: ApiApiQueueEmailRequest): Promise<BaseCandidateActionResponse>;

    /**
     * Queue scheduling reminder emails
     * @param {InlineObject9} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    queueSchedulingRemindersFrontRaw(requestParameters: ApiApiQueueSchedulingRemindersFrontRequest): Promise<runtime.ApiResponse<InlineResponse2004>>;

    /**
     * Queue scheduling reminder emails
     */
    queueSchedulingRemindersFront(requestParameters: ApiApiQueueSchedulingRemindersFrontRequest): Promise<InlineResponse2004>;

    /**
     * Reactivates a job
     * @param {string} id 
     * @param {object} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    reactivateJobRaw(requestParameters: ApiApiReactivateJobRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Reactivates a job
     */
    reactivateJob(requestParameters: ApiApiReactivateJobRequest): Promise<InlineResponse2001>;

    /**
     * record outreach generation extension action
     * @param {ExtensionRecordActionRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    recordOutreachGenerationExtensionActionRaw(requestParameters: ApiApiRecordOutreachGenerationExtensionActionRequest): Promise<runtime.ApiResponse<ExtensionRecordActionResponse>>;

    /**
     * record outreach generation extension action
     */
    recordOutreachGenerationExtensionAction(requestParameters: ApiApiRecordOutreachGenerationExtensionActionRequest): Promise<ExtensionRecordActionResponse>;

    /**
     * Regenerates the sample candidate for a job
     * @param {RegenerateSampleCandidateRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    regenerateSampleCandidateRaw(requestParameters: ApiApiRegenerateSampleCandidateOperationRequest): Promise<runtime.ApiResponse<RegenerateSampleCandidateResponse>>;

    /**
     * Regenerates the sample candidate for a job
     */
    regenerateSampleCandidate(requestParameters: ApiApiRegenerateSampleCandidateOperationRequest): Promise<RegenerateSampleCandidateResponse>;

    /**
     * Register a saap review of type Good_fit, Top_candidate, Bad_fit or None
     * @param {RegisterSaapReviewedCandidateRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    registerSaapReviewedCandidateSubmissionRaw(requestParameters: ApiApiRegisterSaapReviewedCandidateSubmissionRequest): Promise<runtime.ApiResponse<RegisterSaapReviewedCandidateSubmissionResponse>>;

    /**
     * Register a saap review of type Good_fit, Top_candidate, Bad_fit or None
     */
    registerSaapReviewedCandidateSubmission(requestParameters: ApiApiRegisterSaapReviewedCandidateSubmissionRequest): Promise<RegisterSaapReviewedCandidateSubmissionResponse>;

    /**
     * register the review of a similar candidate
     * @param {RegisterSimilarCandidateRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    registerSimilarCandidateReviewRaw(requestParameters: ApiApiRegisterSimilarCandidateReviewRequest): Promise<runtime.ApiResponse<RegisterSimilarCandidateSubmissionResponse>>;

    /**
     * register the review of a similar candidate
     */
    registerSimilarCandidateReview(requestParameters: ApiApiRegisterSimilarCandidateReviewRequest): Promise<RegisterSimilarCandidateSubmissionResponse>;

    /**
     * Remove a campaign\'s association with a search
     * @param {string} id 
     * @param {InlineObject28} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    removeCampaignFromSearchRaw(requestParameters: ApiApiRemoveCampaignFromSearchRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Remove a campaign\'s association with a search
     */
    removeCampaignFromSearch(requestParameters: ApiApiRemoveCampaignFromSearchRequest): Promise<InlineResponse2001>;

    /**
     * Removes candidates from the outbox queue who no longer pass the search,         and if remaining number is lower than a threshold, it will kick off an autovol task to repopulate outbox.
     * @param {RemoveFailingPeopleFromOutbox} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    removeFailingPeopleFromOutboxRaw(requestParameters: ApiApiRemoveFailingPeopleFromOutboxRequest): Promise<runtime.ApiResponse<RemoveFailingPeopleFromOutboxResponse>>;

    /**
     * Removes candidates from the outbox queue who no longer pass the search,         and if remaining number is lower than a threshold, it will kick off an autovol task to repopulate outbox.
     */
    removeFailingPeopleFromOutbox(requestParameters: ApiApiRemoveFailingPeopleFromOutboxRequest): Promise<RemoveFailingPeopleFromOutboxResponse>;

    /**
     * remove from outbox
     * @param {RemoveFromOutboxRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    removeFromOutboxRaw(requestParameters: ApiApiRemoveFromOutboxOperationRequest): Promise<runtime.ApiResponse<RemoveFromOutboxResponse>>;

    /**
     * remove from outbox
     */
    removeFromOutbox(requestParameters: ApiApiRemoveFromOutboxOperationRequest): Promise<RemoveFromOutboxResponse>;

    /**
     * Removes (i.e. rejects) generated sample candidates for a specified job.
     * @param {RemoveSampleCandidatesRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    removeSampleCandidatesRaw(requestParameters: ApiApiRemoveSampleCandidatesOperationRequest): Promise<runtime.ApiResponse<RemoveSampleCandidatesResponse>>;

    /**
     * Removes (i.e. rejects) generated sample candidates for a specified job.
     */
    removeSampleCandidates(requestParameters: ApiApiRemoveSampleCandidatesOperationRequest): Promise<RemoveSampleCandidatesResponse>;

    /**
     * Attempt to replace the interviewer for this candidate.
     * @param {string} candidateId 
     * @param {object} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    replaceInterviewerRaw(requestParameters: ApiApiReplaceInterviewerRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Attempt to replace the interviewer for this candidate.
     */
    replaceInterviewer(requestParameters: ApiApiReplaceInterviewerRequest): Promise<InlineResponse2001>;

    /**
     * Requests a Dover plan upgrade for the calling client via email sent to sales.
     * @param {RequestPlanUpgradeRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    requestPlanUpgradeRaw(requestParameters: ApiApiRequestPlanUpgradeOperationRequest): Promise<runtime.ApiResponse<RequestPlanUpgradeResponse>>;

    /**
     * Requests a Dover plan upgrade for the calling client via email sent to sales.
     */
    requestPlanUpgrade(requestParameters: ApiApiRequestPlanUpgradeOperationRequest): Promise<RequestPlanUpgradeResponse>;

    /**
     * Reschedule an interview for a candidate.
     * @param {string} id A UUID string identifying this candidate.
     * @param {RescheduleInterviewRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    rescheduleInterviewRaw(requestParameters: ApiApiRescheduleInterviewOperationRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>>;

    /**
     * Reschedule an interview for a candidate.
     */
    rescheduleInterview(requestParameters: ApiApiRescheduleInterviewOperationRequest): Promise<BaseCandidateActionResponse>;

    /**
     * Resends the email verification email for the requesting user
     * @param {ResendEmailVerificationEmailRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    resendEmailVerificationEmailRaw(requestParameters: ApiApiResendEmailVerificationEmailOperationRequest): Promise<runtime.ApiResponse<object>>;

    /**
     * Resends the email verification email for the requesting user
     */
    resendEmailVerificationEmail(requestParameters: ApiApiResendEmailVerificationEmailOperationRequest): Promise<object>;

    /**
     * Given a child search, reset its search parameters to match the search transform output of its parent and relink it to its parent.
     * @param {string} id A UUID string identifying this search.
     * @param {object} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    resetAndRelinkChildSearchRaw(requestParameters: ApiApiResetAndRelinkChildSearchRequest): Promise<runtime.ApiResponse<InlineResponse20010>>;

    /**
     * Given a child search, reset its search parameters to match the search transform output of its parent and relink it to its parent.
     */
    resetAndRelinkChildSearch(requestParameters: ApiApiResetAndRelinkChildSearchRequest): Promise<InlineResponse20010>;

    /**
     * Retrieves emails for linkedin public url
     * @param {RetrieveEmailsForLinkedinPublicUrlRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    retrieveEmailsForLinkedinPublicUrlRaw(requestParameters: ApiApiRetrieveEmailsForLinkedinPublicUrlOperationRequest): Promise<runtime.ApiResponse<RetrieveEmailsForLinkedinPublicUrlResponse>>;

    /**
     * Retrieves emails for linkedin public url
     */
    retrieveEmailsForLinkedinPublicUrl(requestParameters: ApiApiRetrieveEmailsForLinkedinPublicUrlOperationRequest): Promise<RetrieveEmailsForLinkedinPublicUrlResponse>;

    /**
     * retrieve job referral
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    retrieveJobReferralRaw(requestParameters: ApiApiRetrieveJobReferralRequest): Promise<runtime.ApiResponse<JobReferral>>;

    /**
     * retrieve job referral
     */
    retrieveJobReferral(requestParameters: ApiApiRetrieveJobReferralRequest): Promise<JobReferral>;

    /**
     * retrieves a user onboarding flow record
     * @param {string} id A UUID string identifying this user onboarding flow.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    retrieveUserOnboardingFlowRaw(requestParameters: ApiApiRetrieveUserOnboardingFlowRequest): Promise<runtime.ApiResponse<UserOnboardingFlow>>;

    /**
     * retrieves a user onboarding flow record
     */
    retrieveUserOnboardingFlow(requestParameters: ApiApiRetrieveUserOnboardingFlowRequest): Promise<UserOnboardingFlow>;

    /**
     * retrieve UserRating
     * @param {string} id A UUID string identifying this user rating.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    retrieveUserRatingRaw(requestParameters: ApiApiRetrieveUserRatingRequest): Promise<runtime.ApiResponse<UserRating>>;

    /**
     * retrieve UserRating
     */
    retrieveUserRating(requestParameters: ApiApiRetrieveUserRatingRequest): Promise<UserRating>;

    /**
     * revert triaging status back to needs review
     * @param {string} id 
     * @param {JobReferral} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    revertToNeedsReviewRaw(requestParameters: ApiApiRevertToNeedsReviewRequest): Promise<runtime.ApiResponse<JobReferral>>;

    /**
     * revert triaging status back to needs review
     */
    revertToNeedsReview(requestParameters: ApiApiRevertToNeedsReviewRequest): Promise<JobReferral>;

    /**
     * saveCompanyPitchQuestionSchema
     * @param {SaveCompanyPitchQuestionSchema} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    saveCompanyPitchQuestionSchemaRaw(requestParameters: ApiApiSaveCompanyPitchQuestionSchemaRequest): Promise<runtime.ApiResponse<SaveCompanyPitchQuestionSchema1>>;

    /**
     * saveCompanyPitchQuestionSchema
     */
    saveCompanyPitchQuestionSchema(requestParameters: ApiApiSaveCompanyPitchQuestionSchemaRequest): Promise<SaveCompanyPitchQuestionSchema1>;

    /**
     * saveJobPitchQuestionSchema
     * @param {string} jobId 
     * @param {SaveJobPitchQuestionSchema} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    saveJobPitchQuestionSchemaRaw(requestParameters: ApiApiSaveJobPitchQuestionSchemaRequest): Promise<runtime.ApiResponse<SaveJobPitchQuestionSchema1>>;

    /**
     * saveJobPitchQuestionSchema
     */
    saveJobPitchQuestionSchema(requestParameters: ApiApiSaveJobPitchQuestionSchemaRequest): Promise<SaveJobPitchQuestionSchema1>;

    /**
     * Schedule candidate interview
     * @param {ScheduleCandidateInterviewRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    scheduleCandidateInterviewRaw(requestParameters: ApiApiScheduleCandidateInterviewOperationRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Schedule candidate interview
     */
    scheduleCandidateInterview(requestParameters: ApiApiScheduleCandidateInterviewOperationRequest): Promise<void>;

    /**
     * searchDover
     * @param {string} [search] search
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    searchDoverRaw(requestParameters: ApiApiSearchDoverRequest): Promise<runtime.ApiResponse<SearchDoverResponse>>;

    /**
     * searchDover
     */
    searchDover(requestParameters: ApiApiSearchDoverRequest): Promise<SearchDoverResponse>;

    /**
     * sends the candidate info to slack if not already sent
     * @param {InlineObject10} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    sendCandidateToSlackRaw(requestParameters: ApiApiSendCandidateToSlackRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * sends the candidate info to slack if not already sent
     */
    sendCandidateToSlack(requestParameters: ApiApiSendCandidateToSlackRequest): Promise<InlineResponse2001>;

    /**
     * send a CampaignMessageRequest\'s EmailSendRequest immediately
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    sendEmailRaw(requestParameters: ApiApiSendEmailRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * send a CampaignMessageRequest\'s EmailSendRequest immediately
     */
    sendEmail(requestParameters: ApiApiSendEmailRequest): Promise<void>;

    /**
     * sends external job slack channel invite to requesting user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    sendJobSlackChannelInviteRaw(requestParameters: ApiApiSendJobSlackChannelInviteRequest): Promise<runtime.ApiResponse<SendJobSlackChannelInviteResponse>>;

    /**
     * sends external job slack channel invite to requesting user
     */
    sendJobSlackChannelInvite(requestParameters: ApiApiSendJobSlackChannelInviteRequest): Promise<SendJobSlackChannelInviteResponse>;

    /**
     * send a new user invitation
     * @param {SendNewUserInvitationeRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    sendNewUserInvitationRaw(requestParameters: ApiApiSendNewUserInvitationRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * send a new user invitation
     */
    sendNewUserInvitation(requestParameters: ApiApiSendNewUserInvitationRequest): Promise<void>;

    /**
     * Set user alias
     * @param {InlineObject13} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    setAliasRaw(requestParameters: ApiApiSetAliasRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Set user alias
     */
    setAlias(requestParameters: ApiApiSetAliasRequest): Promise<InlineResponse2001>;

    /**
     * Changes all email templates to this state.
     * @param {InlineObject19} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    setAllEmailTemplateStateRaw(requestParameters: ApiApiSetAllEmailTemplateStateRequest): Promise<runtime.ApiResponse<Array<HiringStageEmailTemplate>>>;

    /**
     * Changes all email templates to this state.
     */
    setAllEmailTemplateState(requestParameters: ApiApiSetAllEmailTemplateStateRequest): Promise<Array<HiringStageEmailTemplate>>;

    /**
     * Set client alias on user
     * @param {InlineObject14} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    setClientAliasRaw(requestParameters: ApiApiSetClientAliasRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Set client alias on user
     */
    setClientAlias(requestParameters: ApiApiSetClientAliasRequest): Promise<InlineResponse2001>;

    /**
     * Block or unblock a user
     * @param {string} user 
     * @param {SetUserToBlockedRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    setUserBlockedFlagRaw(requestParameters: ApiApiSetUserBlockedFlagRequest): Promise<runtime.ApiResponse<SetUserToBlockedResponse>>;

    /**
     * Block or unblock a user
     */
    setUserBlockedFlag(requestParameters: ApiApiSetUserBlockedFlagRequest): Promise<SetUserToBlockedResponse>;

    /**
     * build similar candidates profiles for search id
     * @param {ShowUnreviewedSimilarCandidatesRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    showUnreviewedSimilarCandidatesRaw(requestParameters: ApiApiShowUnreviewedSimilarCandidatesOperationRequest): Promise<runtime.ApiResponse<Array<ShowUnreviewedSimilarCandidatesResponse>>>;

    /**
     * build similar candidates profiles for search id
     */
    showUnreviewedSimilarCandidates(requestParameters: ApiApiShowUnreviewedSimilarCandidatesOperationRequest): Promise<Array<ShowUnreviewedSimilarCandidatesResponse>>;

    /**
     * Snoozes a job
     * @param {string} id 
     * @param {SnoozeJob} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    snoozeJobRaw(requestParameters: ApiApiSnoozeJobRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Snoozes a job
     */
    snoozeJob(requestParameters: ApiApiSnoozeJobRequest): Promise<InlineResponse2001>;

    /**
     * Action a candidate.
     * @param {string} id A UUID string identifying this candidate.
     * @param {SubmitDecisionRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    submitDecisionRaw(requestParameters: ApiApiSubmitDecisionOperationRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>>;

    /**
     * Action a candidate.
     */
    submitDecision(requestParameters: ApiApiSubmitDecisionOperationRequest): Promise<BaseCandidateActionResponse>;

    /**
     * submit an inbound applicant from an external inbound source
     * @param {ExternalApplicantRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    submitExternalApplicantRaw(requestParameters: ApiApiSubmitExternalApplicantRequest): Promise<runtime.ApiResponse<ExternalApplicantResponse>>;

    /**
     * submit an inbound applicant from an external inbound source
     */
    submitExternalApplicant(requestParameters: ApiApiSubmitExternalApplicantRequest): Promise<ExternalApplicantResponse>;

    /**
     * Submit multipart interview scheduling request
     * @param {SubmitMultipartInterviewSchedulingRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    submitMultipartInterviewSchedulingRequestRaw(requestParameters: ApiApiSubmitMultipartInterviewSchedulingRequestRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Submit multipart interview scheduling request
     */
    submitMultipartInterviewSchedulingRequest(requestParameters: ApiApiSubmitMultipartInterviewSchedulingRequestRequest): Promise<void>;

    /**
     * Submit no show reschedule
     * @param {string} candidateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    submitNoShowRescheduleCandidateRaw(requestParameters: ApiApiSubmitNoShowRescheduleCandidateRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Submit no show reschedule
     */
    submitNoShowRescheduleCandidate(requestParameters: ApiApiSubmitNoShowRescheduleCandidateRequest): Promise<void>;

    /**
     * submit an inbound applicant from an integrated partner source
     * @param {ExternalApplicantRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    submitPartnerApplicantRaw(requestParameters: ApiApiSubmitPartnerApplicantRequest): Promise<runtime.ApiResponse<ExternalApplicantResponse>>;

    /**
     * submit an inbound applicant from an integrated partner source
     */
    submitPartnerApplicant(requestParameters: ApiApiSubmitPartnerApplicantRequest): Promise<ExternalApplicantResponse>;

    /**
     * submit persona questionnaire
     * @param {string} id A UUID string identifying this persona questionnaire.
     * @param {InlineObject22} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    submitPersonaQuestionnaireRaw(requestParameters: ApiApiSubmitPersonaQuestionnaireRequest): Promise<runtime.ApiResponse<object>>;

    /**
     * submit persona questionnaire
     */
    submitPersonaQuestionnaire(requestParameters: ApiApiSubmitPersonaQuestionnaireRequest): Promise<object>;

    /**
     * Attempt to save a user credential
     * @param {SubmitUserCredentialRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    submitUserCredentialRaw(requestParameters: ApiApiSubmitUserCredentialOperationRequest): Promise<runtime.ApiResponse<InlineResponse20068>>;

    /**
     * Attempt to save a user credential
     */
    submitUserCredential(requestParameters: ApiApiSubmitUserCredentialOperationRequest): Promise<InlineResponse20068>;

    /**
     * Swap the interview stage type on a HiringPipelineStage
     * @param {string} id 
     * @param {string} jobId 
     * @param {SwapInterviewStageTypeRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    swapInterviewStageTypeRaw(requestParameters: ApiApiSwapInterviewStageTypeOperationRequest): Promise<runtime.ApiResponse<HiringPipelineStage>>;

    /**
     * Swap the interview stage type on a HiringPipelineStage
     */
    swapInterviewStageType(requestParameters: ApiApiSwapInterviewStageTypeOperationRequest): Promise<HiringPipelineStage>;

    /**
     * Syncs ATS stages from ATS to Dover and lists our cached results
     * @param {string} id 
     * @param {InlineObject21} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    syncAndListJobAtsStagesRaw(requestParameters: ApiApiSyncAndListJobAtsStagesRequest): Promise<runtime.ApiResponse<SyncAndListJobAtsStages>>;

    /**
     * Syncs ATS stages from ATS to Dover and lists our cached results
     */
    syncAndListJobAtsStages(requestParameters: ApiApiSyncAndListJobAtsStagesRequest): Promise<SyncAndListJobAtsStages>;

    /**
     * Triggers a sync that pulls sources from the ATS into Dover
     * @param {string} id A UUID string identifying this job candidate source setting.
     * @param {object} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    syncAtsSourcesRaw(requestParameters: ApiApiSyncAtsSourcesRequest): Promise<runtime.ApiResponse<SyncAtsSourcesResponse>>;

    /**
     * Triggers a sync that pulls sources from the ATS into Dover
     */
    syncAtsSources(requestParameters: ApiApiSyncAtsSourcesRequest): Promise<SyncAtsSourcesResponse>;

    /**
     * toggle the starred status of a candidate for a specific user
     * @param {ToggleStarCandidateRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    toggleStarCandidateRaw(requestParameters: ApiApiToggleStarCandidateOperationRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * toggle the starred status of a candidate for a specific user
     */
    toggleStarCandidate(requestParameters: ApiApiToggleStarCandidateOperationRequest): Promise<void>;

    /**
     * update archive reason
     * @param {string} id A UUID string identifying this archive reason.
     * @param {ArchiveReason} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateArchiveReasonRaw(requestParameters: ApiApiUpdateArchiveReasonRequest): Promise<runtime.ApiResponse<ArchiveReason>>;

    /**
     * update archive reason
     */
    updateArchiveReason(requestParameters: ApiApiUpdateArchiveReasonRequest): Promise<ArchiveReason>;

    /**
     * update campaign message request
     * @param {string} id 
     * @param {UpdateCampaignMessageRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateCampaignMessageRequestRaw(requestParameters: ApiApiUpdateCampaignMessageRequestRequest): Promise<runtime.ApiResponse<UpdateCampaignMessageRequest>>;

    /**
     * update campaign message request
     */
    updateCampaignMessageRequest(requestParameters: ApiApiUpdateCampaignMessageRequestRequest): Promise<UpdateCampaignMessageRequest>;

    /**
     * update a candidate interview
     * @param {string} id A UUID string identifying this candidate interview.
     * @param {UpdateCandidateInterview} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateCandidateInterviewRaw(requestParameters: ApiApiUpdateCandidateInterviewRequest): Promise<runtime.ApiResponse<UpdateCandidateInterview>>;

    /**
     * update a candidate interview
     */
    updateCandidateInterview(requestParameters: ApiApiUpdateCandidateInterviewRequest): Promise<UpdateCandidateInterview>;

    /**
     * Update a CompanyList
     * @param {string} id 
     * @param {CompanyList} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateCompanyListRaw(requestParameters: ApiApiUpdateCompanyListRequest): Promise<runtime.ApiResponse<CompanyList>>;

    /**
     * Update a CompanyList
     */
    updateCompanyList(requestParameters: ApiApiUpdateCompanyListRequest): Promise<CompanyList>;

    /**
     * updates emails_per_week on JobFulfillment table
     * @param {string} id 
     * @param {UpdateDoverOutboundConfiguration} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateDoverOutboundConfigurationRaw(requestParameters: ApiApiUpdateDoverOutboundConfigurationRequest): Promise<runtime.ApiResponse<UpdateDoverOutboundConfiguration>>;

    /**
     * updates emails_per_week on JobFulfillment table
     */
    updateDoverOutboundConfiguration(requestParameters: ApiApiUpdateDoverOutboundConfigurationRequest): Promise<UpdateDoverOutboundConfiguration>;

    /**
     * update candidate email
     * @param {string} id A UUID string identifying this email.
     * @param {Email} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateEmailRaw(requestParameters: ApiApiUpdateEmailRequest): Promise<runtime.ApiResponse<Email>>;

    /**
     * update candidate email
     */
    updateEmail(requestParameters: ApiApiUpdateEmailRequest): Promise<Email>;

    /**
     * Update a HiringPipelineStage
     * @param {string} id 
     * @param {string} jobId 
     * @param {UpdateHiringPipelineStageRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateHiringPipelineStageRaw(requestParameters: ApiApiUpdateHiringPipelineStageOperationRequest): Promise<runtime.ApiResponse<HiringPipelineStage>>;

    /**
     * Update a HiringPipelineStage
     */
    updateHiringPipelineStage(requestParameters: ApiApiUpdateHiringPipelineStageOperationRequest): Promise<HiringPipelineStage>;

    /**
     * update inbound application
     * @param {string} id A UUID string identifying this inbound application.
     * @param {string} jobId 
     * @param {string} firstName 
     * @param {string} lastName 
     * @param {string} email 
     * @param {string} [referrerSource] 
     * @param {string} [linkedinUrl] 
     * @param {string} [phoneNumber] 
     * @param {Blob} [resume] 
     * @param {string} [additionalInfo] 
     * @param {string} [applicationQuestions] 
     * @param {boolean} [bookmarked] 
     * @param {boolean} [optedInToTalentNetwork] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateInboundApplicationRaw(requestParameters: ApiApiUpdateInboundApplicationRequest): Promise<runtime.ApiResponse<ApplicationPortalInboundApplication>>;

    /**
     * update inbound application
     */
    updateInboundApplication(requestParameters: ApiApiUpdateInboundApplicationRequest): Promise<ApplicationPortalInboundApplication>;

    /**
     * update interview rubric response
     * @param {string} id A UUID string identifying this interview rubric response.
     * @param {InterviewRubricResponse} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateInterviewRubricResponseRaw(requestParameters: ApiApiUpdateInterviewRubricResponseRequest): Promise<runtime.ApiResponse<InterviewRubricResponse>>;

    /**
     * update interview rubric response
     */
    updateInterviewRubricResponse(requestParameters: ApiApiUpdateInterviewRubricResponseRequest): Promise<InterviewRubricResponse>;

    /**
     * update interviewer
     * @param {string} id A UUID string identifying this client interviewer.
     * @param {ClientInterviewer} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateInterviewerRaw(requestParameters: ApiApiUpdateInterviewerRequest): Promise<runtime.ApiResponse<ClientInterviewer>>;

    /**
     * update interviewer
     */
    updateInterviewer(requestParameters: ApiApiUpdateInterviewerRequest): Promise<ClientInterviewer>;

    /**
     * update job description
     * @param {string} id A UUID string identifying this job description.
     * @param {UpdateJobDescription} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateJobDescriptionRaw(requestParameters: ApiApiUpdateJobDescriptionRequest): Promise<runtime.ApiResponse<DoverJobDescription>>;

    /**
     * update job description
     */
    updateJobDescription(requestParameters: ApiApiUpdateJobDescriptionRequest): Promise<DoverJobDescription>;

    /**
     * update hiring team info for job
     * @param {string} id 
     * @param {UpdateJobHiringTeam} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateJobHiringTeamRaw(requestParameters: ApiApiUpdateJobHiringTeamRequest): Promise<runtime.ApiResponse<UpdateJobHiringTeam>>;

    /**
     * update hiring team info for job
     */
    updateJobHiringTeam(requestParameters: ApiApiUpdateJobHiringTeamRequest): Promise<UpdateJobHiringTeam>;

    /**
     * update a job position
     * @param {string} id A UUID string identifying this job position.
     * @param {HiringPlanJobPosition} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateJobPositionRaw(requestParameters: ApiApiUpdateJobPositionRequest): Promise<runtime.ApiResponse<HiringPlanJobPosition>>;

    /**
     * update a job position
     */
    updateJobPosition(requestParameters: ApiApiUpdateJobPositionRequest): Promise<HiringPlanJobPosition>;

    /**
     * update a job position opening
     * @param {number} id A unique integer value identifying this job position opening.
     * @param {HiringPlanJobPositionOpening} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateJobPositionOpeningRaw(requestParameters: ApiApiUpdateJobPositionOpeningRequest): Promise<runtime.ApiResponse<HiringPlanJobPositionOpening>>;

    /**
     * update a job position opening
     */
    updateJobPositionOpening(requestParameters: ApiApiUpdateJobPositionOpeningRequest): Promise<HiringPlanJobPositionOpening>;

    /**
     * update job visibility
     * @param {string} id 
     * @param {UpdateJobVisibility} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateJobVisibilityRaw(requestParameters: ApiApiUpdateJobVisibilityRequest): Promise<runtime.ApiResponse<UpdateJobVisibility>>;

    /**
     * update job visibility
     */
    updateJobVisibility(requestParameters: ApiApiUpdateJobVisibilityRequest): Promise<UpdateJobVisibility>;

    /**
     * update notification config
     * @param {string} id 
     * @param {NotificationConfig} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateNotificationConfigRaw(requestParameters: ApiApiUpdateNotificationConfigRequest): Promise<runtime.ApiResponse<NotificationConfig>>;

    /**
     * update notification config
     */
    updateNotificationConfig(requestParameters: ApiApiUpdateNotificationConfigRequest): Promise<NotificationConfig>;

    /**
     * update a hiring pipeline stage
     * @param {string} id A UUID string identifying this hiring pipeline stage.
     * @param {OnboardingHiringPipelineStage} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateOnboardingHiringPipelineStageRaw(requestParameters: ApiApiUpdateOnboardingHiringPipelineStageRequest): Promise<runtime.ApiResponse<OnboardingHiringPipelineStage>>;

    /**
     * update a hiring pipeline stage
     */
    updateOnboardingHiringPipelineStage(requestParameters: ApiApiUpdateOnboardingHiringPipelineStageRequest): Promise<OnboardingHiringPipelineStage>;

    /**
     * update candidate pending customer response
     * @param {InlineObject11} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updatePendingCustomerResponseRaw(requestParameters: ApiApiUpdatePendingCustomerResponseRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * update candidate pending customer response
     */
    updatePendingCustomerResponse(requestParameters: ApiApiUpdatePendingCustomerResponseRequest): Promise<InlineResponse2001>;

    /**
     * Update a Persona
     * @param {string} id 
     * @param {Persona} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updatePersonaRaw(requestParameters: ApiApiUpdatePersonaRequest): Promise<runtime.ApiResponse<Persona>>;

    /**
     * Update a Persona
     */
    updatePersona(requestParameters: ApiApiUpdatePersonaRequest): Promise<Persona>;

    /**
     * update a profile attribute
     * @param {string} id A UUID string identifying this profile attribute.
     * @param {AdminProfileAttribute} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateProfileAttributesRaw(requestParameters: ApiApiUpdateProfileAttributesRequest): Promise<runtime.ApiResponse<AdminProfileAttribute>>;

    /**
     * update a profile attribute
     */
    updateProfileAttributes(requestParameters: ApiApiUpdateProfileAttributesRequest): Promise<AdminProfileAttribute>;

    /**
     * update candidate reply type
     * @param {InlineObject12} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateReplyTypeRaw(requestParameters: ApiApiUpdateReplyTypeRequest): Promise<runtime.ApiResponse<InlineResponse2005>>;

    /**
     * update candidate reply type
     */
    updateReplyType(requestParameters: ApiApiUpdateReplyTypeRequest): Promise<InlineResponse2005>;

    /**
     * Update a SchoolList
     * @param {string} id 
     * @param {SchoolList} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateSchoolListRaw(requestParameters: ApiApiUpdateSchoolListRequest): Promise<runtime.ApiResponse<SchoolList>>;

    /**
     * Update a SchoolList
     */
    updateSchoolList(requestParameters: ApiApiUpdateSchoolListRequest): Promise<SchoolList>;

    /**
     * update search
     * @param {string} id A UUID string identifying this search.
     * @param {AdminSearch} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateSearchRaw(requestParameters: ApiApiUpdateSearchRequest): Promise<runtime.ApiResponse<AdminSearch>>;

    /**
     * update search
     */
    updateSearch(requestParameters: ApiApiUpdateSearchRequest): Promise<AdminSearch>;

    /**
     * derives and sets search params using SearchFeatures
     * @param {string} id 
     * @param {UpdateSearchFromFeaturesRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateSearchFromFeaturesRaw(requestParameters: ApiApiUpdateSearchFromFeaturesOperationRequest): Promise<runtime.ApiResponse<UpdateSearchFromFeaturesSuccess>>;

    /**
     * derives and sets search params using SearchFeatures
     */
    updateSearchFromFeatures(requestParameters: ApiApiUpdateSearchFromFeaturesOperationRequest): Promise<UpdateSearchFromFeaturesSuccess>;

    /**
     * update search v3 params
     * @param {string} id 
     * @param {SearchV3} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateSearchV3Raw(requestParameters: ApiApiUpdateSearchV3Request): Promise<runtime.ApiResponse<SearchV3>>;

    /**
     * update search v3 params
     */
    updateSearchV3(requestParameters: ApiApiUpdateSearchV3Request): Promise<SearchV3>;

    /**
     * Updates the user\'s client role
     * @param {string} user 
     * @param {UpdateUserClientRoleRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateUserClientRoleRaw(requestParameters: ApiApiUpdateUserClientRoleOperationRequest): Promise<runtime.ApiResponse<UpdateClientRoleResponse>>;

    /**
     * Updates the user\'s client role
     */
    updateUserClientRole(requestParameters: ApiApiUpdateUserClientRoleOperationRequest): Promise<UpdateClientRoleResponse>;

    /**
     * updates user onboarding flow
     * @param {string} id A UUID string identifying this user onboarding flow.
     * @param {UserOnboardingFlow} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    updateUserOnboardingFlowRaw(requestParameters: ApiApiUpdateUserOnboardingFlowRequest): Promise<runtime.ApiResponse<UserOnboardingFlow>>;

    /**
     * updates user onboarding flow
     */
    updateUserOnboardingFlow(requestParameters: ApiApiUpdateUserOnboardingFlowRequest): Promise<UserOnboardingFlow>;

    /**
     * Upload logo
     * @param {Blob} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    uploadLogoRaw(requestParameters: ApiApiUploadLogoRequest): Promise<runtime.ApiResponse<object>>;

    /**
     * Upload logo
     */
    uploadLogo(requestParameters: ApiApiUploadLogoRequest): Promise<object>;

    /**
     * upsert job candidate source setting
     * @param {CreateJobCandidateSourceSetting} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    upsertJobCandidateSourceSettingRaw(requestParameters: ApiApiUpsertJobCandidateSourceSettingRequest): Promise<runtime.ApiResponse<JobCandidateSourceSetting>>;

    /**
     * upsert job candidate source setting
     */
    upsertJobCandidateSourceSetting(requestParameters: ApiApiUpsertJobCandidateSourceSettingRequest): Promise<JobCandidateSourceSetting>;

    /**
     * upsert job feature setting
     * @param {UpsertJobFeatureSetting} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    upsertJobFeatureSettingRaw(requestParameters: ApiApiUpsertJobFeatureSettingRequest): Promise<runtime.ApiResponse<JobFeatureSetting>>;

    /**
     * upsert job feature setting
     */
    upsertJobFeatureSetting(requestParameters: ApiApiUpsertJobFeatureSettingRequest): Promise<JobFeatureSetting>;

    /**
     * Upserts potentially multiple JobAtsSourceSetting records, keying off of job_candidate_source_setting_id and ats_source_name
     * @param {JobAtsSourceSettingUpsertMultipleRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    upsertMultipleJobAtsSourceSettingsRaw(requestParameters: ApiApiUpsertMultipleJobAtsSourceSettingsRequest): Promise<runtime.ApiResponse<Array<JobAtsSourceSetting>>>;

    /**
     * Upserts potentially multiple JobAtsSourceSetting records, keying off of job_candidate_source_setting_id and ats_source_name
     */
    upsertMultipleJobAtsSourceSettings(requestParameters: ApiApiUpsertMultipleJobAtsSourceSettingsRequest): Promise<Array<JobAtsSourceSetting>>;

    /**
     * Validate that an email is ok to be added/updated.
     * @param {Email} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    validateEmailRaw(requestParameters: ApiApiValidateEmailRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponseError>>;

    /**
     * Validate that an email is ok to be added/updated.
     */
    validateEmail(requestParameters: ApiApiValidateEmailRequest): Promise<BaseCandidateActionResponseError>;

    /**
     * Validate that a given scheduling link is tied to the given candidate
     * @param {ValidateSchedulingLinkForCandidateRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    validateSchedulingLinkForCandidateRaw(requestParameters: ApiApiValidateSchedulingLinkForCandidateOperationRequest): Promise<runtime.ApiResponse<ValidateSchedulingLinkForCandidateResponse>>;

    /**
     * Validate that a given scheduling link is tied to the given candidate
     */
    validateSchedulingLinkForCandidate(requestParameters: ApiApiValidateSchedulingLinkForCandidateOperationRequest): Promise<ValidateSchedulingLinkForCandidateResponse>;

    /**
     * Withdraw a candidate during/after the initial phone interview.
     * @param {string} candidateId 
     * @param {InlineObject17} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    withdrawCandidateRaw(requestParameters: ApiApiWithdrawCandidateRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Withdraw a candidate during/after the initial phone interview.
     */
    withdrawCandidate(requestParameters: ApiApiWithdrawCandidateRequest): Promise<InlineResponse2001>;

}

/**
 * 
 */
export class ApiApi extends runtime.BaseAPI implements ApiApiInterface {

    /**
     * Associate a campaign with a search
     */
    async addCampaignToSearchRaw(requestParameters: ApiApiAddCampaignToSearchRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addCampaignToSearch.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addCampaignToSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/searches/{id}/add_campaign_to_search`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject27ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Associate a campaign with a search
     */
    async addCampaignToSearch(requestParameters: ApiApiAddCampaignToSearchRequest): Promise<InlineResponse2001> {
        const response = await this.addCampaignToSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * manually add a candidate to initial outreach
     */
    async addCandidateInitialOutreachFlowRaw(requestParameters: ApiApiAddCandidateInitialOutreachFlowRequest): Promise<runtime.ApiResponse<AddCandidateInitialOutreachResponse>> {
        if (requestParameters.job === null || requestParameters.job === undefined) {
            throw new runtime.RequiredError('job','Required parameter requestParameters.job was null or undefined when calling addCandidateInitialOutreachFlow.');
        }

        if (requestParameters.firstName === null || requestParameters.firstName === undefined) {
            throw new runtime.RequiredError('firstName','Required parameter requestParameters.firstName was null or undefined when calling addCandidateInitialOutreachFlow.');
        }

        if (requestParameters.lastName === null || requestParameters.lastName === undefined) {
            throw new runtime.RequiredError('lastName','Required parameter requestParameters.lastName was null or undefined when calling addCandidateInitialOutreachFlow.');
        }

        if (requestParameters.campaign === null || requestParameters.campaign === undefined) {
            throw new runtime.RequiredError('campaign','Required parameter requestParameters.campaign was null or undefined when calling addCandidateInitialOutreachFlow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.job !== undefined) {
            formParams.append('job', requestParameters.job as any);
        }

        if (requestParameters.firstName !== undefined) {
            formParams.append('first_name', requestParameters.firstName as any);
        }

        if (requestParameters.lastName !== undefined) {
            formParams.append('last_name', requestParameters.lastName as any);
        }

        if (requestParameters.email !== undefined) {
            formParams.append('email', requestParameters.email as any);
        }

        if (requestParameters.emailId !== undefined) {
            formParams.append('email_id', requestParameters.emailId as any);
        }

        if (requestParameters.linkedinProfileUrl !== undefined) {
            formParams.append('linkedin_profile_url', requestParameters.linkedinProfileUrl as any);
        }

        if (requestParameters.resume !== undefined) {
            formParams.append('resume', requestParameters.resume as any);
        }

        if (requestParameters.campaign !== undefined) {
            formParams.append('campaign', requestParameters.campaign as any);
        }

        if (requestParameters.firstMessageBody !== undefined) {
            formParams.append('first_message_body', requestParameters.firstMessageBody as any);
        }

        if (requestParameters.firstMessageSubject !== undefined) {
            formParams.append('first_message_subject', requestParameters.firstMessageSubject as any);
        }

        if (requestParameters.ccEmails) {
            formParams.append('cc_emails', requestParameters.ccEmails.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        const response = await this.request({
            path: `/api/v1/manual-sourcing/add_candidate_initial_outreach`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddCandidateInitialOutreachResponseFromJSON(jsonValue));
    }

    /**
     * manually add a candidate to initial outreach
     */
    async addCandidateInitialOutreachFlow(requestParameters: ApiApiAddCandidateInitialOutreachFlowRequest): Promise<AddCandidateInitialOutreachResponse> {
        const response = await this.addCandidateInitialOutreachFlowRaw(requestParameters);
        return await response.value();
    }

    /**
     * manually add a candidates in bulk to initial outreach
     */
    async addCandidateInitialOutreachFlowBulkRaw(requestParameters: ApiApiAddCandidateInitialOutreachFlowBulkRequest): Promise<runtime.ApiResponse<AddCandidateInitialOutreachBulkResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addCandidateInitialOutreachFlowBulk.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/manual-sourcing/add_candidate_initial_outreach_bulk`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddCandidateInitialOutreachBulkRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddCandidateInitialOutreachBulkResponseFromJSON(jsonValue));
    }

    /**
     * manually add a candidates in bulk to initial outreach
     */
    async addCandidateInitialOutreachFlowBulk(requestParameters: ApiApiAddCandidateInitialOutreachFlowBulkRequest): Promise<AddCandidateInitialOutreachBulkResponse> {
        const response = await this.addCandidateInitialOutreachFlowBulkRaw(requestParameters);
        return await response.value();
    }

    /**
     * manually add a candidate to the interview process
     */
    async addCandidateToInterviewProcessFlowRaw(requestParameters: ApiApiAddCandidateToInterviewProcessFlowRequest): Promise<runtime.ApiResponse<AddCandidateInterviewProcessResponse>> {
        if (requestParameters.job === null || requestParameters.job === undefined) {
            throw new runtime.RequiredError('job','Required parameter requestParameters.job was null or undefined when calling addCandidateToInterviewProcessFlow.');
        }

        if (requestParameters.pipelineStage === null || requestParameters.pipelineStage === undefined) {
            throw new runtime.RequiredError('pipelineStage','Required parameter requestParameters.pipelineStage was null or undefined when calling addCandidateToInterviewProcessFlow.');
        }

        if (requestParameters.firstName === null || requestParameters.firstName === undefined) {
            throw new runtime.RequiredError('firstName','Required parameter requestParameters.firstName was null or undefined when calling addCandidateToInterviewProcessFlow.');
        }

        if (requestParameters.lastName === null || requestParameters.lastName === undefined) {
            throw new runtime.RequiredError('lastName','Required parameter requestParameters.lastName was null or undefined when calling addCandidateToInterviewProcessFlow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.job !== undefined) {
            formParams.append('job', requestParameters.job as any);
        }

        if (requestParameters.pipelineStage !== undefined) {
            formParams.append('pipeline_stage', requestParameters.pipelineStage as any);
        }

        if (requestParameters.firstName !== undefined) {
            formParams.append('first_name', requestParameters.firstName as any);
        }

        if (requestParameters.lastName !== undefined) {
            formParams.append('last_name', requestParameters.lastName as any);
        }

        if (requestParameters.email !== undefined) {
            formParams.append('email', requestParameters.email as any);
        }

        if (requestParameters.linkedinProfileUrl !== undefined) {
            formParams.append('linkedin_profile_url', requestParameters.linkedinProfileUrl as any);
        }

        if (requestParameters.sourceId !== undefined) {
            formParams.append('source_id', requestParameters.sourceId as any);
        }

        if (requestParameters.sourceName !== undefined) {
            formParams.append('source_name', requestParameters.sourceName as any);
        }

        if (requestParameters.agencyName !== undefined) {
            formParams.append('agency_name', requestParameters.agencyName as any);
        }

        if (requestParameters.agencyEmail !== undefined) {
            formParams.append('agency_email', requestParameters.agencyEmail as any);
        }

        if (requestParameters.resume !== undefined) {
            formParams.append('resume', requestParameters.resume as any);
        }

        const response = await this.request({
            path: `/api/v1/manual-sourcing/add_candidate_interview_process`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddCandidateInterviewProcessResponseFromJSON(jsonValue));
    }

    /**
     * manually add a candidate to the interview process
     */
    async addCandidateToInterviewProcessFlow(requestParameters: ApiApiAddCandidateToInterviewProcessFlowRequest): Promise<AddCandidateInterviewProcessResponse> {
        const response = await this.addCandidateToInterviewProcessFlowRaw(requestParameters);
        return await response.value();
    }

    /**
     * create candidate email
     */
    async addEmailRaw(requestParameters: ApiApiAddEmailRequest): Promise<runtime.ApiResponse<Email>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailFromJSON(jsonValue));
    }

    /**
     * create candidate email
     */
    async addEmail(requestParameters: ApiApiAddEmailRequest): Promise<Email> {
        const response = await this.addEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * add a given email to a candidate and return the original candidate info
     */
    async addEmailToCandidateRaw(requestParameters: ApiApiAddEmailToCandidateRequest): Promise<runtime.ApiResponse<FrontCandidate>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addEmailToCandidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/add_email_to_candidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontCandidateFromJSON(jsonValue));
    }

    /**
     * add a given email to a candidate and return the original candidate info
     */
    async addEmailToCandidate(requestParameters: ApiApiAddEmailToCandidateRequest): Promise<FrontCandidate> {
        const response = await this.addEmailToCandidateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add questions to an interview rubric response
     */
    async addQuestionsToInterviewRubricResponseRaw(requestParameters: ApiApiAddQuestionsToInterviewRubricResponseOperationRequest): Promise<runtime.ApiResponse<InterviewRubricResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addQuestionsToInterviewRubricResponse.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addQuestionsToInterviewRubricResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-interview-rubric-response/{id}/add_questions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddQuestionsToInterviewRubricResponseRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewRubricResponseFromJSON(jsonValue));
    }

    /**
     * Add questions to an interview rubric response
     */
    async addQuestionsToInterviewRubricResponse(requestParameters: ApiApiAddQuestionsToInterviewRubricResponseOperationRequest): Promise<InterviewRubricResponse> {
        const response = await this.addQuestionsToInterviewRubricResponseRaw(requestParameters);
        return await response.value();
    }

    /**
     * admin list clients
     */
    async adminListClientsRaw(requestParameters: ApiApiAdminListClientsRequest): Promise<runtime.ApiResponse<InlineResponse2008>> {
        const queryParameters: any = {};

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/clients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2008FromJSON(jsonValue));
    }

    /**
     * admin list clients
     */
    async adminListClients(requestParameters: ApiApiAdminListClientsRequest): Promise<InlineResponse2008> {
        const response = await this.adminListClientsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1AdminAdminCampaignsCreateRaw(requestParameters: ApiApiApiV1AdminAdminCampaignsCreateRequest): Promise<runtime.ApiResponse<AdminCampaign>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1AdminAdminCampaignsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/admin-campaigns`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminCampaignToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminCampaignFromJSON(jsonValue));
    }

    /**
     */
    async apiV1AdminAdminCampaignsCreate(requestParameters: ApiApiApiV1AdminAdminCampaignsCreateRequest): Promise<AdminCampaign> {
        const response = await this.apiV1AdminAdminCampaignsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1AdminAdminCampaignsDeleteRaw(requestParameters: ApiApiApiV1AdminAdminCampaignsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1AdminAdminCampaignsDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/admin-campaigns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1AdminAdminCampaignsDelete(requestParameters: ApiApiApiV1AdminAdminCampaignsDeleteRequest): Promise<void> {
        await this.apiV1AdminAdminCampaignsDeleteRaw(requestParameters);
    }

    /**
     */
    async apiV1AdminAdminCampaignsPartialUpdateRaw(requestParameters: ApiApiApiV1AdminAdminCampaignsPartialUpdateRequest): Promise<runtime.ApiResponse<AdminCampaign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1AdminAdminCampaignsPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1AdminAdminCampaignsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/admin-campaigns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AdminCampaignToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminCampaignFromJSON(jsonValue));
    }

    /**
     */
    async apiV1AdminAdminCampaignsPartialUpdate(requestParameters: ApiApiApiV1AdminAdminCampaignsPartialUpdateRequest): Promise<AdminCampaign> {
        const response = await this.apiV1AdminAdminCampaignsPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1AdminAdminCampaignsReadRaw(requestParameters: ApiApiApiV1AdminAdminCampaignsReadRequest): Promise<runtime.ApiResponse<AdminCampaign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1AdminAdminCampaignsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/admin-campaigns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminCampaignFromJSON(jsonValue));
    }

    /**
     */
    async apiV1AdminAdminCampaignsRead(requestParameters: ApiApiApiV1AdminAdminCampaignsReadRequest): Promise<AdminCampaign> {
        const response = await this.apiV1AdminAdminCampaignsReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1AdminAdminCampaignsUpdateRaw(requestParameters: ApiApiApiV1AdminAdminCampaignsUpdateRequest): Promise<runtime.ApiResponse<AdminCampaign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1AdminAdminCampaignsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1AdminAdminCampaignsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/admin-campaigns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminCampaignToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminCampaignFromJSON(jsonValue));
    }

    /**
     */
    async apiV1AdminAdminCampaignsUpdate(requestParameters: ApiApiApiV1AdminAdminCampaignsUpdateRequest): Promise<AdminCampaign> {
        const response = await this.apiV1AdminAdminCampaignsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1AdminAdminSlimSearchTemplatesCreateRaw(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesCreateRequest): Promise<runtime.ApiResponse<SlimSearchTemplate>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1AdminAdminSlimSearchTemplatesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/admin-slim-search-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SlimSearchTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SlimSearchTemplateFromJSON(jsonValue));
    }

    /**
     */
    async apiV1AdminAdminSlimSearchTemplatesCreate(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesCreateRequest): Promise<SlimSearchTemplate> {
        const response = await this.apiV1AdminAdminSlimSearchTemplatesCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1AdminAdminSlimSearchTemplatesDeleteRaw(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1AdminAdminSlimSearchTemplatesDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/admin-slim-search-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1AdminAdminSlimSearchTemplatesDelete(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesDeleteRequest): Promise<void> {
        await this.apiV1AdminAdminSlimSearchTemplatesDeleteRaw(requestParameters);
    }

    /**
     */
    async apiV1AdminAdminSlimSearchTemplatesPartialUpdateRaw(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesPartialUpdateRequest): Promise<runtime.ApiResponse<SlimSearchTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1AdminAdminSlimSearchTemplatesPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1AdminAdminSlimSearchTemplatesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/admin-slim-search-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SlimSearchTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SlimSearchTemplateFromJSON(jsonValue));
    }

    /**
     */
    async apiV1AdminAdminSlimSearchTemplatesPartialUpdate(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesPartialUpdateRequest): Promise<SlimSearchTemplate> {
        const response = await this.apiV1AdminAdminSlimSearchTemplatesPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1AdminAdminSlimSearchTemplatesReadRaw(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesReadRequest): Promise<runtime.ApiResponse<SlimSearchTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1AdminAdminSlimSearchTemplatesRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/admin-slim-search-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SlimSearchTemplateFromJSON(jsonValue));
    }

    /**
     */
    async apiV1AdminAdminSlimSearchTemplatesRead(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesReadRequest): Promise<SlimSearchTemplate> {
        const response = await this.apiV1AdminAdminSlimSearchTemplatesReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1AdminAdminSlimSearchTemplatesUpdateRaw(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesUpdateRequest): Promise<runtime.ApiResponse<SlimSearchTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1AdminAdminSlimSearchTemplatesUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1AdminAdminSlimSearchTemplatesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/admin-slim-search-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SlimSearchTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SlimSearchTemplateFromJSON(jsonValue));
    }

    /**
     */
    async apiV1AdminAdminSlimSearchTemplatesUpdate(requestParameters: ApiApiApiV1AdminAdminSlimSearchTemplatesUpdateRequest): Promise<SlimSearchTemplate> {
        const response = await this.apiV1AdminAdminSlimSearchTemplatesUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1AdminCalibrationCandidatesBulkCreateRaw(requestParameters: ApiApiApiV1AdminCalibrationCandidatesBulkCreateRequest): Promise<runtime.ApiResponse<CalibrationCandidate>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1AdminCalibrationCandidatesBulkCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/calibration-candidates/bulk`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalibrationCandidateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CalibrationCandidateFromJSON(jsonValue));
    }

    /**
     */
    async apiV1AdminCalibrationCandidatesBulkCreate(requestParameters: ApiApiApiV1AdminCalibrationCandidatesBulkCreateRequest): Promise<CalibrationCandidate> {
        const response = await this.apiV1AdminCalibrationCandidatesBulkCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ArchiveReasonsUpdateRaw(requestParameters: ApiApiApiV1ArchiveReasonsUpdateRequest): Promise<runtime.ApiResponse<ArchiveReason>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1ArchiveReasonsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1ArchiveReasonsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/archive-reasons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ArchiveReasonToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ArchiveReasonFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ArchiveReasonsUpdate(requestParameters: ApiApiApiV1ArchiveReasonsUpdateRequest): Promise<ArchiveReason> {
        const response = await this.apiV1ArchiveReasonsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1CampaignMessageRequestPartialUpdateRaw(requestParameters: ApiApiApiV1CampaignMessageRequestPartialUpdateRequest): Promise<runtime.ApiResponse<UpdateCampaignMessageRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1CampaignMessageRequestPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1CampaignMessageRequestPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/campaign-message-request/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCampaignMessageRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCampaignMessageRequestFromJSON(jsonValue));
    }

    /**
     */
    async apiV1CampaignMessageRequestPartialUpdate(requestParameters: ApiApiApiV1CampaignMessageRequestPartialUpdateRequest): Promise<UpdateCampaignMessageRequest> {
        const response = await this.apiV1CampaignMessageRequestPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1CampaignsUpdateRaw(requestParameters: ApiApiApiV1CampaignsUpdateRequest): Promise<runtime.ApiResponse<Campaign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1CampaignsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1CampaignsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/campaigns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignFromJSON(jsonValue));
    }

    /**
     */
    async apiV1CampaignsUpdate(requestParameters: ApiApiApiV1CampaignsUpdateRequest): Promise<Campaign> {
        const response = await this.apiV1CampaignsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1CandidateBiosUpdateRaw(requestParameters: ApiApiApiV1CandidateBiosUpdateRequest): Promise<runtime.ApiResponse<CandidateBio>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1CandidateBiosUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1CandidateBiosUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate-bios/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CandidateBioToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidateBioFromJSON(jsonValue));
    }

    /**
     */
    async apiV1CandidateBiosUpdate(requestParameters: ApiApiApiV1CandidateBiosUpdateRequest): Promise<CandidateBio> {
        const response = await this.apiV1CandidateBiosUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1CandidateNoteDeleteRaw(requestParameters: ApiApiApiV1CandidateNoteDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1CandidateNoteDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate-note/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1CandidateNoteDelete(requestParameters: ApiApiApiV1CandidateNoteDeleteRequest): Promise<void> {
        await this.apiV1CandidateNoteDeleteRaw(requestParameters);
    }

    /**
     */
    async apiV1CandidateNotePartialUpdateRaw(requestParameters: ApiApiApiV1CandidateNotePartialUpdateRequest): Promise<runtime.ApiResponse<CandidateActivityNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1CandidateNotePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1CandidateNotePartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate-note/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CandidateActivityNoteToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidateActivityNoteFromJSON(jsonValue));
    }

    /**
     */
    async apiV1CandidateNotePartialUpdate(requestParameters: ApiApiApiV1CandidateNotePartialUpdateRequest): Promise<CandidateActivityNote> {
        const response = await this.apiV1CandidateNotePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1CandidateNoteUpdateRaw(requestParameters: ApiApiApiV1CandidateNoteUpdateRequest): Promise<runtime.ApiResponse<CandidateActivityNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1CandidateNoteUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1CandidateNoteUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate-note/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CandidateActivityNoteToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidateActivityNoteFromJSON(jsonValue));
    }

    /**
     */
    async apiV1CandidateNoteUpdate(requestParameters: ApiApiApiV1CandidateNoteUpdateRequest): Promise<CandidateActivityNote> {
        const response = await this.apiV1CandidateNoteUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ClientEmailTemplatesUpdateRaw(requestParameters: ApiApiApiV1ClientEmailTemplatesUpdateRequest): Promise<runtime.ApiResponse<ClientEmailTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1ClientEmailTemplatesUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1ClientEmailTemplatesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-email-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientEmailTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientEmailTemplateFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ClientEmailTemplatesUpdate(requestParameters: ApiApiApiV1ClientEmailTemplatesUpdateRequest): Promise<ClientEmailTemplate> {
        const response = await this.apiV1ClientEmailTemplatesUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ClientImagesCreateRaw(requestParameters: ApiApiApiV1ClientImagesCreateRequest): Promise<runtime.ApiResponse<ClientImage>> {
        if (requestParameters.fileContents === null || requestParameters.fileContents === undefined) {
            throw new runtime.RequiredError('fileContents','Required parameter requestParameters.fileContents was null or undefined when calling apiV1ClientImagesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.fileContents !== undefined) {
            formParams.append('file_contents', requestParameters.fileContents as any);
        }

        const response = await this.request({
            path: `/api/v1/client-images`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientImageFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ClientImagesCreate(requestParameters: ApiApiApiV1ClientImagesCreateRequest): Promise<ClientImage> {
        const response = await this.apiV1ClientImagesCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ClientImagesListRaw(requestParameters: ApiApiApiV1ClientImagesListRequest): Promise<runtime.ApiResponse<InlineResponse20020>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-images`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20020FromJSON(jsonValue));
    }

    /**
     */
    async apiV1ClientImagesList(requestParameters: ApiApiApiV1ClientImagesListRequest): Promise<InlineResponse20020> {
        const response = await this.apiV1ClientImagesListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ClientSetupUpdateRaw(requestParameters: ApiApiApiV1ClientSetupUpdateRequest): Promise<runtime.ApiResponse<ClientOnboarding>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1ClientSetupUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1ClientSetupUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-setup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientOnboardingToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientOnboardingFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ClientSetupUpdate(requestParameters: ApiApiApiV1ClientSetupUpdateRequest): Promise<ClientOnboarding> {
        const response = await this.apiV1ClientSetupUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ClientsUpdateRaw(requestParameters: ApiApiApiV1ClientsUpdateRequest): Promise<runtime.ApiResponse<Client>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1ClientsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1ClientsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ClientsUpdate(requestParameters: ApiApiApiV1ClientsUpdateRequest): Promise<Client> {
        const response = await this.apiV1ClientsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ContactEmailsUpdateRaw(requestParameters: ApiApiApiV1ContactEmailsUpdateRequest): Promise<runtime.ApiResponse<ContactEmail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1ContactEmailsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1ContactEmailsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contact-emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContactEmailToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactEmailFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ContactEmailsUpdate(requestParameters: ApiApiApiV1ContactEmailsUpdateRequest): Promise<ContactEmail> {
        const response = await this.apiV1ContactEmailsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ContactPhoneNumbersUpdateRaw(requestParameters: ApiApiApiV1ContactPhoneNumbersUpdateRequest): Promise<runtime.ApiResponse<ContactPhoneNumber>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1ContactPhoneNumbersUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1ContactPhoneNumbersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contact-phone-numbers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContactPhoneNumberToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactPhoneNumberFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ContactPhoneNumbersUpdate(requestParameters: ApiApiApiV1ContactPhoneNumbersUpdateRequest): Promise<ContactPhoneNumber> {
        const response = await this.apiV1ContactPhoneNumbersUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ContactsUpdateRaw(requestParameters: ApiApiApiV1ContactsUpdateRequest): Promise<runtime.ApiResponse<Contact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1ContactsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1ContactsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContactToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ContactsUpdate(requestParameters: ApiApiApiV1ContactsUpdateRequest): Promise<Contact> {
        const response = await this.apiV1ContactsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1EmailsUpdateRaw(requestParameters: ApiApiApiV1EmailsUpdateRequest): Promise<runtime.ApiResponse<Email>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1EmailsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1EmailsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EmailToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailFromJSON(jsonValue));
    }

    /**
     */
    async apiV1EmailsUpdate(requestParameters: ApiApiApiV1EmailsUpdateRequest): Promise<Email> {
        const response = await this.apiV1EmailsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1FeedbackTemplateUpdateRaw(requestParameters: ApiApiApiV1FeedbackTemplateUpdateRequest): Promise<runtime.ApiResponse<UpdateFeedbackTemplateRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1FeedbackTemplateUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1FeedbackTemplateUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/feedback-template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFeedbackTemplateRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateFeedbackTemplateRequestFromJSON(jsonValue));
    }

    /**
     */
    async apiV1FeedbackTemplateUpdate(requestParameters: ApiApiApiV1FeedbackTemplateUpdateRequest): Promise<UpdateFeedbackTemplateRequest> {
        const response = await this.apiV1FeedbackTemplateUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1GenericEmailResponseTemplatesUpdateRaw(requestParameters: ApiApiApiV1GenericEmailResponseTemplatesUpdateRequest): Promise<runtime.ApiResponse<GenericEmailResponseTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1GenericEmailResponseTemplatesUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1GenericEmailResponseTemplatesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/generic-email-response-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GenericEmailResponseTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GenericEmailResponseTemplateFromJSON(jsonValue));
    }

    /**
     */
    async apiV1GenericEmailResponseTemplatesUpdate(requestParameters: ApiApiApiV1GenericEmailResponseTemplatesUpdateRequest): Promise<GenericEmailResponseTemplate> {
        const response = await this.apiV1GenericEmailResponseTemplatesUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1HiringStageEmailTemplatesUpdateRaw(requestParameters: ApiApiApiV1HiringStageEmailTemplatesUpdateRequest): Promise<runtime.ApiResponse<HiringStageEmailTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1HiringStageEmailTemplatesUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1HiringStageEmailTemplatesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-stage-email-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HiringStageEmailTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringStageEmailTemplateFromJSON(jsonValue));
    }

    /**
     */
    async apiV1HiringStageEmailTemplatesUpdate(requestParameters: ApiApiApiV1HiringStageEmailTemplatesUpdateRequest): Promise<HiringStageEmailTemplate> {
        const response = await this.apiV1HiringStageEmailTemplatesUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1InboundApplicationListRaw(requestParameters: ApiApiApiV1InboundApplicationListRequest): Promise<runtime.ApiResponse<InlineResponse20039>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/inbound-application`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20039FromJSON(jsonValue));
    }

    /**
     */
    async apiV1InboundApplicationList(requestParameters: ApiApiApiV1InboundApplicationListRequest): Promise<InlineResponse20039> {
        const response = await this.apiV1InboundApplicationListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1InboundApplicationQuestionDeleteRaw(requestParameters: ApiApiApiV1InboundApplicationQuestionDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1InboundApplicationQuestionDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/inbound/application-question/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1InboundApplicationQuestionDelete(requestParameters: ApiApiApiV1InboundApplicationQuestionDeleteRequest): Promise<void> {
        await this.apiV1InboundApplicationQuestionDeleteRaw(requestParameters);
    }

    /**
     */
    async apiV1InboundApplicationQuestionPartialUpdateRaw(requestParameters: ApiApiApiV1InboundApplicationQuestionPartialUpdateRequest): Promise<runtime.ApiResponse<ApplicationQuestion>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1InboundApplicationQuestionPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1InboundApplicationQuestionPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/inbound/application-question/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationQuestionToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationQuestionFromJSON(jsonValue));
    }

    /**
     */
    async apiV1InboundApplicationQuestionPartialUpdate(requestParameters: ApiApiApiV1InboundApplicationQuestionPartialUpdateRequest): Promise<ApplicationQuestion> {
        const response = await this.apiV1InboundApplicationQuestionPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1InboundApplicationQuestionUpdateRaw(requestParameters: ApiApiApiV1InboundApplicationQuestionUpdateRequest): Promise<runtime.ApiResponse<ApplicationQuestion>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1InboundApplicationQuestionUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1InboundApplicationQuestionUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/inbound/application-question/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationQuestionToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationQuestionFromJSON(jsonValue));
    }

    /**
     */
    async apiV1InboundApplicationQuestionUpdate(requestParameters: ApiApiApiV1InboundApplicationQuestionUpdateRequest): Promise<ApplicationQuestion> {
        const response = await this.apiV1InboundApplicationQuestionUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1InboundApplicationReadRaw(requestParameters: ApiApiApiV1InboundApplicationReadRequest): Promise<runtime.ApiResponse<InboundApplicationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1InboundApplicationRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/inbound-application/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InboundApplicationResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiV1InboundApplicationRead(requestParameters: ApiApiApiV1InboundApplicationReadRequest): Promise<InboundApplicationResponse> {
        const response = await this.apiV1InboundApplicationReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1InboundPartnerCreateRaw(requestParameters: ApiApiApiV1InboundPartnerCreateRequest): Promise<runtime.ApiResponse<ApplicationPortalInboundApplication>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1InboundPartnerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/inbound/partner`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationPortalInboundApplicationToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationPortalInboundApplicationFromJSON(jsonValue));
    }

    /**
     */
    async apiV1InboundPartnerCreate(requestParameters: ApiApiApiV1InboundPartnerCreateRequest): Promise<ApplicationPortalInboundApplication> {
        const response = await this.apiV1InboundPartnerCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1InterviewerProUserUpdateRaw(requestParameters: ApiApiApiV1InterviewerProUserUpdateRequest): Promise<runtime.ApiResponse<ProUser>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling apiV1InterviewerProUserUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1InterviewerProUserUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/interviewer-pro-user/{user}`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProUserToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProUserFromJSON(jsonValue));
    }

    /**
     */
    async apiV1InterviewerProUserUpdate(requestParameters: ApiApiApiV1InterviewerProUserUpdateRequest): Promise<ProUser> {
        const response = await this.apiV1InterviewerProUserUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1InterviewerSchedulingInfoUpdateRaw(requestParameters: ApiApiApiV1InterviewerSchedulingInfoUpdateRequest): Promise<runtime.ApiResponse<InterviewerSchedulingInfo>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1InterviewerSchedulingInfoUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1InterviewerSchedulingInfoUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/interviewer-scheduling-info/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewerSchedulingInfoToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewerSchedulingInfoFromJSON(jsonValue));
    }

    /**
     */
    async apiV1InterviewerSchedulingInfoUpdate(requestParameters: ApiApiApiV1InterviewerSchedulingInfoUpdateRequest): Promise<InterviewerSchedulingInfo> {
        const response = await this.apiV1InterviewerSchedulingInfoUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1JobDescriptionsUpdateRaw(requestParameters: ApiApiApiV1JobDescriptionsUpdateRequest): Promise<runtime.ApiResponse<DoverJobDescription>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1JobDescriptionsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1JobDescriptionsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-descriptions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DoverJobDescriptionToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DoverJobDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async apiV1JobDescriptionsUpdate(requestParameters: ApiApiApiV1JobDescriptionsUpdateRequest): Promise<DoverJobDescription> {
        const response = await this.apiV1JobDescriptionsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1JobReferralsUpdateRaw(requestParameters: ApiApiApiV1JobReferralsUpdateRequest): Promise<runtime.ApiResponse<JobReferral>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1JobReferralsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1JobReferralsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-referrals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: JobReferralToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobReferralFromJSON(jsonValue));
    }

    /**
     */
    async apiV1JobReferralsUpdate(requestParameters: ApiApiApiV1JobReferralsUpdateRequest): Promise<JobReferral> {
        const response = await this.apiV1JobReferralsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1JobSetupsUpdateRaw(requestParameters: ApiApiApiV1JobSetupsUpdateRequest): Promise<runtime.ApiResponse<JobSetup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1JobSetupsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1JobSetupsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-setups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: JobSetupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobSetupFromJSON(jsonValue));
    }

    /**
     */
    async apiV1JobSetupsUpdate(requestParameters: ApiApiApiV1JobSetupsUpdateRequest): Promise<JobSetup> {
        const response = await this.apiV1JobSetupsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1JobsInterviewPlanPartialUpdateRaw(requestParameters: ApiApiApiV1JobsInterviewPlanPartialUpdateRequest): Promise<runtime.ApiResponse<UpdateHiringPipelineStageRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1JobsInterviewPlanPartialUpdate.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling apiV1JobsInterviewPlanPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1JobsInterviewPlanPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{job_id}/interview-plan/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateHiringPipelineStageRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateHiringPipelineStageRequestFromJSON(jsonValue));
    }

    /**
     */
    async apiV1JobsInterviewPlanPartialUpdate(requestParameters: ApiApiApiV1JobsInterviewPlanPartialUpdateRequest): Promise<UpdateHiringPipelineStageRequest> {
        const response = await this.apiV1JobsInterviewPlanPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1NotificationConfigPartialUpdateRaw(requestParameters: ApiApiApiV1NotificationConfigPartialUpdateRequest): Promise<runtime.ApiResponse<NotificationConfig>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1NotificationConfigPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1NotificationConfigPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/notification-config/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationConfigToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationConfigFromJSON(jsonValue));
    }

    /**
     */
    async apiV1NotificationConfigPartialUpdate(requestParameters: ApiApiApiV1NotificationConfigPartialUpdateRequest): Promise<NotificationConfig> {
        const response = await this.apiV1NotificationConfigPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1OnboardingUserOnboardingFlowPartialUpdateRaw(requestParameters: ApiApiApiV1OnboardingUserOnboardingFlowPartialUpdateRequest): Promise<runtime.ApiResponse<UserOnboardingFlow>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1OnboardingUserOnboardingFlowPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1OnboardingUserOnboardingFlowPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/onboarding-user-onboarding-flow/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserOnboardingFlowToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOnboardingFlowFromJSON(jsonValue));
    }

    /**
     */
    async apiV1OnboardingUserOnboardingFlowPartialUpdate(requestParameters: ApiApiApiV1OnboardingUserOnboardingFlowPartialUpdateRequest): Promise<UserOnboardingFlow> {
        const response = await this.apiV1OnboardingUserOnboardingFlowPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1OutreachGenerationReadRaw(requestParameters: ApiApiApiV1OutreachGenerationReadRequest): Promise<runtime.ApiResponse<OutreachGeneration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1OutreachGenerationRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/outreach-generation/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OutreachGenerationFromJSON(jsonValue));
    }

    /**
     */
    async apiV1OutreachGenerationRead(requestParameters: ApiApiApiV1OutreachGenerationReadRequest): Promise<OutreachGeneration> {
        const response = await this.apiV1OutreachGenerationReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1OutreachGenerationUpdateRaw(requestParameters: ApiApiApiV1OutreachGenerationUpdateRequest): Promise<runtime.ApiResponse<OutreachGeneration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1OutreachGenerationUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1OutreachGenerationUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/outreach-generation/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OutreachGenerationToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OutreachGenerationFromJSON(jsonValue));
    }

    /**
     */
    async apiV1OutreachGenerationUpdate(requestParameters: ApiApiApiV1OutreachGenerationUpdateRequest): Promise<OutreachGeneration> {
        const response = await this.apiV1OutreachGenerationUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1PartnerJobsListRaw(requestParameters: ApiApiApiV1PartnerJobsListRequest): Promise<runtime.ApiResponse<InlineResponse20057>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/partner/jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20057FromJSON(jsonValue));
    }

    /**
     */
    async apiV1PartnerJobsList(requestParameters: ApiApiApiV1PartnerJobsListRequest): Promise<InlineResponse20057> {
        const response = await this.apiV1PartnerJobsListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ProfileAttributeDeleteRaw(requestParameters: ApiApiApiV1ProfileAttributeDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1ProfileAttributeDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/profile-attribute/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1ProfileAttributeDelete(requestParameters: ApiApiApiV1ProfileAttributeDeleteRequest): Promise<void> {
        await this.apiV1ProfileAttributeDeleteRaw(requestParameters);
    }

    /**
     */
    async apiV1ProfileAttributeListRaw(requestParameters: ApiApiApiV1ProfileAttributeListRequest): Promise<runtime.ApiResponse<InlineResponse20059>> {
        const queryParameters: any = {};

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/profile-attribute`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20059FromJSON(jsonValue));
    }

    /**
     */
    async apiV1ProfileAttributeList(requestParameters: ApiApiApiV1ProfileAttributeListRequest): Promise<InlineResponse20059> {
        const response = await this.apiV1ProfileAttributeListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ProfileAttributePartialUpdateRaw(requestParameters: ApiApiApiV1ProfileAttributePartialUpdateRequest): Promise<runtime.ApiResponse<AdminProfileAttribute>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1ProfileAttributePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1ProfileAttributePartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/profile-attribute/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AdminProfileAttributeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminProfileAttributeFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ProfileAttributePartialUpdate(requestParameters: ApiApiApiV1ProfileAttributePartialUpdateRequest): Promise<AdminProfileAttribute> {
        const response = await this.apiV1ProfileAttributePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ProfileAttributeReadRaw(requestParameters: ApiApiApiV1ProfileAttributeReadRequest): Promise<runtime.ApiResponse<AdminProfileAttribute>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1ProfileAttributeRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/profile-attribute/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminProfileAttributeFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ProfileAttributeRead(requestParameters: ApiApiApiV1ProfileAttributeReadRequest): Promise<AdminProfileAttribute> {
        const response = await this.apiV1ProfileAttributeReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1RolesUpdateRaw(requestParameters: ApiApiApiV1RolesUpdateRequest): Promise<runtime.ApiResponse<DoverRole>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1RolesUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1RolesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DoverRoleToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DoverRoleFromJSON(jsonValue));
    }

    /**
     */
    async apiV1RolesUpdate(requestParameters: ApiApiApiV1RolesUpdateRequest): Promise<DoverRole> {
        const response = await this.apiV1RolesUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1UsersUpdateRaw(requestParameters: ApiApiApiV1UsersUpdateRequest): Promise<runtime.ApiResponse<DoverUser>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling apiV1UsersUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV1UsersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/{user}`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DoverUserToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DoverUserFromJSON(jsonValue));
    }

    /**
     */
    async apiV1UsersUpdate(requestParameters: ApiApiApiV1UsersUpdateRequest): Promise<DoverUser> {
        const response = await this.apiV1UsersUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV2MentionsUpdateRaw(requestParameters: ApiApiApiV2MentionsUpdateRequest): Promise<runtime.ApiResponse<Mention>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV2MentionsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiV2MentionsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/mentions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MentionToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MentionFromJSON(jsonValue));
    }

    /**
     */
    async apiV2MentionsUpdate(requestParameters: ApiApiApiV2MentionsUpdateRequest): Promise<Mention> {
        const response = await this.apiV2MentionsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV2SaapplicationReviewListRaw(requestParameters: ApiApiApiV2SaapplicationReviewListRequest): Promise<runtime.ApiResponse<InlineResponse20074>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/saapplication-review`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20074FromJSON(jsonValue));
    }

    /**
     */
    async apiV2SaapplicationReviewList(requestParameters: ApiApiApiV2SaapplicationReviewListRequest): Promise<InlineResponse20074> {
        const response = await this.apiV2SaapplicationReviewListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV2TalentReviewListRaw(requestParameters: ApiApiApiV2TalentReviewListRequest): Promise<runtime.ApiResponse<InlineResponse20076>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/talent-review`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20076FromJSON(jsonValue));
    }

    /**
     */
    async apiV2TalentReviewList(requestParameters: ApiApiApiV2TalentReviewListRequest): Promise<InlineResponse20076> {
        const response = await this.apiV2TalentReviewListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Authenticate Metabase user
     */
    async authMetabaseUserRaw(requestParameters: ApiApiAuthMetabaseUserRequest): Promise<runtime.ApiResponse<AuthMetabaseUserResponse>> {
        if (requestParameters.returnTo === null || requestParameters.returnTo === undefined) {
            throw new runtime.RequiredError('returnTo','Required parameter requestParameters.returnTo was null or undefined when calling authMetabaseUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.returnTo !== undefined) {
            queryParameters['return_to'] = requestParameters.returnTo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/reporting/auth-metabase-user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthMetabaseUserResponseFromJSON(jsonValue));
    }

    /**
     * Authenticate Metabase user
     */
    async authMetabaseUser(requestParameters: ApiApiAuthMetabaseUserRequest): Promise<AuthMetabaseUserResponse> {
        const response = await this.authMetabaseUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Use GPT3 to do initial categorization of a candidate email
     */
    async autocategoCandidateEmailRaw(requestParameters: ApiApiAutocategoCandidateEmailRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling autocategoCandidateEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/autocatego_candidate_email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject1ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * Use GPT3 to do initial categorization of a candidate email
     */
    async autocategoCandidateEmail(requestParameters: ApiApiAutocategoCandidateEmailRequest): Promise<InlineResponse200> {
        const response = await this.autocategoCandidateEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * Autoformat an interview response for a candidate for the interview specified
     */
    async autoformatInterviewRubricResponseRaw(requestParameters: ApiApiAutoformatInterviewRubricResponseRequest): Promise<runtime.ApiResponse<InterviewRubricResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling autoformatInterviewRubricResponse.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling autoformatInterviewRubricResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-interview-rubric-response/{id}/autoformat_interview_rubric_response`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AutoformatInterviewRubricRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewRubricResponseFromJSON(jsonValue));
    }

    /**
     * Autoformat an interview response for a candidate for the interview specified
     */
    async autoformatInterviewRubricResponse(requestParameters: ApiApiAutoformatInterviewRubricResponseRequest): Promise<InterviewRubricResponse> {
        const response = await this.autoformatInterviewRubricResponseRaw(requestParameters);
        return await response.value();
    }

    /**
     * Initiates a billing portal session with Stripe.
     */
    async beginBillingPortalSessionRaw(requestParameters: ApiApiBeginBillingPortalSessionOperationRequest): Promise<runtime.ApiResponse<BeginBillingPortalSessionResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling beginBillingPortalSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/billing/portal/begin-session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BeginBillingPortalSessionRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BeginBillingPortalSessionResponseFromJSON(jsonValue));
    }

    /**
     * Initiates a billing portal session with Stripe.
     */
    async beginBillingPortalSession(requestParameters: ApiApiBeginBillingPortalSessionOperationRequest): Promise<BeginBillingPortalSessionResponse> {
        const response = await this.beginBillingPortalSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Initiates the checkout process for securely collected credit card info.
     */
    async beginCheckoutRaw(requestParameters: ApiApiBeginCheckoutOperationRequest): Promise<runtime.ApiResponse<BeginCheckoutResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling beginCheckout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/billing/checkout/begin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BeginCheckoutRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BeginCheckoutResponseFromJSON(jsonValue));
    }

    /**
     * Initiates the checkout process for securely collected credit card info.
     */
    async beginCheckout(requestParameters: ApiApiBeginCheckoutOperationRequest): Promise<BeginCheckoutResponse> {
        const response = await this.beginCheckoutRaw(requestParameters);
        return await response.value();
    }

    /**
     * bulk create calibration candidate
     */
    async bulkCreateCalibrationCandidatesRaw(requestParameters: ApiApiBulkCreateCalibrationCandidatesRequest): Promise<runtime.ApiResponse<Array<CalibrationCandidate>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling bulkCreateCalibrationCandidates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/calibration-candidates/bulk/bulk_create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data.map(CalibrationCandidateToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CalibrationCandidateFromJSON));
    }

    /**
     * bulk create calibration candidate
     */
    async bulkCreateCalibrationCandidates(requestParameters: ApiApiBulkCreateCalibrationCandidatesRequest): Promise<Array<CalibrationCandidate>> {
        const response = await this.bulkCreateCalibrationCandidatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * bulk delete calibration candidate
     */
    async bulkDestroyCalibrationCandidatesRaw(requestParameters: ApiApiBulkDestroyCalibrationCandidatesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling bulkDestroyCalibrationCandidates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/calibration-candidates/bulk/bulk_destroy`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * bulk delete calibration candidate
     */
    async bulkDestroyCalibrationCandidates(requestParameters: ApiApiBulkDestroyCalibrationCandidatesRequest): Promise<void> {
        await this.bulkDestroyCalibrationCandidatesRaw(requestParameters);
    }

    /**
     * bulk update calibration candidate
     */
    async bulkUpdateCalibrationCandidatesRaw(requestParameters: ApiApiBulkUpdateCalibrationCandidatesRequest): Promise<runtime.ApiResponse<Array<CalibrationCandidate>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling bulkUpdateCalibrationCandidates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/calibration-candidates/bulk/bulk_update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data.map(CalibrationCandidateToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CalibrationCandidateFromJSON));
    }

    /**
     * bulk update calibration candidate
     */
    async bulkUpdateCalibrationCandidates(requestParameters: ApiApiBulkUpdateCalibrationCandidatesRequest): Promise<Array<CalibrationCandidate>> {
        const response = await this.bulkUpdateCalibrationCandidatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * attempts to update all campaigns associated with a job
     */
    async bulkUpdateCampaignsRaw(requestParameters: ApiApiBulkUpdateCampaignsRequest): Promise<runtime.ApiResponse<BulkCampaignUpdateResult>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling bulkUpdateCampaigns.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/campaigns/bulk_update_campaigns`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkCampaignUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkCampaignUpdateResultFromJSON(jsonValue));
    }

    /**
     * attempts to update all campaigns associated with a job
     */
    async bulkUpdateCampaigns(requestParameters: ApiApiBulkUpdateCampaignsRequest): Promise<BulkCampaignUpdateResult> {
        const response = await this.bulkUpdateCampaignsRaw(requestParameters);
        return await response.value();
    }

    /**
     * bulk upserts client domains
     */
    async bulkUpsertClientDomainsRaw(requestParameters: ApiApiBulkUpsertClientDomainsRequest): Promise<runtime.ApiResponse<BulkUpsertClientDomainResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling bulkUpsertClientDomains.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-domains/bulk_upsert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkUpsertClientDomainToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkUpsertClientDomainResponseFromJSON(jsonValue));
    }

    /**
     * bulk upserts client domains
     */
    async bulkUpsertClientDomains(requestParameters: ApiApiBulkUpsertClientDomainsRequest): Promise<BulkUpsertClientDomainResponse> {
        const response = await this.bulkUpsertClientDomainsRaw(requestParameters);
        return await response.value();
    }

    /**
     * updates the states of multiple job features for a given job in bulk
     */
    async bulkUpsertJobFeatureSettingsRaw(requestParameters: ApiApiBulkUpsertJobFeatureSettingsRequest): Promise<runtime.ApiResponse<Array<BulkUpsertJobFeatureSetting>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bulkUpsertJobFeatureSettings.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling bulkUpsertJobFeatureSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/upsert-feature-settings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data.map(BulkUpsertJobFeatureSettingToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BulkUpsertJobFeatureSettingFromJSON));
    }

    /**
     * updates the states of multiple job features for a given job in bulk
     */
    async bulkUpsertJobFeatureSettings(requestParameters: ApiApiBulkUpsertJobFeatureSettingsRequest): Promise<Array<BulkUpsertJobFeatureSetting>> {
        const response = await this.bulkUpsertJobFeatureSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send test emails
     */
    async campaignSendTestEmailsRaw(requestParameters: ApiApiCampaignSendTestEmailsOperationRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling campaignSendTestEmails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/send-test-emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignSendTestEmailsRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Send test emails
     */
    async campaignSendTestEmails(requestParameters: ApiApiCampaignSendTestEmailsOperationRequest): Promise<object> {
        const response = await this.campaignSendTestEmailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * checks if we can create a manual sourcing context for a linkedin id and job
     */
    async canManuallySourceProfileRaw(requestParameters: ApiApiCanManuallySourceProfileRequest): Promise<runtime.ApiResponse<CheckCanCreateManualContextForJobResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling canManuallySourceProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/manual-sourcing/can_manually_source_profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckCanCreateManualContextForJobRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckCanCreateManualContextForJobResponseFromJSON(jsonValue));
    }

    /**
     * checks if we can create a manual sourcing context for a linkedin id and job
     */
    async canManuallySourceProfile(requestParameters: ApiApiCanManuallySourceProfileRequest): Promise<CheckCanCreateManualContextForJobResponse> {
        const response = await this.canManuallySourceProfileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Cancel an email to a candidate.
     */
    async cancelEmailRaw(requestParameters: ApiApiCancelEmailOperationRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cancelEmail.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling cancelEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-actions/{id}/cancel_email`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelEmailRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseCandidateActionResponseFromJSON(jsonValue));
    }

    /**
     * Cancel an email to a candidate.
     */
    async cancelEmail(requestParameters: ApiApiCancelEmailOperationRequest): Promise<BaseCandidateActionResponse> {
        const response = await this.cancelEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * Cancel initial interview for this candidate.
     */
    async cancelInterviewRaw(requestParameters: ApiApiCancelInterviewRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.candidateId === null || requestParameters.candidateId === undefined) {
            throw new runtime.RequiredError('candidateId','Required parameter requestParameters.candidateId was null or undefined when calling cancelInterview.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling cancelInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/dover-interviewers/candidates/{candidate_id}/cancel-interview`.replace(`{${"candidate_id"}}`, encodeURIComponent(String(requestParameters.candidateId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Cancel initial interview for this candidate.
     */
    async cancelInterview(requestParameters: ApiApiCancelInterviewRequest): Promise<InlineResponse2001> {
        const response = await this.cancelInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Cancel multipart interview request
     */
    async cancelMultipartInterviewRequestRaw(requestParameters: ApiApiCancelMultipartInterviewRequestRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling cancelMultipartInterviewRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/cancel-multipart-interview-request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelMultipartInterviewRequestToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel multipart interview request
     */
    async cancelMultipartInterviewRequest(requestParameters: ApiApiCancelMultipartInterviewRequestRequest): Promise<void> {
        await this.cancelMultipartInterviewRequestRaw(requestParameters);
    }

    /**
     * Cancel all queued automated EmailSendRequests for a candidate
     */
    async cancelQueuedAutomatedEmailsRaw(requestParameters: ApiApiCancelQueuedAutomatedEmailsRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling cancelQueuedAutomatedEmails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/cancel_queued_automated_emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject2ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Cancel all queued automated EmailSendRequests for a candidate
     */
    async cancelQueuedAutomatedEmails(requestParameters: ApiApiCancelQueuedAutomatedEmailsRequest): Promise<InlineResponse2001> {
        const response = await this.cancelQueuedAutomatedEmailsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async changeAppScoringEnabledRaw(requestParameters: ApiApiChangeAppScoringEnabledOperationRequest): Promise<runtime.ApiResponse<ChangeAppScoringEnabledResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeAppScoringEnabled.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling changeAppScoringEnabled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/change_app_scoring_enabled`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeAppScoringEnabledRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeAppScoringEnabledResponseFromJSON(jsonValue));
    }

    /**
     */
    async changeAppScoringEnabled(requestParameters: ApiApiChangeAppScoringEnabledOperationRequest): Promise<ChangeAppScoringEnabledResponse> {
        const response = await this.changeAppScoringEnabledRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update archive reason on a candidate.
     */
    async changeArchiveReasonRaw(requestParameters: ApiApiChangeArchiveReasonOperationRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeArchiveReason.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling changeArchiveReason.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-actions/{id}/change_archive_reason`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeArchiveReasonRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseCandidateActionResponseFromJSON(jsonValue));
    }

    /**
     * Update archive reason on a candidate.
     */
    async changeArchiveReason(requestParameters: ApiApiChangeArchiveReasonOperationRequest): Promise<BaseCandidateActionResponse> {
        const response = await this.changeArchiveReasonRaw(requestParameters);
        return await response.value();
    }

    /**
     * Change interviewer
     */
    async changeInterviewerRaw(requestParameters: ApiApiChangeInterviewerOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling changeInterviewer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/change-interviewer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeInterviewerRequestToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change interviewer
     */
    async changeInterviewer(requestParameters: ApiApiChangeInterviewerOperationRequest): Promise<void> {
        await this.changeInterviewerRaw(requestParameters);
    }

    /**
     * checks if profile exists
     */
    async checkIfProfileExistsRaw(requestParameters: ApiApiCheckIfProfileExistsOperationRequest): Promise<runtime.ApiResponse<CheckIfProfileExistsResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling checkIfProfileExists.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/outreach-generation/check_if_profile_exists/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckIfProfileExistsRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckIfProfileExistsResponseFromJSON(jsonValue));
    }

    /**
     * checks if profile exists
     */
    async checkIfProfileExists(requestParameters: ApiApiCheckIfProfileExistsOperationRequest): Promise<CheckIfProfileExistsResponse> {
        const response = await this.checkIfProfileExistsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Clear user alias
     */
    async clearAliasRaw(): Promise<runtime.ApiResponse<InlineResponse2001>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/users/clear_alias`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Clear user alias
     */
    async clearAlias(): Promise<InlineResponse2001> {
        const response = await this.clearAliasRaw();
        return await response.value();
    }

    /**
     * Clone a search_v3 object
     */
    async cloneSearchV3Raw(requestParameters: ApiApiCloneSearchV3Request): Promise<runtime.ApiResponse<CloneSearchV3Response>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling cloneSearchV3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/searches/clone_search_v3`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject25ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CloneSearchV3ResponseFromJSON(jsonValue));
    }

    /**
     * Clone a search_v3 object
     */
    async cloneSearchV3(requestParameters: ApiApiCloneSearchV3Request): Promise<CloneSearchV3Response> {
        const response = await this.cloneSearchV3Raw(requestParameters);
        return await response.value();
    }

    /**
     * Completes the checkout process by securely passing a session id from the external billing service.
     */
    async completeCheckoutRaw(requestParameters: ApiApiCompleteCheckoutOperationRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling completeCheckout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/billing/checkout/complete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteCheckoutRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Completes the checkout process by securely passing a session id from the external billing service.
     */
    async completeCheckout(requestParameters: ApiApiCompleteCheckoutOperationRequest): Promise<object> {
        const response = await this.completeCheckoutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async confirmJobPersonaRaw(requestParameters: ApiApiConfirmJobPersonaOperationRequest): Promise<runtime.ApiResponse<InlineResponse20010>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling confirmJobPersona.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling confirmJobPersona.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/confirm_persona`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmJobPersonaRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20010FromJSON(jsonValue));
    }

    /**
     */
    async confirmJobPersona(requestParameters: ApiApiConfirmJobPersonaOperationRequest): Promise<InlineResponse20010> {
        const response = await this.confirmJobPersonaRaw(requestParameters);
        return await response.value();
    }

    /**
     * create inbound application
     */
    async createApplicationPortalInboundApplicationRaw(requestParameters: ApiApiCreateApplicationPortalInboundApplicationRequest): Promise<runtime.ApiResponse<ApplicationPortalInboundApplication>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling createApplicationPortalInboundApplication.');
        }

        if (requestParameters.firstName === null || requestParameters.firstName === undefined) {
            throw new runtime.RequiredError('firstName','Required parameter requestParameters.firstName was null or undefined when calling createApplicationPortalInboundApplication.');
        }

        if (requestParameters.lastName === null || requestParameters.lastName === undefined) {
            throw new runtime.RequiredError('lastName','Required parameter requestParameters.lastName was null or undefined when calling createApplicationPortalInboundApplication.');
        }

        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling createApplicationPortalInboundApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.jobId !== undefined) {
            formParams.append('job_id', requestParameters.jobId as any);
        }

        if (requestParameters.referrerSource !== undefined) {
            formParams.append('referrer_source', requestParameters.referrerSource as any);
        }

        if (requestParameters.firstName !== undefined) {
            formParams.append('first_name', requestParameters.firstName as any);
        }

        if (requestParameters.lastName !== undefined) {
            formParams.append('last_name', requestParameters.lastName as any);
        }

        if (requestParameters.linkedinUrl !== undefined) {
            formParams.append('linkedin_url', requestParameters.linkedinUrl as any);
        }

        if (requestParameters.email !== undefined) {
            formParams.append('email', requestParameters.email as any);
        }

        if (requestParameters.phoneNumber !== undefined) {
            formParams.append('phone_number', requestParameters.phoneNumber as any);
        }

        if (requestParameters.resume !== undefined) {
            formParams.append('resume', requestParameters.resume as any);
        }

        if (requestParameters.additionalInfo !== undefined) {
            formParams.append('additional_info', requestParameters.additionalInfo as any);
        }

        if (requestParameters.applicationQuestions !== undefined) {
            formParams.append('application_questions', requestParameters.applicationQuestions as any);
        }

        if (requestParameters.bookmarked !== undefined) {
            formParams.append('bookmarked', requestParameters.bookmarked as any);
        }

        if (requestParameters.optedInToTalentNetwork !== undefined) {
            formParams.append('opted_in_to_talent_network', requestParameters.optedInToTalentNetwork as any);
        }

        const response = await this.request({
            path: `/api/v1/inbound/application-portal-inbound-application`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationPortalInboundApplicationFromJSON(jsonValue));
    }

    /**
     * create inbound application
     */
    async createApplicationPortalInboundApplication(requestParameters: ApiApiCreateApplicationPortalInboundApplicationRequest): Promise<ApplicationPortalInboundApplication> {
        const response = await this.createApplicationPortalInboundApplicationRaw(requestParameters);
        return await response.value();
    }

    /**
     * create application question
     */
    async createApplicationQuestionRaw(requestParameters: ApiApiCreateApplicationQuestionRequest): Promise<runtime.ApiResponse<ApplicationQuestion>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createApplicationQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/inbound/application-question`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationQuestionToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationQuestionFromJSON(jsonValue));
    }

    /**
     * create application question
     */
    async createApplicationQuestion(requestParameters: ApiApiCreateApplicationQuestionRequest): Promise<ApplicationQuestion> {
        const response = await this.createApplicationQuestionRaw(requestParameters);
        return await response.value();
    }

    /**
     * create archive reason
     */
    async createArchiveReasonRaw(requestParameters: ApiApiCreateArchiveReasonRequest): Promise<runtime.ApiResponse<ArchiveReason>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createArchiveReason.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/archive-reasons`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ArchiveReasonToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ArchiveReasonFromJSON(jsonValue));
    }

    /**
     * create archive reason
     */
    async createArchiveReason(requestParameters: ApiApiCreateArchiveReasonRequest): Promise<ArchiveReason> {
        const response = await this.createArchiveReasonRaw(requestParameters);
        return await response.value();
    }

    /**
     * create campaign
     */
    async createCampaignRaw(requestParameters: ApiApiCreateCampaignRequest): Promise<runtime.ApiResponse<CreateCampaign>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/campaigns`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCampaignToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCampaignFromJSON(jsonValue));
    }

    /**
     * create campaign
     */
    async createCampaign(requestParameters: ApiApiCreateCampaignRequest): Promise<CreateCampaign> {
        const response = await this.createCampaignRaw(requestParameters);
        return await response.value();
    }

    /**
     * create candidate activity note
     */
    async createCandidateActivityNoteRaw(requestParameters: ApiApiCreateCandidateActivityNoteRequest): Promise<runtime.ApiResponse<CandidateActivityNote>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createCandidateActivityNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate-note`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CandidateActivityNoteToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidateActivityNoteFromJSON(jsonValue));
    }

    /**
     * create candidate activity note
     */
    async createCandidateActivityNote(requestParameters: ApiApiCreateCandidateActivityNoteRequest): Promise<CandidateActivityNote> {
        const response = await this.createCandidateActivityNoteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create Candidate Files
     */
    async createCandidateFileRaw(requestParameters: ApiApiCreateCandidateFileRequest): Promise<runtime.ApiResponse<CandidateFiles>> {
        if (requestParameters.candidate === null || requestParameters.candidate === undefined) {
            throw new runtime.RequiredError('candidate','Required parameter requestParameters.candidate was null or undefined when calling createCandidateFile.');
        }

        if (requestParameters.uploadedContext === null || requestParameters.uploadedContext === undefined) {
            throw new runtime.RequiredError('uploadedContext','Required parameter requestParameters.uploadedContext was null or undefined when calling createCandidateFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.candidate !== undefined) {
            formParams.append('candidate', requestParameters.candidate as any);
        }

        if (requestParameters.fileContents !== undefined) {
            formParams.append('file_contents', requestParameters.fileContents as any);
        }

        if (requestParameters.uploadedContext !== undefined) {
            formParams.append('uploaded_context', requestParameters.uploadedContext as any);
        }

        const response = await this.request({
            path: `/api/v2/candidate-files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidateFilesFromJSON(jsonValue));
    }

    /**
     * Create Candidate Files
     */
    async createCandidateFile(requestParameters: ApiApiCreateCandidateFileRequest): Promise<CandidateFiles> {
        const response = await this.createCandidateFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create change log for search
     */
    async createChangeLogForSearchRaw(requestParameters: ApiApiCreateChangeLogForSearchRequest): Promise<runtime.ApiResponse<CreateChangeLogForSearchResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createChangeLogForSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/change-log-entries/create_change_log_for_search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetChangeLogEntryRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateChangeLogForSearchResponseFromJSON(jsonValue));
    }

    /**
     * Create change log for search
     */
    async createChangeLogForSearch(requestParameters: ApiApiCreateChangeLogForSearchRequest): Promise<CreateChangeLogForSearchResponse> {
        const response = await this.createChangeLogForSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * create a client email template
     */
    async createClientEmailTemplateRaw(requestParameters: ApiApiCreateClientEmailTemplateRequest): Promise<runtime.ApiResponse<ClientEmailTemplate>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createClientEmailTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-email-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientEmailTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientEmailTemplateFromJSON(jsonValue));
    }

    /**
     * create a client email template
     */
    async createClientEmailTemplate(requestParameters: ApiApiCreateClientEmailTemplateRequest): Promise<ClientEmailTemplate> {
        const response = await this.createClientEmailTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * create a CompanyList
     */
    async createCompanyListRaw(requestParameters: ApiApiCreateCompanyListRequest): Promise<runtime.ApiResponse<CompanyList>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createCompanyList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/company-lists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyListToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyListFromJSON(jsonValue));
    }

    /**
     * create a CompanyList
     */
    async createCompanyList(requestParameters: ApiApiCreateCompanyListRequest): Promise<CompanyList> {
        const response = await this.createCompanyListRaw(requestParameters);
        return await response.value();
    }

    /**
     * bulk create a set of emails for a specific contact
     */
    async createContactEmailsRaw(requestParameters: ApiApiCreateContactEmailsOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createContactEmails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contact-emails/create_contact_emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateContactEmailsRequestToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * bulk create a set of emails for a specific contact
     */
    async createContactEmails(requestParameters: ApiApiCreateContactEmailsOperationRequest): Promise<void> {
        await this.createContactEmailsRaw(requestParameters);
    }

    /**
     * bulk create a set of phone numbers for a specific contact
     */
    async createContactPhoneNumbersRaw(requestParameters: ApiApiCreateContactPhoneNumbersOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createContactPhoneNumbers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contact-phone-numbers/create_contact_phone_numbers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateContactPhoneNumbersRequestToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * bulk create a set of phone numbers for a specific contact
     */
    async createContactPhoneNumbers(requestParameters: ApiApiCreateContactPhoneNumbersOperationRequest): Promise<void> {
        await this.createContactPhoneNumbersRaw(requestParameters);
    }

    /**
     * Create DTN Canddate. This will create a candidate and then user can action in the UI.
     */
    async createDtnCandidateRaw(requestParameters: ApiApiCreateDtnCandidateOperationRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createDtnCandidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-actions/create_dtn_candidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDtnCandidateRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseCandidateActionResponseFromJSON(jsonValue));
    }

    /**
     * Create DTN Canddate. This will create a candidate and then user can action in the UI.
     */
    async createDtnCandidate(requestParameters: ApiApiCreateDtnCandidateOperationRequest): Promise<BaseCandidateActionResponse> {
        const response = await this.createDtnCandidateRaw(requestParameters);
        return await response.value();
    }

    /**
     * create feedback template
     */
    async createFeedbackTemplateRaw(requestParameters: ApiApiCreateFeedbackTemplateOperationRequest): Promise<runtime.ApiResponse<FeedbackTemplateDetail>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createFeedbackTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/feedback-template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFeedbackTemplateRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedbackTemplateDetailFromJSON(jsonValue));
    }

    /**
     * create feedback template
     */
    async createFeedbackTemplate(requestParameters: ApiApiCreateFeedbackTemplateOperationRequest): Promise<FeedbackTemplateDetail> {
        const response = await this.createFeedbackTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a HiringPipelineStage
     */
    async createHiringPipelineStageRaw(requestParameters: ApiApiCreateHiringPipelineStageOperationRequest): Promise<runtime.ApiResponse<HiringPipelineStage>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling createHiringPipelineStage.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createHiringPipelineStage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{job_id}/interview-plan`.replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateHiringPipelineStageRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringPipelineStageFromJSON(jsonValue));
    }

    /**
     * Create a HiringPipelineStage
     */
    async createHiringPipelineStage(requestParameters: ApiApiCreateHiringPipelineStageOperationRequest): Promise<HiringPipelineStage> {
        const response = await this.createHiringPipelineStageRaw(requestParameters);
        return await response.value();
    }

    /**
     * create hiring stage email template
     */
    async createHiringStageEmailTemplateRaw(requestParameters: ApiApiCreateHiringStageEmailTemplateRequest): Promise<runtime.ApiResponse<HiringStageEmailTemplate>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createHiringStageEmailTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-stage-email-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HiringStageEmailTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringStageEmailTemplateFromJSON(jsonValue));
    }

    /**
     * create hiring stage email template
     */
    async createHiringStageEmailTemplate(requestParameters: ApiApiCreateHiringStageEmailTemplateRequest): Promise<HiringStageEmailTemplate> {
        const response = await this.createHiringStageEmailTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * create interviewer
     */
    async createInterviewerRaw(requestParameters: ApiApiCreateInterviewerRequest): Promise<runtime.ApiResponse<ClientInterviewer>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createInterviewer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-interviewers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientInterviewerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientInterviewerFromJSON(jsonValue));
    }

    /**
     * create interviewer
     */
    async createInterviewer(requestParameters: ApiApiCreateInterviewerRequest): Promise<ClientInterviewer> {
        const response = await this.createInterviewerRaw(requestParameters);
        return await response.value();
    }

    /**
     * create jd object with text
     */
    async createJobDescriptionWithTextRaw(requestParameters: ApiApiCreateJobDescriptionWithTextOperationRequest): Promise<runtime.ApiResponse<CreateJobDescriptionWithTextResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createJobDescriptionWithText.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-descriptions/create_job_description_with_text`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateJobDescriptionWithTextRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateJobDescriptionWithTextResponseFromJSON(jsonValue));
    }

    /**
     * create jd object with text
     */
    async createJobDescriptionWithText(requestParameters: ApiApiCreateJobDescriptionWithTextOperationRequest): Promise<CreateJobDescriptionWithTextResponse> {
        const response = await this.createJobDescriptionWithTextRaw(requestParameters);
        return await response.value();
    }

    /**
     * create job position for hiring plan
     */
    async createJobPositionForHiringPlanRaw(requestParameters: ApiApiCreateJobPositionForHiringPlanRequest): Promise<runtime.ApiResponse<HiringPlanJobPosition>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createJobPositionForHiringPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-plan-job-positions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HiringPlanJobPositionToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringPlanJobPositionFromJSON(jsonValue));
    }

    /**
     * create job position for hiring plan
     */
    async createJobPositionForHiringPlan(requestParameters: ApiApiCreateJobPositionForHiringPlanRequest): Promise<HiringPlanJobPosition> {
        const response = await this.createJobPositionForHiringPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     * create job position opening for hiring plan
     */
    async createJobPositionOpeningForHiringPlanRaw(requestParameters: ApiApiCreateJobPositionOpeningForHiringPlanRequest): Promise<runtime.ApiResponse<HiringPlanJobPositionOpening>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createJobPositionOpeningForHiringPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-plan-job-position-openings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HiringPlanJobPositionOpeningToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringPlanJobPositionOpeningFromJSON(jsonValue));
    }

    /**
     * create job position opening for hiring plan
     */
    async createJobPositionOpeningForHiringPlan(requestParameters: ApiApiCreateJobPositionOpeningForHiringPlanRequest): Promise<HiringPlanJobPositionOpening> {
        const response = await this.createJobPositionOpeningForHiringPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createNewJobRaw(requestParameters: ApiApiCreateNewJobOperationRequest): Promise<runtime.ApiResponse<CreateNewJobResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createNewJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/create-job`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNewJobRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateNewJobResponseFromJSON(jsonValue));
    }

    /**
     */
    async createNewJob(requestParameters: ApiApiCreateNewJobOperationRequest): Promise<CreateNewJobResponse> {
        const response = await this.createNewJobRaw(requestParameters);
        return await response.value();
    }

    /**
     * create notification config
     */
    async createNotificationConfigRaw(requestParameters: ApiApiCreateNotificationConfigRequest): Promise<runtime.ApiResponse<NotificationConfig>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createNotificationConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/notification-config`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationConfigToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationConfigFromJSON(jsonValue));
    }

    /**
     * create notification config
     */
    async createNotificationConfig(requestParameters: ApiApiCreateNotificationConfigRequest): Promise<NotificationConfig> {
        const response = await this.createNotificationConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     * creates or update outreach generation
     */
    async createOrUpdateOutreachGenerationRaw(requestParameters: ApiApiCreateOrUpdateOutreachGenerationRequest): Promise<runtime.ApiResponse<OutreachGeneration>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createOrUpdateOutreachGeneration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/outreach-generation/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OutreachGenerationToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OutreachGenerationFromJSON(jsonValue));
    }

    /**
     * creates or update outreach generation
     */
    async createOrUpdateOutreachGeneration(requestParameters: ApiApiCreateOrUpdateOutreachGenerationRequest): Promise<OutreachGeneration> {
        const response = await this.createOrUpdateOutreachGenerationRaw(requestParameters);
        return await response.value();
    }

    /**
     * create a Persona
     */
    async createPersonaRaw(requestParameters: ApiApiCreatePersonaRequest): Promise<runtime.ApiResponse<Persona>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createPersona.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/personas`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonaToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonaFromJSON(jsonValue));
    }

    /**
     * create a Persona
     */
    async createPersona(requestParameters: ApiApiCreatePersonaRequest): Promise<Persona> {
        const response = await this.createPersonaRaw(requestParameters);
        return await response.value();
    }

    /**
     * create pro user
     */
    async createProUserRaw(requestParameters: ApiApiCreateProUserRequest): Promise<runtime.ApiResponse<DoverUser>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createProUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DoverUserToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DoverUserFromJSON(jsonValue));
    }

    /**
     * create pro user
     */
    async createProUser(requestParameters: ApiApiCreateProUserRequest): Promise<DoverUser> {
        const response = await this.createProUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * create profile attribute
     */
    async createProfileAttributesRaw(requestParameters: ApiApiCreateProfileAttributesRequest): Promise<runtime.ApiResponse<AdminProfileAttribute>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createProfileAttributes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/profile-attribute`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminProfileAttributeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminProfileAttributeFromJSON(jsonValue));
    }

    /**
     * create profile attribute
     */
    async createProfileAttributes(requestParameters: ApiApiCreateProfileAttributesRequest): Promise<AdminProfileAttribute> {
        const response = await this.createProfileAttributesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a reengagement transform
     */
    async createReengagementTransformRaw(requestParameters: ApiApiCreateReengagementTransformOperationRequest): Promise<runtime.ApiResponse<CreateReengagementTransformResponseSuccess>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createReengagementTransform.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/searches/create_reengagement_transform`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateReengagementTransformRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateReengagementTransformResponseSuccessFromJSON(jsonValue));
    }

    /**
     * Create a reengagement transform
     */
    async createReengagementTransform(requestParameters: ApiApiCreateReengagementTransformOperationRequest): Promise<CreateReengagementTransformResponseSuccess> {
        const response = await this.createReengagementTransformRaw(requestParameters);
        return await response.value();
    }

    /**
     * create a SchoolList
     */
    async createSchoolListRaw(requestParameters: ApiApiCreateSchoolListRequest): Promise<runtime.ApiResponse<SchoolList>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createSchoolList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/school-lists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchoolListToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolListFromJSON(jsonValue));
    }

    /**
     * create a SchoolList
     */
    async createSchoolList(requestParameters: ApiApiCreateSchoolListRequest): Promise<SchoolList> {
        const response = await this.createSchoolListRaw(requestParameters);
        return await response.value();
    }

    /**
     * create search
     */
    async createSearchRaw(requestParameters: ApiApiCreateSearchRequest): Promise<runtime.ApiResponse<AdminSearch>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminSearchToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminSearchFromJSON(jsonValue));
    }

    /**
     * create search
     */
    async createSearch(requestParameters: ApiApiCreateSearchRequest): Promise<AdminSearch> {
        const response = await this.createSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a search from the answers to a Persona Questionnaire
     */
    async createSearchFromPQRaw(requestParameters: ApiApiCreateSearchFromPQRequest): Promise<runtime.ApiResponse<InlineObject25>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createSearchFromPQ.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/searches/create_search_from_pq`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject26ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineObject25FromJSON(jsonValue));
    }

    /**
     * Create a search from the answers to a Persona Questionnaire
     */
    async createSearchFromPQ(requestParameters: ApiApiCreateSearchFromPQRequest): Promise<InlineObject25> {
        const response = await this.createSearchFromPQRaw(requestParameters);
        return await response.value();
    }

    /**
     * create search v3 params
     */
    async createSearchV3Raw(requestParameters: ApiApiCreateSearchV3Request): Promise<runtime.ApiResponse<SearchV3>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createSearchV3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchV3ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchV3FromJSON(jsonValue));
    }

    /**
     * create search v3 params
     */
    async createSearchV3(requestParameters: ApiApiCreateSearchV3Request): Promise<SearchV3> {
        const response = await this.createSearchV3Raw(requestParameters);
        return await response.value();
    }

    /**
     * create a title patterns
     */
    async createTitlePatternRaw(requestParameters: ApiApiCreateTitlePatternRequest): Promise<runtime.ApiResponse<TitlePattern>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createTitlePattern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/title-patterns`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TitlePatternToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TitlePatternFromJSON(jsonValue));
    }

    /**
     * create a title patterns
     */
    async createTitlePattern(requestParameters: ApiApiCreateTitlePatternRequest): Promise<TitlePattern> {
        const response = await this.createTitlePatternRaw(requestParameters);
        return await response.value();
    }

    /**
     * create UserRating
     */
    async createUserRatingRaw(requestParameters: ApiApiCreateUserRatingRequest): Promise<runtime.ApiResponse<UserRating>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createUserRating.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/reporting/user-rating`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRatingToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserRatingFromJSON(jsonValue));
    }

    /**
     * create UserRating
     */
    async createUserRating(requestParameters: ApiApiCreateUserRatingRequest): Promise<UserRating> {
        const response = await this.createUserRatingRaw(requestParameters);
        return await response.value();
    }

    /**
     * upload a file
     */
    async createUserUploadedFileRaw(requestParameters: ApiApiCreateUserUploadedFileRequest): Promise<runtime.ApiResponse<UserUploadedFile>> {
        if (requestParameters.fileContents === null || requestParameters.fileContents === undefined) {
            throw new runtime.RequiredError('fileContents','Required parameter requestParameters.fileContents was null or undefined when calling createUserUploadedFile.');
        }

        if (requestParameters.uploadedContext === null || requestParameters.uploadedContext === undefined) {
            throw new runtime.RequiredError('uploadedContext','Required parameter requestParameters.uploadedContext was null or undefined when calling createUserUploadedFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.fileContents !== undefined) {
            formParams.append('file_contents', requestParameters.fileContents as any);
        }

        if (requestParameters.uploadedContext !== undefined) {
            formParams.append('uploaded_context', requestParameters.uploadedContext as any);
        }

        if (requestParameters.originalFileName !== undefined) {
            formParams.append('original_file_name', requestParameters.originalFileName as any);
        }

        if (requestParameters.lastProcessedAt !== undefined) {
            formParams.append('last_processed_at', requestParameters.lastProcessedAt as any);
        }

        const response = await this.request({
            path: `/api/v1/files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserUploadedFileFromJSON(jsonValue));
    }

    /**
     * upload a file
     */
    async createUserUploadedFile(requestParameters: ApiApiCreateUserUploadedFileRequest): Promise<UserUploadedFile> {
        const response = await this.createUserUploadedFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deactivates a job
     */
    async deactivateJobRaw(requestParameters: ApiApiDeactivateJobRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deactivateJob.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling deactivateJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/deactivate_job`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeactivateJobToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Deactivates a job
     */
    async deactivateJob(requestParameters: ApiApiDeactivateJobRequest): Promise<InlineResponse2001> {
        const response = await this.deactivateJobRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete client email template with given id
     */
    async deleteClientEmailTemplateRaw(requestParameters: ApiApiDeleteClientEmailTemplateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteClientEmailTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-email-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete client email template with given id
     */
    async deleteClientEmailTemplate(requestParameters: ApiApiDeleteClientEmailTemplateRequest): Promise<void> {
        await this.deleteClientEmailTemplateRaw(requestParameters);
    }

    /**
     * delete a CompanyList
     */
    async deleteCompanyListRaw(requestParameters: ApiApiDeleteCompanyListRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompanyList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/company-lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete a CompanyList
     */
    async deleteCompanyList(requestParameters: ApiApiDeleteCompanyListRequest): Promise<void> {
        await this.deleteCompanyListRaw(requestParameters);
    }

    /**
     * delete contact email
     */
    async deleteContactEmailRaw(requestParameters: ApiApiDeleteContactEmailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteContactEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contact-emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete contact email
     */
    async deleteContactEmail(requestParameters: ApiApiDeleteContactEmailRequest): Promise<void> {
        await this.deleteContactEmailRaw(requestParameters);
    }

    /**
     * delete contact phone number
     */
    async deleteContactPhoneNumberRaw(requestParameters: ApiApiDeleteContactPhoneNumberRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteContactPhoneNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contact-phone-numbers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete contact phone number
     */
    async deleteContactPhoneNumber(requestParameters: ApiApiDeleteContactPhoneNumberRequest): Promise<void> {
        await this.deleteContactPhoneNumberRaw(requestParameters);
    }

    /**
     * delete candidate email
     */
    async deleteEmailRaw(requestParameters: ApiApiDeleteEmailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete candidate email
     */
    async deleteEmail(requestParameters: ApiApiDeleteEmailRequest): Promise<void> {
        await this.deleteEmailRaw(requestParameters);
    }

    /**
     * delete feedback template with given id
     */
    async deleteFeedbackTemplateRaw(requestParameters: ApiApiDeleteFeedbackTemplateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteFeedbackTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/feedback-template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete feedback template with given id
     */
    async deleteFeedbackTemplate(requestParameters: ApiApiDeleteFeedbackTemplateRequest): Promise<void> {
        await this.deleteFeedbackTemplateRaw(requestParameters);
    }

    /**
     * destroy hiring stage email template
     */
    async deleteHiringStageEmailTemplateRaw(requestParameters: ApiApiDeleteHiringStageEmailTemplateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteHiringStageEmailTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-stage-email-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * destroy hiring stage email template
     */
    async deleteHiringStageEmailTemplate(requestParameters: ApiApiDeleteHiringStageEmailTemplateRequest): Promise<void> {
        await this.deleteHiringStageEmailTemplateRaw(requestParameters);
    }

    /**
     * delete job position
     */
    async deleteJobPositionForHiringPlanRaw(requestParameters: ApiApiDeleteJobPositionForHiringPlanRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteJobPositionForHiringPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-plan-job-positions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete job position
     */
    async deleteJobPositionForHiringPlan(requestParameters: ApiApiDeleteJobPositionForHiringPlanRequest): Promise<void> {
        await this.deleteJobPositionForHiringPlanRaw(requestParameters);
    }

    /**
     * delete job position opening
     */
    async deleteJobPositionOpeningForHiringPlanRaw(requestParameters: ApiApiDeleteJobPositionOpeningForHiringPlanRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteJobPositionOpeningForHiringPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-plan-job-position-openings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete job position opening
     */
    async deleteJobPositionOpeningForHiringPlan(requestParameters: ApiApiDeleteJobPositionOpeningForHiringPlanRequest): Promise<void> {
        await this.deleteJobPositionOpeningForHiringPlanRaw(requestParameters);
    }

    /**
     * delete a Persona
     */
    async deletePersonaRaw(requestParameters: ApiApiDeletePersonaRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePersona.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/personas/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete a Persona
     */
    async deletePersona(requestParameters: ApiApiDeletePersonaRequest): Promise<void> {
        await this.deletePersonaRaw(requestParameters);
    }

    /**
     * delete a SchoolList
     */
    async deleteSchoolListRaw(requestParameters: ApiApiDeleteSchoolListRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSchoolList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/school-lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete a SchoolList
     */
    async deleteSchoolList(requestParameters: ApiApiDeleteSchoolListRequest): Promise<void> {
        await this.deleteSchoolListRaw(requestParameters);
    }

    /**
     * Delete Candidate Files
     */
    async destroyCandidateFileRaw(requestParameters: ApiApiDestroyCandidateFileRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling destroyCandidateFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-files/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Candidate Files
     */
    async destroyCandidateFile(requestParameters: ApiApiDestroyCandidateFileRequest): Promise<void> {
        await this.destroyCandidateFileRaw(requestParameters);
    }

    /**
     * delete search
     */
    async destroySearchRaw(requestParameters: ApiApiDestroySearchRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling destroySearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/searches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete search
     */
    async destroySearch(requestParameters: ApiApiDestroySearchRequest): Promise<void> {
        await this.destroySearchRaw(requestParameters);
    }

    /**
     * determine next action for candidates
     */
    async determineNextActionForCandidatesRaw(requestParameters: ApiApiDetermineNextActionForCandidatesRequest): Promise<runtime.ApiResponse<Array<CandidateNextAction>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling determineNextActionForCandidates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate/next-action/determine_next_actions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NextActionForCandidatesRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CandidateNextActionFromJSON));
    }

    /**
     * determine next action for candidates
     */
    async determineNextActionForCandidates(requestParameters: ApiApiDetermineNextActionForCandidatesRequest): Promise<Array<CandidateNextAction>> {
        const response = await this.determineNextActionForCandidatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Disable a HiringPipelineStage
     */
    async disableHiringPipelineStageRaw(requestParameters: ApiApiDisableHiringPipelineStageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling disableHiringPipelineStage.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling disableHiringPipelineStage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{job_id}/interview-plan/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Disable a HiringPipelineStage
     */
    async disableHiringPipelineStage(requestParameters: ApiApiDisableHiringPipelineStageRequest): Promise<void> {
        await this.disableHiringPipelineStageRaw(requestParameters);
    }

    /**
     * Retrieves emails for linkedin public url
     */
    async exchangeEmailIdForEmailRaw(requestParameters: ApiApiExchangeEmailIdForEmailOperationRequest): Promise<runtime.ApiResponse<ExchangeEmailIdForEmailResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling exchangeEmailIdForEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/email_finding/exchange-email-id-for-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExchangeEmailIdForEmailRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExchangeEmailIdForEmailResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves emails for linkedin public url
     */
    async exchangeEmailIdForEmail(requestParameters: ApiApiExchangeEmailIdForEmailOperationRequest): Promise<ExchangeEmailIdForEmailResponse> {
        const response = await this.exchangeEmailIdForEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async exportDataAsCSVRaw(requestParameters: ApiApiExportDataAsCSVRequest): Promise<runtime.ApiResponse<CandidateCSVExportResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling exportDataAsCSV.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/clients/{id}/export-data-as-csv`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidateCSVExportResponseFromJSON(jsonValue));
    }

    /**
     */
    async exportDataAsCSV(requestParameters: ApiApiExportDataAsCSVRequest): Promise<CandidateCSVExportResponse> {
        const response = await this.exportDataAsCSVRaw(requestParameters);
        return await response.value();
    }

    /**
     * ensure that we can source the current profile
     */
    async extensionV2CanSourceProfileRaw(requestParameters: ApiApiExtensionV2CanSourceProfileRequest): Promise<runtime.ApiResponse<CanSourceProfileResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling extensionV2CanSourceProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/extension_v2/can-source-profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CanSourceProfileRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CanSourceProfileResponseFromJSON(jsonValue));
    }

    /**
     * ensure that we can source the current profile
     */
    async extensionV2CanSourceProfile(requestParameters: ApiApiExtensionV2CanSourceProfileRequest): Promise<CanSourceProfileResponse> {
        const response = await this.extensionV2CanSourceProfileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieves emails given linkedin profile information
     */
    async extensionV2FindEmailsRaw(requestParameters: ApiApiExtensionV2FindEmailsOperationRequest): Promise<runtime.ApiResponse<ExtensionV2FindEmailsResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling extensionV2FindEmails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/extension_v2/find-emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtensionV2FindEmailsRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtensionV2FindEmailsResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves emails given linkedin profile information
     */
    async extensionV2FindEmails(requestParameters: ApiApiExtensionV2FindEmailsOperationRequest): Promise<ExtensionV2FindEmailsResponse> {
        const response = await this.extensionV2FindEmailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * fetch candidate counts
     */
    async fetchCandidateCountsRaw(requestParameters: ApiApiFetchCandidateCountsRequest): Promise<runtime.ApiResponse<Array<CandidatePipelineCountResponse>>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling fetchCandidateCounts.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling fetchCandidateCounts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-pipeline/{job_id}/fetch-candidate-counts`.replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CandidatePipelineCountRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CandidatePipelineCountResponseFromJSON));
    }

    /**
     * fetch candidate counts
     */
    async fetchCandidateCounts(requestParameters: ApiApiFetchCandidateCountsRequest): Promise<Array<CandidatePipelineCountResponse>> {
        const response = await this.fetchCandidateCountsRaw(requestParameters);
        return await response.value();
    }

    /**
     * fetches hiring funnel stats for the provided job
     */
    async fetchFunnelStatsRaw(requestParameters: ApiApiFetchFunnelStatsRequest): Promise<runtime.ApiResponse<CombinedFunnelStats>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchFunnelStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/fetch_funnel_stats`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CombinedFunnelStatsFromJSON(jsonValue));
    }

    /**
     * fetches hiring funnel stats for the provided job
     */
    async fetchFunnelStats(requestParameters: ApiApiFetchFunnelStatsRequest): Promise<CombinedFunnelStats> {
        const response = await this.fetchFunnelStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * finds the associated ats candidate, if one exists, given an ats candidate url
     */
    async findAtsCandidateRaw(requestParameters: ApiApiFindAtsCandidateOperationRequest): Promise<runtime.ApiResponse<FindAtsCandidateResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling findAtsCandidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/manual-sourcing/find_ats_candidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FindAtsCandidateRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAtsCandidateResponseFromJSON(jsonValue));
    }

    /**
     * finds the associated ats candidate, if one exists, given an ats candidate url
     */
    async findAtsCandidate(requestParameters: ApiApiFindAtsCandidateOperationRequest): Promise<FindAtsCandidateResponse> {
        const response = await this.findAtsCandidateRaw(requestParameters);
        return await response.value();
    }

    /**
     * tries to find a possible dupe contact that can be manually merged (null responses imply that we can\'t support manual merging)
     */
    async findDupeContactRaw(requestParameters: ApiApiFindDupeContactRequest): Promise<runtime.ApiResponse<FindDupeContactResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findDupeContact.');
        }

        if (requestParameters.candidateId === null || requestParameters.candidateId === undefined) {
            throw new runtime.RequiredError('candidateId','Required parameter requestParameters.candidateId was null or undefined when calling findDupeContact.');
        }

        const queryParameters: any = {};

        if (requestParameters.candidateId !== undefined) {
            queryParameters['candidate_id'] = requestParameters.candidateId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contacts/{id}/find_dupe_contact`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindDupeContactResponseFromJSON(jsonValue));
    }

    /**
     * tries to find a possible dupe contact that can be manually merged (null responses imply that we can\'t support manual merging)
     */
    async findDupeContact(requestParameters: ApiApiFindDupeContactRequest): Promise<FindDupeContactResponse> {
        const response = await this.findDupeContactRaw(requestParameters);
        return await response.value();
    }

    /**
     * Find generic response templates that a candidate hasn\'t seen before
     */
    async findGenericResponseTemplatesRaw(requestParameters: ApiApiFindGenericResponseTemplatesRequest): Promise<runtime.ApiResponse<Array<InlineResponse2002>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling findGenericResponseTemplates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/find_generic_response_templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject3ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InlineResponse2002FromJSON));
    }

    /**
     * Find generic response templates that a candidate hasn\'t seen before
     */
    async findGenericResponseTemplates(requestParameters: ApiApiFindGenericResponseTemplatesRequest): Promise<Array<InlineResponse2002>> {
        const response = await this.findGenericResponseTemplatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * find all candidates related to a given contact
     */
    async findRelatedCandidatesRaw(requestParameters: ApiApiFindRelatedCandidatesRequest): Promise<runtime.ApiResponse<Array<RelatedCandidate>>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling findRelatedCandidates.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.contactId !== undefined) {
            queryParameters['contact_id'] = requestParameters.contactId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contacts/find_related_candidates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RelatedCandidateFromJSON));
    }

    /**
     * find all candidates related to a given contact
     */
    async findRelatedCandidates(requestParameters: ApiApiFindRelatedCandidatesRequest): Promise<Array<RelatedCandidate>> {
        const response = await this.findRelatedCandidatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Find slack user data asscociated with the job channel
     */
    async findSlackUserDataRaw(requestParameters: ApiApiFindSlackUserDataRequest): Promise<runtime.ApiResponse<Array<InlineResponse2003>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling findSlackUserData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/find_slack_user_data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject4ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InlineResponse2003FromJSON));
    }

    /**
     * Find slack user data asscociated with the job channel
     */
    async findSlackUserData(requestParameters: ApiApiFindSlackUserDataRequest): Promise<Array<InlineResponse2003>> {
        const response = await this.findSlackUserDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a single answer for an interview, based on the possible actions in InterviewQuestionFormattingPromptType enum.
     */
    async formatIndividualAIAnswerRaw(requestParameters: ApiApiFormatIndividualAIAnswerRequest): Promise<runtime.ApiResponse<FormatAiAnswerResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling formatIndividualAIAnswer.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling formatIndividualAIAnswer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-interview-rubric-response/{id}/format_individual_ai_answer`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormatAiAnswerRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FormatAiAnswerResponseFromJSON(jsonValue));
    }

    /**
     * Update a single answer for an interview, based on the possible actions in InterviewQuestionFormattingPromptType enum.
     */
    async formatIndividualAIAnswer(requestParameters: ApiApiFormatIndividualAIAnswerRequest): Promise<FormatAiAnswerResponse> {
        const response = await this.formatIndividualAIAnswerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Accept events from Front
     */
    async frontEventWebhookRaw(): Promise<runtime.ApiResponse<InlineResponse2001>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/events/webhook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Accept events from Front
     */
    async frontEventWebhook(): Promise<InlineResponse2001> {
        const response = await this.frontEventWebhookRaw();
        return await response.value();
    }

    /**
     * attempts to generate and save content to a campaign\'s messages
     */
    async generateAndSaveCampaignContentRaw(requestParameters: ApiApiGenerateAndSaveCampaignContentRequest): Promise<runtime.ApiResponse<GenerateAndSaveCampaignContentResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling generateAndSaveCampaignContent.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling generateAndSaveCampaignContent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/campaigns/{id}/generate_and_save_content`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject16ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GenerateAndSaveCampaignContentResultFromJSON(jsonValue));
    }

    /**
     * attempts to generate and save content to a campaign\'s messages
     */
    async generateAndSaveCampaignContent(requestParameters: ApiApiGenerateAndSaveCampaignContentRequest): Promise<GenerateAndSaveCampaignContentResult> {
        const response = await this.generateAndSaveCampaignContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * generateCareersPage
     */
    async generateCareersPageRaw(requestParameters: ApiApiGenerateCareersPageOperationRequest): Promise<runtime.ApiResponse<GenerateCareersPageResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling generateCareersPage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/generate-careers-page`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateCareersPageRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GenerateCareersPageResponseFromJSON(jsonValue));
    }

    /**
     * generateCareersPage
     */
    async generateCareersPage(requestParameters: ApiApiGenerateCareersPageOperationRequest): Promise<GenerateCareersPageResponse> {
        const response = await this.generateCareersPageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Generate Email Outreach
     */
    async generateEmailOutreachRaw(requestParameters: ApiApiGenerateEmailOutreachRequest): Promise<runtime.ApiResponse<InlineResponse20035>> {
        if (requestParameters.campaign === null || requestParameters.campaign === undefined) {
            throw new runtime.RequiredError('campaign','Required parameter requestParameters.campaign was null or undefined when calling generateEmailOutreach.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/generate-email-outreach/{campaign}`.replace(`{${"campaign"}}`, encodeURIComponent(String(requestParameters.campaign))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20035FromJSON(jsonValue));
    }

    /**
     * Generate Email Outreach
     */
    async generateEmailOutreach(requestParameters: ApiApiGenerateEmailOutreachRequest): Promise<InlineResponse20035> {
        const response = await this.generateEmailOutreachRaw(requestParameters);
        return await response.value();
    }

    /**
     * Use AI to generate interview questions
     */
    async generateInterviewQuestionsRaw(requestParameters: ApiApiGenerateInterviewQuestionsRequest): Promise<runtime.ApiResponse<InterviewQuestionSuggestionsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling generateInterviewQuestions.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling generateInterviewQuestions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-interview-rubric-response/{id}/generate_interview_questions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewQuestionSuggestionsRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewQuestionSuggestionsResponseFromJSON(jsonValue));
    }

    /**
     * Use AI to generate interview questions
     */
    async generateInterviewQuestions(requestParameters: ApiApiGenerateInterviewQuestionsRequest): Promise<InterviewQuestionSuggestionsResponse> {
        const response = await this.generateInterviewQuestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * generates a job description for a given job using GPT-4
     */
    async generateJobDescriptionRaw(requestParameters: ApiApiGenerateJobDescriptionOperationRequest): Promise<runtime.ApiResponse<GenerateJobDescriptionResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling generateJobDescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/generate-job-description`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateJobDescriptionRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GenerateJobDescriptionResponseFromJSON(jsonValue));
    }

    /**
     * generates a job description for a given job using GPT-4
     */
    async generateJobDescription(requestParameters: ApiApiGenerateJobDescriptionOperationRequest): Promise<GenerateJobDescriptionResponse> {
        const response = await this.generateJobDescriptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Use GPT3 to generate a Not Interested response
     */
    async generateNotInterestedResponseRaw(requestParameters: ApiApiGenerateNotInterestedResponseRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling generateNotInterestedResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/generate_not_interested_response`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject5ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * Use GPT3 to generate a Not Interested response
     */
    async generateNotInterestedResponse(requestParameters: ApiApiGenerateNotInterestedResponseRequest): Promise<InlineResponse200> {
        const response = await this.generateNotInterestedResponseRaw(requestParameters);
        return await response.value();
    }

    /**
     * generates new outreach email
     */
    async generateOutreachEmailRaw(requestParameters: ApiApiGenerateOutreachEmailRequest): Promise<runtime.ApiResponse<OutreachGeneration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling generateOutreachEmail.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling generateOutreachEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/outreach-generation/{id}/generate/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OutreachGenerationToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OutreachGenerationFromJSON(jsonValue));
    }

    /**
     * generates new outreach email
     */
    async generateOutreachEmail(requestParameters: ApiApiGenerateOutreachEmailRequest): Promise<OutreachGeneration> {
        const response = await this.generateOutreachEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * generate outreach with no profile
     */
    async generateOutreachSimpleRaw(requestParameters: ApiApiGenerateOutreachSimpleRequest): Promise<runtime.ApiResponse<GenerateOutreachNoProfileResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling generateOutreachSimple.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/outreach-generation/generate_outreach_simple/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateOutreachNoProfileRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GenerateOutreachNoProfileResponseFromJSON(jsonValue));
    }

    /**
     * generate outreach with no profile
     */
    async generateOutreachSimple(requestParameters: ApiApiGenerateOutreachSimpleRequest): Promise<GenerateOutreachNoProfileResponse> {
        const response = await this.generateOutreachSimpleRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve search
     */
    async getApplicationPortalJobRaw(requestParameters: ApiApiGetApplicationPortalJobRequest): Promise<runtime.ApiResponse<ApplicationPortalJob>> {
        if (requestParameters.jobOrSearchId === null || requestParameters.jobOrSearchId === undefined) {
            throw new runtime.RequiredError('jobOrSearchId','Required parameter requestParameters.jobOrSearchId was null or undefined when calling getApplicationPortalJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/inbound/application-portal-job/{jobOrSearchId}`.replace(`{${"jobOrSearchId"}}`, encodeURIComponent(String(requestParameters.jobOrSearchId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationPortalJobFromJSON(jsonValue));
    }

    /**
     * retrieve search
     */
    async getApplicationPortalJob(requestParameters: ApiApiGetApplicationPortalJobRequest): Promise<ApplicationPortalJob> {
        const response = await this.getApplicationPortalJobRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve Ashby data
     */
    async getAshbyDataRaw(requestParameters: ApiApiGetAshbyDataRequest): Promise<runtime.ApiResponse<AtsData>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getAshbyData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/ashby-data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject15ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AtsDataFromJSON(jsonValue));
    }

    /**
     * retrieve Ashby data
     */
    async getAshbyData(requestParameters: ApiApiGetAshbyDataRequest): Promise<AtsData> {
        const response = await this.getAshbyDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch interview feedback templates using ATS api
     */
    async getAtsInterviewFeedbackTemplatesRaw(requestParameters: ApiApiGetAtsInterviewFeedbackTemplatesRequest): Promise<runtime.ApiResponse<Array<AtsInterviewFeedbackTemplate>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAtsInterviewFeedbackTemplates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-setups/{id}/get_ats_interview_feedback_templates`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AtsInterviewFeedbackTemplateFromJSON));
    }

    /**
     * Fetch interview feedback templates using ATS api
     */
    async getAtsInterviewFeedbackTemplates(requestParameters: ApiApiGetAtsInterviewFeedbackTemplatesRequest): Promise<Array<AtsInterviewFeedbackTemplate>> {
        const response = await this.getAtsInterviewFeedbackTemplatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch interview stages associated with the given job from ATS api
     */
    async getAtsInterviewStagesRaw(requestParameters: ApiApiGetAtsInterviewStagesRequest): Promise<runtime.ApiResponse<Array<AtsJobStages>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAtsInterviewStages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-setups/{id}/get_ats_interview_stages`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AtsJobStagesFromJSON));
    }

    /**
     * Fetch interview stages associated with the given job from ATS api
     */
    async getAtsInterviewStages(requestParameters: ApiApiGetAtsInterviewStagesRequest): Promise<Array<AtsJobStages>> {
        const response = await this.getAtsInterviewStagesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Return the campaign limits data for a base client
     */
    async getBaseClientCampaignLimitsRaw(requestParameters: ApiApiGetBaseClientCampaignLimitsRequest): Promise<runtime.ApiResponse<BaseClientCampaignLimit>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/clients/base-client-campaign-limits`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseClientCampaignLimitFromJSON(jsonValue));
    }

    /**
     * Return the campaign limits data for a base client
     */
    async getBaseClientCampaignLimits(requestParameters: ApiApiGetBaseClientCampaignLimitsRequest): Promise<BaseClientCampaignLimit> {
        const response = await this.getBaseClientCampaignLimitsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns benchmarks for what percentage of candidates are coming through each channel
     */
    async getBenchmarkPersonaChannelStatsRaw(requestParameters: ApiApiGetBenchmarkPersonaChannelStatsRequest): Promise<runtime.ApiResponse<BenchmarkChannelPersonaStats>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBenchmarkPersonaChannelStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/get_benchmark_persona_channel_stats`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BenchmarkChannelPersonaStatsFromJSON(jsonValue));
    }

    /**
     * Returns benchmarks for what percentage of candidates are coming through each channel
     */
    async getBenchmarkPersonaChannelStats(requestParameters: ApiApiGetBenchmarkPersonaChannelStatsRequest): Promise<BenchmarkChannelPersonaStats> {
        const response = await this.getBenchmarkPersonaChannelStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch the template to use for bulk rejection given a hiring pipeline stage
     */
    async getBulkRejectionTemplateRaw(requestParameters: ApiApiGetBulkRejectionTemplateOperationRequest): Promise<runtime.ApiResponse<BulkActionEmailTemplate>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getBulkRejectionTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate/get-bulk-rejection-template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetBulkRejectionTemplateRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkActionEmailTemplateFromJSON(jsonValue));
    }

    /**
     * Fetch the template to use for bulk rejection given a hiring pipeline stage
     */
    async getBulkRejectionTemplate(requestParameters: ApiApiGetBulkRejectionTemplateOperationRequest): Promise<BulkActionEmailTemplate> {
        const response = await this.getBulkRejectionTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve campaign
     */
    async getCampaignRaw(requestParameters: ApiApiGetCampaignRequest): Promise<runtime.ApiResponse<Campaign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/campaigns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignFromJSON(jsonValue));
    }

    /**
     * retrieve campaign
     */
    async getCampaign(requestParameters: ApiApiGetCampaignRequest): Promise<Campaign> {
        const response = await this.getCampaignRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve campaign message request
     */
    async getCampaignMessageRequestRaw(requestParameters: ApiApiGetCampaignMessageRequestRequest): Promise<runtime.ApiResponse<CampaignMessageRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCampaignMessageRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/campaign-message-request/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignMessageRequestFromJSON(jsonValue));
    }

    /**
     * retrieve campaign message request
     */
    async getCampaignMessageRequest(requestParameters: ApiApiGetCampaignMessageRequestRequest): Promise<CampaignMessageRequest> {
        const response = await this.getCampaignMessageRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieves stats for a campaign
     */
    async getCampaignStatsRaw(requestParameters: ApiApiGetCampaignStatsRequest): Promise<runtime.ApiResponse<CampaignStats>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCampaignStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/campaigns/{id}/get_campaign_stats`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignStatsFromJSON(jsonValue));
    }

    /**
     * retrieves stats for a campaign
     */
    async getCampaignStats(requestParameters: ApiApiGetCampaignStatsRequest): Promise<CampaignStats> {
        const response = await this.getCampaignStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve candidate bio
     */
    async getCandidateBioRaw(requestParameters: ApiApiGetCandidateBioRequest): Promise<runtime.ApiResponse<CandidateBio>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCandidateBio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate-bios/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidateBioFromJSON(jsonValue));
    }

    /**
     * retrieve candidate bio
     */
    async getCandidateBio(requestParameters: ApiApiGetCandidateBioRequest): Promise<CandidateBio> {
        const response = await this.getCandidateBioRaw(requestParameters);
        return await response.value();
    }

    /**
     * fetch candidate info for a given candidate and company email
     */
    async getCandidateByEmailRaw(requestParameters: ApiApiGetCandidateByEmailRequest): Promise<runtime.ApiResponse<FrontCandidate>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getCandidateByEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/get_candidate_info_by_email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject6ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontCandidateFromJSON(jsonValue));
    }

    /**
     * fetch candidate info for a given candidate and company email
     */
    async getCandidateByEmail(requestParameters: ApiApiGetCandidateByEmailRequest): Promise<FrontCandidate> {
        const response = await this.getCandidateByEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * get candidate email activity
     */
    async getCandidateEmailActivityRaw(requestParameters: ApiApiGetCandidateEmailActivityOperationRequest): Promise<runtime.ApiResponse<GetCandidateEmailActivityResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getCandidateEmailActivity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/get_candidate_email_activity`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetCandidateEmailActivityRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCandidateEmailActivityResponseFromJSON(jsonValue));
    }

    /**
     * get candidate email activity
     */
    async getCandidateEmailActivity(requestParameters: ApiApiGetCandidateEmailActivityOperationRequest): Promise<GetCandidateEmailActivityResponse> {
        const response = await this.getCandidateEmailActivityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns stats about how sources perform for this job\'s persona
     */
    async getCandidateSourceStatsRaw(requestParameters: ApiApiGetCandidateSourceStatsRequest): Promise<runtime.ApiResponse<Array<JobCandidateSourceStats>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCandidateSourceStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/get_candidate_source_stats`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobCandidateSourceStatsFromJSON));
    }

    /**
     * Returns stats about how sources perform for this job\'s persona
     */
    async getCandidateSourceStats(requestParameters: ApiApiGetCandidateSourceStatsRequest): Promise<Array<JobCandidateSourceStats>> {
        const response = await this.getCandidateSourceStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve candidate
     */
    async getCandidateV2Raw(requestParameters: ApiApiGetCandidateV2Request): Promise<runtime.ApiResponse<PipelineCandidate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCandidateV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-pipeline/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineCandidateFromJSON(jsonValue));
    }

    /**
     * retrieve candidate
     */
    async getCandidateV2(requestParameters: ApiApiGetCandidateV2Request): Promise<PipelineCandidate> {
        const response = await this.getCandidateV2Raw(requestParameters);
        return await response.value();
    }

    /**
     * gets the Client\'s associated CanonicalCompany record
     */
    async getCanonicalCompanyInfoRaw(requestParameters: ApiApiGetCanonicalCompanyInfoRequest): Promise<runtime.ApiResponse<CanonicalCompany>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-setup/get_canonical_company_info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CanonicalCompanyFromJSON(jsonValue));
    }

    /**
     * gets the Client\'s associated CanonicalCompany record
     */
    async getCanonicalCompanyInfo(requestParameters: ApiApiGetCanonicalCompanyInfoRequest): Promise<CanonicalCompany> {
        const response = await this.getCanonicalCompanyInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Unauthenticated route to retrieve public client information for dover careers page
     */
    async getCareersPageClientRaw(requestParameters: ApiApiGetCareersPageClientRequest): Promise<runtime.ApiResponse<CareersPageClient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCareersPageClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/careers-page/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CareersPageClientFromJSON(jsonValue));
    }

    /**
     * Unauthenticated route to retrieve public client information for dover careers page
     */
    async getCareersPageClient(requestParameters: ApiApiGetCareersPageClientRequest): Promise<CareersPageClient> {
        const response = await this.getCareersPageClientRaw(requestParameters);
        return await response.value();
    }

    /**
     * Unauthenticated route to retrieve public client information for dover careers page
     */
    async getCareersPageClientBySlugRaw(requestParameters: ApiApiGetCareersPageClientBySlugRequest): Promise<runtime.ApiResponse<CareersPageClient>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getCareersPageClientBySlug.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/careers-page-slug/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CareersPageClientFromJSON(jsonValue));
    }

    /**
     * Unauthenticated route to retrieve public client information for dover careers page
     */
    async getCareersPageClientBySlug(requestParameters: ApiApiGetCareersPageClientBySlugRequest): Promise<CareersPageClient> {
        const response = await this.getCareersPageClientBySlugRaw(requestParameters);
        return await response.value();
    }

    /**
     * Unauthenticated route to get active jobs by client for dover careers page
     */
    async getCareersPageJobsRaw(requestParameters: ApiApiGetCareersPageJobsRequest): Promise<runtime.ApiResponse<InlineResponse20017>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getCareersPageJobs.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/careers-page/{client_id}/jobs`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20017FromJSON(jsonValue));
    }

    /**
     * Unauthenticated route to get active jobs by client for dover careers page
     */
    async getCareersPageJobs(requestParameters: ApiApiGetCareersPageJobsRequest): Promise<InlineResponse20017> {
        const response = await this.getCareersPageJobsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get change logs by searchId
     */
    async getChangeLogsRaw(requestParameters: ApiApiGetChangeLogsRequest): Promise<runtime.ApiResponse<Array<ChangeLogEntry>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getChangeLogs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/change-log-entries/get_change_logs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetChangeLogEntryRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChangeLogEntryFromJSON));
    }

    /**
     * Get change logs by searchId
     */
    async getChangeLogs(requestParameters: ApiApiGetChangeLogsRequest): Promise<Array<ChangeLogEntry>> {
        const response = await this.getChangeLogsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get domains associated with the current user client
     */
    async getClientDomainsRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/domains`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get domains associated with the current user client
     */
    async getClientDomains(): Promise<Array<string>> {
        const response = await this.getClientDomainsRaw();
        return await response.value();
    }

    /**
     * get a client email template by id
     */
    async getClientEmailTemplateRaw(requestParameters: ApiApiGetClientEmailTemplateRequest): Promise<runtime.ApiResponse<ClientEmailTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getClientEmailTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-email-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientEmailTemplateFromJSON(jsonValue));
    }

    /**
     * get a client email template by id
     */
    async getClientEmailTemplate(requestParameters: ApiApiGetClientEmailTemplateRequest): Promise<ClientEmailTemplate> {
        const response = await this.getClientEmailTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * get interview preference availiability for an interviewer
     */
    async getClientInterviewerAvailabilityRaw(requestParameters: ApiApiGetClientInterviewerAvailabilityRequest): Promise<runtime.ApiResponse<MultipartSchedulingClientInterviewerAvailability>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getClientInterviewerAvailability.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/multipart-scheduling-client-interviewer/{id}/get-availability`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MultipartSchedulingClientInterviewerAvailabilityFromJSON(jsonValue));
    }

    /**
     * get interview preference availiability for an interviewer
     */
    async getClientInterviewerAvailability(requestParameters: ApiApiGetClientInterviewerAvailabilityRequest): Promise<MultipartSchedulingClientInterviewerAvailability> {
        const response = await this.getClientInterviewerAvailabilityRaw(requestParameters);
        return await response.value();
    }

    /**
     * get calendar events for an interviewer
     */
    async getClientInterviewerCalendarEventsRaw(requestParameters: ApiApiGetClientInterviewerCalendarEventsRequest): Promise<runtime.ApiResponse<Array<MultipartSchedulingClientInterviewerCalendarEvent>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getClientInterviewerCalendarEvents.');
        }

        if (requestParameters.start === null || requestParameters.start === undefined) {
            throw new runtime.RequiredError('start','Required parameter requestParameters.start was null or undefined when calling getClientInterviewerCalendarEvents.');
        }

        if (requestParameters.end === null || requestParameters.end === undefined) {
            throw new runtime.RequiredError('end','Required parameter requestParameters.end was null or undefined when calling getClientInterviewerCalendarEvents.');
        }

        const queryParameters: any = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/multipart-scheduling-client-interviewer/{id}/get-calendar-events`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MultipartSchedulingClientInterviewerCalendarEventFromJSON));
    }

    /**
     * get calendar events for an interviewer
     */
    async getClientInterviewerCalendarEvents(requestParameters: ApiApiGetClientInterviewerCalendarEventsRequest): Promise<Array<MultipartSchedulingClientInterviewerCalendarEvent>> {
        const response = await this.getClientInterviewerCalendarEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get the client interviewer associated with the requesting user
     */
    async getClientInterviewerForUserRaw(requestParameters: ApiApiGetClientInterviewerForUserRequest): Promise<runtime.ApiResponse<ClientInterviewer>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-interviewers/get_for_user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientInterviewerFromJSON(jsonValue));
    }

    /**
     * get the client interviewer associated with the requesting user
     */
    async getClientInterviewerForUser(requestParameters: ApiApiGetClientInterviewerForUserRequest): Promise<ClientInterviewer> {
        const response = await this.getClientInterviewerForUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve client onboarding settings for authed user
     */
    async getClientOnboardingRaw(requestParameters: ApiApiGetClientOnboardingRequest): Promise<runtime.ApiResponse<ClientOnboarding>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-setup/retrieve`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientOnboardingFromJSON(jsonValue));
    }

    /**
     * retrieve client onboarding settings for authed user
     */
    async getClientOnboarding(requestParameters: ApiApiGetClientOnboardingRequest): Promise<ClientOnboarding> {
        const response = await this.getClientOnboardingRaw(requestParameters);
        return await response.value();
    }

    /**
     * get file upload stats for users in a single client for a given uploaded context
     */
    async getClientUserFileStatsRaw(requestParameters: ApiApiGetClientUserFileStatsRequest): Promise<runtime.ApiResponse<Array<ClientUserFileStats>>> {
        if (requestParameters.statsUploadedContext === null || requestParameters.statsUploadedContext === undefined) {
            throw new runtime.RequiredError('statsUploadedContext','Required parameter requestParameters.statsUploadedContext was null or undefined when calling getClientUserFileStats.');
        }

        const queryParameters: any = {};

        if (requestParameters.uploadedContext !== undefined) {
            queryParameters['uploaded_context'] = requestParameters.uploadedContext;
        }

        if (requestParameters.processingState !== undefined) {
            queryParameters['processing_state'] = requestParameters.processingState;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/files/client-stats/{stats_uploaded_context}`.replace(`{${"stats_uploaded_context"}}`, encodeURIComponent(String(requestParameters.statsUploadedContext))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientUserFileStatsFromJSON));
    }

    /**
     * get file upload stats for users in a single client for a given uploaded context
     */
    async getClientUserFileStats(requestParameters: ApiApiGetClientUserFileStatsRequest): Promise<Array<ClientUserFileStats>> {
        const response = await this.getClientUserFileStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get companies by urn
     */
    async getCompaniesByUrnRaw(requestParameters: ApiApiGetCompaniesByUrnOperationRequest): Promise<runtime.ApiResponse<Array<Company>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getCompaniesByUrn.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/companies/get_companies_by_urn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetCompaniesByUrnRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyFromJSON));
    }

    /**
     * Get companies by urn
     */
    async getCompaniesByUrn(requestParameters: ApiApiGetCompaniesByUrnOperationRequest): Promise<Array<Company>> {
        const response = await this.getCompaniesByUrnRaw(requestParameters);
        return await response.value();
    }

    /**
     * getCompanyPitchQuestionSchema
     */
    async getCompanyPitchQuestionSchemaRaw(requestParameters: ApiApiGetCompanyPitchQuestionSchemaRequest): Promise<runtime.ApiResponse<GetCompanyPitchQuestionSchemaResponse>> {
        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/company-pitch-questions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanyPitchQuestionSchemaResponseFromJSON(jsonValue));
    }

    /**
     * getCompanyPitchQuestionSchema
     */
    async getCompanyPitchQuestionSchema(requestParameters: ApiApiGetCompanyPitchQuestionSchemaRequest): Promise<GetCompanyPitchQuestionSchemaResponse> {
        const response = await this.getCompanyPitchQuestionSchemaRaw(requestParameters);
        return await response.value();
    }

    /**
     * gets the setup steps and their state for the requester\'s client
     */
    async getCompanySetupStepsRaw(requestParameters: ApiApiGetCompanySetupStepsRequest): Promise<runtime.ApiResponse<CompanySetupSteps>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-setup/setup-steps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySetupStepsFromJSON(jsonValue));
    }

    /**
     * gets the setup steps and their state for the requester\'s client
     */
    async getCompanySetupSteps(requestParameters: ApiApiGetCompanySetupStepsRequest): Promise<CompanySetupSteps> {
        const response = await this.getCompanySetupStepsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get a contact by id
     */
    async getContactRaw(requestParameters: ApiApiGetContactRequest): Promise<runtime.ApiResponse<Contact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactFromJSON(jsonValue));
    }

    /**
     * get a contact by id
     */
    async getContact(requestParameters: ApiApiGetContactRequest): Promise<Contact> {
        const response = await this.getContactRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the current onboarding step for the requesting user
     */
    async getCurrentOnboardingStepForUserRaw(): Promise<runtime.ApiResponse<CurrentUserOnboardingStepResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/get-current-onboarding-step-for-user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CurrentUserOnboardingStepResponseFromJSON(jsonValue));
    }

    /**
     * Returns the current onboarding step for the requesting user
     */
    async getCurrentOnboardingStepForUser(): Promise<CurrentUserOnboardingStepResponse> {
        const response = await this.getCurrentOnboardingStepForUserRaw();
        return await response.value();
    }

    /**
     * Returns stats needed for the deactivation modal
     */
    async getDeactivationStatsRaw(requestParameters: ApiApiGetDeactivationStatsRequest): Promise<runtime.ApiResponse<DeactivationStats>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDeactivationStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/get_deactivation_stats`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeactivationStatsFromJSON(jsonValue));
    }

    /**
     * Returns stats needed for the deactivation modal
     */
    async getDeactivationStats(requestParameters: ApiApiGetDeactivationStatsRequest): Promise<DeactivationStats> {
        const response = await this.getDeactivationStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve dover entity
     */
    async getDoverEntityRaw(requestParameters: ApiApiGetDoverEntityRequest): Promise<runtime.ApiResponse<DoverEntityRoute>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDoverEntity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/dover-entity/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DoverEntityRouteFromJSON(jsonValue));
    }

    /**
     * retrieve dover entity
     */
    async getDoverEntity(requestParameters: ApiApiGetDoverEntityRequest): Promise<DoverEntityRoute> {
        const response = await this.getDoverEntityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get list of candidates where first round is in scheduling or scheduled.
     */
    async getDoverInterviewerCandidatesRaw(requestParameters: ApiApiGetDoverInterviewerCandidatesRequest): Promise<runtime.ApiResponse<Array<ShortenedCandidate>>> {
        if (requestParameters.doverEntityId === null || requestParameters.doverEntityId === undefined) {
            throw new runtime.RequiredError('doverEntityId','Required parameter requestParameters.doverEntityId was null or undefined when calling getDoverInterviewerCandidates.');
        }

        const queryParameters: any = {};

        if (requestParameters.isCallCompleted !== undefined) {
            queryParameters['is_call_completed'] = requestParameters.isCallCompleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/dover-interviewers/{dover_entity_id}/candidates`.replace(`{${"dover_entity_id"}}`, encodeURIComponent(String(requestParameters.doverEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShortenedCandidateFromJSON));
    }

    /**
     * Get list of candidates where first round is in scheduling or scheduled.
     */
    async getDoverInterviewerCandidates(requestParameters: ApiApiGetDoverInterviewerCandidatesRequest): Promise<Array<ShortenedCandidate>> {
        const response = await this.getDoverInterviewerCandidatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * gets the emails per week option (passive, standard, boosted)
     */
    async getDoverOutboundConfigurationRaw(requestParameters: ApiApiGetDoverOutboundConfigurationRequest): Promise<runtime.ApiResponse<GetDoverOutboundConfiguration200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDoverOutboundConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/get_dover_outbound_configuration`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDoverOutboundConfiguration200ResponseFromJSON(jsonValue));
    }

    /**
     * gets the emails per week option (passive, standard, boosted)
     */
    async getDoverOutboundConfiguration(requestParameters: ApiApiGetDoverOutboundConfigurationRequest): Promise<GetDoverOutboundConfiguration200Response> {
        const response = await this.getDoverOutboundConfigurationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch a template for the candidate and specified action
     */
    async getEmailTemplateV2Raw(requestParameters: ApiApiGetEmailTemplateV2Request): Promise<runtime.ApiResponse<EmailTemplateV2>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getEmailTemplateV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate/get-email-template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetEmailTemplateRequestV2ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTemplateV2FromJSON(jsonValue));
    }

    /**
     * Fetch a template for the candidate and specified action
     */
    async getEmailTemplateV2(requestParameters: ApiApiGetEmailTemplateV2Request): Promise<EmailTemplateV2> {
        const response = await this.getEmailTemplateV2Raw(requestParameters);
        return await response.value();
    }

    /**
     * get example linkedin for a profile attribute
     */
    async getExampleLinkedinRaw(requestParameters: ApiApiGetExampleLinkedinRequest): Promise<runtime.ApiResponse<InlineResponse20060>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getExampleLinkedin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/profile-attribute/{id}/get_example_linkedin`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20060FromJSON(jsonValue));
    }

    /**
     * get example linkedin for a profile attribute
     */
    async getExampleLinkedin(requestParameters: ApiApiGetExampleLinkedinRequest): Promise<InlineResponse20060> {
        const response = await this.getExampleLinkedinRaw(requestParameters);
        return await response.value();
    }

    /**
     * get feature flags by candidate
     */
    async getFeatureFlagsByCandidateRaw(requestParameters: ApiApiGetFeatureFlagsByCandidateOperationRequest): Promise<runtime.ApiResponse<GetFeatureFlagsByCandidateResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getFeatureFlagsByCandidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/get_feature_flags_by_candidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetFeatureFlagsByCandidateRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFeatureFlagsByCandidateResponseFromJSON(jsonValue));
    }

    /**
     * get feature flags by candidate
     */
    async getFeatureFlagsByCandidate(requestParameters: ApiApiGetFeatureFlagsByCandidateOperationRequest): Promise<GetFeatureFlagsByCandidateResponse> {
        const response = await this.getFeatureFlagsByCandidateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all feature flags for a user
     */
    async getFeatureFlagsForUserRaw(): Promise<runtime.ApiResponse<FeatureFlags>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/get-feature-flags-for-user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureFlagsFromJSON(jsonValue));
    }

    /**
     * Get all feature flags for a user
     */
    async getFeatureFlagsForUser(): Promise<FeatureFlags> {
        const response = await this.getFeatureFlagsForUserRaw();
        return await response.value();
    }

    /**
     * retrieve feedback template with the given id
     */
    async getFeedbackTemplateRaw(requestParameters: ApiApiGetFeedbackTemplateRequest): Promise<runtime.ApiResponse<FeedbackTemplateDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getFeedbackTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/feedback-template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedbackTemplateDetailFromJSON(jsonValue));
    }

    /**
     * retrieve feedback template with the given id
     */
    async getFeedbackTemplate(requestParameters: ApiApiGetFeedbackTemplateRequest): Promise<FeedbackTemplateDetail> {
        const response = await this.getFeedbackTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get boolean value for if user has a ClientEmailSender with valid ClientGoogleCredential.
     */
    async getGmailAuthValidRaw(requestParameters: ApiApiGetGmailAuthValidRequest): Promise<runtime.ApiResponse<GmailAuth>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/gmail-auth-valid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GmailAuthFromJSON(jsonValue));
    }

    /**
     * Get boolean value for if user has a ClientEmailSender with valid ClientGoogleCredential.
     */
    async getGmailAuthValid(requestParameters: ApiApiGetGmailAuthValidRequest): Promise<GmailAuth> {
        const response = await this.getGmailAuthValidRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve Greenhouse data
     */
    async getGreenhouseDataRaw(requestParameters: ApiApiGetGreenhouseDataRequest): Promise<runtime.ApiResponse<AtsData>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getGreenhouseData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/greenhouse-data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject18ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AtsDataFromJSON(jsonValue));
    }

    /**
     * retrieve Greenhouse data
     */
    async getGreenhouseData(requestParameters: ApiApiGetGreenhouseDataRequest): Promise<AtsData> {
        const response = await this.getGreenhouseDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get HiringPipelineStage
     */
    async getHiringPipelineStageRaw(requestParameters: ApiApiGetHiringPipelineStageRequest): Promise<runtime.ApiResponse<HiringPipelineStage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getHiringPipelineStage.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling getHiringPipelineStage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{job_id}/interview-plan/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringPipelineStageFromJSON(jsonValue));
    }

    /**
     * Get HiringPipelineStage
     */
    async getHiringPipelineStage(requestParameters: ApiApiGetHiringPipelineStageRequest): Promise<HiringPipelineStage> {
        const response = await this.getHiringPipelineStageRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve hiring stage email template
     */
    async getHiringStageEmailTemplateRaw(requestParameters: ApiApiGetHiringStageEmailTemplateRequest): Promise<runtime.ApiResponse<HiringStageEmailTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getHiringStageEmailTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-stage-email-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringStageEmailTemplateFromJSON(jsonValue));
    }

    /**
     * retrieve hiring stage email template
     */
    async getHiringStageEmailTemplate(requestParameters: ApiApiGetHiringStageEmailTemplateRequest): Promise<HiringStageEmailTemplate> {
        const response = await this.getHiringStageEmailTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get an in depth explanation of how a candidate fares against a search
     */
    async getInDepthExplanationRaw(requestParameters: ApiApiGetInDepthExplanationOperationRequest): Promise<runtime.ApiResponse<GetInDepthExplanationResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getInDepthExplanation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/saapplication-review/get_in_depth_explanation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetInDepthExplanationRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInDepthExplanationResponseFromJSON(jsonValue));
    }

    /**
     * Get an in depth explanation of how a candidate fares against a search
     */
    async getInDepthExplanation(requestParameters: ApiApiGetInDepthExplanationOperationRequest): Promise<GetInDepthExplanationResponse> {
        const response = await this.getInDepthExplanationRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve industries
     */
    async getIndustryRaw(requestParameters: ApiApiGetIndustryRequest): Promise<runtime.ApiResponse<Industry>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getIndustry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/industry/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IndustryFromJSON(jsonValue));
    }

    /**
     * retrieve industries
     */
    async getIndustry(requestParameters: ApiApiGetIndustryRequest): Promise<Industry> {
        const response = await this.getIndustryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets the initial call interview for a candidate
     */
    async getInitialCallInterviewRaw(requestParameters: ApiApiGetInitialCallInterviewRequest): Promise<runtime.ApiResponse<Interview>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getInitialCallInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate-bios/{id}/get_initial_call_interview`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewFromJSON(jsonValue));
    }

    /**
     * Gets the initial call interview for a candidate
     */
    async getInitialCallInterview(requestParameters: ApiApiGetInitialCallInterviewRequest): Promise<Interview> {
        const response = await this.getInitialCallInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * fetch interview plan info for a given job
     */
    async getInterviewPlanByJobRaw(requestParameters: ApiApiGetInterviewPlanByJobRequest): Promise<runtime.ApiResponse<Array<FrontHiringPipelineStage>>> {
        if (requestParameters.job === null || requestParameters.job === undefined) {
            throw new runtime.RequiredError('job','Required parameter requestParameters.job was null or undefined when calling getInterviewPlanByJob.');
        }

        const queryParameters: any = {};

        if (requestParameters.job !== undefined) {
            queryParameters['job'] = requestParameters.job;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/get_interview_plan_by_job`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FrontHiringPipelineStageFromJSON));
    }

    /**
     * fetch interview plan info for a given job
     */
    async getInterviewPlanByJob(requestParameters: ApiApiGetInterviewPlanByJobRequest): Promise<Array<FrontHiringPipelineStage>> {
        const response = await this.getInterviewPlanByJobRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get an interview response for a candidate for the interview specifified
     */
    async getInterviewRubricResponseRaw(requestParameters: ApiApiGetInterviewRubricResponseRequest): Promise<runtime.ApiResponse<InterviewRubricResponse>> {
        if (requestParameters.candidateId === null || requestParameters.candidateId === undefined) {
            throw new runtime.RequiredError('candidateId','Required parameter requestParameters.candidateId was null or undefined when calling getInterviewRubricResponse.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.candidateId !== undefined) {
            queryParameters['candidate_id'] = requestParameters.candidateId;
        }

        if (requestParameters.interviewId !== undefined) {
            queryParameters['interview_id'] = requestParameters.interviewId;
        }

        if (requestParameters.forceCreate !== undefined) {
            queryParameters['force_create'] = requestParameters.forceCreate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-interview-rubric-response/get_interview_rubric_response`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewRubricResponseFromJSON(jsonValue));
    }

    /**
     * Get an interview response for a candidate for the interview specifified
     */
    async getInterviewRubricResponse(requestParameters: ApiApiGetInterviewRubricResponseRequest): Promise<InterviewRubricResponse> {
        const response = await this.getInterviewRubricResponseRaw(requestParameters);
        return await response.value();
    }

    /**
     * get interview rubric response
     */
    async getInterviewRubricResponseByIdRaw(requestParameters: ApiApiGetInterviewRubricResponseByIdRequest): Promise<runtime.ApiResponse<InterviewRubricResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getInterviewRubricResponseById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-interview-rubric-response/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewRubricResponseFromJSON(jsonValue));
    }

    /**
     * get interview rubric response
     */
    async getInterviewRubricResponseById(requestParameters: ApiApiGetInterviewRubricResponseByIdRequest): Promise<InterviewRubricResponse> {
        const response = await this.getInterviewRubricResponseByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get where a candidate is at a given moment in the hiring/interview proccess
     */
    async getInterviewStatusRaw(requestParameters: ApiApiGetInterviewStatusRequest): Promise<runtime.ApiResponse<InterviewStatus>> {
        if (requestParameters.candidateId === null || requestParameters.candidateId === undefined) {
            throw new runtime.RequiredError('candidateId','Required parameter requestParameters.candidateId was null or undefined when calling getInterviewStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/interview-status/{candidate_id}`.replace(`{${"candidate_id"}}`, encodeURIComponent(String(requestParameters.candidateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewStatusFromJSON(jsonValue));
    }

    /**
     * Get where a candidate is at a given moment in the hiring/interview proccess
     */
    async getInterviewStatus(requestParameters: ApiApiGetInterviewStatusRequest): Promise<InterviewStatus> {
        const response = await this.getInterviewStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve interviewer
     */
    async getInterviewerRaw(requestParameters: ApiApiGetInterviewerRequest): Promise<runtime.ApiResponse<ClientInterviewer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getInterviewer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-interviewers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientInterviewerFromJSON(jsonValue));
    }

    /**
     * retrieve interviewer
     */
    async getInterviewer(requestParameters: ApiApiGetInterviewerRequest): Promise<ClientInterviewer> {
        const response = await this.getInterviewerRaw(requestParameters);
        return await response.value();
    }

    /**
     * get the pro user
     */
    async getInterviewerProUserRaw(requestParameters: ApiApiGetInterviewerProUserRequest): Promise<runtime.ApiResponse<ProUser>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/interviewer-pro-user/get_for_user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProUserFromJSON(jsonValue));
    }

    /**
     * get the pro user
     */
    async getInterviewerProUser(requestParameters: ApiApiGetInterviewerProUserRequest): Promise<ProUser> {
        const response = await this.getInterviewerProUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve job
     */
    async getJobRaw(requestParameters: ApiApiGetJobRequest): Promise<runtime.ApiResponse<DashboardJob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardJobFromJSON(jsonValue));
    }

    /**
     * retrieve job
     */
    async getJob(requestParameters: ApiApiGetJobRequest): Promise<DashboardJob> {
        const response = await this.getJobRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets the job application inbound event
     */
    async getJobApplicationEventRaw(requestParameters: ApiApiGetJobApplicationEventRequest): Promise<runtime.ApiResponse<Array<ActivityFeedItem>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJobApplicationEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidates/activity-feed/{id}/job_application_event`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActivityFeedItemFromJSON));
    }

    /**
     * Gets the job application inbound event
     */
    async getJobApplicationEvent(requestParameters: ApiApiGetJobApplicationEventRequest): Promise<Array<ActivityFeedItem>> {
        const response = await this.getJobApplicationEventRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the JD for the job
     */
    async getJobDescriptionRaw(requestParameters: ApiApiGetJobDescriptionRequest): Promise<runtime.ApiResponse<DoverJobDescription>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJobDescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/get_job_description`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DoverJobDescriptionFromJSON(jsonValue));
    }

    /**
     * Returns the JD for the job
     */
    async getJobDescription(requestParameters: ApiApiGetJobDescriptionRequest): Promise<DoverJobDescription> {
        const response = await this.getJobDescriptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * gets the set of job features and state for a given job
     */
    async getJobFeaturesRaw(requestParameters: ApiApiGetJobFeaturesRequest): Promise<runtime.ApiResponse<JobFeatures>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJobFeatures.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/features`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobFeaturesFromJSON(jsonValue));
    }

    /**
     * gets the set of job features and state for a given job
     */
    async getJobFeatures(requestParameters: ApiApiGetJobFeaturesRequest): Promise<JobFeatures> {
        const response = await this.getJobFeaturesRaw(requestParameters);
        return await response.value();
    }

    /**
     * get stats about this job\'s funnel
     */
    async getJobFunnelStatsRaw(requestParameters: ApiApiGetJobFunnelStatsRequest): Promise<runtime.ApiResponse<JobFunnelStats>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJobFunnelStats.');
        }

        const queryParameters: any = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        if (requestParameters.channelName !== undefined) {
            queryParameters['channel_name'] = requestParameters.channelName;
        }

        if (requestParameters.candidateSource) {
            queryParameters['candidate_source'] = requestParameters.candidateSource.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.demographic !== undefined) {
            queryParameters['demographic'] = requestParameters.demographic;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/reporting/jobs/{id}/funnel-stats`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobFunnelStatsFromJSON(jsonValue));
    }

    /**
     * get stats about this job\'s funnel
     */
    async getJobFunnelStats(requestParameters: ApiApiGetJobFunnelStatsRequest): Promise<JobFunnelStats> {
        const response = await this.getJobFunnelStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get stats about how inbound is performing for this job
     */
    async getJobInboundStatsRaw(requestParameters: ApiApiGetJobInboundStatsRequest): Promise<runtime.ApiResponse<JobInboundStats>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJobInboundStats.');
        }

        const queryParameters: any = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        if (requestParameters.channelName !== undefined) {
            queryParameters['channel_name'] = requestParameters.channelName;
        }

        if (requestParameters.candidateSource) {
            queryParameters['candidate_source'] = requestParameters.candidateSource.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/reporting/jobs/{id}/inbound-stats`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobInboundStatsFromJSON(jsonValue));
    }

    /**
     * get stats about how inbound is performing for this job
     */
    async getJobInboundStats(requestParameters: ApiApiGetJobInboundStatsRequest): Promise<JobInboundStats> {
        const response = await this.getJobInboundStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get stats about how outbound is performing for this job
     */
    async getJobOutboundStatsRaw(requestParameters: ApiApiGetJobOutboundStatsRequest): Promise<runtime.ApiResponse<JobOutboundStats>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJobOutboundStats.');
        }

        const queryParameters: any = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        if (requestParameters.channelName !== undefined) {
            queryParameters['channel_name'] = requestParameters.channelName;
        }

        if (requestParameters.candidateSource) {
            queryParameters['candidate_source'] = requestParameters.candidateSource.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.demographic !== undefined) {
            queryParameters['demographic'] = requestParameters.demographic;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/reporting/jobs/{id}/outbound-stats`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobOutboundStatsFromJSON(jsonValue));
    }

    /**
     * get stats about how outbound is performing for this job
     */
    async getJobOutboundStats(requestParameters: ApiApiGetJobOutboundStatsRequest): Promise<JobOutboundStats> {
        const response = await this.getJobOutboundStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get benchmarks about how this job\'s outreach should be performing
     */
    async getJobOutreachBenchmarksRaw(requestParameters: ApiApiGetJobOutreachBenchmarksRequest): Promise<runtime.ApiResponse<JobOutreachBenchmarks>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJobOutreachBenchmarks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/reporting/jobs/{id}/outreach-benchmarks`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobOutreachBenchmarksFromJSON(jsonValue));
    }

    /**
     * get benchmarks about how this job\'s outreach should be performing
     */
    async getJobOutreachBenchmarks(requestParameters: ApiApiGetJobOutreachBenchmarksRequest): Promise<JobOutreachBenchmarks> {
        const response = await this.getJobOutreachBenchmarksRaw(requestParameters);
        return await response.value();
    }

    /**
     * get stats about this job\'s outreach performance
     */
    async getJobOutreachStatsRaw(requestParameters: ApiApiGetJobOutreachStatsRequest): Promise<runtime.ApiResponse<JobOutreachStats>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJobOutreachStats.');
        }

        const queryParameters: any = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        if (requestParameters.channelName !== undefined) {
            queryParameters['channel_name'] = requestParameters.channelName;
        }

        if (requestParameters.candidateSource) {
            queryParameters['candidate_source'] = requestParameters.candidateSource.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.demographic !== undefined) {
            queryParameters['demographic'] = requestParameters.demographic;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/reporting/jobs/{id}/outreach-stats`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobOutreachStatsFromJSON(jsonValue));
    }

    /**
     * get stats about this job\'s outreach performance
     */
    async getJobOutreachStats(requestParameters: ApiApiGetJobOutreachStatsRequest): Promise<JobOutreachStats> {
        const response = await this.getJobOutreachStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * getJobPitchQuestionSchema
     */
    async getJobPitchQuestionSchemaRaw(requestParameters: ApiApiGetJobPitchQuestionSchemaRequest): Promise<runtime.ApiResponse<GetJobPitchQuestionSchemaResponse>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling getJobPitchQuestionSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-pitch-questions/{job_id}`.replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetJobPitchQuestionSchemaResponseFromJSON(jsonValue));
    }

    /**
     * getJobPitchQuestionSchema
     */
    async getJobPitchQuestionSchema(requestParameters: ApiApiGetJobPitchQuestionSchemaRequest): Promise<GetJobPitchQuestionSchemaResponse> {
        const response = await this.getJobPitchQuestionSchemaRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve job position
     */
    async getJobPositionRaw(requestParameters: ApiApiGetJobPositionRequest): Promise<runtime.ApiResponse<HiringPlanJobPosition>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJobPosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-plan-job-positions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringPlanJobPositionFromJSON(jsonValue));
    }

    /**
     * retrieve job position
     */
    async getJobPosition(requestParameters: ApiApiGetJobPositionRequest): Promise<HiringPlanJobPosition> {
        const response = await this.getJobPositionRaw(requestParameters);
        return await response.value();
    }

    /**
     * get stats about how referrals is performing for this job
     */
    async getJobReferralsStatsRaw(requestParameters: ApiApiGetJobReferralsStatsRequest): Promise<runtime.ApiResponse<JobReferralsStats>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJobReferralsStats.');
        }

        const queryParameters: any = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/reporting/jobs/{id}/referrals-stats`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobReferralsStatsFromJSON(jsonValue));
    }

    /**
     * get stats about how referrals is performing for this job
     */
    async getJobReferralsStats(requestParameters: ApiApiGetJobReferralsStatsRequest): Promise<JobReferralsStats> {
        const response = await this.getJobReferralsStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get all employees that referred at least one passing connection
     */
    async getJobReferrersRaw(requestParameters: ApiApiGetJobReferrersRequest): Promise<runtime.ApiResponse<Array<AuthUser>>> {
        const queryParameters: any = {};

        if (requestParameters.job !== undefined) {
            queryParameters['job'] = requestParameters.job;
        }

        if (requestParameters.referrer !== undefined) {
            queryParameters['referrer'] = requestParameters.referrer;
        }

        if (requestParameters.triagingStatus !== undefined) {
            queryParameters['triaging_status'] = requestParameters.triagingStatus;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-referrals/referrers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuthUserFromJSON));
    }

    /**
     * get all employees that referred at least one passing connection
     */
    async getJobReferrers(requestParameters: ApiApiGetJobReferrersRequest): Promise<Array<AuthUser>> {
        const response = await this.getJobReferrersRaw(requestParameters);
        return await response.value();
    }

    /**
     * get metadata about reporting for this job
     */
    async getJobReportMetaRaw(requestParameters: ApiApiGetJobReportMetaRequest): Promise<runtime.ApiResponse<JobReportMeta>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJobReportMeta.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/reporting/jobs/{id}/meta`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobReportMetaFromJSON(jsonValue));
    }

    /**
     * get metadata about reporting for this job
     */
    async getJobReportMeta(requestParameters: ApiApiGetJobReportMetaRequest): Promise<JobReportMeta> {
        const response = await this.getJobReportMetaRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve job setup info
     */
    async getJobSetupRaw(requestParameters: ApiApiGetJobSetupRequest): Promise<runtime.ApiResponse<JobSetup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJobSetup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-setups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobSetupFromJSON(jsonValue));
    }

    /**
     * retrieve job setup info
     */
    async getJobSetup(requestParameters: ApiApiGetJobSetupRequest): Promise<JobSetup> {
        const response = await this.getJobSetupRaw(requestParameters);
        return await response.value();
    }

    /**
     * gets the setup steps and their state for a given job
     */
    async getJobSetupStepsRaw(requestParameters: ApiApiGetJobSetupStepsRequest): Promise<runtime.ApiResponse<JobSetupStepsWithSetupSummaryState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJobSetupSteps.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-setups/{id}/setup-steps`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobSetupStepsWithSetupSummaryStateFromJSON(jsonValue));
    }

    /**
     * gets the setup steps and their state for a given job
     */
    async getJobSetupSteps(requestParameters: ApiApiGetJobSetupStepsRequest): Promise<JobSetupStepsWithSetupSummaryState> {
        const response = await this.getJobSetupStepsRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve keyword
     */
    async getKeywordRaw(requestParameters: ApiApiGetKeywordRequest): Promise<runtime.ApiResponse<Keyword>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getKeyword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/keywords/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KeywordFromJSON(jsonValue));
    }

    /**
     * retrieve keyword
     */
    async getKeyword(requestParameters: ApiApiGetKeywordRequest): Promise<Keyword> {
        const response = await this.getKeywordRaw(requestParameters);
        return await response.value();
    }

    /**
     * get keywords with bucket indices
     */
    async getKeywordsWithBucketIndicesRaw(requestParameters: ApiApiGetKeywordsWithBucketIndicesRequest): Promise<runtime.ApiResponse<Array<KeywordWithBucketIndicesResponse>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getKeywordsWithBucketIndices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/profile-search-keywords/get_keywords_with_bucket_indices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data.map(KeywordWithBucketIndicesRequestToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KeywordWithBucketIndicesResponseFromJSON));
    }

    /**
     * get keywords with bucket indices
     */
    async getKeywordsWithBucketIndices(requestParameters: ApiApiGetKeywordsWithBucketIndicesRequest): Promise<Array<KeywordWithBucketIndicesResponse>> {
        const response = await this.getKeywordsWithBucketIndicesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get information about client\'s Lever refresh token.
     */
    async getLeverAuthInfoRaw(requestParameters: ApiApiGetLeverAuthInfoRequest): Promise<runtime.ApiResponse<LeverAuthInfo>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLeverAuthInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-setup/{id}/get_lever_auth_info`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LeverAuthInfoFromJSON(jsonValue));
    }

    /**
     * Get information about client\'s Lever refresh token.
     */
    async getLeverAuthInfo(requestParameters: ApiApiGetLeverAuthInfoRequest): Promise<LeverAuthInfo> {
        const response = await this.getLeverAuthInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve Lever data
     */
    async getLeverDataRaw(): Promise<runtime.ApiResponse<AtsData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/lever-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AtsDataFromJSON(jsonValue));
    }

    /**
     * retrieve Lever data
     */
    async getLeverData(): Promise<AtsData> {
        const response = await this.getLeverDataRaw();
        return await response.value();
    }

    /**
     * recalculate matching count of profile attribute
     */
    async getMatchingCountRaw(requestParameters: ApiApiGetMatchingCountRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMatchingCount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/profile-attribute/{id}/get_matching_count`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * recalculate matching count of profile attribute
     */
    async getMatchingCount(requestParameters: ApiApiGetMatchingCountRequest): Promise<InlineResponse2001> {
        const response = await this.getMatchingCountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get meeting type of interviewer.
     */
    async getMeetingTypeRaw(requestParameters: ApiApiGetMeetingTypeRequest): Promise<runtime.ApiResponse<InlineResponse20052>> {
        const queryParameters: any = {};

        if (requestParameters.accountId !== undefined) {
            queryParameters['account_id'] = requestParameters.accountId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/meeting-type`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20052FromJSON(jsonValue));
    }

    /**
     * Get meeting type of interviewer.
     */
    async getMeetingType(requestParameters: ApiApiGetMeetingTypeRequest): Promise<InlineResponse20052> {
        const response = await this.getMeetingTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Filter the potentially new emails from a list
     */
    async getMissingEmailsRaw(requestParameters: ApiApiGetMissingEmailsRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getMissingEmails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/missing_emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject7ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Filter the potentially new emails from a list
     */
    async getMissingEmails(requestParameters: ApiApiGetMissingEmailsRequest): Promise<Array<string>> {
        const response = await this.getMissingEmailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the relevant multipart substage information for a given multipart interview
     */
    async getMultipartInterviewStageRaw(requestParameters: ApiApiGetMultipartInterviewStageRequest): Promise<runtime.ApiResponse<MultipartSchedulingMultipartInterviewStage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMultipartInterviewStage.');
        }

        const queryParameters: any = {};

        if (requestParameters.candidateId !== undefined) {
            queryParameters['candidate_id'] = requestParameters.candidateId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/multipart-interview-stage/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MultipartSchedulingMultipartInterviewStageFromJSON(jsonValue));
    }

    /**
     * Get the relevant multipart substage information for a given multipart interview
     */
    async getMultipartInterviewStage(requestParameters: ApiApiGetMultipartInterviewStageRequest): Promise<MultipartSchedulingMultipartInterviewStage> {
        const response = await this.getMultipartInterviewStageRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve candidate
     */
    async getMultipartSchedulingCandidateRaw(requestParameters: ApiApiGetMultipartSchedulingCandidateRequest): Promise<runtime.ApiResponse<MultipartSchedulingCandidate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMultipartSchedulingCandidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/multipart-scheduling-candidate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MultipartSchedulingCandidateFromJSON(jsonValue));
    }

    /**
     * retrieve candidate
     */
    async getMultipartSchedulingCandidate(requestParameters: ApiApiGetMultipartSchedulingCandidateRequest): Promise<MultipartSchedulingCandidate> {
        const response = await this.getMultipartSchedulingCandidateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns an API Key for a provided job source and the requesting user\'s client. Returns existing key if one exists.
     */
    async getOrCreateApiKeyCredentialRaw(requestParameters: ApiApiGetOrCreateApiKeyCredentialOperationRequest): Promise<runtime.ApiResponse<GetOrCreateApiKeyCredentialResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getOrCreateApiKeyCredential.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/get-or-create-apikey-credential`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetOrCreateApiKeyCredentialRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrCreateApiKeyCredentialResponseFromJSON(jsonValue));
    }

    /**
     * Returns an API Key for a provided job source and the requesting user\'s client. Returns existing key if one exists.
     */
    async getOrCreateApiKeyCredential(requestParameters: ApiApiGetOrCreateApiKeyCredentialOperationRequest): Promise<GetOrCreateApiKeyCredentialResponse> {
        const response = await this.getOrCreateApiKeyCredentialRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a keyword based on name
     */
    async getOrCreateKeywordWithNameRaw(requestParameters: ApiApiGetOrCreateKeywordWithNameRequest): Promise<runtime.ApiResponse<ProfileSearchKeywordSerializer>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getOrCreateKeywordWithName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/profile-search-keywords/get_or_create_keyword_with_name`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject23ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileSearchKeywordSerializerFromJSON(jsonValue));
    }

    /**
     * Create a keyword based on name
     */
    async getOrCreateKeywordWithName(requestParameters: ApiApiGetOrCreateKeywordWithNameRequest): Promise<ProfileSearchKeywordSerializer> {
        const response = await this.getOrCreateKeywordWithNameRaw(requestParameters);
        return await response.value();
    }

    /**
     * Given a candidate and a hiring pipeline stage, will get or create the relevant multipart interview and its substages
     */
    async getOrCreateMultipartInterviewSubstagesRaw(requestParameters: ApiApiGetOrCreateMultipartInterviewSubstagesRequest): Promise<runtime.ApiResponse<CandidateMultipartInterview>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getOrCreateMultipartInterviewSubstages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate-multipart-interview/get_or_create_multipart_interview_substages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetOrCreateMultipartStagesRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidateMultipartInterviewFromJSON(jsonValue));
    }

    /**
     * Given a candidate and a hiring pipeline stage, will get or create the relevant multipart interview and its substages
     */
    async getOrCreateMultipartInterviewSubstages(requestParameters: ApiApiGetOrCreateMultipartInterviewSubstagesRequest): Promise<CandidateMultipartInterview> {
        const response = await this.getOrCreateMultipartInterviewSubstagesRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieves or creates a user onboarding flow record given a user and a flow name
     */
    async getOrCreateUserOnboardingFlowRaw(requestParameters: ApiApiGetOrCreateUserOnboardingFlowOperationRequest): Promise<runtime.ApiResponse<UserOnboardingFlow>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getOrCreateUserOnboardingFlow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/onboarding-user-onboarding-flow/get_or_create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetOrCreateUserOnboardingFlowRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOnboardingFlowFromJSON(jsonValue));
    }

    /**
     * retrieves or creates a user onboarding flow record given a user and a flow name
     */
    async getOrCreateUserOnboardingFlow(requestParameters: ApiApiGetOrCreateUserOnboardingFlowOperationRequest): Promise<UserOnboardingFlow> {
        const response = await this.getOrCreateUserOnboardingFlowRaw(requestParameters);
        return await response.value();
    }

    /**
     * get persona questionnaire using persona v2
     */
    async getPQUsingPersonaV2Raw(requestParameters: ApiApiGetPQUsingPersonaV2Request): Promise<runtime.ApiResponse<SearchV3PersonaQuestionnaire>> {
        if (requestParameters.personaId === null || requestParameters.personaId === undefined) {
            throw new runtime.RequiredError('personaId','Required parameter requestParameters.personaId was null or undefined when calling getPQUsingPersonaV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.personaId !== undefined) {
            queryParameters['persona_id'] = requestParameters.personaId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/search-v3-persona-questionnaires/get_pq_using_persona_v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchV3PersonaQuestionnaireFromJSON(jsonValue));
    }

    /**
     * get persona questionnaire using persona v2
     */
    async getPQUsingPersonaV2(requestParameters: ApiApiGetPQUsingPersonaV2Request): Promise<SearchV3PersonaQuestionnaire> {
        const response = await this.getPQUsingPersonaV2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Given a job, try to fetch the persona that is associated with the search
     */
    async getPersonaForJobRaw(requestParameters: ApiApiGetPersonaForJobOperationRequest): Promise<runtime.ApiResponse<GetPersonaForJobResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getPersonaForJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/personas/get_persona_for_job`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetPersonaForJobRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPersonaForJobResponseFromJSON(jsonValue));
    }

    /**
     * Given a job, try to fetch the persona that is associated with the search
     */
    async getPersonaForJob(requestParameters: ApiApiGetPersonaForJobOperationRequest): Promise<GetPersonaForJobResponse> {
        const response = await this.getPersonaForJobRaw(requestParameters);
        return await response.value();
    }

    /**
     * get persona questionnaire
     */
    async getPersonaQuestionnaireRaw(requestParameters: ApiApiGetPersonaQuestionnaireRequest): Promise<runtime.ApiResponse<PersonaQuestionnaire>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPersonaQuestionnaire.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/persona-questionnaire/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonaQuestionnaireFromJSON(jsonValue));
    }

    /**
     * get persona questionnaire
     */
    async getPersonaQuestionnaire(requestParameters: ApiApiGetPersonaQuestionnaireRequest): Promise<PersonaQuestionnaire> {
        const response = await this.getPersonaQuestionnaireRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPersonaViaSoftMatchRaw(requestParameters: ApiApiGetPersonaViaSoftMatchOperationRequest): Promise<runtime.ApiResponse<SlimPersona>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getPersonaViaSoftMatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/get-persona-via-soft-match`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetPersonaViaSoftMatchRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SlimPersonaFromJSON(jsonValue));
    }

    /**
     */
    async getPersonaViaSoftMatch(requestParameters: ApiApiGetPersonaViaSoftMatchOperationRequest): Promise<SlimPersona> {
        const response = await this.getPersonaViaSoftMatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve pro user
     */
    async getProUserRaw(requestParameters: ApiApiGetProUserRequest): Promise<runtime.ApiResponse<DoverUser>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling getProUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/{user}`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DoverUserFromJSON(jsonValue));
    }

    /**
     * retrieve pro user
     */
    async getProUser(requestParameters: ApiApiGetProUserRequest): Promise<DoverUser> {
        const response = await this.getProUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * In-app route to get count of published jobs for a client
     */
    async getPublishedJobCountRaw(): Promise<runtime.ApiResponse<PublishedJobCount>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/get-published-job-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PublishedJobCountFromJSON(jsonValue));
    }

    /**
     * In-app route to get count of published jobs for a client
     */
    async getPublishedJobCount(): Promise<PublishedJobCount> {
        const response = await this.getPublishedJobCountRaw();
        return await response.value();
    }

    /**
     * gets the triaging tab counts
     */
    async getReferralStatsRaw(requestParameters: ApiApiGetReferralStatsRequest): Promise<runtime.ApiResponse<JobReferralStats>> {
        const queryParameters: any = {};

        if (requestParameters.job !== undefined) {
            queryParameters['job'] = requestParameters.job;
        }

        if (requestParameters.referrer !== undefined) {
            queryParameters['referrer'] = requestParameters.referrer;
        }

        if (requestParameters.triagingStatus !== undefined) {
            queryParameters['triaging_status'] = requestParameters.triagingStatus;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-referrals/stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobReferralStatsFromJSON(jsonValue));
    }

    /**
     * gets the triaging tab counts
     */
    async getReferralStats(requestParameters: ApiApiGetReferralStatsRequest): Promise<JobReferralStats> {
        const response = await this.getReferralStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get keywords related to a bucket of industries
     */
    async getRelatedKeywordsRaw(requestParameters: ApiApiGetRelatedKeywordsRequest): Promise<runtime.ApiResponse<InlineResponse20044>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRelatedKeywords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/industry/{id}/get_related_industries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20044FromJSON(jsonValue));
    }

    /**
     * get keywords related to a bucket of industries
     */
    async getRelatedKeywords(requestParameters: ApiApiGetRelatedKeywordsRequest): Promise<InlineResponse20044> {
        const response = await this.getRelatedKeywordsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get remaining concierge hours from meta base, None if not on concierge plan
     */
    async getRemainingConciergeHoursRaw(): Promise<runtime.ApiResponse<GetRemainingConciergeHoursResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/billing/concierge-hours/concierge-hours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRemainingConciergeHoursResponseFromJSON(jsonValue));
    }

    /**
     * Get remaining concierge hours from meta base, None if not on concierge plan
     */
    async getRemainingConciergeHours(): Promise<GetRemainingConciergeHoursResponse> {
        const response = await this.getRemainingConciergeHoursRaw();
        return await response.value();
    }

    /**
     * Get calendars for scheduling_email_alias with appropriate write permissions.
     */
    async getSchedulableCalendarsRaw(requestParameters: ApiApiGetSchedulableCalendarsRequest): Promise<runtime.ApiResponse<Array<SchedulableCalendar>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSchedulableCalendars.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-setups/{id}/get_schedulable_calendars`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SchedulableCalendarFromJSON));
    }

    /**
     * Get calendars for scheduling_email_alias with appropriate write permissions.
     */
    async getSchedulableCalendars(requestParameters: ApiApiGetSchedulableCalendarsRequest): Promise<Array<SchedulableCalendar>> {
        const response = await this.getSchedulableCalendarsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a scheduling link, given candidate, interviewer, and HPS
     */
    async getSchedulingLinkRaw(requestParameters: ApiApiGetSchedulingLinkOperationRequest): Promise<runtime.ApiResponse<SchedulingLink>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getSchedulingLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate/get-scheduling-link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetSchedulingLinkRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulingLinkFromJSON(jsonValue));
    }

    /**
     * Get a scheduling link, given candidate, interviewer, and HPS
     */
    async getSchedulingLink(requestParameters: ApiApiGetSchedulingLinkOperationRequest): Promise<SchedulingLink> {
        const response = await this.getSchedulingLinkRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve search
     */
    async getSearchRaw(requestParameters: ApiApiGetSearchRequest): Promise<runtime.ApiResponse<AdminSearch>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/searches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminSearchFromJSON(jsonValue));
    }

    /**
     * retrieve search
     */
    async getSearch(requestParameters: ApiApiGetSearchRequest): Promise<AdminSearch> {
        const response = await this.getSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * getSearchCountEstimate
     */
    async getSearchCountEstimateRaw(requestParameters: ApiApiGetSearchCountEstimateRequest): Promise<runtime.ApiResponse<InlineResponse20066>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getSearchCountEstimate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/search-counts-estimate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EsSearchCountsRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20066FromJSON(jsonValue));
    }

    /**
     * getSearchCountEstimate
     */
    async getSearchCountEstimate(requestParameters: ApiApiGetSearchCountEstimateRequest): Promise<InlineResponse20066> {
        const response = await this.getSearchCountEstimateRaw(requestParameters);
        return await response.value();
    }

    /**
     * get search v3 params from JD
     */
    async getSearchParamsFromJDRaw(requestParameters: ApiApiGetSearchParamsFromJDOperationRequest): Promise<runtime.ApiResponse<GetSearchParamsFromJDResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getSearchParamsFromJD.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/get-search-params-from-jd`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetSearchParamsFromJDRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSearchParamsFromJDResponseFromJSON(jsonValue));
    }

    /**
     * get search v3 params from JD
     */
    async getSearchParamsFromJD(requestParameters: ApiApiGetSearchParamsFromJDOperationRequest): Promise<GetSearchParamsFromJDResponse> {
        const response = await this.getSearchParamsFromJDRaw(requestParameters);
        return await response.value();
    }

    /**
     * Given a search and a search transform, produces a new set of search params
     */
    async getSearchTransformOutputRaw(requestParameters: ApiApiGetSearchTransformOutputRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSearchTransformOutput.');
        }

        if (requestParameters.searchTransformName === null || requestParameters.searchTransformName === undefined) {
            throw new runtime.RequiredError('searchTransformName','Required parameter requestParameters.searchTransformName was null or undefined when calling getSearchTransformOutput.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/searches/{id}/search-transforms/{search_transform_name}/output`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"search_transform_name"}}`, encodeURIComponent(String(requestParameters.searchTransformName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Given a search and a search transform, produces a new set of search params
     */
    async getSearchTransformOutput(requestParameters: ApiApiGetSearchTransformOutputRequest): Promise<object> {
        const response = await this.getSearchTransformOutputRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve search transform relationship
     */
    async getSearchTransformRelationshipRaw(requestParameters: ApiApiGetSearchTransformRelationshipRequest): Promise<runtime.ApiResponse<AdminSearchTransformRelationship>> {
        if (requestParameters.childSearch === null || requestParameters.childSearch === undefined) {
            throw new runtime.RequiredError('childSearch','Required parameter requestParameters.childSearch was null or undefined when calling getSearchTransformRelationship.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/search-transform/{child_search}`.replace(`{${"child_search"}}`, encodeURIComponent(String(requestParameters.childSearch))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminSearchTransformRelationshipFromJSON(jsonValue));
    }

    /**
     * retrieve search transform relationship
     */
    async getSearchTransformRelationship(requestParameters: ApiApiGetSearchTransformRelationshipRequest): Promise<AdminSearchTransformRelationship> {
        const response = await this.getSearchTransformRelationshipRaw(requestParameters);
        return await response.value();
    }

    /**
     * get search v3 params
     */
    async getSearchV3Raw(requestParameters: ApiApiGetSearchV3Request): Promise<runtime.ApiResponse<SearchV3>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSearchV3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/searches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchV3FromJSON(jsonValue));
    }

    /**
     * get search v3 params
     */
    async getSearchV3(requestParameters: ApiApiGetSearchV3Request): Promise<SearchV3> {
        const response = await this.getSearchV3Raw(requestParameters);
        return await response.value();
    }

    /**
     * get search v3 depth result
     */
    async getSearchV3DepthResultRaw(requestParameters: ApiApiGetSearchV3DepthResultRequest): Promise<runtime.ApiResponse<DepthResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getSearchV3DepthResult.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/search-v3-depth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DepthRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DepthResponseFromJSON(jsonValue));
    }

    /**
     * get search v3 depth result
     */
    async getSearchV3DepthResult(requestParameters: ApiApiGetSearchV3DepthResultRequest): Promise<DepthResponse> {
        const response = await this.getSearchV3DepthResultRaw(requestParameters);
        return await response.value();
    }

    /**
     * get searches with similar candidates sourcing
     */
    async getSimilarCandidatesSearchesRaw(requestParameters: ApiApiGetSimilarCandidatesSearchesRequest): Promise<runtime.ApiResponse<Array<SearchV3>>> {
        const queryParameters: any = {};

        if (requestParameters.job !== undefined) {
            queryParameters['job'] = requestParameters.job;
        }

        if (requestParameters.searchTypeList !== undefined) {
            queryParameters['search_type__list'] = requestParameters.searchTypeList;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/searches/get_searches_with_similar_candidates_sourcing`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SearchV3FromJSON));
    }

    /**
     * get searches with similar candidates sourcing
     */
    async getSimilarCandidatesSearches(requestParameters: ApiApiGetSimilarCandidatesSearchesRequest): Promise<Array<SearchV3>> {
        const response = await this.getSimilarCandidatesSearchesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get transcript for candidate interview
     */
    async getTranscriptForInterviewRaw(requestParameters: ApiApiGetTranscriptForInterviewRequest): Promise<runtime.ApiResponse<InterviewTranscriptResponse>> {
        if (requestParameters.candidateInterviewId === null || requestParameters.candidateInterviewId === undefined) {
            throw new runtime.RequiredError('candidateInterviewId','Required parameter requestParameters.candidateInterviewId was null or undefined when calling getTranscriptForInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/interviewer/get_transcript_for_interview/{candidate_interview_id}`.replace(`{${"candidate_interview_id"}}`, encodeURIComponent(String(requestParameters.candidateInterviewId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewTranscriptResponseFromJSON(jsonValue));
    }

    /**
     * Get transcript for candidate interview
     */
    async getTranscriptForInterview(requestParameters: ApiApiGetTranscriptForInterviewRequest): Promise<InterviewTranscriptResponse> {
        const response = await this.getTranscriptForInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieves information about the requesting user
     */
    async getUserInfoRaw(requestParameters: ApiApiGetUserInfoRequest): Promise<runtime.ApiResponse<DoverUser>> {
        const queryParameters: any = {};

        if (requestParameters.isHiringManager !== undefined) {
            queryParameters['is_hiring_manager'] = requestParameters.isHiringManager;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.checkEmailVerification !== undefined) {
            queryParameters['check_email_verification'] = requestParameters.checkEmailVerification;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/get_user_info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DoverUserFromJSON(jsonValue));
    }

    /**
     * retrieves information about the requesting user
     */
    async getUserInfo(requestParameters: ApiApiGetUserInfoRequest): Promise<DoverUser> {
        const response = await this.getUserInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * get the interviewer scheduling info for the current user
     */
    async getUserInterviewerSchedulingInfoRaw(requestParameters: ApiApiGetUserInterviewerSchedulingInfoRequest): Promise<runtime.ApiResponse<InterviewerSchedulingInfo>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/interviewer-scheduling-info/get_for_user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewerSchedulingInfoFromJSON(jsonValue));
    }

    /**
     * get the interviewer scheduling info for the current user
     */
    async getUserInterviewerSchedulingInfo(requestParameters: ApiApiGetUserInterviewerSchedulingInfoRequest): Promise<InterviewerSchedulingInfo> {
        const response = await this.getUserInterviewerSchedulingInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user ratings by the entity type and uuid
     */
    async getUserRatingsByEntityRaw(requestParameters: ApiApiGetUserRatingsByEntityRequest): Promise<runtime.ApiResponse<Array<UserRating>>> {
        if (requestParameters.entityType === null || requestParameters.entityType === undefined) {
            throw new runtime.RequiredError('entityType','Required parameter requestParameters.entityType was null or undefined when calling getUserRatingsByEntity.');
        }

        if (requestParameters.entityUuid === null || requestParameters.entityUuid === undefined) {
            throw new runtime.RequiredError('entityUuid','Required parameter requestParameters.entityUuid was null or undefined when calling getUserRatingsByEntity.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/reporting/user-rating/list/{entity_type}/{entity_uuid}`.replace(`{${"entity_type"}}`, encodeURIComponent(String(requestParameters.entityType))).replace(`{${"entity_uuid"}}`, encodeURIComponent(String(requestParameters.entityUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRatingFromJSON));
    }

    /**
     * get user ratings by the entity type and uuid
     */
    async getUserRatingsByEntity(requestParameters: ApiApiGetUserRatingsByEntityRequest): Promise<Array<UserRating>> {
        const response = await this.getUserRatingsByEntityRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieves roles and permissions information about the requesting user
     */
    async getUserRolesAndPermissionsRaw(requestParameters: ApiApiGetUserRolesAndPermissionsRequest): Promise<runtime.ApiResponse<UserRolesAndPermissions>> {
        const queryParameters: any = {};

        if (requestParameters.isHiringManager !== undefined) {
            queryParameters['is_hiring_manager'] = requestParameters.isHiringManager;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/roles_and_permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserRolesAndPermissionsFromJSON(jsonValue));
    }

    /**
     * retrieves roles and permissions information about the requesting user
     */
    async getUserRolesAndPermissions(requestParameters: ApiApiGetUserRolesAndPermissionsRequest): Promise<UserRolesAndPermissions> {
        const response = await this.getUserRolesAndPermissionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a information about file you\'ve uploaded
     */
    async getUserUploadedFileRaw(requestParameters: ApiApiGetUserUploadedFileRequest): Promise<runtime.ApiResponse<UserUploadedFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserUploadedFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/files/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserUploadedFileFromJSON(jsonValue));
    }

    /**
     * retrieve a information about file you\'ve uploaded
     */
    async getUserUploadedFile(requestParameters: ApiApiGetUserUploadedFileRequest): Promise<UserUploadedFile> {
        const response = await this.getUserUploadedFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Return the client for the requesting user
     */
    async getUsersClientRaw(requestParameters: ApiApiGetUsersClientRequest): Promise<runtime.ApiResponse<Client>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/clients/get_requesting_users_client`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientFromJSON(jsonValue));
    }

    /**
     * Return the client for the requesting user
     */
    async getUsersClient(requestParameters: ApiApiGetUsersClientRequest): Promise<Client> {
        const response = await this.getUsersClientRaw(requestParameters);
        return await response.value();
    }

    /**
     * Given a user return their onboarding flow if they have one
     */
    async hasSeenOnboardingFlowRaw(requestParameters: ApiApiHasSeenOnboardingFlowRequest): Promise<runtime.ApiResponse<HasSeenOnboardingFlow>> {
        const queryParameters: any = {};

        if (requestParameters.flowName !== undefined) {
            queryParameters['flow_name'] = requestParameters.flowName;
        }

        if (requestParameters.proUserUserEmail !== undefined) {
            queryParameters['pro_user__user__email'] = requestParameters.proUserUserEmail;
        }

        if (requestParameters.relatedObjectUuid !== undefined) {
            queryParameters['related_object_uuid'] = requestParameters.relatedObjectUuid;
        }

        if (requestParameters.contentTypeModel !== undefined) {
            queryParameters['content_type__model'] = requestParameters.contentTypeModel;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/onboarding-user-onboarding-flow/has_seen_onboarding_flow`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HasSeenOnboardingFlowFromJSON(jsonValue));
    }

    /**
     * Given a user return their onboarding flow if they have one
     */
    async hasSeenOnboardingFlow(requestParameters: ApiApiHasSeenOnboardingFlowRequest): Promise<HasSeenOnboardingFlow> {
        const response = await this.hasSeenOnboardingFlowRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send test email
     */
    async hiringStageEmailTemplateSendTestEmailRaw(requestParameters: ApiApiHiringStageEmailTemplateSendTestEmailOperationRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling hiringStageEmailTemplateSendTestEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/scheduling/send-test-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HiringStageEmailTemplateSendTestEmailRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Send test email
     */
    async hiringStageEmailTemplateSendTestEmail(requestParameters: ApiApiHiringStageEmailTemplateSendTestEmailOperationRequest): Promise<object> {
        const response = await this.hiringStageEmailTemplateSendTestEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * Check that slackbot auth is set up correctly
     */
    async jobCheckSlackbotAuthRaw(requestParameters: ApiApiJobCheckSlackbotAuthRequest): Promise<runtime.ApiResponse<InlineResponse20050>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling jobCheckSlackbotAuth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/check_slackbot_auth`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20050FromJSON(jsonValue));
    }

    /**
     * Check that slackbot auth is set up correctly
     */
    async jobCheckSlackbotAuth(requestParameters: ApiApiJobCheckSlackbotAuthRequest): Promise<InlineResponse20050> {
        const response = await this.jobCheckSlackbotAuthRaw(requestParameters);
        return await response.value();
    }

    /**
     * list application questions
     */
    async listApplicationQuestionsRaw(requestParameters: ApiApiListApplicationQuestionsRequest): Promise<runtime.ApiResponse<InlineResponse20040>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.job !== undefined) {
            queryParameters['job'] = requestParameters.job;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/inbound/application-question`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20040FromJSON(jsonValue));
    }

    /**
     * list application questions
     */
    async listApplicationQuestions(requestParameters: ApiApiListApplicationQuestionsRequest): Promise<InlineResponse20040> {
        const response = await this.listApplicationQuestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list scored saap review applications via AI
     */
    async listApplicationsViaAIRaw(requestParameters: ApiApiListApplicationsViaAIRequest): Promise<runtime.ApiResponse<ListAppsViaAIResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling listApplicationsViaAI.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/saapplication-review/list_applications_via_ai`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListAppsViaAIRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAppsViaAIResponseFromJSON(jsonValue));
    }

    /**
     * list scored saap review applications via AI
     */
    async listApplicationsViaAI(requestParameters: ApiApiListApplicationsViaAIRequest): Promise<ListAppsViaAIResponse> {
        const response = await this.listApplicationsViaAIRaw(requestParameters);
        return await response.value();
    }

    /**
     * list archive reasons
     */
    async listArchiveReasonsRaw(requestParameters: ApiApiListArchiveReasonsRequest): Promise<runtime.ApiResponse<ListArchiveReasonsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/archive-reasons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListArchiveReasonsResponseFromJSON(jsonValue));
    }

    /**
     * list archive reasons
     */
    async listArchiveReasons(requestParameters: ApiApiListArchiveReasonsRequest): Promise<ListArchiveReasonsResponse> {
        const response = await this.listArchiveReasonsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list ats sources
     */
    async listAtsSourcesRaw(requestParameters: ApiApiListAtsSourcesRequest): Promise<runtime.ApiResponse<InlineResponse20012>> {
        const queryParameters: any = {};

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.atsType !== undefined) {
            queryParameters['ats_type'] = requestParameters.atsType;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/ats-source`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20012FromJSON(jsonValue));
    }

    /**
     * list ats sources
     */
    async listAtsSources(requestParameters: ApiApiListAtsSourcesRequest): Promise<InlineResponse20012> {
        const response = await this.listAtsSourcesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list all users for a client
     */
    async listAuthenticatedUsersRaw(requestParameters: ApiApiListAuthenticatedUsersRequest): Promise<runtime.ApiResponse<Array<AuthedUser>>> {
        const queryParameters: any = {};

        if (requestParameters.isHiringManager !== undefined) {
            queryParameters['is_hiring_manager'] = requestParameters.isHiringManager;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/list_authenticated_users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuthedUserFromJSON));
    }

    /**
     * list all users for a client
     */
    async listAuthenticatedUsers(requestParameters: ApiApiListAuthenticatedUsersRequest): Promise<Array<AuthedUser>> {
        const response = await this.listAuthenticatedUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * list all EmailSenderAliases that a user has permissions for
     */
    async listAuthorizedEmailAliasesRaw(): Promise<runtime.ApiResponse<Array<AuthorizedEmailSenderAlias>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/list-authorized-email-aliases`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuthorizedEmailSenderAliasFromJSON));
    }

    /**
     * list all EmailSenderAliases that a user has permissions for
     */
    async listAuthorizedEmailAliases(): Promise<Array<AuthorizedEmailSenderAlias>> {
        const response = await this.listAuthorizedEmailAliasesRaw();
        return await response.value();
    }

    /**
     * List keyword buckets children and aliases
     */
    async listBucketChildrenAndAliasesRaw(requestParameters: ApiApiListBucketChildrenAndAliasesRequest): Promise<runtime.ApiResponse<InlineResponse20063>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling listBucketChildrenAndAliases.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/profile-search-keywords/list_bucket_children_and_aliases`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject24ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20063FromJSON(jsonValue));
    }

    /**
     * List keyword buckets children and aliases
     */
    async listBucketChildrenAndAliases(requestParameters: ApiApiListBucketChildrenAndAliasesRequest): Promise<InlineResponse20063> {
        const response = await this.listBucketChildrenAndAliasesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list campaigns
     */
    async listCampaignsRaw(requestParameters: ApiApiListCampaignsRequest): Promise<runtime.ApiResponse<InlineResponse2006>> {
        const queryParameters: any = {};

        if (requestParameters.jobCampaignConfigJob !== undefined) {
            queryParameters['job_campaign_config__job'] = requestParameters.jobCampaignConfigJob;
        }

        if (requestParameters.jobCampaignConfigJobClient !== undefined) {
            queryParameters['job_campaign_config__job__client'] = requestParameters.jobCampaignConfigJobClient;
        }

        if (requestParameters.jobCampaignConfigJobPersona !== undefined) {
            queryParameters['job_campaign_config__job__persona'] = requestParameters.jobCampaignConfigJobPersona;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/admin-campaigns`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2006FromJSON(jsonValue));
    }

    /**
     * list campaigns
     */
    async listCampaigns(requestParameters: ApiApiListCampaignsRequest): Promise<InlineResponse2006> {
        const response = await this.listCampaignsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list candidate activity notes
     */
    async listCandidateActivityNotesRaw(requestParameters: ApiApiListCandidateActivityNotesRequest): Promise<runtime.ApiResponse<InlineResponse20015>> {
        const queryParameters: any = {};

        if (requestParameters.candidate !== undefined) {
            queryParameters['candidate'] = requestParameters.candidate;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate-note`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20015FromJSON(jsonValue));
    }

    /**
     * list candidate activity notes
     */
    async listCandidateActivityNotes(requestParameters: ApiApiListCandidateActivityNotesRequest): Promise<InlineResponse20015> {
        const response = await this.listCandidateActivityNotesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list candidate email events
     */
    async listCandidateEmailEventsV2Raw(requestParameters: ApiApiListCandidateEmailEventsV2Request): Promise<runtime.ApiResponse<Array<ActivityFeedItem>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listCandidateEmailEventsV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidates/activity-feed/{id}/email_events_v2`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActivityFeedItemFromJSON));
    }

    /**
     * list candidate email events
     */
    async listCandidateEmailEventsV2(requestParameters: ApiApiListCandidateEmailEventsV2Request): Promise<Array<ActivityFeedItem>> {
        const response = await this.listCandidateEmailEventsV2Raw(requestParameters);
        return await response.value();
    }

    /**
     * List Candidate Files
     */
    async listCandidateFilesRaw(requestParameters: ApiApiListCandidateFilesRequest): Promise<runtime.ApiResponse<InlineResponse20073>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.candidateId !== undefined) {
            queryParameters['candidate_id'] = requestParameters.candidateId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-files`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20073FromJSON(jsonValue));
    }

    /**
     * List Candidate Files
     */
    async listCandidateFiles(requestParameters: ApiApiListCandidateFilesRequest): Promise<InlineResponse20073> {
        const response = await this.listCandidateFilesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list candidate interview events
     */
    async listCandidateInterviewEventsRaw(requestParameters: ApiApiListCandidateInterviewEventsRequest): Promise<runtime.ApiResponse<Array<ActivityFeedItem>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listCandidateInterviewEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidates/activity-feed/{id}/interview_events`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActivityFeedItemFromJSON));
    }

    /**
     * list candidate interview events
     */
    async listCandidateInterviewEvents(requestParameters: ApiApiListCandidateInterviewEventsRequest): Promise<Array<ActivityFeedItem>> {
        const response = await this.listCandidateInterviewEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list moved job events
     */
    async listCandidateMovedJobEventsRaw(requestParameters: ApiApiListCandidateMovedJobEventsRequest): Promise<runtime.ApiResponse<Array<ActivityFeedItem>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listCandidateMovedJobEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidates/activity-feed/{id}/moved_job_events`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActivityFeedItemFromJSON));
    }

    /**
     * list moved job events
     */
    async listCandidateMovedJobEvents(requestParameters: ApiApiListCandidateMovedJobEventsRequest): Promise<Array<ActivityFeedItem>> {
        const response = await this.listCandidateMovedJobEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list candidate multipart interview
     */
    async listCandidateMultipartInterviewRaw(requestParameters: ApiApiListCandidateMultipartInterviewRequest): Promise<runtime.ApiResponse<InlineResponse20014>> {
        if (requestParameters.candidateId === null || requestParameters.candidateId === undefined) {
            throw new runtime.RequiredError('candidateId','Required parameter requestParameters.candidateId was null or undefined when calling listCandidateMultipartInterview.');
        }

        if (requestParameters.interviewStageId === null || requestParameters.interviewStageId === undefined) {
            throw new runtime.RequiredError('interviewStageId','Required parameter requestParameters.interviewStageId was null or undefined when calling listCandidateMultipartInterview.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.candidateId !== undefined) {
            queryParameters['candidate_id'] = requestParameters.candidateId;
        }

        if (requestParameters.interviewStageId !== undefined) {
            queryParameters['interview_stage_id'] = requestParameters.interviewStageId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate-multipart-interview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20014FromJSON(jsonValue));
    }

    /**
     * list candidate multipart interview
     */
    async listCandidateMultipartInterview(requestParameters: ApiApiListCandidateMultipartInterviewRequest): Promise<InlineResponse20014> {
        const response = await this.listCandidateMultipartInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * list candidate sources
     */
    async listCandidateSourcesRaw(requestParameters: ApiApiListCandidateSourcesRequest): Promise<runtime.ApiResponse<InlineResponse20016>> {
        const queryParameters: any = {};

        if (requestParameters.thirdPartyAllowed !== undefined) {
            queryParameters['third_party_allowed'] = requestParameters.thirdPartyAllowed;
        }

        if (requestParameters.includeAdminPreview !== undefined) {
            queryParameters['include_admin_preview'] = requestParameters.includeAdminPreview;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.jobId !== undefined) {
            queryParameters['job_id'] = requestParameters.jobId;
        }

        if (requestParameters.includeYcWaas !== undefined) {
            queryParameters['include_yc_waas'] = requestParameters.includeYcWaas;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate-sources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20016FromJSON(jsonValue));
    }

    /**
     * list candidate sources
     */
    async listCandidateSources(requestParameters: ApiApiListCandidateSourcesRequest): Promise<InlineResponse20016> {
        const response = await this.listCandidateSourcesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list stage change events
     */
    async listCandidateStageChangeEventsRaw(requestParameters: ApiApiListCandidateStageChangeEventsRequest): Promise<runtime.ApiResponse<Array<ActivityFeedItem>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listCandidateStageChangeEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidates/activity-feed/{id}/stage_change_events`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActivityFeedItemFromJSON));
    }

    /**
     * list stage change events
     */
    async listCandidateStageChangeEvents(requestParameters: ApiApiListCandidateStageChangeEventsRequest): Promise<Array<ActivityFeedItem>> {
        const response = await this.listCandidateStageChangeEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list client domains
     */
    async listClientDomainsRaw(requestParameters: ApiApiListClientDomainsRequest): Promise<runtime.ApiResponse<InlineResponse20018>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-domains`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20018FromJSON(jsonValue));
    }

    /**
     * list client domains
     */
    async listClientDomains(requestParameters: ApiApiListClientDomainsRequest): Promise<InlineResponse20018> {
        const response = await this.listClientDomainsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list given client\'s email templates
     */
    async listClientEmailTemplatesRaw(requestParameters: ApiApiListClientEmailTemplatesRequest): Promise<runtime.ApiResponse<InlineResponse20019>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-email-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20019FromJSON(jsonValue));
    }

    /**
     * list given client\'s email templates
     */
    async listClientEmailTemplates(requestParameters: ApiApiListClientEmailTemplatesRequest): Promise<InlineResponse20019> {
        const response = await this.listClientEmailTemplatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list CompanyLists
     */
    async listCompanyListsRaw(requestParameters: ApiApiListCompanyListsRequest): Promise<runtime.ApiResponse<InlineResponse20023>> {
        const queryParameters: any = {};

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/company-lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20023FromJSON(jsonValue));
    }

    /**
     * list CompanyLists
     */
    async listCompanyLists(requestParameters: ApiApiListCompanyListsRequest): Promise<InlineResponse20023> {
        const response = await this.listCompanyListsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list CompanySizes
     */
    async listCompanySizesRaw(): Promise<runtime.ApiResponse<Array<ListCompanySizeSerializer>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/company-sizes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListCompanySizeSerializerFromJSON));
    }

    /**
     * list CompanySizes
     */
    async listCompanySizes(): Promise<Array<ListCompanySizeSerializer>> {
        const response = await this.listCompanySizesRaw();
        return await response.value();
    }

    /**
     * list all emails currently scoped to the given contact
     */
    async listContactEmailsRaw(requestParameters: ApiApiListContactEmailsRequest): Promise<runtime.ApiResponse<InlineResponse20024>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling listContactEmails.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.contactId !== undefined) {
            queryParameters['contact_id'] = requestParameters.contactId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contact-emails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20024FromJSON(jsonValue));
    }

    /**
     * list all emails currently scoped to the given contact
     */
    async listContactEmails(requestParameters: ApiApiListContactEmailsRequest): Promise<InlineResponse20024> {
        const response = await this.listContactEmailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list all phone numbers currently scoped to the given contact
     */
    async listContactPhoneNumbersRaw(requestParameters: ApiApiListContactPhoneNumbersRequest): Promise<runtime.ApiResponse<InlineResponse20025>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling listContactPhoneNumbers.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.contactId !== undefined) {
            queryParameters['contact_id'] = requestParameters.contactId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contact-phone-numbers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20025FromJSON(jsonValue));
    }

    /**
     * list all phone numbers currently scoped to the given contact
     */
    async listContactPhoneNumbers(requestParameters: ApiApiListContactPhoneNumbersRequest): Promise<InlineResponse20025> {
        const response = await this.listContactPhoneNumbersRaw(requestParameters);
        return await response.value();
    }

    /**
     * list Countries
     */
    async listCountriesRaw(): Promise<runtime.ApiResponse<Array<Country>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CountryFromJSON));
    }

    /**
     * list Countries
     */
    async listCountries(): Promise<Array<Country>> {
        const response = await this.listCountriesRaw();
        return await response.value();
    }

    /**
     * Lists the currency options for compensation
     */
    async listCurrencyOptionsRaw(requestParameters: ApiApiListCurrencyOptionsRequest): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-setups/list-currency-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Lists the currency options for compensation
     */
    async listCurrencyOptions(requestParameters: ApiApiListCurrencyOptionsRequest): Promise<Array<string>> {
        const response = await this.listCurrencyOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list diversity options
     */
    async listDiversityOptionsRaw(): Promise<runtime.ApiResponse<Array<DiversityOption>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/diversity-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiversityOptionFromJSON));
    }

    /**
     * list diversity options
     */
    async listDiversityOptions(): Promise<Array<DiversityOption>> {
        const response = await this.listDiversityOptionsRaw();
        return await response.value();
    }

    /**
     * list dover entities
     */
    async listDoverEntitiesRaw(requestParameters: ApiApiListDoverEntitiesRequest): Promise<runtime.ApiResponse<InlineResponse20026>> {
        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/dover-entity`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20026FromJSON(jsonValue));
    }

    /**
     * list dover entities
     */
    async listDoverEntities(requestParameters: ApiApiListDoverEntitiesRequest): Promise<InlineResponse20026> {
        const response = await this.listDoverEntitiesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list dover interviewer previews
     */
    async listDoverInterviewerPreviewsRaw(requestParameters: ApiApiListDoverInterviewerPreviewsRequest): Promise<runtime.ApiResponse<InlineResponse20027>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/dover-interviewer-preview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20027FromJSON(jsonValue));
    }

    /**
     * list dover interviewer previews
     */
    async listDoverInterviewerPreviews(requestParameters: ApiApiListDoverInterviewerPreviewsRequest): Promise<InlineResponse20027> {
        const response = await this.listDoverInterviewerPreviewsRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrive dover outbound persona stats list
     */
    async listDoverOutboundPersonaStatsRaw(requestParameters: ApiApiListDoverOutboundPersonaStatsRequest): Promise<runtime.ApiResponse<InlineResponse20028>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.job !== undefined) {
            queryParameters['job'] = requestParameters.job;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/dover-outbound-persona-stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20028FromJSON(jsonValue));
    }

    /**
     * retrive dover outbound persona stats list
     */
    async listDoverOutboundPersonaStats(requestParameters: ApiApiListDoverOutboundPersonaStatsRequest): Promise<InlineResponse20028> {
        const response = await this.listDoverOutboundPersonaStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * In-app route to power list of publishable jobs in editable careers page
     */
    async listEditCareersPageJobsRaw(requestParameters: ApiApiListEditCareersPageJobsRequest): Promise<runtime.ApiResponse<InlineResponse20017>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling listEditCareersPageJobs.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/careers-page/list_edit_careers_page_jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20017FromJSON(jsonValue));
    }

    /**
     * In-app route to power list of publishable jobs in editable careers page
     */
    async listEditCareersPageJobs(requestParameters: ApiApiListEditCareersPageJobsRequest): Promise<InlineResponse20017> {
        const response = await this.listEditCareersPageJobsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list education levels
     */
    async listEducationLevelsRaw(): Promise<runtime.ApiResponse<Array<EducationLevelsResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/education-levels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EducationLevelsResponseFromJSON));
    }

    /**
     * list education levels
     */
    async listEducationLevels(): Promise<Array<EducationLevelsResponse>> {
        const response = await this.listEducationLevelsRaw();
        return await response.value();
    }

    /**
     * list client email sender alias
     */
    async listEmailSenderAliasesRaw(requestParameters: ApiApiListEmailSenderAliasesRequest): Promise<runtime.ApiResponse<InlineResponse20029>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/email-aliases`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20029FromJSON(jsonValue));
    }

    /**
     * list client email sender alias
     */
    async listEmailSenderAliases(requestParameters: ApiApiListEmailSenderAliasesRequest): Promise<InlineResponse20029> {
        const response = await this.listEmailSenderAliasesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all feedback forms for a candidate for the interview specifified
     */
    async listFeedbackFormsForInterviewRaw(requestParameters: ApiApiListFeedbackFormsForInterviewRequest): Promise<runtime.ApiResponse<Array<SlimInterviewRubricResponse>>> {
        if (requestParameters.interviewId === null || requestParameters.interviewId === undefined) {
            throw new runtime.RequiredError('interviewId','Required parameter requestParameters.interviewId was null or undefined when calling listFeedbackFormsForInterview.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.interviewId !== undefined) {
            queryParameters['interview_id'] = requestParameters.interviewId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-interview-rubric-response/list_feedback_forms_for_interview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SlimInterviewRubricResponseFromJSON));
    }

    /**
     * Get all feedback forms for a candidate for the interview specifified
     */
    async listFeedbackFormsForInterview(requestParameters: ApiApiListFeedbackFormsForInterviewRequest): Promise<Array<SlimInterviewRubricResponse>> {
        const response = await this.listFeedbackFormsForInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * list feedback templates
     */
    async listFeedbackTemplatesRaw(requestParameters: ApiApiListFeedbackTemplatesRequest): Promise<runtime.ApiResponse<FeedbackTemplateListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.matchExact !== undefined) {
            queryParameters['match_exact'] = requestParameters.matchExact;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/feedback-template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedbackTemplateListResponseFromJSON(jsonValue));
    }

    /**
     * list feedback templates
     */
    async listFeedbackTemplates(requestParameters: ApiApiListFeedbackTemplatesRequest): Promise<FeedbackTemplateListResponse> {
        const response = await this.listFeedbackTemplatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list fields of study
     */
    async listFieldsOfStudyRaw(requestParameters: ApiApiListFieldsOfStudyRequest): Promise<runtime.ApiResponse<InlineResponse20033>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/fields-of-study`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20033FromJSON(jsonValue));
    }

    /**
     * list fields of study
     */
    async listFieldsOfStudy(requestParameters: ApiApiListFieldsOfStudyRequest): Promise<InlineResponse20033> {
        const response = await this.listFieldsOfStudyRaw(requestParameters);
        return await response.value();
    }

    /**
     * list generic email response templates
     */
    async listGenericEmailResponseTemplatesRaw(requestParameters: ApiApiListGenericEmailResponseTemplatesRequest): Promise<runtime.ApiResponse<InlineResponse20036>> {
        const queryParameters: any = {};

        if (requestParameters.templateType !== undefined) {
            queryParameters['template_type'] = requestParameters.templateType;
        }

        if (requestParameters.jobId !== undefined) {
            queryParameters['job_id'] = requestParameters.jobId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/generic-email-response-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20036FromJSON(jsonValue));
    }

    /**
     * list generic email response templates
     */
    async listGenericEmailResponseTemplates(requestParameters: ApiApiListGenericEmailResponseTemplatesRequest): Promise<InlineResponse20036> {
        const response = await this.listGenericEmailResponseTemplatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * List HiringPipelineStages
     */
    async listHiringPipelineStagesRaw(requestParameters: ApiApiListHiringPipelineStagesRequest): Promise<runtime.ApiResponse<HiringPipelineStageListResponse>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling listHiringPipelineStages.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{job_id}/interview-plan`.replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringPipelineStageListResponseFromJSON(jsonValue));
    }

    /**
     * List HiringPipelineStages
     */
    async listHiringPipelineStages(requestParameters: ApiApiListHiringPipelineStagesRequest): Promise<HiringPipelineStageListResponse> {
        const response = await this.listHiringPipelineStagesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list hiring stage email templates
     */
    async listHiringStageEmailTemplatesRaw(requestParameters: ApiApiListHiringStageEmailTemplatesRequest): Promise<runtime.ApiResponse<InlineResponse20038>> {
        const queryParameters: any = {};

        if (requestParameters.job !== undefined) {
            queryParameters['job'] = requestParameters.job;
        }

        if (requestParameters.templateTypeList !== undefined) {
            queryParameters['template_type__list'] = requestParameters.templateTypeList;
        }

        if (requestParameters.requiredEmailAlias !== undefined) {
            queryParameters['required_email_alias'] = requestParameters.requiredEmailAlias;
        }

        if (requestParameters.requiredInterviewer !== undefined) {
            queryParameters['required_interviewer'] = requestParameters.requiredInterviewer;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-stage-email-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20038FromJSON(jsonValue));
    }

    /**
     * list hiring stage email templates
     */
    async listHiringStageEmailTemplates(requestParameters: ApiApiListHiringStageEmailTemplatesRequest): Promise<InlineResponse20038> {
        const response = await this.listHiringStageEmailTemplatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list industries
     */
    async listIndustriesRaw(requestParameters: ApiApiListIndustriesRequest): Promise<runtime.ApiResponse<InlineResponse20043>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/industry`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20043FromJSON(jsonValue));
    }

    /**
     * list industries
     */
    async listIndustries(requestParameters: ApiApiListIndustriesRequest): Promise<InlineResponse20043> {
        const response = await this.listIndustriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * List industries children and aliases
     */
    async listIndustriesChildrenAndAliasesRaw(requestParameters: ApiApiListIndustriesChildrenAndAliasesRequest): Promise<runtime.ApiResponse<InlineResponse20042>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling listIndustriesChildrenAndAliases.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/industries/list_industries_children_and_aliases`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject20ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20042FromJSON(jsonValue));
    }

    /**
     * List industries children and aliases
     */
    async listIndustriesChildrenAndAliases(requestParameters: ApiApiListIndustriesChildrenAndAliasesRequest): Promise<InlineResponse20042> {
        const response = await this.listIndustriesChildrenAndAliasesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list interview rubric templates
     */
    async listInterviewRubricTemplatesForPersonaRaw(requestParameters: ApiApiListInterviewRubricTemplatesForPersonaRequest): Promise<runtime.ApiResponse<InlineResponse20045>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.personaId !== undefined) {
            queryParameters['persona_id'] = requestParameters.personaId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/interview-rubric-template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20045FromJSON(jsonValue));
    }

    /**
     * list interview rubric templates
     */
    async listInterviewRubricTemplatesForPersona(requestParameters: ApiApiListInterviewRubricTemplatesForPersonaRequest): Promise<InlineResponse20045> {
        const response = await this.listInterviewRubricTemplatesForPersonaRaw(requestParameters);
        return await response.value();
    }

    /**
     * list interviewer
     */
    async listInterviewersRaw(requestParameters: ApiApiListInterviewersRequest): Promise<runtime.ApiResponse<InlineResponse20021>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.hiringPipelineStageId !== undefined) {
            queryParameters['hiring_pipeline_stage_id'] = requestParameters.hiringPipelineStageId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-interviewers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20021FromJSON(jsonValue));
    }

    /**
     * list interviewer
     */
    async listInterviewers(requestParameters: ApiApiListInterviewersRequest): Promise<InlineResponse20021> {
        const response = await this.listInterviewersRaw(requestParameters);
        return await response.value();
    }

    /**
     * list job ats source settings
     */
    async listJobAtsSourceSettingsRaw(requestParameters: ApiApiListJobAtsSourceSettingsRequest): Promise<runtime.ApiResponse<InlineResponse20046>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-ats-source-setting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20046FromJSON(jsonValue));
    }

    /**
     * list job ats source settings
     */
    async listJobAtsSourceSettings(requestParameters: ApiApiListJobAtsSourceSettingsRequest): Promise<InlineResponse20046> {
        const response = await this.listJobAtsSourceSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list job candidate source settings
     */
    async listJobCandidateSourceSettingsRaw(requestParameters: ApiApiListJobCandidateSourceSettingsRequest): Promise<runtime.ApiResponse<InlineResponse20047>> {
        const queryParameters: any = {};

        if (requestParameters.job !== undefined) {
            queryParameters['job'] = requestParameters.job;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.desiredState !== undefined) {
            queryParameters['desired_state'] = requestParameters.desiredState;
        }

        if (requestParameters.includeAdminPreview !== undefined) {
            queryParameters['include_admin_preview'] = requestParameters.includeAdminPreview;
        }

        if (requestParameters.coreSource !== undefined) {
            queryParameters['core_source'] = requestParameters.coreSource;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.includeYcWaas !== undefined) {
            queryParameters['include_yc_waas'] = requestParameters.includeYcWaas;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-candidate-source-settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20047FromJSON(jsonValue));
    }

    /**
     * list job candidate source settings
     */
    async listJobCandidateSourceSettings(requestParameters: ApiApiListJobCandidateSourceSettingsRequest): Promise<InlineResponse20047> {
        const response = await this.listJobCandidateSourceSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list available job personas
     */
    async listJobPersonasRaw(requestParameters: ApiApiListJobPersonasRequest): Promise<runtime.ApiResponse<InlineResponse20055>> {
        const queryParameters: any = {};

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/onboarding-job-personas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20055FromJSON(jsonValue));
    }

    /**
     * list available job personas
     */
    async listJobPersonas(requestParameters: ApiApiListJobPersonasRequest): Promise<InlineResponse20055> {
        const response = await this.listJobPersonasRaw(requestParameters);
        return await response.value();
    }

    /**
     * list job position
     */
    async listJobPositionsRaw(requestParameters: ApiApiListJobPositionsRequest): Promise<runtime.ApiResponse<InlineResponse20037>> {
        const queryParameters: any = {};

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-plan-job-positions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20037FromJSON(jsonValue));
    }

    /**
     * list job position
     */
    async listJobPositions(requestParameters: ApiApiListJobPositionsRequest): Promise<InlineResponse20037> {
        const response = await this.listJobPositionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list job referrals
     */
    async listJobReferralsRaw(requestParameters: ApiApiListJobReferralsRequest): Promise<runtime.ApiResponse<InlineResponse20048>> {
        const queryParameters: any = {};

        if (requestParameters.job !== undefined) {
            queryParameters['job'] = requestParameters.job;
        }

        if (requestParameters.referrer !== undefined) {
            queryParameters['referrer'] = requestParameters.referrer;
        }

        if (requestParameters.triagingStatus !== undefined) {
            queryParameters['triaging_status'] = requestParameters.triagingStatus;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-referrals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20048FromJSON(jsonValue));
    }

    /**
     * list job referrals
     */
    async listJobReferrals(requestParameters: ApiApiListJobReferralsRequest): Promise<InlineResponse20048> {
        const response = await this.listJobReferralsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list jobs
     */
    async listJobsRaw(requestParameters: ApiApiListJobsRequest): Promise<runtime.ApiResponse<InlineResponse20049>> {
        const queryParameters: any = {};

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.clientReviewType !== undefined) {
            queryParameters['client_review_type'] = requestParameters.clientReviewType;
        }

        if (requestParameters.hasActiveCampaign !== undefined) {
            queryParameters['has_active_campaign'] = requestParameters.hasActiveCampaign;
        }

        if (requestParameters.myJobs !== undefined) {
            queryParameters['my_jobs'] = requestParameters.myJobs;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20049FromJSON(jsonValue));
    }

    /**
     * list jobs
     */
    async listJobs(requestParameters: ApiApiListJobsRequest): Promise<InlineResponse20049> {
        const response = await this.listJobsRaw(requestParameters);
        return await response.value();
    }

    /**
     * gets the setup steps and their state for all jobs for a given client
     */
    async listJobsWithSetupStepsRaw(requestParameters: ApiApiListJobsWithSetupStepsRequest): Promise<runtime.ApiResponse<Array<JobSetupSteps>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-setups/list-setup-steps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobSetupStepsFromJSON));
    }

    /**
     * gets the setup steps and their state for all jobs for a given client
     */
    async listJobsWithSetupSteps(requestParameters: ApiApiListJobsWithSetupStepsRequest): Promise<Array<JobSetupSteps>> {
        const response = await this.listJobsWithSetupStepsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list keywords
     */
    async listKeywordsRaw(requestParameters: ApiApiListKeywordsRequest): Promise<runtime.ApiResponse<InlineResponse20051>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.customerFacing !== undefined) {
            queryParameters['customer_facing'] = requestParameters.customerFacing;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/keywords`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20051FromJSON(jsonValue));
    }

    /**
     * list keywords
     */
    async listKeywords(requestParameters: ApiApiListKeywordsRequest): Promise<InlineResponse20051> {
        const response = await this.listKeywordsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists the remote and in office location options for a job
     */
    async listLocationOptionsRaw(requestParameters: ApiApiListLocationOptionsRequest): Promise<runtime.ApiResponse<ListLocationsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-setups/list-location-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListLocationsResponseFromJSON(jsonValue));
    }

    /**
     * Lists the remote and in office location options for a job
     */
    async listLocationOptions(requestParameters: ApiApiListLocationOptionsRequest): Promise<ListLocationsResponse> {
        const response = await this.listLocationOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list locations
     */
    async listLocationsRaw(): Promise<runtime.ApiResponse<Array<ProfileSearchLocationSerializer>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/locations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProfileSearchLocationSerializerFromJSON));
    }

    /**
     * list locations
     */
    async listLocations(): Promise<Array<ProfileSearchLocationSerializer>> {
        const response = await this.listLocationsRaw();
        return await response.value();
    }

    /**
     * list mentions for a given user
     */
    async listMentionsForUserRaw(requestParameters: ApiApiListMentionsForUserRequest): Promise<runtime.ApiResponse<Array<Mention>>> {
        const queryParameters: any = {};

        if (requestParameters.created !== undefined) {
            queryParameters['created'] = requestParameters.created;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.markedAsRead !== undefined) {
            queryParameters['marked_as_read'] = requestParameters.markedAsRead;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/mentions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MentionFromJSON));
    }

    /**
     * list mentions for a given user
     */
    async listMentionsForUser(requestParameters: ApiApiListMentionsForUserRequest): Promise<Array<Mention>> {
        const response = await this.listMentionsForUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * list notification config
     */
    async listNotificationConfigRaw(requestParameters: ApiApiListNotificationConfigRequest): Promise<runtime.ApiResponse<InlineResponse20053>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/notification-config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20053FromJSON(jsonValue));
    }

    /**
     * list notification config
     */
    async listNotificationConfig(requestParameters: ApiApiListNotificationConfigRequest): Promise<InlineResponse20053> {
        const response = await this.listNotificationConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     * list hiring pipeline stages
     */
    async listOnboardingHiringPipelineStagesRaw(requestParameters: ApiApiListOnboardingHiringPipelineStagesRequest): Promise<runtime.ApiResponse<InlineResponse20054>> {
        const queryParameters: any = {};

        if (requestParameters.job !== undefined) {
            queryParameters['job'] = requestParameters.job;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/onboarding-hiring-pipeline-stages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20054FromJSON(jsonValue));
    }

    /**
     * list hiring pipeline stages
     */
    async listOnboardingHiringPipelineStages(requestParameters: ApiApiListOnboardingHiringPipelineStagesRequest): Promise<InlineResponse20054> {
        const response = await this.listOnboardingHiringPipelineStagesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listOnboardingTitlePatternsRaw(): Promise<runtime.ApiResponse<Array<JobSetupTitlePattern>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/list-onboarding-title-patterns`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobSetupTitlePatternFromJSON));
    }

    /**
     */
    async listOnboardingTitlePatterns(): Promise<Array<JobSetupTitlePattern>> {
        const response = await this.listOnboardingTitlePatternsRaw();
        return await response.value();
    }

    /**
     * Given a persona, list the other searches using this persona (pertaining to the requesting client)
     */
    async listOtherSearchesUsingPersonaRaw(requestParameters: ApiApiListOtherSearchesUsingPersonaRequest): Promise<runtime.ApiResponse<OtherSearchesUsingPersonaResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling listOtherSearchesUsingPersona.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/personas/list_other_searches_using_persona`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OtherSearchesUsingPersonaRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OtherSearchesUsingPersonaResponseFromJSON(jsonValue));
    }

    /**
     * Given a persona, list the other searches using this persona (pertaining to the requesting client)
     */
    async listOtherSearchesUsingPersona(requestParameters: ApiApiListOtherSearchesUsingPersonaRequest): Promise<OtherSearchesUsingPersonaResponse> {
        const response = await this.listOtherSearchesUsingPersonaRaw(requestParameters);
        return await response.value();
    }

    /**
     * list Personas
     */
    async listPersonasRaw(requestParameters: ApiApiListPersonasRequest): Promise<runtime.ApiResponse<InlineResponse20058>> {
        const queryParameters: any = {};

        if (requestParameters.idList !== undefined) {
            queryParameters['id__list'] = requestParameters.idList;
        }

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/personas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20058FromJSON(jsonValue));
    }

    /**
     * list Personas
     */
    async listPersonas(requestParameters: ApiApiListPersonasRequest): Promise<InlineResponse20058> {
        const response = await this.listPersonasRaw(requestParameters);
        return await response.value();
    }

    /**
     * list candidates
     */
    async listPipelineCandidatesRaw(requestParameters: ApiApiListPipelineCandidatesRequest): Promise<runtime.ApiResponse<CandidatePipelineListResponse>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling listPipelineCandidates.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling listPipelineCandidates.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-pipeline/{job_id}/list`.replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CandidatePipelineListRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidatePipelineListResponseFromJSON(jsonValue));
    }

    /**
     * list candidates
     */
    async listPipelineCandidates(requestParameters: ApiApiListPipelineCandidatesRequest): Promise<CandidatePipelineListResponse> {
        const response = await this.listPipelineCandidatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list pro users
     */
    async listProUsersRaw(requestParameters: ApiApiListProUsersRequest): Promise<runtime.ApiResponse<InlineResponse20072>> {
        const queryParameters: any = {};

        if (requestParameters.isHiringManager !== undefined) {
            queryParameters['is_hiring_manager'] = requestParameters.isHiringManager;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20072FromJSON(jsonValue));
    }

    /**
     * list pro users
     */
    async listProUsers(requestParameters: ApiApiListProUsersRequest): Promise<InlineResponse20072> {
        const response = await this.listProUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * list pro users by client id
     */
    async listProUsersByClientIdRaw(requestParameters: ApiApiListProUsersByClientIdRequest): Promise<runtime.ApiResponse<InlineResponse20072>> {
        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users-by-client`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20072FromJSON(jsonValue));
    }

    /**
     * list pro users by client id
     */
    async listProUsersByClientId(requestParameters: ApiApiListProUsersByClientIdRequest): Promise<InlineResponse20072> {
        const response = await this.listProUsersByClientIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * list profile attributes
     */
    async listProfileAttributesRaw(): Promise<runtime.ApiResponse<Array<AdminProfileAttribute>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/list-profile-attributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminProfileAttributeFromJSON));
    }

    /**
     * list profile attributes
     */
    async listProfileAttributes(): Promise<Array<AdminProfileAttribute>> {
        const response = await this.listProfileAttributesRaw();
        return await response.value();
    }

    /**
     * list industries where customer_facing=True
     */
    async listProfileSearchIndustriesRaw(requestParameters: ApiApiListProfileSearchIndustriesRequest): Promise<runtime.ApiResponse<InlineResponse20041>> {
        const queryParameters: any = {};

        if (requestParameters.idList !== undefined) {
            queryParameters['id__list'] = requestParameters.idList;
        }

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/industries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20041FromJSON(jsonValue));
    }

    /**
     * list industries where customer_facing=True
     */
    async listProfileSearchIndustries(requestParameters: ApiApiListProfileSearchIndustriesRequest): Promise<InlineResponse20041> {
        const response = await this.listProfileSearchIndustriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list profile search jobs
     */
    async listProfileSearchJobsRaw(requestParameters: ApiApiListProfileSearchJobsRequest): Promise<runtime.ApiResponse<InlineResponse20061>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/profile-search-jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20061FromJSON(jsonValue));
    }

    /**
     * list profile search jobs
     */
    async listProfileSearchJobs(requestParameters: ApiApiListProfileSearchJobsRequest): Promise<InlineResponse20061> {
        const response = await this.listProfileSearchJobsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list Keywords where customer_facing=True
     */
    async listProfileSearchKeywordsRaw(requestParameters: ApiApiListProfileSearchKeywordsRequest): Promise<runtime.ApiResponse<InlineResponse20062>> {
        const queryParameters: any = {};

        if (requestParameters.idList !== undefined) {
            queryParameters['id__list'] = requestParameters.idList;
        }

        if (requestParameters.queryText !== undefined) {
            queryParameters['query_text'] = requestParameters.queryText;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/profile-search-keywords`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20062FromJSON(jsonValue));
    }

    /**
     * list Keywords where customer_facing=True
     */
    async listProfileSearchKeywords(requestParameters: ApiApiListProfileSearchKeywordsRequest): Promise<InlineResponse20062> {
        const response = await this.listProfileSearchKeywordsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list recruiting partners
     */
    async listRecruitingPartnersRaw(requestParameters: ApiApiListRecruitingPartnersRequest): Promise<runtime.ApiResponse<RecruitingPartnerListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/recruiting-partners`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RecruitingPartnerListResponseFromJSON(jsonValue));
    }

    /**
     * list recruiting partners
     */
    async listRecruitingPartners(requestParameters: ApiApiListRecruitingPartnersRequest): Promise<RecruitingPartnerListResponse> {
        const response = await this.listRecruitingPartnersRaw(requestParameters);
        return await response.value();
    }

    /**
     * list roles for dover perms
     */
    async listRolesRaw(requestParameters: ApiApiListRolesRequest): Promise<runtime.ApiResponse<InlineResponse20064>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20064FromJSON(jsonValue));
    }

    /**
     * list roles for dover perms
     */
    async listRoles(requestParameters: ApiApiListRolesRequest): Promise<InlineResponse20064> {
        const response = await this.listRolesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list scored saap review applications
     */
    async listSaapReviewApplicationsRaw(requestParameters: ApiApiListSaapReviewApplicationsRequest): Promise<runtime.ApiResponse<ListApplicationsReturn>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling listSaapReviewApplications.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/saapplication-review/list_applications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListSaapReviewApplicationRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListApplicationsReturnFromJSON(jsonValue));
    }

    /**
     * list scored saap review applications
     */
    async listSaapReviewApplications(requestParameters: ApiApiListSaapReviewApplicationsRequest): Promise<ListApplicationsReturn> {
        const response = await this.listSaapReviewApplicationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list SchoolList
     */
    async listSchoolListRaw(): Promise<runtime.ApiResponse<Array<SchoolList>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/school-lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SchoolListFromJSON));
    }

    /**
     * list SchoolList
     */
    async listSchoolList(): Promise<Array<SchoolList>> {
        const response = await this.listSchoolListRaw();
        return await response.value();
    }

    /**
     * List all search transforms and their relative support for a given search
     */
    async listSearchTransformsRaw(requestParameters: ApiApiListSearchTransformsRequest): Promise<runtime.ApiResponse<Array<SearchTransformValidationResult>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listSearchTransforms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/searches/{id}/search-transforms`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SearchTransformValidationResultFromJSON));
    }

    /**
     * List all search transforms and their relative support for a given search
     */
    async listSearchTransforms(requestParameters: ApiApiListSearchTransformsRequest): Promise<Array<SearchTransformValidationResult>> {
        const response = await this.listSearchTransformsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list search v3 params
     */
    async listSearchV3Raw(requestParameters: ApiApiListSearchV3Request): Promise<runtime.ApiResponse<InlineResponse20067>> {
        const queryParameters: any = {};

        if (requestParameters.job !== undefined) {
            queryParameters['job'] = requestParameters.job;
        }

        if (requestParameters.searchTypeList !== undefined) {
            queryParameters['search_type__list'] = requestParameters.searchTypeList;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/searches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20067FromJSON(jsonValue));
    }

    /**
     * list search v3 params
     */
    async listSearchV3(requestParameters: ApiApiListSearchV3Request): Promise<InlineResponse20067> {
        const response = await this.listSearchV3Raw(requestParameters);
        return await response.value();
    }

    /**
     * list search v3 profile results
     */
    async listSearchV3ProfileResultsRaw(requestParameters: ApiApiListSearchV3ProfileResultsRequest): Promise<runtime.ApiResponse<Array<ProfileResponse>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling listSearchV3ProfileResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/search-v3-profiles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProfileResponseFromJSON));
    }

    /**
     * list search v3 profile results
     */
    async listSearchV3ProfileResults(requestParameters: ApiApiListSearchV3ProfileResultsRequest): Promise<Array<ProfileResponse>> {
        const response = await this.listSearchV3ProfileResultsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list searches
     */
    async listSearchesRaw(requestParameters: ApiApiListSearchesRequest): Promise<runtime.ApiResponse<InlineResponse2009>> {
        const queryParameters: any = {};

        if (requestParameters.job !== undefined) {
            queryParameters['job'] = requestParameters.job;
        }

        if (requestParameters.jobClient !== undefined) {
            queryParameters['job__client'] = requestParameters.jobClient;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.proOnly !== undefined) {
            queryParameters['pro_only'] = requestParameters.proOnly;
        }

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/searches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2009FromJSON(jsonValue));
    }

    /**
     * list searches
     */
    async listSearches(requestParameters: ApiApiListSearchesRequest): Promise<InlineResponse2009> {
        const response = await this.listSearchesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list seniorities
     */
    async listSenioritiesRaw(): Promise<runtime.ApiResponse<Array<Seniority>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/seniorities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SeniorityFromJSON));
    }

    /**
     * list seniorities
     */
    async listSeniorities(): Promise<Array<Seniority>> {
        const response = await this.listSenioritiesRaw();
        return await response.value();
    }

    /**
     * list similar companies
     */
    async listSimilarCompaniesRaw(requestParameters: ApiApiListSimilarCompaniesRequest): Promise<runtime.ApiResponse<InlineResponse20022>> {
        const queryParameters: any = {};

        if (requestParameters.idList !== undefined) {
            queryParameters['id__list'] = requestParameters.idList;
        }

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20022FromJSON(jsonValue));
    }

    /**
     * list similar companies
     */
    async listSimilarCompanies(requestParameters: ApiApiListSimilarCompaniesRequest): Promise<InlineResponse20022> {
        const response = await this.listSimilarCompaniesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list similar schools
     */
    async listSimilarSchoolsRaw(requestParameters: ApiApiListSimilarSchoolsRequest): Promise<runtime.ApiResponse<InlineResponse20065>> {
        const queryParameters: any = {};

        if (requestParameters.idList !== undefined) {
            queryParameters['id__list'] = requestParameters.idList;
        }

        if (requestParameters.nameList !== undefined) {
            queryParameters['name__list'] = requestParameters.nameList;
        }

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/schools`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20065FromJSON(jsonValue));
    }

    /**
     * list similar schools
     */
    async listSimilarSchools(requestParameters: ApiApiListSimilarSchoolsRequest): Promise<InlineResponse20065> {
        const response = await this.listSimilarSchoolsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list slim search templates
     */
    async listSlimSearchTemplatesRaw(requestParameters: ApiApiListSlimSearchTemplatesRequest): Promise<runtime.ApiResponse<InlineResponse2007>> {
        const queryParameters: any = {};

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/admin-slim-search-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2007FromJSON(jsonValue));
    }

    /**
     * list slim search templates
     */
    async listSlimSearchTemplates(requestParameters: ApiApiListSlimSearchTemplatesRequest): Promise<InlineResponse2007> {
        const response = await this.listSlimSearchTemplatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list starred candidates for a user
     */
    async listStarredCandidatesRaw(requestParameters: ApiApiListStarredCandidatesRequest): Promise<runtime.ApiResponse<InlineResponse20075>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/starred-candidate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20075FromJSON(jsonValue));
    }

    /**
     * list starred candidates for a user
     */
    async listStarredCandidates(requestParameters: ApiApiListStarredCandidatesRequest): Promise<InlineResponse20075> {
        const response = await this.listStarredCandidatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list states
     */
    async listStatesRaw(): Promise<runtime.ApiResponse<Array<ListStatesResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListStatesResponseFromJSON));
    }

    /**
     * list states
     */
    async listStates(): Promise<Array<ListStatesResponse>> {
        const response = await this.listStatesRaw();
        return await response.value();
    }

    /**
     * list talent network candidates
     */
    async listTalentNetworkRaw(requestParameters: ApiApiListTalentNetworkRequest): Promise<runtime.ApiResponse<TalentNetworkReturn>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling listTalentNetwork.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/talent-review/list_talent_network`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TalentNetworkRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TalentNetworkReturnFromJSON(jsonValue));
    }

    /**
     * list talent network candidates
     */
    async listTalentNetwork(requestParameters: ApiApiListTalentNetworkRequest): Promise<TalentNetworkReturn> {
        const response = await this.listTalentNetworkRaw(requestParameters);
        return await response.value();
    }

    /**
     * list Title patterns
     */
    async listTitlePatternsRaw(requestParameters: ApiApiListTitlePatternsRequest): Promise<runtime.ApiResponse<InlineResponse20069>> {
        const queryParameters: any = {};

        if (requestParameters.idList !== undefined) {
            queryParameters['id__list'] = requestParameters.idList;
        }

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/title-patterns`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20069FromJSON(jsonValue));
    }

    /**
     * list Title patterns
     */
    async listTitlePatterns(requestParameters: ApiApiListTitlePatternsRequest): Promise<InlineResponse20069> {
        const response = await this.listTitlePatternsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Given a persona, list the title patterns, enriched with whether pattern is selected and or default
     */
    async listTitlePatternsForPersonaRaw(requestParameters: ApiApiListTitlePatternsForPersonaRequest): Promise<runtime.ApiResponse<Array<TitlePatternInfo>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling listTitlePatternsForPersona.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/personas/list_title_patterns_for_persona`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonaIDRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TitlePatternInfoFromJSON));
    }

    /**
     * Given a persona, list the title patterns, enriched with whether pattern is selected and or default
     */
    async listTitlePatternsForPersona(requestParameters: ApiApiListTitlePatternsForPersonaRequest): Promise<Array<TitlePatternInfo>> {
        const response = await this.listTitlePatternsForPersonaRaw(requestParameters);
        return await response.value();
    }

    /**
     * list titles
     */
    async listTitlesRaw(): Promise<runtime.ApiResponse<Array<Title>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/titles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TitleFromJSON));
    }

    /**
     * list titles
     */
    async listTitles(): Promise<Array<Title>> {
        const response = await this.listTitlesRaw();
        return await response.value();
    }

    /**
     * list unsubscribe footers
     */
    async listUnsubscribeFootersRaw(requestParameters: ApiApiListUnsubscribeFootersRequest): Promise<runtime.ApiResponse<InlineResponse20070>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/unsubscribe-footers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20070FromJSON(jsonValue));
    }

    /**
     * list unsubscribe footers
     */
    async listUnsubscribeFooters(requestParameters: ApiApiListUnsubscribeFootersRequest): Promise<InlineResponse20070> {
        const response = await this.listUnsubscribeFootersRaw(requestParameters);
        return await response.value();
    }

    /**
     * list user credentials
     */
    async listUserCredentialsRaw(requestParameters: ApiApiListUserCredentialsRequest): Promise<runtime.ApiResponse<InlineResponse20071>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/user-credentials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20071FromJSON(jsonValue));
    }

    /**
     * list user credentials
     */
    async listUserCredentials(requestParameters: ApiApiListUserCredentialsRequest): Promise<InlineResponse20071> {
        const response = await this.listUserCredentialsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list campaigns
     */
    async listUserFacingCampaignsRaw(requestParameters: ApiApiListUserFacingCampaignsRequest): Promise<runtime.ApiResponse<InlineResponse20013>> {
        const queryParameters: any = {};

        if (requestParameters.showDeleted !== undefined) {
            queryParameters['show_deleted'] = requestParameters.showDeleted;
        }

        if (requestParameters.job !== undefined) {
            queryParameters['job'] = requestParameters.job;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/campaigns`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20013FromJSON(jsonValue));
    }

    /**
     * list campaigns
     */
    async listUserFacingCampaigns(requestParameters: ApiApiListUserFacingCampaignsRequest): Promise<InlineResponse20013> {
        const response = await this.listUserFacingCampaignsRaw(requestParameters);
        return await response.value();
    }

    /**
     * List user onboarding flows
     */
    async listUserOnboardingFlowsRaw(requestParameters: ApiApiListUserOnboardingFlowsRequest): Promise<runtime.ApiResponse<InlineResponse20056>> {
        const queryParameters: any = {};

        if (requestParameters.flowName !== undefined) {
            queryParameters['flow_name'] = requestParameters.flowName;
        }

        if (requestParameters.proUserUserEmail !== undefined) {
            queryParameters['pro_user__user__email'] = requestParameters.proUserUserEmail;
        }

        if (requestParameters.relatedObjectUuid !== undefined) {
            queryParameters['related_object_uuid'] = requestParameters.relatedObjectUuid;
        }

        if (requestParameters.contentTypeModel !== undefined) {
            queryParameters['content_type__model'] = requestParameters.contentTypeModel;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/onboarding-user-onboarding-flow`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20056FromJSON(jsonValue));
    }

    /**
     * List user onboarding flows
     */
    async listUserOnboardingFlows(requestParameters: ApiApiListUserOnboardingFlowsRequest): Promise<InlineResponse20056> {
        const response = await this.listUserOnboardingFlowsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list information about files that users in your organization have uploaded
     */
    async listUserUploadedFilesRaw(requestParameters: ApiApiListUserUploadedFilesRequest): Promise<runtime.ApiResponse<InlineResponse20034>> {
        const queryParameters: any = {};

        if (requestParameters.uploadedContext !== undefined) {
            queryParameters['uploaded_context'] = requestParameters.uploadedContext;
        }

        if (requestParameters.processingState !== undefined) {
            queryParameters['processing_state'] = requestParameters.processingState;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/files`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20034FromJSON(jsonValue));
    }

    /**
     * list information about files that users in your organization have uploaded
     */
    async listUserUploadedFiles(requestParameters: ApiApiListUserUploadedFilesRequest): Promise<InlineResponse20034> {
        const response = await this.listUserUploadedFilesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list users
     */
    async listUsersRaw(requestParameters: ApiApiListUsersRequest): Promise<runtime.ApiResponse<InlineResponse20011>> {
        const queryParameters: any = {};

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20011FromJSON(jsonValue));
    }

    /**
     * list users
     */
    async listUsers(requestParameters: ApiApiListUsersRequest): Promise<InlineResponse20011> {
        const response = await this.listUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * list valid send at times for Email Send Request
     */
    async listValidEmailSendRequestSendTimesRaw(requestParameters: ApiApiListValidEmailSendRequestSendTimesRequest): Promise<runtime.ApiResponse<Array<ValidEmailSendAtTime>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling listValidEmailSendRequestSendTimes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/list-valid-email-send-request-send-times`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidSendAtTimesRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ValidEmailSendAtTimeFromJSON));
    }

    /**
     * list valid send at times for Email Send Request
     */
    async listValidEmailSendRequestSendTimes(requestParameters: ApiApiListValidEmailSendRequestSendTimesRequest): Promise<Array<ValidEmailSendAtTime>> {
        const response = await this.listValidEmailSendRequestSendTimesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list world regions
     */
    async listWorldRegionsRaw(): Promise<runtime.ApiResponse<Array<WorldRegionResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/world-regions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorldRegionResponseFromJSON));
    }

    /**
     * list world regions
     */
    async listWorldRegions(): Promise<Array<WorldRegionResponse>> {
        const response = await this.listWorldRegionsRaw();
        return await response.value();
    }

    /**
     * mark that the authenticated user manually contacted the referral
     */
    async markJobReferralAsContactedRaw(requestParameters: ApiApiMarkJobReferralAsContactedRequest): Promise<runtime.ApiResponse<JobReferral>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markJobReferralAsContacted.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling markJobReferralAsContacted.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-referrals/{id}/mark-as-contacted`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JobReferralToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobReferralFromJSON(jsonValue));
    }

    /**
     * mark that the authenticated user manually contacted the referral
     */
    async markJobReferralAsContacted(requestParameters: ApiApiMarkJobReferralAsContactedRequest): Promise<JobReferral> {
        const response = await this.markJobReferralAsContactedRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the current onboarding step for the requesting user
     */
    async markOnboardingCompleteRaw(requestParameters: ApiApiMarkOnboardingCompleteOperationRequest): Promise<runtime.ApiResponse<MarkOnboardingCompleteResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling markOnboardingComplete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/mark-onboarding-complete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MarkOnboardingCompleteRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MarkOnboardingCompleteResponseFromJSON(jsonValue));
    }

    /**
     * Returns the current onboarding step for the requesting user
     */
    async markOnboardingComplete(requestParameters: ApiApiMarkOnboardingCompleteOperationRequest): Promise<MarkOnboardingCompleteResponse> {
        const response = await this.markOnboardingCompleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * merge a secondary contact\'s info into a primary contact
     */
    async mergeContactsRaw(requestParameters: ApiApiMergeContactsOperationRequest): Promise<runtime.ApiResponse<MergeContactsRequest>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mergeContacts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contacts/merge_contacts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergeContactsRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MergeContactsRequestFromJSON(jsonValue));
    }

    /**
     * merge a secondary contact\'s info into a primary contact
     */
    async mergeContacts(requestParameters: ApiApiMergeContactsOperationRequest): Promise<MergeContactsRequest> {
        const response = await this.mergeContactsRaw(requestParameters);
        return await response.value();
    }

    /**
     * NANA2
     */
    async modifyTitlePatternsInSearchRaw(requestParameters: ApiApiModifyTitlePatternsInSearchOperationRequest): Promise<runtime.ApiResponse<ModifyTitlePatternsInSearchResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling modifyTitlePatternsInSearch.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling modifyTitlePatternsInSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/searches/{id}/modify_title_patterns_in_search`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModifyTitlePatternsInSearchRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModifyTitlePatternsInSearchResponseFromJSON(jsonValue));
    }

    /**
     * NANA2
     */
    async modifyTitlePatternsInSearch(requestParameters: ApiApiModifyTitlePatternsInSearchOperationRequest): Promise<ModifyTitlePatternsInSearchResponse> {
        const response = await this.modifyTitlePatternsInSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send an email to a candidate.
     */
    async moveJobRaw(requestParameters: ApiApiMoveJobOperationRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling moveJob.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling moveJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-actions/{id}/move_job`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MoveJobRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseCandidateActionResponseFromJSON(jsonValue));
    }

    /**
     * Send an email to a candidate.
     */
    async moveJob(requestParameters: ApiApiMoveJobOperationRequest): Promise<BaseCandidateActionResponse> {
        const response = await this.moveJobRaw(requestParameters);
        return await response.value();
    }

    /**
     * parse basic candidate info from a resume
     */
    async parseCandidateInfoRaw(requestParameters: ApiApiParseCandidateInfoRequest): Promise<runtime.ApiResponse<ParseCandidateInfoResult>> {
        if (requestParameters.resume === null || requestParameters.resume === undefined) {
            throw new runtime.RequiredError('resume','Required parameter requestParameters.resume was null or undefined when calling parseCandidateInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.resume !== undefined) {
            formParams.append('resume', requestParameters.resume as any);
        }

        const response = await this.request({
            path: `/api/v1/manual-sourcing/parse_candidate_info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ParseCandidateInfoResultFromJSON(jsonValue));
    }

    /**
     * parse basic candidate info from a resume
     */
    async parseCandidateInfo(requestParameters: ApiApiParseCandidateInfoRequest): Promise<ParseCandidateInfoResult> {
        const response = await this.parseCandidateInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Parses client email template from existing conte. Does not persist DB object.
     */
    async parseClientEmailTemplateRaw(requestParameters: ApiApiParseClientEmailTemplateRequest): Promise<runtime.ApiResponse<ParseClientEmailTemplate>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling parseClientEmailTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-email-templates/parse_template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParseClientEmailTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ParseClientEmailTemplateFromJSON(jsonValue));
    }

    /**
     * Parses client email template from existing conte. Does not persist DB object.
     */
    async parseClientEmailTemplate(requestParameters: ApiApiParseClientEmailTemplateRequest): Promise<ParseClientEmailTemplate> {
        const response = await this.parseClientEmailTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * parse features from a job description
     */
    async parseJDFeaturesRaw(requestParameters: ApiApiParseJDFeaturesOperationRequest): Promise<runtime.ApiResponse<ParseJDFeaturesResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling parseJDFeatures.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/parse-jd-features`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParseJDFeaturesRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ParseJDFeaturesResponseFromJSON(jsonValue));
    }

    /**
     * parse features from a job description
     */
    async parseJDFeatures(requestParameters: ApiApiParseJDFeaturesOperationRequest): Promise<ParseJDFeaturesResponse> {
        const response = await this.parseJDFeaturesRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update calibration candidate
     */
    async partialBulkUpdateCalibrationCandidatesRaw(requestParameters: ApiApiPartialBulkUpdateCalibrationCandidatesRequest): Promise<runtime.ApiResponse<Array<CalibrationCandidate>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialBulkUpdateCalibrationCandidates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/calibration-candidates/bulk/partial_bulk_update`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data.map(CalibrationCandidateToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CalibrationCandidateFromJSON));
    }

    /**
     * partial update calibration candidate
     */
    async partialBulkUpdateCalibrationCandidates(requestParameters: ApiApiPartialBulkUpdateCalibrationCandidatesRequest): Promise<Array<CalibrationCandidate>> {
        const response = await this.partialBulkUpdateCalibrationCandidatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update campaign
     */
    async partialUpdateCampaignRaw(requestParameters: ApiApiPartialUpdateCampaignRequest): Promise<runtime.ApiResponse<Campaign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCampaign.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/campaigns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignFromJSON(jsonValue));
    }

    /**
     * partial update campaign
     */
    async partialUpdateCampaign(requestParameters: ApiApiPartialUpdateCampaignRequest): Promise<Campaign> {
        const response = await this.partialUpdateCampaignRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update candidate bio
     */
    async partialUpdateCandidateBioRaw(requestParameters: ApiApiPartialUpdateCandidateBioRequest): Promise<runtime.ApiResponse<CandidateBio>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCandidateBio.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateCandidateBio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate-bios/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCandidateBioBodyToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidateBioFromJSON(jsonValue));
    }

    /**
     * partial update candidate bio
     */
    async partialUpdateCandidateBio(requestParameters: ApiApiPartialUpdateCandidateBioRequest): Promise<CandidateBio> {
        const response = await this.partialUpdateCandidateBioRaw(requestParameters);
        return await response.value();
    }

    /**
     * partially update a candidate interview
     */
    async partialUpdateCandidateInterviewRaw(requestParameters: ApiApiPartialUpdateCandidateInterviewRequest): Promise<runtime.ApiResponse<UpdateCandidateInterview>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCandidateInterview.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateCandidateInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate-interview/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCandidateInterviewToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCandidateInterviewFromJSON(jsonValue));
    }

    /**
     * partially update a candidate interview
     */
    async partialUpdateCandidateInterview(requestParameters: ApiApiPartialUpdateCandidateInterviewRequest): Promise<UpdateCandidateInterview> {
        const response = await this.partialUpdateCandidateInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial client update
     */
    async partialUpdateClientRaw(requestParameters: ApiApiPartialUpdateClientRequest): Promise<runtime.ApiResponse<Client>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateClient.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateClientBodyToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientFromJSON(jsonValue));
    }

    /**
     * partial client update
     */
    async partialUpdateClient(requestParameters: ApiApiPartialUpdateClientRequest): Promise<Client> {
        const response = await this.partialUpdateClientRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update client email template
     */
    async partialUpdateClientEmailTemplateRaw(requestParameters: ApiApiPartialUpdateClientEmailTemplateRequest): Promise<runtime.ApiResponse<ClientEmailTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateClientEmailTemplate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateClientEmailTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-email-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ClientEmailTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientEmailTemplateFromJSON(jsonValue));
    }

    /**
     * partial update client email template
     */
    async partialUpdateClientEmailTemplate(requestParameters: ApiApiPartialUpdateClientEmailTemplateRequest): Promise<ClientEmailTemplate> {
        const response = await this.partialUpdateClientEmailTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update CompanyList
     */
    async partialUpdateCompanyListRaw(requestParameters: ApiApiPartialUpdateCompanyListRequest): Promise<runtime.ApiResponse<CompanyList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompanyList.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateCompanyList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/company-lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyListToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyListFromJSON(jsonValue));
    }

    /**
     * partial update CompanyList
     */
    async partialUpdateCompanyList(requestParameters: ApiApiPartialUpdateCompanyListRequest): Promise<CompanyList> {
        const response = await this.partialUpdateCompanyListRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update contact
     */
    async partialUpdateContactRaw(requestParameters: ApiApiPartialUpdateContactRequest): Promise<runtime.ApiResponse<Contact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateContact.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ContactToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactFromJSON(jsonValue));
    }

    /**
     * partial update contact
     */
    async partialUpdateContact(requestParameters: ApiApiPartialUpdateContactRequest): Promise<Contact> {
        const response = await this.partialUpdateContactRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update contact email
     */
    async partialUpdateContactEmailRaw(requestParameters: ApiApiPartialUpdateContactEmailRequest): Promise<runtime.ApiResponse<ContactEmail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateContactEmail.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateContactEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contact-emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ContactEmailToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactEmailFromJSON(jsonValue));
    }

    /**
     * partial update contact email
     */
    async partialUpdateContactEmail(requestParameters: ApiApiPartialUpdateContactEmailRequest): Promise<ContactEmail> {
        const response = await this.partialUpdateContactEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update contact phone number
     */
    async partialUpdateContactPhoneNumberRaw(requestParameters: ApiApiPartialUpdateContactPhoneNumberRequest): Promise<runtime.ApiResponse<ContactPhoneNumber>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateContactPhoneNumber.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateContactPhoneNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/contact-phone-numbers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ContactPhoneNumberToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactPhoneNumberFromJSON(jsonValue));
    }

    /**
     * partial update contact phone number
     */
    async partialUpdateContactPhoneNumber(requestParameters: ApiApiPartialUpdateContactPhoneNumberRequest): Promise<ContactPhoneNumber> {
        const response = await this.partialUpdateContactPhoneNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update feedback template
     */
    async partialUpdateFeedbackTemplateRaw(requestParameters: ApiApiPartialUpdateFeedbackTemplateRequest): Promise<runtime.ApiResponse<FeedbackTemplateDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateFeedbackTemplate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateFeedbackTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/feedback-template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFeedbackTemplateRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedbackTemplateDetailFromJSON(jsonValue));
    }

    /**
     * partial update feedback template
     */
    async partialUpdateFeedbackTemplate(requestParameters: ApiApiPartialUpdateFeedbackTemplateRequest): Promise<FeedbackTemplateDetail> {
        const response = await this.partialUpdateFeedbackTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update generic email response template
     */
    async partialUpdateGenericEmailResponseTemplateRaw(requestParameters: ApiApiPartialUpdateGenericEmailResponseTemplateRequest): Promise<runtime.ApiResponse<GenericEmailResponseTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateGenericEmailResponseTemplate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateGenericEmailResponseTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/generic-email-response-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: GenericEmailResponseTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GenericEmailResponseTemplateFromJSON(jsonValue));
    }

    /**
     * partial update generic email response template
     */
    async partialUpdateGenericEmailResponseTemplate(requestParameters: ApiApiPartialUpdateGenericEmailResponseTemplateRequest): Promise<GenericEmailResponseTemplate> {
        const response = await this.partialUpdateGenericEmailResponseTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update hiring stage email template
     */
    async partialUpdateHiringStageEmailTemplateRaw(requestParameters: ApiApiPartialUpdateHiringStageEmailTemplateRequest): Promise<runtime.ApiResponse<HiringStageEmailTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateHiringStageEmailTemplate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateHiringStageEmailTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-stage-email-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HiringStageEmailTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringStageEmailTemplateFromJSON(jsonValue));
    }

    /**
     * partial update hiring stage email template
     */
    async partialUpdateHiringStageEmailTemplate(requestParameters: ApiApiPartialUpdateHiringStageEmailTemplateRequest): Promise<HiringStageEmailTemplate> {
        const response = await this.partialUpdateHiringStageEmailTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * partially update inbound application
     */
    async partialUpdateInboundApplicationRaw(requestParameters: ApiApiPartialUpdateInboundApplicationRequest): Promise<runtime.ApiResponse<ApplicationPortalInboundApplication>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateInboundApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.jobId !== undefined) {
            formParams.append('job_id', requestParameters.jobId as any);
        }

        if (requestParameters.referrerSource !== undefined) {
            formParams.append('referrer_source', requestParameters.referrerSource as any);
        }

        if (requestParameters.firstName !== undefined) {
            formParams.append('first_name', requestParameters.firstName as any);
        }

        if (requestParameters.lastName !== undefined) {
            formParams.append('last_name', requestParameters.lastName as any);
        }

        if (requestParameters.linkedinUrl !== undefined) {
            formParams.append('linkedin_url', requestParameters.linkedinUrl as any);
        }

        if (requestParameters.email !== undefined) {
            formParams.append('email', requestParameters.email as any);
        }

        if (requestParameters.phoneNumber !== undefined) {
            formParams.append('phone_number', requestParameters.phoneNumber as any);
        }

        if (requestParameters.resume !== undefined) {
            formParams.append('resume', requestParameters.resume as any);
        }

        if (requestParameters.additionalInfo !== undefined) {
            formParams.append('additional_info', requestParameters.additionalInfo as any);
        }

        if (requestParameters.applicationQuestions !== undefined) {
            formParams.append('application_questions', requestParameters.applicationQuestions as any);
        }

        if (requestParameters.bookmarked !== undefined) {
            formParams.append('bookmarked', requestParameters.bookmarked as any);
        }

        if (requestParameters.optedInToTalentNetwork !== undefined) {
            formParams.append('opted_in_to_talent_network', requestParameters.optedInToTalentNetwork as any);
        }

        const response = await this.request({
            path: `/api/v1/inbound/application-portal-inbound-application/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationPortalInboundApplicationFromJSON(jsonValue));
    }

    /**
     * partially update inbound application
     */
    async partialUpdateInboundApplication(requestParameters: ApiApiPartialUpdateInboundApplicationRequest): Promise<ApplicationPortalInboundApplication> {
        const response = await this.partialUpdateInboundApplicationRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial interview rubric response update
     */
    async partialUpdateInterviewRubricResponseRaw(requestParameters: ApiApiPartialUpdateInterviewRubricResponseRequest): Promise<runtime.ApiResponse<InterviewRubricResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateInterviewRubricResponse.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateInterviewRubricResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-interview-rubric-response/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewRubricResponseToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewRubricResponseFromJSON(jsonValue));
    }

    /**
     * partial interview rubric response update
     */
    async partialUpdateInterviewRubricResponse(requestParameters: ApiApiPartialUpdateInterviewRubricResponseRequest): Promise<InterviewRubricResponse> {
        const response = await this.partialUpdateInterviewRubricResponseRaw(requestParameters);
        return await response.value();
    }

    /**
     * update multipart pipeline stage
     */
    async partialUpdateInterviewStageRaw(requestParameters: ApiApiPartialUpdateInterviewStageRequest): Promise<runtime.ApiResponse<OnboardingMultipartInterviewStage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateInterviewStage.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling partialUpdateInterviewStage.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateInterviewStage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{job_id}/interview-plan/{id}/multipart_interview_stage`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardingMultipartInterviewStageToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingMultipartInterviewStageFromJSON(jsonValue));
    }

    /**
     * update multipart pipeline stage
     */
    async partialUpdateInterviewStage(requestParameters: ApiApiPartialUpdateInterviewStageRequest): Promise<OnboardingMultipartInterviewStage> {
        const response = await this.partialUpdateInterviewStageRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial interviewer update
     */
    async partialUpdateInterviewerRaw(requestParameters: ApiApiPartialUpdateInterviewerRequest): Promise<runtime.ApiResponse<ClientInterviewer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateInterviewer.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateInterviewer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-interviewers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ClientInterviewerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientInterviewerFromJSON(jsonValue));
    }

    /**
     * partial interviewer update
     */
    async partialUpdateInterviewer(requestParameters: ApiApiPartialUpdateInterviewerRequest): Promise<ClientInterviewer> {
        const response = await this.partialUpdateInterviewerRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update hiring stage email template
     */
    async partialUpdateInterviewerProUserRaw(requestParameters: ApiApiPartialUpdateInterviewerProUserRequest): Promise<runtime.ApiResponse<ProUser>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling partialUpdateInterviewerProUser.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateInterviewerProUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/interviewer-pro-user/{user}`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProUserToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProUserFromJSON(jsonValue));
    }

    /**
     * partial update hiring stage email template
     */
    async partialUpdateInterviewerProUser(requestParameters: ApiApiPartialUpdateInterviewerProUserRequest): Promise<ProUser> {
        const response = await this.partialUpdateInterviewerProUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update interviewer scheduling info
     */
    async partialUpdateInterviewerSchedulingInfoRaw(requestParameters: ApiApiPartialUpdateInterviewerSchedulingInfoRequest): Promise<runtime.ApiResponse<InterviewerSchedulingInfo>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateInterviewerSchedulingInfo.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateInterviewerSchedulingInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/interviewer-scheduling-info/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewerSchedulingInfoToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewerSchedulingInfoFromJSON(jsonValue));
    }

    /**
     * partial update interviewer scheduling info
     */
    async partialUpdateInterviewerSchedulingInfo(requestParameters: ApiApiPartialUpdateInterviewerSchedulingInfoRequest): Promise<InterviewerSchedulingInfo> {
        const response = await this.partialUpdateInterviewerSchedulingInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * partially update a job position
     */
    async partialUpdateJobPositionRaw(requestParameters: ApiApiPartialUpdateJobPositionRequest): Promise<runtime.ApiResponse<HiringPlanJobPosition>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateJobPosition.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateJobPosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-plan-job-positions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HiringPlanJobPositionToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringPlanJobPositionFromJSON(jsonValue));
    }

    /**
     * partially update a job position
     */
    async partialUpdateJobPosition(requestParameters: ApiApiPartialUpdateJobPositionRequest): Promise<HiringPlanJobPosition> {
        const response = await this.partialUpdateJobPositionRaw(requestParameters);
        return await response.value();
    }

    /**
     * partially update a job position opening
     */
    async partialUpdateJobPositionOpeningRaw(requestParameters: ApiApiPartialUpdateJobPositionOpeningRequest): Promise<runtime.ApiResponse<HiringPlanJobPositionOpening>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateJobPositionOpening.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateJobPositionOpening.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-plan-job-position-openings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HiringPlanJobPositionOpeningToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringPlanJobPositionOpeningFromJSON(jsonValue));
    }

    /**
     * partially update a job position opening
     */
    async partialUpdateJobPositionOpening(requestParameters: ApiApiPartialUpdateJobPositionOpeningRequest): Promise<HiringPlanJobPositionOpening> {
        const response = await this.partialUpdateJobPositionOpeningRaw(requestParameters);
        return await response.value();
    }

    /**
     * partially update a job referral
     */
    async partialUpdateJobReferralRaw(requestParameters: ApiApiPartialUpdateJobReferralRequest): Promise<runtime.ApiResponse<JobReferral>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateJobReferral.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateJobReferral.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-referrals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: JobReferralToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobReferralFromJSON(jsonValue));
    }

    /**
     * partially update a job referral
     */
    async partialUpdateJobReferral(requestParameters: ApiApiPartialUpdateJobReferralRequest): Promise<JobReferral> {
        const response = await this.partialUpdateJobReferralRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial job setup info update
     */
    async partialUpdateJobSetupRaw(requestParameters: ApiApiPartialUpdateJobSetupRequest): Promise<runtime.ApiResponse<JobSetup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateJobSetup.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateJobSetup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-setups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: JobSetupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobSetupFromJSON(jsonValue));
    }

    /**
     * partial job setup info update
     */
    async partialUpdateJobSetup(requestParameters: ApiApiPartialUpdateJobSetupRequest): Promise<JobSetup> {
        const response = await this.partialUpdateJobSetupRaw(requestParameters);
        return await response.value();
    }

    /**
     * partially update mention
     */
    async partialUpdateMentionRaw(requestParameters: ApiApiPartialUpdateMentionRequest): Promise<runtime.ApiResponse<Mention>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateMention.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateMention.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/mentions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MentionToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MentionFromJSON(jsonValue));
    }

    /**
     * partially update mention
     */
    async partialUpdateMention(requestParameters: ApiApiPartialUpdateMentionRequest): Promise<Mention> {
        const response = await this.partialUpdateMentionRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update a hiring pipeline stage
     */
    async partialUpdateOnboardingHiringPipelineStageRaw(requestParameters: ApiApiPartialUpdateOnboardingHiringPipelineStageRequest): Promise<runtime.ApiResponse<OnboardingHiringPipelineStage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateOnboardingHiringPipelineStage.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateOnboardingHiringPipelineStage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/onboarding-hiring-pipeline-stages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardingHiringPipelineStageToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingHiringPipelineStageFromJSON(jsonValue));
    }

    /**
     * partial update a hiring pipeline stage
     */
    async partialUpdateOnboardingHiringPipelineStage(requestParameters: ApiApiPartialUpdateOnboardingHiringPipelineStageRequest): Promise<OnboardingHiringPipelineStage> {
        const response = await this.partialUpdateOnboardingHiringPipelineStageRaw(requestParameters);
        return await response.value();
    }

    /**
     * update multipart pipeline stage
     */
    async partialUpdateOnboardingMultipartInterviewStageRaw(requestParameters: ApiApiPartialUpdateOnboardingMultipartInterviewStageRequest): Promise<runtime.ApiResponse<OnboardingMultipartInterviewStage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateOnboardingMultipartInterviewStage.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateOnboardingMultipartInterviewStage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/onboarding-hiring-pipeline-stages/{id}/multipart_interview_stage`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardingMultipartInterviewStageToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingMultipartInterviewStageFromJSON(jsonValue));
    }

    /**
     * update multipart pipeline stage
     */
    async partialUpdateOnboardingMultipartInterviewStage(requestParameters: ApiApiPartialUpdateOnboardingMultipartInterviewStageRequest): Promise<OnboardingMultipartInterviewStage> {
        const response = await this.partialUpdateOnboardingMultipartInterviewStageRaw(requestParameters);
        return await response.value();
    }

    /**
     * idempotent update of outreach generation
     */
    async partialUpdateOutreachGenerationRaw(requestParameters: ApiApiPartialUpdateOutreachGenerationRequest): Promise<runtime.ApiResponse<OutreachGeneration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateOutreachGeneration.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateOutreachGeneration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/outreach-generation/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OutreachGenerationToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OutreachGenerationFromJSON(jsonValue));
    }

    /**
     * idempotent update of outreach generation
     */
    async partialUpdateOutreachGeneration(requestParameters: ApiApiPartialUpdateOutreachGenerationRequest): Promise<OutreachGeneration> {
        const response = await this.partialUpdateOutreachGenerationRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update Persona
     */
    async partialUpdatePersonaRaw(requestParameters: ApiApiPartialUpdatePersonaRequest): Promise<runtime.ApiResponse<Persona>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdatePersona.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdatePersona.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/personas/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PersonaToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonaFromJSON(jsonValue));
    }

    /**
     * partial update Persona
     */
    async partialUpdatePersona(requestParameters: ApiApiPartialUpdatePersonaRequest): Promise<Persona> {
        const response = await this.partialUpdatePersonaRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial updates info for the requesting user
     */
    async partialUpdateProUserRaw(requestParameters: ApiApiPartialUpdateProUserRequest): Promise<runtime.ApiResponse<DoverUser>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling partialUpdateProUser.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateProUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/{user}`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DoverUserToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DoverUserFromJSON(jsonValue));
    }

    /**
     * partial updates info for the requesting user
     */
    async partialUpdateProUser(requestParameters: ApiApiPartialUpdateProUserRequest): Promise<DoverUser> {
        const response = await this.partialUpdateProUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * update a dover role
     */
    async partialUpdateRoleRaw(requestParameters: ApiApiPartialUpdateRoleRequest): Promise<runtime.ApiResponse<DoverRole>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateRole.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DoverRoleToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DoverRoleFromJSON(jsonValue));
    }

    /**
     * update a dover role
     */
    async partialUpdateRole(requestParameters: ApiApiPartialUpdateRoleRequest): Promise<DoverRole> {
        const response = await this.partialUpdateRoleRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update SchoolList
     */
    async partialUpdateSchoolListRaw(requestParameters: ApiApiPartialUpdateSchoolListRequest): Promise<runtime.ApiResponse<SchoolList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateSchoolList.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateSchoolList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/school-lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SchoolListToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolListFromJSON(jsonValue));
    }

    /**
     * partial update SchoolList
     */
    async partialUpdateSchoolList(requestParameters: ApiApiPartialUpdateSchoolListRequest): Promise<SchoolList> {
        const response = await this.partialUpdateSchoolListRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial search update
     */
    async partialUpdateSearchRaw(requestParameters: ApiApiPartialUpdateSearchRequest): Promise<runtime.ApiResponse<AdminSearch>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateSearch.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/searches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AdminSearchToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminSearchFromJSON(jsonValue));
    }

    /**
     * partial search update
     */
    async partialUpdateSearch(requestParameters: ApiApiPartialUpdateSearchRequest): Promise<AdminSearch> {
        const response = await this.partialUpdateSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * partial update search v3 params
     */
    async partialUpdateSearchV3Raw(requestParameters: ApiApiPartialUpdateSearchV3Request): Promise<runtime.ApiResponse<SearchV3>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateSearchV3.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdateSearchV3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/searches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SearchV3ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchV3FromJSON(jsonValue));
    }

    /**
     * partial update search v3 params
     */
    async partialUpdateSearchV3(requestParameters: ApiApiPartialUpdateSearchV3Request): Promise<SearchV3> {
        const response = await this.partialUpdateSearchV3Raw(requestParameters);
        return await response.value();
    }

    /**
     * partial client onboarding settings update
     */
    async patchClientOnboardingRaw(requestParameters: ApiApiPatchClientOnboardingRequest): Promise<runtime.ApiResponse<ClientOnboarding>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling patchClientOnboarding.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling patchClientOnboarding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-setup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ClientOnboardingToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientOnboardingFromJSON(jsonValue));
    }

    /**
     * partial client onboarding settings update
     */
    async patchClientOnboarding(requestParameters: ApiApiPatchClientOnboardingRequest): Promise<ClientOnboarding> {
        const response = await this.patchClientOnboardingRaw(requestParameters);
        return await response.value();
    }

    /**
     * adds reply to Slack thread with candidate
     */
    async postSlackThreadReplyRaw(requestParameters: ApiApiPostSlackThreadReplyRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling postSlackThreadReply.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/post_slack_thread_reply`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject8ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * adds reply to Slack thread with candidate
     */
    async postSlackThreadReply(requestParameters: ApiApiPostSlackThreadReplyRequest): Promise<InlineResponse2001> {
        const response = await this.postSlackThreadReplyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queues a batch of rejections for multiple candidates.
     */
    async queueBulkRejectionRaw(requestParameters: ApiApiQueueBulkRejectionRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling queueBulkRejection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-actions/queue_bulk_reject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueueBulkRejectRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseCandidateActionResponseFromJSON(jsonValue));
    }

    /**
     * Queues a batch of rejections for multiple candidates.
     */
    async queueBulkRejection(requestParameters: ApiApiQueueBulkRejectionRequest): Promise<BaseCandidateActionResponse> {
        const response = await this.queueBulkRejectionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send an email to a candidate.
     */
    async queueEmailRaw(requestParameters: ApiApiQueueEmailRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling queueEmail.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling queueEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-actions/{id}/send_email`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendEmailRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseCandidateActionResponseFromJSON(jsonValue));
    }

    /**
     * Send an email to a candidate.
     */
    async queueEmail(requestParameters: ApiApiQueueEmailRequest): Promise<BaseCandidateActionResponse> {
        const response = await this.queueEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queue scheduling reminder emails
     */
    async queueSchedulingRemindersFrontRaw(requestParameters: ApiApiQueueSchedulingRemindersFrontRequest): Promise<runtime.ApiResponse<InlineResponse2004>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling queueSchedulingRemindersFront.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/queue_scheduling_reminders_front`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject9ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2004FromJSON(jsonValue));
    }

    /**
     * Queue scheduling reminder emails
     */
    async queueSchedulingRemindersFront(requestParameters: ApiApiQueueSchedulingRemindersFrontRequest): Promise<InlineResponse2004> {
        const response = await this.queueSchedulingRemindersFrontRaw(requestParameters);
        return await response.value();
    }

    /**
     * Reactivates a job
     */
    async reactivateJobRaw(requestParameters: ApiApiReactivateJobRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling reactivateJob.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling reactivateJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/reactivate_job`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Reactivates a job
     */
    async reactivateJob(requestParameters: ApiApiReactivateJobRequest): Promise<InlineResponse2001> {
        const response = await this.reactivateJobRaw(requestParameters);
        return await response.value();
    }

    /**
     * record outreach generation extension action
     */
    async recordOutreachGenerationExtensionActionRaw(requestParameters: ApiApiRecordOutreachGenerationExtensionActionRequest): Promise<runtime.ApiResponse<ExtensionRecordActionResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling recordOutreachGenerationExtensionAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/outreach-generation/record_action/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtensionRecordActionRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtensionRecordActionResponseFromJSON(jsonValue));
    }

    /**
     * record outreach generation extension action
     */
    async recordOutreachGenerationExtensionAction(requestParameters: ApiApiRecordOutreachGenerationExtensionActionRequest): Promise<ExtensionRecordActionResponse> {
        const response = await this.recordOutreachGenerationExtensionActionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Regenerates the sample candidate for a job
     */
    async regenerateSampleCandidateRaw(requestParameters: ApiApiRegenerateSampleCandidateOperationRequest): Promise<runtime.ApiResponse<RegenerateSampleCandidateResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling regenerateSampleCandidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-pipeline/regenerate_sample_candidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegenerateSampleCandidateRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RegenerateSampleCandidateResponseFromJSON(jsonValue));
    }

    /**
     * Regenerates the sample candidate for a job
     */
    async regenerateSampleCandidate(requestParameters: ApiApiRegenerateSampleCandidateOperationRequest): Promise<RegenerateSampleCandidateResponse> {
        const response = await this.regenerateSampleCandidateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Register a saap review of type Good_fit, Top_candidate, Bad_fit or None
     */
    async registerSaapReviewedCandidateSubmissionRaw(requestParameters: ApiApiRegisterSaapReviewedCandidateSubmissionRequest): Promise<runtime.ApiResponse<RegisterSaapReviewedCandidateSubmissionResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling registerSaapReviewedCandidateSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/register-saap-reviewed-candidate-submission`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterSaapReviewedCandidateRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterSaapReviewedCandidateSubmissionResponseFromJSON(jsonValue));
    }

    /**
     * Register a saap review of type Good_fit, Top_candidate, Bad_fit or None
     */
    async registerSaapReviewedCandidateSubmission(requestParameters: ApiApiRegisterSaapReviewedCandidateSubmissionRequest): Promise<RegisterSaapReviewedCandidateSubmissionResponse> {
        const response = await this.registerSaapReviewedCandidateSubmissionRaw(requestParameters);
        return await response.value();
    }

    /**
     * register the review of a similar candidate
     */
    async registerSimilarCandidateReviewRaw(requestParameters: ApiApiRegisterSimilarCandidateReviewRequest): Promise<runtime.ApiResponse<RegisterSimilarCandidateSubmissionResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling registerSimilarCandidateReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/register-similar-candidate-review`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterSimilarCandidateRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterSimilarCandidateSubmissionResponseFromJSON(jsonValue));
    }

    /**
     * register the review of a similar candidate
     */
    async registerSimilarCandidateReview(requestParameters: ApiApiRegisterSimilarCandidateReviewRequest): Promise<RegisterSimilarCandidateSubmissionResponse> {
        const response = await this.registerSimilarCandidateReviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Remove a campaign\'s association with a search
     */
    async removeCampaignFromSearchRaw(requestParameters: ApiApiRemoveCampaignFromSearchRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeCampaignFromSearch.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling removeCampaignFromSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/searches/{id}/remove_campaign_from_search`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject28ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Remove a campaign\'s association with a search
     */
    async removeCampaignFromSearch(requestParameters: ApiApiRemoveCampaignFromSearchRequest): Promise<InlineResponse2001> {
        const response = await this.removeCampaignFromSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Removes candidates from the outbox queue who no longer pass the search,         and if remaining number is lower than a threshold, it will kick off an autovol task to repopulate outbox.
     */
    async removeFailingPeopleFromOutboxRaw(requestParameters: ApiApiRemoveFailingPeopleFromOutboxRequest): Promise<runtime.ApiResponse<RemoveFailingPeopleFromOutboxResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling removeFailingPeopleFromOutbox.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/searches/refresh_outbox_queue_with_new_search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveFailingPeopleFromOutboxToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RemoveFailingPeopleFromOutboxResponseFromJSON(jsonValue));
    }

    /**
     * Removes candidates from the outbox queue who no longer pass the search,         and if remaining number is lower than a threshold, it will kick off an autovol task to repopulate outbox.
     */
    async removeFailingPeopleFromOutbox(requestParameters: ApiApiRemoveFailingPeopleFromOutboxRequest): Promise<RemoveFailingPeopleFromOutboxResponse> {
        const response = await this.removeFailingPeopleFromOutboxRaw(requestParameters);
        return await response.value();
    }

    /**
     * remove from outbox
     */
    async removeFromOutboxRaw(requestParameters: ApiApiRemoveFromOutboxOperationRequest): Promise<runtime.ApiResponse<RemoveFromOutboxResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling removeFromOutbox.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-pipeline/remove_from_outbox`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveFromOutboxRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RemoveFromOutboxResponseFromJSON(jsonValue));
    }

    /**
     * remove from outbox
     */
    async removeFromOutbox(requestParameters: ApiApiRemoveFromOutboxOperationRequest): Promise<RemoveFromOutboxResponse> {
        const response = await this.removeFromOutboxRaw(requestParameters);
        return await response.value();
    }

    /**
     * Removes (i.e. rejects) generated sample candidates for a specified job.
     */
    async removeSampleCandidatesRaw(requestParameters: ApiApiRemoveSampleCandidatesOperationRequest): Promise<runtime.ApiResponse<RemoveSampleCandidatesResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling removeSampleCandidates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-actions/remove_sample_candidates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveSampleCandidatesRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RemoveSampleCandidatesResponseFromJSON(jsonValue));
    }

    /**
     * Removes (i.e. rejects) generated sample candidates for a specified job.
     */
    async removeSampleCandidates(requestParameters: ApiApiRemoveSampleCandidatesOperationRequest): Promise<RemoveSampleCandidatesResponse> {
        const response = await this.removeSampleCandidatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Attempt to replace the interviewer for this candidate.
     */
    async replaceInterviewerRaw(requestParameters: ApiApiReplaceInterviewerRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.candidateId === null || requestParameters.candidateId === undefined) {
            throw new runtime.RequiredError('candidateId','Required parameter requestParameters.candidateId was null or undefined when calling replaceInterviewer.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling replaceInterviewer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/dover-interviewers/candidates/{candidate_id}/replace-interviewer`.replace(`{${"candidate_id"}}`, encodeURIComponent(String(requestParameters.candidateId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Attempt to replace the interviewer for this candidate.
     */
    async replaceInterviewer(requestParameters: ApiApiReplaceInterviewerRequest): Promise<InlineResponse2001> {
        const response = await this.replaceInterviewerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requests a Dover plan upgrade for the calling client via email sent to sales.
     */
    async requestPlanUpgradeRaw(requestParameters: ApiApiRequestPlanUpgradeOperationRequest): Promise<runtime.ApiResponse<RequestPlanUpgradeResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling requestPlanUpgrade.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/dover_plan_management/request-plan-upgrade`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestPlanUpgradeRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestPlanUpgradeResponseFromJSON(jsonValue));
    }

    /**
     * Requests a Dover plan upgrade for the calling client via email sent to sales.
     */
    async requestPlanUpgrade(requestParameters: ApiApiRequestPlanUpgradeOperationRequest): Promise<RequestPlanUpgradeResponse> {
        const response = await this.requestPlanUpgradeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Reschedule an interview for a candidate.
     */
    async rescheduleInterviewRaw(requestParameters: ApiApiRescheduleInterviewOperationRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rescheduleInterview.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling rescheduleInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-actions/{id}/reschedule_interview`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RescheduleInterviewRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseCandidateActionResponseFromJSON(jsonValue));
    }

    /**
     * Reschedule an interview for a candidate.
     */
    async rescheduleInterview(requestParameters: ApiApiRescheduleInterviewOperationRequest): Promise<BaseCandidateActionResponse> {
        const response = await this.rescheduleInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Resends the email verification email for the requesting user
     */
    async resendEmailVerificationEmailRaw(requestParameters: ApiApiResendEmailVerificationEmailOperationRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling resendEmailVerificationEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/resend-email-verification-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendEmailVerificationEmailRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Resends the email verification email for the requesting user
     */
    async resendEmailVerificationEmail(requestParameters: ApiApiResendEmailVerificationEmailOperationRequest): Promise<object> {
        const response = await this.resendEmailVerificationEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * Given a child search, reset its search parameters to match the search transform output of its parent and relink it to its parent.
     */
    async resetAndRelinkChildSearchRaw(requestParameters: ApiApiResetAndRelinkChildSearchRequest): Promise<runtime.ApiResponse<InlineResponse20010>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resetAndRelinkChildSearch.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling resetAndRelinkChildSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/searches/{id}/reset-and-relink-child-search`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20010FromJSON(jsonValue));
    }

    /**
     * Given a child search, reset its search parameters to match the search transform output of its parent and relink it to its parent.
     */
    async resetAndRelinkChildSearch(requestParameters: ApiApiResetAndRelinkChildSearchRequest): Promise<InlineResponse20010> {
        const response = await this.resetAndRelinkChildSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieves emails for linkedin public url
     */
    async retrieveEmailsForLinkedinPublicUrlRaw(requestParameters: ApiApiRetrieveEmailsForLinkedinPublicUrlOperationRequest): Promise<runtime.ApiResponse<RetrieveEmailsForLinkedinPublicUrlResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling retrieveEmailsForLinkedinPublicUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/email_finding/retrieve-emails-for-linkedin-public-url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RetrieveEmailsForLinkedinPublicUrlRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RetrieveEmailsForLinkedinPublicUrlResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves emails for linkedin public url
     */
    async retrieveEmailsForLinkedinPublicUrl(requestParameters: ApiApiRetrieveEmailsForLinkedinPublicUrlOperationRequest): Promise<RetrieveEmailsForLinkedinPublicUrlResponse> {
        const response = await this.retrieveEmailsForLinkedinPublicUrlRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve job referral
     */
    async retrieveJobReferralRaw(requestParameters: ApiApiRetrieveJobReferralRequest): Promise<runtime.ApiResponse<JobReferral>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling retrieveJobReferral.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-referrals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobReferralFromJSON(jsonValue));
    }

    /**
     * retrieve job referral
     */
    async retrieveJobReferral(requestParameters: ApiApiRetrieveJobReferralRequest): Promise<JobReferral> {
        const response = await this.retrieveJobReferralRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieves a user onboarding flow record
     */
    async retrieveUserOnboardingFlowRaw(requestParameters: ApiApiRetrieveUserOnboardingFlowRequest): Promise<runtime.ApiResponse<UserOnboardingFlow>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling retrieveUserOnboardingFlow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/onboarding-user-onboarding-flow/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOnboardingFlowFromJSON(jsonValue));
    }

    /**
     * retrieves a user onboarding flow record
     */
    async retrieveUserOnboardingFlow(requestParameters: ApiApiRetrieveUserOnboardingFlowRequest): Promise<UserOnboardingFlow> {
        const response = await this.retrieveUserOnboardingFlowRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve UserRating
     */
    async retrieveUserRatingRaw(requestParameters: ApiApiRetrieveUserRatingRequest): Promise<runtime.ApiResponse<UserRating>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling retrieveUserRating.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/reporting/user-rating/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserRatingFromJSON(jsonValue));
    }

    /**
     * retrieve UserRating
     */
    async retrieveUserRating(requestParameters: ApiApiRetrieveUserRatingRequest): Promise<UserRating> {
        const response = await this.retrieveUserRatingRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert triaging status back to needs review
     */
    async revertToNeedsReviewRaw(requestParameters: ApiApiRevertToNeedsReviewRequest): Promise<runtime.ApiResponse<JobReferral>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling revertToNeedsReview.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling revertToNeedsReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-referrals/{id}/revert-to-needs-review`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JobReferralToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobReferralFromJSON(jsonValue));
    }

    /**
     * revert triaging status back to needs review
     */
    async revertToNeedsReview(requestParameters: ApiApiRevertToNeedsReviewRequest): Promise<JobReferral> {
        const response = await this.revertToNeedsReviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * saveCompanyPitchQuestionSchema
     */
    async saveCompanyPitchQuestionSchemaRaw(requestParameters: ApiApiSaveCompanyPitchQuestionSchemaRequest): Promise<runtime.ApiResponse<SaveCompanyPitchQuestionSchema1>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling saveCompanyPitchQuestionSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/save-company-pitch-questions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveCompanyPitchQuestionSchemaToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SaveCompanyPitchQuestionSchema1FromJSON(jsonValue));
    }

    /**
     * saveCompanyPitchQuestionSchema
     */
    async saveCompanyPitchQuestionSchema(requestParameters: ApiApiSaveCompanyPitchQuestionSchemaRequest): Promise<SaveCompanyPitchQuestionSchema1> {
        const response = await this.saveCompanyPitchQuestionSchemaRaw(requestParameters);
        return await response.value();
    }

    /**
     * saveJobPitchQuestionSchema
     */
    async saveJobPitchQuestionSchemaRaw(requestParameters: ApiApiSaveJobPitchQuestionSchemaRequest): Promise<runtime.ApiResponse<SaveJobPitchQuestionSchema1>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling saveJobPitchQuestionSchema.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling saveJobPitchQuestionSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/save-job-pitch-questions/{job_id}`.replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveJobPitchQuestionSchemaToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SaveJobPitchQuestionSchema1FromJSON(jsonValue));
    }

    /**
     * saveJobPitchQuestionSchema
     */
    async saveJobPitchQuestionSchema(requestParameters: ApiApiSaveJobPitchQuestionSchemaRequest): Promise<SaveJobPitchQuestionSchema1> {
        const response = await this.saveJobPitchQuestionSchemaRaw(requestParameters);
        return await response.value();
    }

    /**
     * Schedule candidate interview
     */
    async scheduleCandidateInterviewRaw(requestParameters: ApiApiScheduleCandidateInterviewOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling scheduleCandidateInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/schedule-candidate-interview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduleCandidateInterviewRequestToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Schedule candidate interview
     */
    async scheduleCandidateInterview(requestParameters: ApiApiScheduleCandidateInterviewOperationRequest): Promise<void> {
        await this.scheduleCandidateInterviewRaw(requestParameters);
    }

    /**
     * searchDover
     */
    async searchDoverRaw(requestParameters: ApiApiSearchDoverRequest): Promise<runtime.ApiResponse<SearchDoverResponse>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/search-dover`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchDoverResponseFromJSON(jsonValue));
    }

    /**
     * searchDover
     */
    async searchDover(requestParameters: ApiApiSearchDoverRequest): Promise<SearchDoverResponse> {
        const response = await this.searchDoverRaw(requestParameters);
        return await response.value();
    }

    /**
     * sends the candidate info to slack if not already sent
     */
    async sendCandidateToSlackRaw(requestParameters: ApiApiSendCandidateToSlackRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling sendCandidateToSlack.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/send_to_slack`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject10ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * sends the candidate info to slack if not already sent
     */
    async sendCandidateToSlack(requestParameters: ApiApiSendCandidateToSlackRequest): Promise<InlineResponse2001> {
        const response = await this.sendCandidateToSlackRaw(requestParameters);
        return await response.value();
    }

    /**
     * send a CampaignMessageRequest\'s EmailSendRequest immediately
     */
    async sendEmailRaw(requestParameters: ApiApiSendEmailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/campaign-message-request/{id}/send_email`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * send a CampaignMessageRequest\'s EmailSendRequest immediately
     */
    async sendEmail(requestParameters: ApiApiSendEmailRequest): Promise<void> {
        await this.sendEmailRaw(requestParameters);
    }

    /**
     * sends external job slack channel invite to requesting user
     */
    async sendJobSlackChannelInviteRaw(requestParameters: ApiApiSendJobSlackChannelInviteRequest): Promise<runtime.ApiResponse<SendJobSlackChannelInviteResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendJobSlackChannelInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/send_job_slack_channel_invite`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SendJobSlackChannelInviteResponseFromJSON(jsonValue));
    }

    /**
     * sends external job slack channel invite to requesting user
     */
    async sendJobSlackChannelInvite(requestParameters: ApiApiSendJobSlackChannelInviteRequest): Promise<SendJobSlackChannelInviteResponse> {
        const response = await this.sendJobSlackChannelInviteRaw(requestParameters);
        return await response.value();
    }

    /**
     * send a new user invitation
     */
    async sendNewUserInvitationRaw(requestParameters: ApiApiSendNewUserInvitationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling sendNewUserInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/send-new-user-invitation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendNewUserInvitationeRequestToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * send a new user invitation
     */
    async sendNewUserInvitation(requestParameters: ApiApiSendNewUserInvitationRequest): Promise<void> {
        await this.sendNewUserInvitationRaw(requestParameters);
    }

    /**
     * Set user alias
     */
    async setAliasRaw(requestParameters: ApiApiSetAliasRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling setAlias.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/users/set_alias`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject13ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Set user alias
     */
    async setAlias(requestParameters: ApiApiSetAliasRequest): Promise<InlineResponse2001> {
        const response = await this.setAliasRaw(requestParameters);
        return await response.value();
    }

    /**
     * Changes all email templates to this state.
     */
    async setAllEmailTemplateStateRaw(requestParameters: ApiApiSetAllEmailTemplateStateRequest): Promise<runtime.ApiResponse<Array<HiringStageEmailTemplate>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling setAllEmailTemplateState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-stage-email-templates/set_all_email_template_state`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject19ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HiringStageEmailTemplateFromJSON));
    }

    /**
     * Changes all email templates to this state.
     */
    async setAllEmailTemplateState(requestParameters: ApiApiSetAllEmailTemplateStateRequest): Promise<Array<HiringStageEmailTemplate>> {
        const response = await this.setAllEmailTemplateStateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set client alias on user
     */
    async setClientAliasRaw(requestParameters: ApiApiSetClientAliasRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling setClientAlias.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/users/set_client_alias`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject14ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Set client alias on user
     */
    async setClientAlias(requestParameters: ApiApiSetClientAliasRequest): Promise<InlineResponse2001> {
        const response = await this.setClientAliasRaw(requestParameters);
        return await response.value();
    }

    /**
     * Block or unblock a user
     */
    async setUserBlockedFlagRaw(requestParameters: ApiApiSetUserBlockedFlagRequest): Promise<runtime.ApiResponse<SetUserToBlockedResponse>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling setUserBlockedFlag.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling setUserBlockedFlag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/{user}/set_user_blocked_flag`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetUserToBlockedRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SetUserToBlockedResponseFromJSON(jsonValue));
    }

    /**
     * Block or unblock a user
     */
    async setUserBlockedFlag(requestParameters: ApiApiSetUserBlockedFlagRequest): Promise<SetUserToBlockedResponse> {
        const response = await this.setUserBlockedFlagRaw(requestParameters);
        return await response.value();
    }

    /**
     * build similar candidates profiles for search id
     */
    async showUnreviewedSimilarCandidatesRaw(requestParameters: ApiApiShowUnreviewedSimilarCandidatesOperationRequest): Promise<runtime.ApiResponse<Array<ShowUnreviewedSimilarCandidatesResponse>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling showUnreviewedSimilarCandidates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/get-unreviewed-similar-candidates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShowUnreviewedSimilarCandidatesRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShowUnreviewedSimilarCandidatesResponseFromJSON));
    }

    /**
     * build similar candidates profiles for search id
     */
    async showUnreviewedSimilarCandidates(requestParameters: ApiApiShowUnreviewedSimilarCandidatesOperationRequest): Promise<Array<ShowUnreviewedSimilarCandidatesResponse>> {
        const response = await this.showUnreviewedSimilarCandidatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Snoozes a job
     */
    async snoozeJobRaw(requestParameters: ApiApiSnoozeJobRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling snoozeJob.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling snoozeJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/snooze_job`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SnoozeJobToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Snoozes a job
     */
    async snoozeJob(requestParameters: ApiApiSnoozeJobRequest): Promise<InlineResponse2001> {
        const response = await this.snoozeJobRaw(requestParameters);
        return await response.value();
    }

    /**
     * Action a candidate.
     */
    async submitDecisionRaw(requestParameters: ApiApiSubmitDecisionOperationRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitDecision.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling submitDecision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/candidate-actions/{id}/submit_decision`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitDecisionRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseCandidateActionResponseFromJSON(jsonValue));
    }

    /**
     * Action a candidate.
     */
    async submitDecision(requestParameters: ApiApiSubmitDecisionOperationRequest): Promise<BaseCandidateActionResponse> {
        const response = await this.submitDecisionRaw(requestParameters);
        return await response.value();
    }

    /**
     * submit an inbound applicant from an external inbound source
     */
    async submitExternalApplicantRaw(requestParameters: ApiApiSubmitExternalApplicantRequest): Promise<runtime.ApiResponse<ExternalApplicantResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling submitExternalApplicant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/inbound/application-portal-inbound-application/external-applicant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalApplicantRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalApplicantResponseFromJSON(jsonValue));
    }

    /**
     * submit an inbound applicant from an external inbound source
     */
    async submitExternalApplicant(requestParameters: ApiApiSubmitExternalApplicantRequest): Promise<ExternalApplicantResponse> {
        const response = await this.submitExternalApplicantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Submit multipart interview scheduling request
     */
    async submitMultipartInterviewSchedulingRequestRaw(requestParameters: ApiApiSubmitMultipartInterviewSchedulingRequestRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling submitMultipartInterviewSchedulingRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/submit-multipart-interview-scheduling-request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitMultipartInterviewSchedulingRequestToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Submit multipart interview scheduling request
     */
    async submitMultipartInterviewSchedulingRequest(requestParameters: ApiApiSubmitMultipartInterviewSchedulingRequestRequest): Promise<void> {
        await this.submitMultipartInterviewSchedulingRequestRaw(requestParameters);
    }

    /**
     * Submit no show reschedule
     */
    async submitNoShowRescheduleCandidateRaw(requestParameters: ApiApiSubmitNoShowRescheduleCandidateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.candidateId === null || requestParameters.candidateId === undefined) {
            throw new runtime.RequiredError('candidateId','Required parameter requestParameters.candidateId was null or undefined when calling submitNoShowRescheduleCandidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/submit-no-show-reschedule/{candidate_id}`.replace(`{${"candidate_id"}}`, encodeURIComponent(String(requestParameters.candidateId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Submit no show reschedule
     */
    async submitNoShowRescheduleCandidate(requestParameters: ApiApiSubmitNoShowRescheduleCandidateRequest): Promise<void> {
        await this.submitNoShowRescheduleCandidateRaw(requestParameters);
    }

    /**
     * submit an inbound applicant from an integrated partner source
     */
    async submitPartnerApplicantRaw(requestParameters: ApiApiSubmitPartnerApplicantRequest): Promise<runtime.ApiResponse<ExternalApplicantResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling submitPartnerApplicant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/inbound/partner/submit-applicant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalApplicantRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalApplicantResponseFromJSON(jsonValue));
    }

    /**
     * submit an inbound applicant from an integrated partner source
     */
    async submitPartnerApplicant(requestParameters: ApiApiSubmitPartnerApplicantRequest): Promise<ExternalApplicantResponse> {
        const response = await this.submitPartnerApplicantRaw(requestParameters);
        return await response.value();
    }

    /**
     * submit persona questionnaire
     */
    async submitPersonaQuestionnaireRaw(requestParameters: ApiApiSubmitPersonaQuestionnaireRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitPersonaQuestionnaire.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling submitPersonaQuestionnaire.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/persona-questionnaire/{id}/submit_persona_questionnaire`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject22ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * submit persona questionnaire
     */
    async submitPersonaQuestionnaire(requestParameters: ApiApiSubmitPersonaQuestionnaireRequest): Promise<object> {
        const response = await this.submitPersonaQuestionnaireRaw(requestParameters);
        return await response.value();
    }

    /**
     * Attempt to save a user credential
     */
    async submitUserCredentialRaw(requestParameters: ApiApiSubmitUserCredentialOperationRequest): Promise<runtime.ApiResponse<InlineResponse20068>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling submitUserCredential.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/submit-user-credential`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitUserCredentialRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20068FromJSON(jsonValue));
    }

    /**
     * Attempt to save a user credential
     */
    async submitUserCredential(requestParameters: ApiApiSubmitUserCredentialOperationRequest): Promise<InlineResponse20068> {
        const response = await this.submitUserCredentialRaw(requestParameters);
        return await response.value();
    }

    /**
     * Swap the interview stage type on a HiringPipelineStage
     */
    async swapInterviewStageTypeRaw(requestParameters: ApiApiSwapInterviewStageTypeOperationRequest): Promise<runtime.ApiResponse<HiringPipelineStage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling swapInterviewStageType.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling swapInterviewStageType.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling swapInterviewStageType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{job_id}/interview-plan/{id}/swap`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SwapInterviewStageTypeRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringPipelineStageFromJSON(jsonValue));
    }

    /**
     * Swap the interview stage type on a HiringPipelineStage
     */
    async swapInterviewStageType(requestParameters: ApiApiSwapInterviewStageTypeOperationRequest): Promise<HiringPipelineStage> {
        const response = await this.swapInterviewStageTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Syncs ATS stages from ATS to Dover and lists our cached results
     */
    async syncAndListJobAtsStagesRaw(requestParameters: ApiApiSyncAndListJobAtsStagesRequest): Promise<runtime.ApiResponse<SyncAndListJobAtsStages>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling syncAndListJobAtsStages.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling syncAndListJobAtsStages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/sync_and_list_ats_stages`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject21ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SyncAndListJobAtsStagesFromJSON(jsonValue));
    }

    /**
     * Syncs ATS stages from ATS to Dover and lists our cached results
     */
    async syncAndListJobAtsStages(requestParameters: ApiApiSyncAndListJobAtsStagesRequest): Promise<SyncAndListJobAtsStages> {
        const response = await this.syncAndListJobAtsStagesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Triggers a sync that pulls sources from the ATS into Dover
     */
    async syncAtsSourcesRaw(requestParameters: ApiApiSyncAtsSourcesRequest): Promise<runtime.ApiResponse<SyncAtsSourcesResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling syncAtsSources.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling syncAtsSources.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-candidate-source-settings/{id}/sync-ats-sources`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SyncAtsSourcesResponseFromJSON(jsonValue));
    }

    /**
     * Triggers a sync that pulls sources from the ATS into Dover
     */
    async syncAtsSources(requestParameters: ApiApiSyncAtsSourcesRequest): Promise<SyncAtsSourcesResponse> {
        const response = await this.syncAtsSourcesRaw(requestParameters);
        return await response.value();
    }

    /**
     * toggle the starred status of a candidate for a specific user
     */
    async toggleStarCandidateRaw(requestParameters: ApiApiToggleStarCandidateOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling toggleStarCandidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v2/starred-candidate/toggle_star_candidate`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ToggleStarCandidateRequestToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * toggle the starred status of a candidate for a specific user
     */
    async toggleStarCandidate(requestParameters: ApiApiToggleStarCandidateOperationRequest): Promise<void> {
        await this.toggleStarCandidateRaw(requestParameters);
    }

    /**
     * update archive reason
     */
    async updateArchiveReasonRaw(requestParameters: ApiApiUpdateArchiveReasonRequest): Promise<runtime.ApiResponse<ArchiveReason>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateArchiveReason.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateArchiveReason.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/archive-reasons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ArchiveReasonToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ArchiveReasonFromJSON(jsonValue));
    }

    /**
     * update archive reason
     */
    async updateArchiveReason(requestParameters: ApiApiUpdateArchiveReasonRequest): Promise<ArchiveReason> {
        const response = await this.updateArchiveReasonRaw(requestParameters);
        return await response.value();
    }

    /**
     * update campaign message request
     */
    async updateCampaignMessageRequestRaw(requestParameters: ApiApiUpdateCampaignMessageRequestRequest): Promise<runtime.ApiResponse<UpdateCampaignMessageRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCampaignMessageRequest.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateCampaignMessageRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/campaign-message-request/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCampaignMessageRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCampaignMessageRequestFromJSON(jsonValue));
    }

    /**
     * update campaign message request
     */
    async updateCampaignMessageRequest(requestParameters: ApiApiUpdateCampaignMessageRequestRequest): Promise<UpdateCampaignMessageRequest> {
        const response = await this.updateCampaignMessageRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * update a candidate interview
     */
    async updateCandidateInterviewRaw(requestParameters: ApiApiUpdateCandidateInterviewRequest): Promise<runtime.ApiResponse<UpdateCandidateInterview>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCandidateInterview.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateCandidateInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate-interview/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCandidateInterviewToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCandidateInterviewFromJSON(jsonValue));
    }

    /**
     * update a candidate interview
     */
    async updateCandidateInterview(requestParameters: ApiApiUpdateCandidateInterviewRequest): Promise<UpdateCandidateInterview> {
        const response = await this.updateCandidateInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a CompanyList
     */
    async updateCompanyListRaw(requestParameters: ApiApiUpdateCompanyListRequest): Promise<runtime.ApiResponse<CompanyList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompanyList.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateCompanyList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/company-lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyListToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyListFromJSON(jsonValue));
    }

    /**
     * Update a CompanyList
     */
    async updateCompanyList(requestParameters: ApiApiUpdateCompanyListRequest): Promise<CompanyList> {
        const response = await this.updateCompanyListRaw(requestParameters);
        return await response.value();
    }

    /**
     * updates emails_per_week on JobFulfillment table
     */
    async updateDoverOutboundConfigurationRaw(requestParameters: ApiApiUpdateDoverOutboundConfigurationRequest): Promise<runtime.ApiResponse<UpdateDoverOutboundConfiguration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDoverOutboundConfiguration.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateDoverOutboundConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/update_dover_outbound_configuration`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDoverOutboundConfigurationToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateDoverOutboundConfigurationFromJSON(jsonValue));
    }

    /**
     * updates emails_per_week on JobFulfillment table
     */
    async updateDoverOutboundConfiguration(requestParameters: ApiApiUpdateDoverOutboundConfigurationRequest): Promise<UpdateDoverOutboundConfiguration> {
        const response = await this.updateDoverOutboundConfigurationRaw(requestParameters);
        return await response.value();
    }

    /**
     * update candidate email
     */
    async updateEmailRaw(requestParameters: ApiApiUpdateEmailRequest): Promise<runtime.ApiResponse<Email>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateEmail.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: EmailToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailFromJSON(jsonValue));
    }

    /**
     * update candidate email
     */
    async updateEmail(requestParameters: ApiApiUpdateEmailRequest): Promise<Email> {
        const response = await this.updateEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a HiringPipelineStage
     */
    async updateHiringPipelineStageRaw(requestParameters: ApiApiUpdateHiringPipelineStageOperationRequest): Promise<runtime.ApiResponse<HiringPipelineStage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateHiringPipelineStage.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling updateHiringPipelineStage.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateHiringPipelineStage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/jobs/{job_id}/interview-plan/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateHiringPipelineStageRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringPipelineStageFromJSON(jsonValue));
    }

    /**
     * Update a HiringPipelineStage
     */
    async updateHiringPipelineStage(requestParameters: ApiApiUpdateHiringPipelineStageOperationRequest): Promise<HiringPipelineStage> {
        const response = await this.updateHiringPipelineStageRaw(requestParameters);
        return await response.value();
    }

    /**
     * update inbound application
     */
    async updateInboundApplicationRaw(requestParameters: ApiApiUpdateInboundApplicationRequest): Promise<runtime.ApiResponse<ApplicationPortalInboundApplication>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateInboundApplication.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling updateInboundApplication.');
        }

        if (requestParameters.firstName === null || requestParameters.firstName === undefined) {
            throw new runtime.RequiredError('firstName','Required parameter requestParameters.firstName was null or undefined when calling updateInboundApplication.');
        }

        if (requestParameters.lastName === null || requestParameters.lastName === undefined) {
            throw new runtime.RequiredError('lastName','Required parameter requestParameters.lastName was null or undefined when calling updateInboundApplication.');
        }

        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling updateInboundApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.jobId !== undefined) {
            formParams.append('job_id', requestParameters.jobId as any);
        }

        if (requestParameters.referrerSource !== undefined) {
            formParams.append('referrer_source', requestParameters.referrerSource as any);
        }

        if (requestParameters.firstName !== undefined) {
            formParams.append('first_name', requestParameters.firstName as any);
        }

        if (requestParameters.lastName !== undefined) {
            formParams.append('last_name', requestParameters.lastName as any);
        }

        if (requestParameters.linkedinUrl !== undefined) {
            formParams.append('linkedin_url', requestParameters.linkedinUrl as any);
        }

        if (requestParameters.email !== undefined) {
            formParams.append('email', requestParameters.email as any);
        }

        if (requestParameters.phoneNumber !== undefined) {
            formParams.append('phone_number', requestParameters.phoneNumber as any);
        }

        if (requestParameters.resume !== undefined) {
            formParams.append('resume', requestParameters.resume as any);
        }

        if (requestParameters.additionalInfo !== undefined) {
            formParams.append('additional_info', requestParameters.additionalInfo as any);
        }

        if (requestParameters.applicationQuestions !== undefined) {
            formParams.append('application_questions', requestParameters.applicationQuestions as any);
        }

        if (requestParameters.bookmarked !== undefined) {
            formParams.append('bookmarked', requestParameters.bookmarked as any);
        }

        if (requestParameters.optedInToTalentNetwork !== undefined) {
            formParams.append('opted_in_to_talent_network', requestParameters.optedInToTalentNetwork as any);
        }

        const response = await this.request({
            path: `/api/v1/inbound/application-portal-inbound-application/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationPortalInboundApplicationFromJSON(jsonValue));
    }

    /**
     * update inbound application
     */
    async updateInboundApplication(requestParameters: ApiApiUpdateInboundApplicationRequest): Promise<ApplicationPortalInboundApplication> {
        const response = await this.updateInboundApplicationRaw(requestParameters);
        return await response.value();
    }

    /**
     * update interview rubric response
     */
    async updateInterviewRubricResponseRaw(requestParameters: ApiApiUpdateInterviewRubricResponseRequest): Promise<runtime.ApiResponse<InterviewRubricResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateInterviewRubricResponse.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateInterviewRubricResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-interview-rubric-response/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewRubricResponseToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewRubricResponseFromJSON(jsonValue));
    }

    /**
     * update interview rubric response
     */
    async updateInterviewRubricResponse(requestParameters: ApiApiUpdateInterviewRubricResponseRequest): Promise<InterviewRubricResponse> {
        const response = await this.updateInterviewRubricResponseRaw(requestParameters);
        return await response.value();
    }

    /**
     * update interviewer
     */
    async updateInterviewerRaw(requestParameters: ApiApiUpdateInterviewerRequest): Promise<runtime.ApiResponse<ClientInterviewer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateInterviewer.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateInterviewer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/client-interviewers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientInterviewerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientInterviewerFromJSON(jsonValue));
    }

    /**
     * update interviewer
     */
    async updateInterviewer(requestParameters: ApiApiUpdateInterviewerRequest): Promise<ClientInterviewer> {
        const response = await this.updateInterviewerRaw(requestParameters);
        return await response.value();
    }

    /**
     * update job description
     */
    async updateJobDescriptionRaw(requestParameters: ApiApiUpdateJobDescriptionRequest): Promise<runtime.ApiResponse<DoverJobDescription>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateJobDescription.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateJobDescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-descriptions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateJobDescriptionToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DoverJobDescriptionFromJSON(jsonValue));
    }

    /**
     * update job description
     */
    async updateJobDescription(requestParameters: ApiApiUpdateJobDescriptionRequest): Promise<DoverJobDescription> {
        const response = await this.updateJobDescriptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * update hiring team info for job
     */
    async updateJobHiringTeamRaw(requestParameters: ApiApiUpdateJobHiringTeamRequest): Promise<runtime.ApiResponse<UpdateJobHiringTeam>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateJobHiringTeam.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateJobHiringTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-setups/{id}/update_hiring_team`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateJobHiringTeamToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateJobHiringTeamFromJSON(jsonValue));
    }

    /**
     * update hiring team info for job
     */
    async updateJobHiringTeam(requestParameters: ApiApiUpdateJobHiringTeamRequest): Promise<UpdateJobHiringTeam> {
        const response = await this.updateJobHiringTeamRaw(requestParameters);
        return await response.value();
    }

    /**
     * update a job position
     */
    async updateJobPositionRaw(requestParameters: ApiApiUpdateJobPositionRequest): Promise<runtime.ApiResponse<HiringPlanJobPosition>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateJobPosition.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateJobPosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-plan-job-positions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HiringPlanJobPositionToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringPlanJobPositionFromJSON(jsonValue));
    }

    /**
     * update a job position
     */
    async updateJobPosition(requestParameters: ApiApiUpdateJobPositionRequest): Promise<HiringPlanJobPosition> {
        const response = await this.updateJobPositionRaw(requestParameters);
        return await response.value();
    }

    /**
     * update a job position opening
     */
    async updateJobPositionOpeningRaw(requestParameters: ApiApiUpdateJobPositionOpeningRequest): Promise<runtime.ApiResponse<HiringPlanJobPositionOpening>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateJobPositionOpening.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateJobPositionOpening.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/hiring-plan-job-position-openings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HiringPlanJobPositionOpeningToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HiringPlanJobPositionOpeningFromJSON(jsonValue));
    }

    /**
     * update a job position opening
     */
    async updateJobPositionOpening(requestParameters: ApiApiUpdateJobPositionOpeningRequest): Promise<HiringPlanJobPositionOpening> {
        const response = await this.updateJobPositionOpeningRaw(requestParameters);
        return await response.value();
    }

    /**
     * update job visibility
     */
    async updateJobVisibilityRaw(requestParameters: ApiApiUpdateJobVisibilityRequest): Promise<runtime.ApiResponse<UpdateJobVisibility>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateJobVisibility.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateJobVisibility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-setups/{id}/update_job_visibility`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateJobVisibilityToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateJobVisibilityFromJSON(jsonValue));
    }

    /**
     * update job visibility
     */
    async updateJobVisibility(requestParameters: ApiApiUpdateJobVisibilityRequest): Promise<UpdateJobVisibility> {
        const response = await this.updateJobVisibilityRaw(requestParameters);
        return await response.value();
    }

    /**
     * update notification config
     */
    async updateNotificationConfigRaw(requestParameters: ApiApiUpdateNotificationConfigRequest): Promise<runtime.ApiResponse<NotificationConfig>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateNotificationConfig.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateNotificationConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/notification-config/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationConfigToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationConfigFromJSON(jsonValue));
    }

    /**
     * update notification config
     */
    async updateNotificationConfig(requestParameters: ApiApiUpdateNotificationConfigRequest): Promise<NotificationConfig> {
        const response = await this.updateNotificationConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     * update a hiring pipeline stage
     */
    async updateOnboardingHiringPipelineStageRaw(requestParameters: ApiApiUpdateOnboardingHiringPipelineStageRequest): Promise<runtime.ApiResponse<OnboardingHiringPipelineStage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOnboardingHiringPipelineStage.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateOnboardingHiringPipelineStage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/onboarding-hiring-pipeline-stages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardingHiringPipelineStageToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingHiringPipelineStageFromJSON(jsonValue));
    }

    /**
     * update a hiring pipeline stage
     */
    async updateOnboardingHiringPipelineStage(requestParameters: ApiApiUpdateOnboardingHiringPipelineStageRequest): Promise<OnboardingHiringPipelineStage> {
        const response = await this.updateOnboardingHiringPipelineStageRaw(requestParameters);
        return await response.value();
    }

    /**
     * update candidate pending customer response
     */
    async updatePendingCustomerResponseRaw(requestParameters: ApiApiUpdatePendingCustomerResponseRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updatePendingCustomerResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/update_pending_customer_response`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject11ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * update candidate pending customer response
     */
    async updatePendingCustomerResponse(requestParameters: ApiApiUpdatePendingCustomerResponseRequest): Promise<InlineResponse2001> {
        const response = await this.updatePendingCustomerResponseRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a Persona
     */
    async updatePersonaRaw(requestParameters: ApiApiUpdatePersonaRequest): Promise<runtime.ApiResponse<Persona>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePersona.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updatePersona.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/personas/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PersonaToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonaFromJSON(jsonValue));
    }

    /**
     * Update a Persona
     */
    async updatePersona(requestParameters: ApiApiUpdatePersonaRequest): Promise<Persona> {
        const response = await this.updatePersonaRaw(requestParameters);
        return await response.value();
    }

    /**
     * update a profile attribute
     */
    async updateProfileAttributesRaw(requestParameters: ApiApiUpdateProfileAttributesRequest): Promise<runtime.ApiResponse<AdminProfileAttribute>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProfileAttributes.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateProfileAttributes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/profile-attribute/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminProfileAttributeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminProfileAttributeFromJSON(jsonValue));
    }

    /**
     * update a profile attribute
     */
    async updateProfileAttributes(requestParameters: ApiApiUpdateProfileAttributesRequest): Promise<AdminProfileAttribute> {
        const response = await this.updateProfileAttributesRaw(requestParameters);
        return await response.value();
    }

    /**
     * update candidate reply type
     */
    async updateReplyTypeRaw(requestParameters: ApiApiUpdateReplyTypeRequest): Promise<runtime.ApiResponse<InlineResponse2005>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateReplyType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/front/update_reply_type`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject12ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2005FromJSON(jsonValue));
    }

    /**
     * update candidate reply type
     */
    async updateReplyType(requestParameters: ApiApiUpdateReplyTypeRequest): Promise<InlineResponse2005> {
        const response = await this.updateReplyTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a SchoolList
     */
    async updateSchoolListRaw(requestParameters: ApiApiUpdateSchoolListRequest): Promise<runtime.ApiResponse<SchoolList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSchoolList.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateSchoolList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/school-lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SchoolListToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolListFromJSON(jsonValue));
    }

    /**
     * Update a SchoolList
     */
    async updateSchoolList(requestParameters: ApiApiUpdateSchoolListRequest): Promise<SchoolList> {
        const response = await this.updateSchoolListRaw(requestParameters);
        return await response.value();
    }

    /**
     * update search
     */
    async updateSearchRaw(requestParameters: ApiApiUpdateSearchRequest): Promise<runtime.ApiResponse<AdminSearch>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSearch.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/searches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminSearchToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminSearchFromJSON(jsonValue));
    }

    /**
     * update search
     */
    async updateSearch(requestParameters: ApiApiUpdateSearchRequest): Promise<AdminSearch> {
        const response = await this.updateSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * derives and sets search params using SearchFeatures
     */
    async updateSearchFromFeaturesRaw(requestParameters: ApiApiUpdateSearchFromFeaturesOperationRequest): Promise<runtime.ApiResponse<UpdateSearchFromFeaturesSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSearchFromFeatures.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateSearchFromFeatures.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/searches/{id}/update_search_from_features`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSearchFromFeaturesRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateSearchFromFeaturesSuccessFromJSON(jsonValue));
    }

    /**
     * derives and sets search params using SearchFeatures
     */
    async updateSearchFromFeatures(requestParameters: ApiApiUpdateSearchFromFeaturesOperationRequest): Promise<UpdateSearchFromFeaturesSuccess> {
        const response = await this.updateSearchFromFeaturesRaw(requestParameters);
        return await response.value();
    }

    /**
     * update search v3 params
     */
    async updateSearchV3Raw(requestParameters: ApiApiUpdateSearchV3Request): Promise<runtime.ApiResponse<SearchV3>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSearchV3.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateSearchV3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/searches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SearchV3ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchV3FromJSON(jsonValue));
    }

    /**
     * update search v3 params
     */
    async updateSearchV3(requestParameters: ApiApiUpdateSearchV3Request): Promise<SearchV3> {
        const response = await this.updateSearchV3Raw(requestParameters);
        return await response.value();
    }

    /**
     * Updates the user\'s client role
     */
    async updateUserClientRoleRaw(requestParameters: ApiApiUpdateUserClientRoleOperationRequest): Promise<runtime.ApiResponse<UpdateClientRoleResponse>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling updateUserClientRole.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateUserClientRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/{user}/set_client_role`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserClientRoleRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateClientRoleResponseFromJSON(jsonValue));
    }

    /**
     * Updates the user\'s client role
     */
    async updateUserClientRole(requestParameters: ApiApiUpdateUserClientRoleOperationRequest): Promise<UpdateClientRoleResponse> {
        const response = await this.updateUserClientRoleRaw(requestParameters);
        return await response.value();
    }

    /**
     * updates user onboarding flow
     */
    async updateUserOnboardingFlowRaw(requestParameters: ApiApiUpdateUserOnboardingFlowRequest): Promise<runtime.ApiResponse<UserOnboardingFlow>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserOnboardingFlow.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateUserOnboardingFlow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/onboarding-user-onboarding-flow/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserOnboardingFlowToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOnboardingFlowFromJSON(jsonValue));
    }

    /**
     * updates user onboarding flow
     */
    async updateUserOnboardingFlow(requestParameters: ApiApiUpdateUserOnboardingFlowRequest): Promise<UserOnboardingFlow> {
        const response = await this.updateUserOnboardingFlowRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload logo
     */
    async uploadLogoRaw(requestParameters: ApiApiUploadLogoRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadLogo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/v1/upload-logo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Upload logo
     */
    async uploadLogo(requestParameters: ApiApiUploadLogoRequest): Promise<object> {
        const response = await this.uploadLogoRaw(requestParameters);
        return await response.value();
    }

    /**
     * upsert job candidate source setting
     */
    async upsertJobCandidateSourceSettingRaw(requestParameters: ApiApiUpsertJobCandidateSourceSettingRequest): Promise<runtime.ApiResponse<JobCandidateSourceSetting>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling upsertJobCandidateSourceSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-candidate-source-settings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateJobCandidateSourceSettingToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobCandidateSourceSettingFromJSON(jsonValue));
    }

    /**
     * upsert job candidate source setting
     */
    async upsertJobCandidateSourceSetting(requestParameters: ApiApiUpsertJobCandidateSourceSettingRequest): Promise<JobCandidateSourceSetting> {
        const response = await this.upsertJobCandidateSourceSettingRaw(requestParameters);
        return await response.value();
    }

    /**
     * upsert job feature setting
     */
    async upsertJobFeatureSettingRaw(requestParameters: ApiApiUpsertJobFeatureSettingRequest): Promise<runtime.ApiResponse<JobFeatureSetting>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling upsertJobFeatureSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-feature-settings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertJobFeatureSettingToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JobFeatureSettingFromJSON(jsonValue));
    }

    /**
     * upsert job feature setting
     */
    async upsertJobFeatureSetting(requestParameters: ApiApiUpsertJobFeatureSettingRequest): Promise<JobFeatureSetting> {
        const response = await this.upsertJobFeatureSettingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upserts potentially multiple JobAtsSourceSetting records, keying off of job_candidate_source_setting_id and ats_source_name
     */
    async upsertMultipleJobAtsSourceSettingsRaw(requestParameters: ApiApiUpsertMultipleJobAtsSourceSettingsRequest): Promise<runtime.ApiResponse<Array<JobAtsSourceSetting>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling upsertMultipleJobAtsSourceSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/job-ats-source-setting/upsert-multiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JobAtsSourceSettingUpsertMultipleRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobAtsSourceSettingFromJSON));
    }

    /**
     * Upserts potentially multiple JobAtsSourceSetting records, keying off of job_candidate_source_setting_id and ats_source_name
     */
    async upsertMultipleJobAtsSourceSettings(requestParameters: ApiApiUpsertMultipleJobAtsSourceSettingsRequest): Promise<Array<JobAtsSourceSetting>> {
        const response = await this.upsertMultipleJobAtsSourceSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Validate that an email is ok to be added/updated.
     */
    async validateEmailRaw(requestParameters: ApiApiValidateEmailRequest): Promise<runtime.ApiResponse<BaseCandidateActionResponseError>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling validateEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/validate-emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseCandidateActionResponseErrorFromJSON(jsonValue));
    }

    /**
     * Validate that an email is ok to be added/updated.
     */
    async validateEmail(requestParameters: ApiApiValidateEmailRequest): Promise<BaseCandidateActionResponseError> {
        const response = await this.validateEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * Validate that a given scheduling link is tied to the given candidate
     */
    async validateSchedulingLinkForCandidateRaw(requestParameters: ApiApiValidateSchedulingLinkForCandidateOperationRequest): Promise<runtime.ApiResponse<ValidateSchedulingLinkForCandidateResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling validateSchedulingLinkForCandidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/candidate/validate-scheduling-link-for-candidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateSchedulingLinkForCandidateRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateSchedulingLinkForCandidateResponseFromJSON(jsonValue));
    }

    /**
     * Validate that a given scheduling link is tied to the given candidate
     */
    async validateSchedulingLinkForCandidate(requestParameters: ApiApiValidateSchedulingLinkForCandidateOperationRequest): Promise<ValidateSchedulingLinkForCandidateResponse> {
        const response = await this.validateSchedulingLinkForCandidateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Withdraw a candidate during/after the initial phone interview.
     */
    async withdrawCandidateRaw(requestParameters: ApiApiWithdrawCandidateRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.candidateId === null || requestParameters.candidateId === undefined) {
            throw new runtime.RequiredError('candidateId','Required parameter requestParameters.candidateId was null or undefined when calling withdrawCandidate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling withdrawCandidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/dover-interviewers/candidates/{candidate_id}/withdraw`.replace(`{${"candidate_id"}}`, encodeURIComponent(String(requestParameters.candidateId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject17ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Withdraw a candidate during/after the initial phone interview.
     */
    async withdrawCandidate(requestParameters: ApiApiWithdrawCandidateRequest): Promise<InlineResponse2001> {
        const response = await this.withdrawCandidateRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CreateCandidateFileUploadedContextEnum {
    Resume = 'RESUME',
    Portfolio = 'PORTFOLIO',
    InterviewSubmission = 'INTERVIEW_SUBMISSION',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateUserUploadedFileUploadedContextEnum {
    Referrals = 'REFERRALS',
    ManualSourcingExtensionLiProfile = 'MANUAL_SOURCING_EXTENSION_LI_PROFILE',
    CareersPageImage = 'CAREERS_PAGE_IMAGE',
    CompanyLevelContactExclusion = 'COMPANY_LEVEL_CONTACT_EXCLUSION'
}
