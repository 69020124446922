/* eslint-disable react/no-unstable-nested-components */

import { Button } from "@doverhq/dover-ui";
import { Skeleton, Stack } from "@mui/material";
import { SxProps } from "@mui/system";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue } from "jotai";
import React, { useMemo, useEffect } from "react";
import { useTable, Cell, Column, useSortBy } from "react-table";
import { useQueryParam, StringParam, BooleanParam } from "use-query-params";

import ActionsCell from "components/dover/SearchesTable/components/ActionsCell";
import DisabledAutopilotActivationTooltip from "components/dover/SearchesTable/components/DisabledAutopilotActivationTooltip";
import EnablingAutopilotLoadingOverlay from "components/dover/SearchesTable/components/EnablingAutopilotLoadingOverlay";
import { NumContactedCell } from "components/dover/SearchesTable/components/NumContactedCell";
import { RemainingCell } from "components/dover/SearchesTable/components/RemainingCell";
import { ScrollableTable } from "components/dover/SearchesTable/components/ScrollableTable";
import { SearchNameCell } from "components/dover/SearchesTable/components/SearchNameCell";
import { SettingsCell } from "components/dover/SearchesTable/components/SettingsCell";
import { ToggleActivationCell } from "components/dover/SearchesTable/components/ToggleActivationCell";
import { ACTIVE_QUERY_PARAM, ORDERING_QUERY_PARAM } from "components/dover/SearchesTable/constants";
import { ToggleEnablingAutopilotAtom } from "components/dover/sourcing/atoms";
import { Banner, BannerVariant } from "components/library/Banner";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import useShouldDisableAutopilotSetup from "services/doverapi/cross-endpoint-hooks/useDisableAutopilotSetup";
import useEnableAutopilotV2 from "services/doverapi/cross-endpoint-hooks/useEnableAutopilotV2";
import { useGetDoverPlan } from "services/doverapi/endpoints/client/hooks";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { useGetManagedOutboundState } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { useUpsertJobFeatureSettingMutation } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { useListSearchesV3Query } from "services/doverapi/endpoints/search-v3/endpoints";
import {
  ClientDoverPlanEnum,
  DashboardJob,
  JobFeatureStateEnum,
  SearchV3,
  SearchV3SearchTypeEnum,
} from "services/openapi";
import { colors } from "styles/theme";

export const COLUMN_PADDING = "16px";

enum ColumnNameEnum {
  Name = "Name",
  Contacted = "Contacted",
  Remaining = "Remaining",
  Status = "Status",
  Settings = "Settings",
  Actions = "Actions",
}

type CustomColumn<T extends object> = Column<T> & { sx?: SxProps };

const SearchesTable = ({ inJobView }: { inJobView?: boolean }): React.ReactElement => {
  const [ordering, setOrdering] = useQueryParam(ORDERING_QUERY_PARAM, StringParam);

  // ffs
  const useSourcingPage = useFeatureFlag(FeatureFlag.SourcingPage);

  // sometimes jobId will come from ?jobId={jobId}, and sometimes from /job/{jobId}
  const jobId = useJobIdFromUrl();
  const { data: job } = useGetJobQuery(jobId ? jobId : skipToken);
  const [, { isLoading: isUpdatingJobFeatureSetting }] = useUpsertJobFeatureSettingMutation();

  const [active] = useQueryParam(ACTIVE_QUERY_PARAM, BooleanParam);
  const doverPlan = useGetDoverPlan();

  const managedOutboundState = useGetManagedOutboundState({ jobId });
  const outboundEnabled = managedOutboundState === JobFeatureStateEnum.Enabled;
  const shouldEnableAutopilotJobFeature = !outboundEnabled && doverPlan !== ClientDoverPlanEnum.Free;
  const enableAutopilotV2 = useEnableAutopilotV2(job, shouldEnableAutopilotJobFeature);

  // Loading state for enabling autopilot hook
  const isEnablingAutopilot = useAtomValue(ToggleEnablingAutopilotAtom);

  // only show outbound searches
  const { data: searches, isFetching: isFetchingSearches } = useListSearchesV3Query(
    jobId
      ? {
          limit: 1000,
          job: jobId,
          searchTypeList: SearchV3SearchTypeEnum.Outbound,
        }
      : skipToken
  );
  const disableAutopilotSetup = useShouldDisableAutopilotSetup(jobId);
  const outboundDisabled = disableAutopilotSetup();
  const autopilotDisabledTooltip =
    outboundDisabled && jobId ? <DisabledAutopilotActivationTooltip jobId={jobId} /> : undefined;

  const filteredSearches = React.useMemo(() => {
    if (searches === undefined) {
      return undefined;
    }

    // want to show active searches first, then inactive searches
    const activeSearches = searches.filter(search => !!search.active);
    const nonActiveSearches = searches.filter(search => !search.active);

    if (active) {
      return activeSearches;
    }

    return [...activeSearches, ...nonActiveSearches];
  }, [active, searches]);

  const columns = useMemo((): CustomColumn<SearchV3>[] => {
    const customColumns = [
      {
        Header: ColumnNameEnum.Name,
        Cell: ({ row }: Cell<SearchV3>): React.ReactElement => (
          <SearchNameCell
            searchName={row.original.name}
            createdBy={row.original.createdBy}
            modified={row.original.modified}
          />
        ),
        accessor: ColumnNameEnum.Name,
        sx: { width: "300px" },
      },
      {
        Header: ColumnNameEnum.Contacted,
        Cell: ({ row }: Cell<SearchV3>): React.ReactElement => (
          <NumContactedCell numContacted={row.original.numContacted} />
        ),
        accessor: ColumnNameEnum.Contacted,
      },
      {
        Header: ColumnNameEnum.Remaining,
        Cell: ({ row }: Cell<SearchV3>): React.ReactElement => (
          <RemainingCell searchParams={row.original.v3Params} searchId={row.original.id} jobId={jobId ?? undefined} />
        ),
        accessor: ColumnNameEnum.Remaining as any,
      },
      {
        Header: ColumnNameEnum.Status,
        Cell: ({ row }: Cell<SearchV3>): React.ReactElement => <ToggleActivationCell search={row.original} />,
        accessor: ColumnNameEnum.Status as any,
        sx: { width: "200px" },
      },
      {
        Header: ColumnNameEnum.Actions,
        Cell: ({ row }: Cell<SearchV3>): React.ReactElement => <ActionsCell searchId={row.original.id} />,
        accessor: ColumnNameEnum.Actions,
        sx: { width: "200px" },
      },
    ];
    if (jobId && !inJobView) {
      customColumns.push({
        Header: ColumnNameEnum.Settings,
        Cell: (): React.ReactElement => <SettingsCell jobId={jobId} />,
        accessor: ColumnNameEnum.Settings,
        sx: { width: "200px" },
      });
    }
    return customColumns;
  }, [inJobView, jobId]);

  const sortBy = ordering
    ? [
        {
          id: ordering.replace("-", ""),
          desc: ordering.includes("-"),
        },
      ]
    : [
        {
          id: "Name",
          desc: true,
        },
      ];

  const table = useTable(
    {
      columns,
      data: filteredSearches || [],
      manualSortBy: true,
      initialState: { sortBy },
    },
    useSortBy
  );

  useEffect(() => {
    if (!table.state.sortBy.length) {
      setOrdering(null);
      return;
    }
    const direction = table.state.sortBy[0].desc ? "-" : "";
    setOrdering(direction + table.state.sortBy[0].id);
  }, [setOrdering, table.state.sortBy]);

  if (isEnablingAutopilot) {
    return <EnablingAutopilotLoadingOverlay />;
  }

  if (isFetchingSearches) {
    return <Skeleton width="100%" height="160px" />;
  }

  return (
    <Stack spacing={2}>
      {!useSourcingPage && outboundDisabled && doverPlan !== ClientDoverPlanEnum.Free && (
        <Banner variant={BannerVariant.Warning} centeredIcon={true}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <BodySmall>Sourcing Autopilot is off. No new candidates will be sourced for this role.</BodySmall>
            <Stack direction="row" spacing={1}>
              <Tooltip title={autopilotDisabledTooltip}>
                <div>
                  <Button variant="primaryOutlined" isDisabled={outboundDisabled} onPress={enableAutopilotV2}>
                    Enable
                  </Button>
                </div>
              </Tooltip>
            </Stack>
          </Stack>
        </Banner>
      )}
      {filteredSearches && filteredSearches.length > 0 && (
        <ScrollableTable
          table={table}
          sx={{
            borderRadius: "6px",
            border: `1px solid ${colors.grayscale.gray200}`,
            boxShadow: `0px 3px 6px ${colors.grayscale.gray300}`,
          }}
        />
      )}
      {/* TODO: This is a gross conditional -- should simplify */}
      {((!outboundEnabled && searches && searches.length === 0) || isUpdatingJobFeatureSetting) && !!job && (
        <>
          {inJobView ? (
            <BodySmall color={colors.grayscale.gray600} centered>
              No searches found
            </BodySmall>
          ) : (
            <>
              {/* Product ask - should not be able to activate job feature from this button */}
              <GetStartedWithAutopilotButton job={job} />
            </>
          )}
        </>
      )}
    </Stack>
  );
};

export const GetStartedWithAutopilotButton = ({ job }: { job?: DashboardJob }): React.ReactElement => {
  const enableAutopilotV2 = useEnableAutopilotV2(job, false);
  const useSourcingPage = useFeatureFlag(FeatureFlag.SourcingPage);

  return (
    <Stack direction="row" justifyContent="center">
      <Button variant="primaryOutlined" onPress={enableAutopilotV2}>
        {useSourcingPage ? "Create a new search" : "Get Started with Sourcing Autopilot"}
      </Button>
    </Stack>
  );
};

export default SearchesTable;
