/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtensionV2FindEmailsRequest
 */
export interface ExtensionV2FindEmailsRequest {
    /**
     * 
     * @type {string}
     * @memberof ExtensionV2FindEmailsRequest
     */
    linkedinPublicUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtensionV2FindEmailsRequest
     */
    runFullEmailFinding: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExtensionV2FindEmailsRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionV2FindEmailsRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionV2FindEmailsRequest
     */
    mostRecentPositionTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionV2FindEmailsRequest
     */
    mostRecentCompanyName?: string;
}

export function ExtensionV2FindEmailsRequestFromJSON(json: any): ExtensionV2FindEmailsRequest {
    return ExtensionV2FindEmailsRequestFromJSONTyped(json, false);
}

export function ExtensionV2FindEmailsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtensionV2FindEmailsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'linkedinPublicUrl': json['linkedin_public_url'],
        'runFullEmailFinding': json['run_full_email_finding'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'mostRecentPositionTitle': !exists(json, 'most_recent_position_title') ? undefined : json['most_recent_position_title'],
        'mostRecentCompanyName': !exists(json, 'most_recent_company_name') ? undefined : json['most_recent_company_name'],
    };
}

export function ExtensionV2FindEmailsRequestToJSON(value?: ExtensionV2FindEmailsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'linkedin_public_url': value.linkedinPublicUrl,
        'run_full_email_finding': value.runFullEmailFinding,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'most_recent_position_title': value.mostRecentPositionTitle,
        'most_recent_company_name': value.mostRecentCompanyName,
    };
}


