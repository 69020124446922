/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtensionV2Email
 */
export interface ExtensionV2Email {
    /**
     * 
     * @type {string}
     * @memberof ExtensionV2Email
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionV2Email
     */
    maskedEmail: string;
}

export function ExtensionV2EmailFromJSON(json: any): ExtensionV2Email {
    return ExtensionV2EmailFromJSONTyped(json, false);
}

export function ExtensionV2EmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtensionV2Email {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'maskedEmail': json['masked_email'],
    };
}

export function ExtensionV2EmailToJSON(value?: ExtensionV2Email | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'masked_email': value.maskedEmail,
    };
}


