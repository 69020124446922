import { Stack } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { ReactComponent as MinusIcon } from "assets/icons/minus.svg";
import ClientEmailTemplateSelector from "components/dover/EmailTemplates/ClientEmailTemplateSelector";
import { useActionVersion } from "components/dover/top-level-modal-manager/hooks/useActionVersion";
import { useEmailState } from "components/dover/top-level-modal-manager/hooks/useEmailState";
import { useValidate } from "components/dover/top-level-modal-manager/hooks/useValidate";
import {
  CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES,
  CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES,
  CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES,
  CANDIDATE_ACTION_MODAL_MAX_WIDTH,
  CANDIDATE_ACTION_MODAL_TITLE_WEIGHT,
  INCLUDE_EMAIL_BY_DEFAULT_STORAGE_NAME,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/constants";
import { CandidateActionEmailEditor } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor";
import { WarningBanner } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor/WarningBanner";
import { RejectReasonSelect } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/RejectReasonSelect";
import { CandidateActionModalProps } from "components/dover/top-level-modal-manager/types";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import { ScheduledSendButton } from "components/ScheduledSendButton";
import { useCandidateJobId } from "hooks/useCandidateJobId";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useSubmitDecision } from "hooks/useSubmitDecision";
import {
  ArchiveReason,
  ArchiveReasonTypeEnum,
  CandidateActionEmailArgs,
  GetEmailTemplateRequestV2DecisionEnum,
  SubmitDecisionRequestDecisionEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { toastOptions } from "utils/showToast";

export const RejectModal = ({
  isOpen,
  closeModal,
  candidateId,
  archiveContext,
  initialRejectionReason,
}: CandidateActionModalProps): React.ReactElement => {
  const candidateActionVersion = useActionVersion(candidateId);
  const jobId = useCandidateJobId(candidateId);

  const submitDecision = useSubmitDecision();

  const [sendAt, setSendAt] = useState<Date>(new Date());
  const [rejectionReason, setRejectionReason] = useState<ArchiveReason | null>(initialRejectionReason ?? null);
  const [clientEmailTemplateId, setClientEmailTemplateId] = useState<string | null | undefined>(undefined);

  const [savedEmailEditorOpenState, toggleEmailEditor] = useLocalStorage(INCLUDE_EMAIL_BY_DEFAULT_STORAGE_NAME, false);
  const openEmailEditor = savedEmailEditorOpenState && rejectionReason?.type === ArchiveReasonTypeEnum.Rejected;

  const {
    to,
    from,
    setFrom,
    initialSubject,
    subject,
    setSubject,
    initialBody,
    setInitialBody,
    body,
    setBody,
    cc,
    setCc,
    bcc,
    setBcc,
    messageKey,
    threadId,
    isFetching,
    isValidatingLink,
    invalidLink,
    initialClientEmailTemplateId,
  } = useEmailState({ candidateId, clientEmailTemplateId, decision: GetEmailTemplateRequestV2DecisionEnum.Reject });

  const { warning, tooltip, disabledMap, hideEditor } = useValidate(
    openEmailEditor ? "RejectWithEmail" : "RejectWithoutEmail",
    {
      fetchingTemplateAndBioData: isFetching,
      subject,
      to,
      from,
      body,
      isValidatingLink,
      invalidLink,
      rejectionReason,
    }
  );

  const clickToggleEmail = (): void => {
    // When closing the email we are actually unmounting the whole tiptap component
    // These means that when we re-open it (remount it) the state will be set back to the initial body value
    // This means the content shown in the tiptap editor will be out of sync with the body state value
    // This causes issues with the validation logic and also causes the user to lose their work
    // So we want to save their current work before closing it to keep everything in sync
    if (openEmailEditor) {
      setInitialBody(body);
    }

    toggleEmailEditor(!openEmailEditor);
  };

  const onSubmit = useCallback(async () => {
    if (!rejectionReason?.id || !jobId) {
      return;
    }

    // Only send email if the user has edited the email
    const emailArgs: CandidateActionEmailArgs = {
      emailAlias: from.id,
      toEmails: [to.email],
      ccEmails: cc.map(o => o.email),
      bccEmails: [],
      subject,
      body,
      threadId,
      messageKey: messageKey,
      sendAt: sendAt,
    };

    const args = {
      jobId,
      args: {
        id: candidateId,
        data: {
          candidateActionVersion,
          decision: SubmitDecisionRequestDecisionEnum.Reject,
          auto: false,
          emailArgs: openEmailEditor ? emailArgs : undefined,
          archiveReason: rejectionReason.id,
        },
      },
    };

    const submitDecisionPromise = submitDecision(args).unwrap();

    closeModal();

    try {
      await toast.promise(
        submitDecisionPromise,
        {
          error: "Error updating candidate",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    }
  }, [
    body,
    candidateActionVersion,
    candidateId,
    cc,
    closeModal,
    sendAt,
    from.id,
    jobId,
    messageKey,
    openEmailEditor,
    rejectionReason?.id,
    subject,
    submitDecision,
    threadId,
    to.email,
  ]);

  const ActionButtons = useMemo(
    () => (
      <Stack width="100%">
        {!isFetching && <WarningBanner warning={warning} />}
        <Stack width="100%" spacing={2} direction="row" justifyContent="space-between" padding="12px">
          {openEmailEditor && (
            <Stack direction="row" justifyContent="flex-end" alignItems="center">
              <Body>Send </Body>
              <ScheduledSendButton setSendAt={setSendAt} />
            </Stack>
          )}
          <Button
            variant={ButtonVariant.SecondarySuccess}
            onClick={onSubmit}
            disabled={disabledMap.Send}
            tooltip={tooltip}
          >
            {openEmailEditor ? "Send and Submit" : "Submit"}
          </Button>
        </Stack>
      </Stack>
    ),
    [disabledMap.Send, setSendAt, isFetching, onSubmit, openEmailEditor, tooltip, warning]
  );

  let toggleEmailIcon;
  let toggleEmailText;
  if (openEmailEditor) {
    toggleEmailIcon = <MinusIcon color={colors.grayscale.gray600} className="svg-color" />;
    toggleEmailText = "Remove email";
  } else {
    toggleEmailIcon = <AddIcon color={colors.grayscale.gray600} className="svg-color" />;
    toggleEmailText = "Include email";
  }

  return (
    <>
      <CustomModal
        open={isOpen}
        onClose={closeModal}
        title={<Body weight={CANDIDATE_ACTION_MODAL_TITLE_WEIGHT}>Reject</Body>}
        maxWidth={CANDIDATE_ACTION_MODAL_MAX_WIDTH}
        customDialogStyles={CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES}
        customContentStyles={CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES}
        dialogActions={ActionButtons}
        dialogActionsStyles={CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES}
      >
        <Stack spacing={2}>
          <>
            <RejectReasonSelect
              reason={rejectionReason}
              setReason={setRejectionReason}
              archiveContext={archiveContext ?? ArchiveReasonTypeEnum.Rejected}
            />
            {to.email && rejectionReason?.type === ArchiveReasonTypeEnum.Rejected && (
              <Stack spacing={1}>
                <Stack direction="row" justifyContent="space-between">
                  {/* // Include empty div to keep flex spacing */}
                  {openEmailEditor ? <BodySmall weight="500">Template</BodySmall> : <div />}
                  <Button variant={ButtonVariant.Ghost} onClick={clickToggleEmail} removePadding>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      {toggleEmailIcon}
                      <BodySmall color={colors.grayscale.gray600}>{toggleEmailText}</BodySmall>
                    </Stack>
                  </Button>
                </Stack>
                {openEmailEditor && (
                  <ClientEmailTemplateSelector
                    hideLabel
                    clientEmailTemplateId={clientEmailTemplateId ?? initialClientEmailTemplateId}
                    setClientEmailTemplateId={setClientEmailTemplateId}
                  />
                )}
              </Stack>
            )}
          </>
          {!hideEditor && openEmailEditor && (
            <Stack spacing={1}>
              <BodySmall weight="500">Email</BodySmall>
              <CandidateActionEmailEditor
                candidateId={candidateId}
                body={initialBody}
                onBodyChanged={setBody}
                subject={initialSubject}
                onSubjectChanged={setSubject}
                from={from}
                onFromChanged={setFrom}
                to={to}
                cc={cc}
                onCcChanged={setCc}
                bcc={bcc}
                onBccChanged={setBcc}
                disableFrom={disabledMap.From}
                disableTo={disabledMap.To}
                disableCc={disabledMap.CC}
                disableBcc={disabledMap.BCC}
                disableSubject={disabledMap.Subject}
                disableBody={disabledMap.Body}
                loading={isFetching}
              />
            </Stack>
          )}
        </Stack>
      </CustomModal>
    </>
  );
};
