import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { TextField } from "components/library/TextField";
import { Body } from "components/library/typography";
import { useLocalStorage } from "hooks/useLocalStorage";
import { SubmitReferralFormSchemaType } from "views/ReferralForm/types";

interface ReferrerFormProps {
  authedUserFirstName?: string;
  authedUserLastName?: string;
  authedUserEmail?: string;
  onNext: () => void;
}

export const ReferrerForm = ({
  authedUserFirstName,
  authedUserLastName,
  authedUserEmail,
  onNext,
}: ReferrerFormProps): React.ReactElement => {
  const {
    register,
    formState: { errors: formErrors },
    setValue,
    getValues,
    trigger,
  } = useFormContext<SubmitReferralFormSchemaType>();

  const [savedReferrerFirstName, setSavedReferrerFirstName] = useLocalStorage("referrerFirstName", authedUserFirstName);
  const [savedReferrerLastName, setSavedReferrerLastName] = useLocalStorage("referrerLastName", authedUserLastName);
  const [savedReferrerEmail, setSavedReferrerEmail] = useLocalStorage("referrerEmail", authedUserEmail);

  useEffect(() => {
    setValue("referrerFirstName", savedReferrerFirstName || "");
    setValue("referrerLastName", savedReferrerLastName || "");
    setValue("referrerEmail", savedReferrerEmail || "");
  }, [savedReferrerFirstName, savedReferrerLastName, savedReferrerEmail, setValue]);

  const pageIsValid = async (): Promise<boolean> => {
    const isValid = await trigger(["referrerFirstName", "referrerLastName", "referrerEmail"]);
    return isValid;
  };

  const handleNext = async (): Promise<void> => {
    setSavedReferrerFirstName(getValues("referrerFirstName"));
    setSavedReferrerLastName(getValues("referrerLastName"));
    setSavedReferrerEmail(getValues("referrerEmail"));

    if (await pageIsValid()) {
      onNext();
    }
  };

  return (
    <Card fullWidth>
      <Stack spacing={2}>
        <Body weight="600">Enter your information as the referrer</Body>
        <TextField
          title={`First name`}
          required={true}
          inputProps={{ ...register("referrerFirstName") }}
          errorMessage={formErrors.referrerFirstName?.message}
          error={!!formErrors.referrerFirstName}
          autoFocus={!savedReferrerFirstName}
        />
        <TextField
          title={`Last Name`}
          required={true}
          inputProps={{ ...register("referrerLastName") }}
          errorMessage={formErrors.referrerLastName?.message}
          error={!!formErrors.referrerLastName}
        />
        <TextField
          title={`Email`}
          required={true}
          inputProps={{ ...register("referrerEmail") }}
          errorMessage={formErrors.referrerEmail?.message}
          error={!!formErrors.referrerEmail}
        />
        <Stack alignItems={"flex-end"}>
          <Button width="fit-content" variant={ButtonVariant.Primary} onClick={handleNext}>
            Next
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};
