/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExtensionV2Email,
    ExtensionV2EmailFromJSON,
    ExtensionV2EmailFromJSONTyped,
    ExtensionV2EmailToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExtensionV2FindEmailsResponse
 */
export interface ExtensionV2FindEmailsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ExtensionV2FindEmailsResponse
     */
    success: boolean;
    /**
     * 
     * @type {Array<ExtensionV2Email>}
     * @memberof ExtensionV2FindEmailsResponse
     */
    emails: Array<ExtensionV2Email>;
}

export function ExtensionV2FindEmailsResponseFromJSON(json: any): ExtensionV2FindEmailsResponse {
    return ExtensionV2FindEmailsResponseFromJSONTyped(json, false);
}

export function ExtensionV2FindEmailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtensionV2FindEmailsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'emails': ((json['emails'] as Array<any>).map(ExtensionV2EmailFromJSON)),
    };
}

export function ExtensionV2FindEmailsResponseToJSON(value?: ExtensionV2FindEmailsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'emails': ((value.emails as Array<any>).map(ExtensionV2EmailToJSON)),
    };
}


