import { Stack } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

import { ReactComponent as LigntningSVG } from "assets/icons/green-lightning.svg";
import { FileDropzone, nameLengthValidator } from "components/FileDropzone";
import { BodySmall } from "components/library/typography";
import { TWENTY_MEGA_BYTES } from "sections/addCandidateV2";
import { FilePreview } from "sections/addCandidateV2/components/ResumeUploadBox";
import { useParseCandidateInfoMutation } from "services/doverapi/endpoints/candidate";
import { ParseCandidateInfoResult } from "services/openapi";
import { colors } from "styles/theme";

interface BasicResumeUploaderProps {
  onClearUpload: () => void;
  onSuccessfulUpload: (acceptedFiles: File) => void;
  onParseSuccessful: (result: ParseCandidateInfoResult) => void;
}

export const useBasicResumeUploader = (props: BasicResumeUploaderProps): [boolean, React.ReactElement] => {
  const [, { isLoading: isParsingResume }] = useParseCandidateInfoMutation({
    fixedCacheKey: "parseCandidateInfo",
  });

  return [isParsingResume, <BasicResumeUploader {...props} />];
};

export const BasicResumeUploader = ({
  onSuccessfulUpload,
  onParseSuccessful,
  onClearUpload,
}: BasicResumeUploaderProps): React.ReactElement => {
  const [resume, setResume] = useState<File | undefined>(undefined);

  const [parseCandidateInfo] = useParseCandidateInfoMutation({
    fixedCacheKey: "parseCandidateInfo",
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]): void => {
      // This function still runs even if all files were rejeted
      if (acceptedFiles.length === 0) {
        return;
      }

      // set local state
      setResume(acceptedFiles[0]);
      onSuccessfulUpload(acceptedFiles[0]);

      const parseResult = parseCandidateInfo({ resume: acceptedFiles[0] }).unwrap();
      parseResult.then((result: ParseCandidateInfoResult): void => {
        onParseSuccessful(result);
      });
    },
    [parseCandidateInfo, onSuccessfulUpload, onParseSuccessful]
  );

  const dropzoneOptions = useMemo(
    () => ({
      onDrop,
      maxFiles: 1,
      maxSize: TWENTY_MEGA_BYTES,
      validator: nameLengthValidator,
      accept: ".pdf",
    }),
    [onDrop]
  );

  return resume ? (
    <FilePreview
      fileName={resume.name}
      onDelete={(): void => {
        setResume(undefined);
        onClearUpload();
      }}
    />
  ) : (
    <Stack direction="column" spacing={0.8}>
      <FileDropzone options={dropzoneOptions} />
      <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
        <LigntningSVG width="20px" height="20px" />
        <BodySmall color={colors.grayscale.gray500}>Auto-fill candidate info from their resume</BodySmall>
      </Stack>
    </Stack>
  );
};
