import { Stack } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

import { useBasicResumeUploader } from "components/BasicResumeUploader";
import JobSelector from "components/JobSelector/JobSelector";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { TextField } from "components/library/TextField";
import { Body } from "components/library/typography";
import { ParseCandidateInfoResult } from "services/openapi";
import { ReferralJob } from "views/ReferralForm";
import { SubmitReferralFormSchemaType } from "views/ReferralForm/types";

interface CandidateFormProps {
  jobs: ReferralJob[];
  onNext: () => void;
}

export const CandidateForm = ({ jobs, onNext }: CandidateFormProps): React.ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    setValue,
    getValues,
  } = useFormContext<SubmitReferralFormSchemaType>();

  const onSuccessfulUpload = (resume: File): void => {
    setValue("resume", resume);
  };

  const onParseSuccessful = (result: ParseCandidateInfoResult): void => {
    if (result.firstName && !getValues("candidateFirstName")) {
      setValue("candidateFirstName", result.firstName);
    }
    if (result.lastName && !getValues("candidateLastName")) {
      setValue("candidateLastName", result.lastName);
    }
    if (result.email && !getValues("candidateEmail")) {
      setValue("candidateEmail", result.email);
    }
    if (result.linkedinUrl && !getValues("candidateLinkedinUrl")) {
      setValue("candidateLinkedinUrl", result.linkedinUrl);
    }
  };

  const onClearUpload = (): void => {
    setValue("resume", undefined);
  };

  const [isParsingResume, basicResumeUploader] = useBasicResumeUploader({
    onSuccessfulUpload,
    onParseSuccessful,
    onClearUpload,
  });

  const addReferralCandidate = async (): Promise<void> => {
    console.log("submitting form", getValues());
    // after success API, go next
    onNext();
  };

  const onSubmitClick = async (): Promise<void> => {
    handleSubmit(addReferralCandidate)();
  };

  const jobId = getValues("jobId");

  return (
    <Card fullWidth>
      <Stack spacing={2}>
        <Body weight="600">Enter referral candidate details</Body>
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
            <Body weight="500">Resume</Body>
            <Body>(optional)</Body>
          </Stack>
          {basicResumeUploader}
        </Stack>
        <TextField
          title={`First name`}
          required={true}
          inputProps={{ ...register("candidateFirstName") }}
          errorMessage={formErrors.candidateFirstName?.message}
          error={!!formErrors.candidateFirstName}
          autoFocus
          loading={isParsingResume}
        />
        <TextField
          title={`Last Name`}
          required={true}
          inputProps={{ ...register("candidateLastName") }}
          errorMessage={formErrors.candidateLastName?.message}
          error={!!formErrors.candidateLastName}
          loading={isParsingResume}
        />
        <TextField
          title={`Email`}
          required={true}
          inputProps={{ ...register("candidateEmail") }}
          errorMessage={formErrors.candidateEmail?.message}
          error={!!formErrors.candidateEmail}
          loading={isParsingResume}
        />
        <TextField
          title={`LinkedIn URL`}
          placeholderText="https://linkedin.com/in/your-referral"
          required={true}
          inputProps={{ ...register("candidateLinkedinUrl") }}
          errorMessage={formErrors.candidateLinkedinUrl?.message}
          error={!!formErrors.candidateLinkedinUrl}
          loading={isParsingResume}
        />
        <Stack spacing={0.5}>
          <Body weight="500">Job</Body>

          <JobSelector
            jobs={jobs}
            jobId={jobId}
            setJobId={(jobId: string): void => setValue("jobId", jobId, { shouldValidate: true })}
          />
        </Stack>
        <TextField
          multiline
          minRows={3}
          title={`Additional Comments`}
          required={true}
          inputProps={{ ...register("additionalComments") }}
          errorMessage={formErrors.additionalComments?.message}
          error={!!formErrors.additionalComments}
        />
        <Stack alignItems={"flex-end"} minHeight={"43px"}>
          <Button
            variant={ButtonVariant.Primary}
            onClick={onSubmitClick}
            disabled={!jobId}
            tooltip={!jobId ? "Select a job to continue" : ""}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};
